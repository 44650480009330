import React, { useState, useEffect } from "react";
import { Grid, Container, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import attachTokenProofsService from "Services/attachTokenProofsService";
import MultipleDocumentUpload from "Components/MultipleDocumentUpload";
import { useTranslate } from "react-admin";
import Loader from "Components/Loader";

export const AttachProofTab = ({ notification, state, onClickBack, headerPayload, bookingFailedPopup,
  setBookingFailedPopup, portal_booking_id, units }) => {

  const [tokenPaymentProofDocFiles, setTokenPaymentProofDocFiles] = useState([]);
  const [updateLoading, setUpdateLoading] = useState();
  const [tokenPaymentProofDocumentArray, setTokenPaymentProofDocumentArray] = useState([]);
  const [additionalDocumentError, setAdditionalDocumentError] = useState({ error: false, message: "" });
  const [attachTokenProofLoading, setAttachTokenProofLoading] = useState(false);
  const navigate = useNavigate();
  const translate = useTranslate();

  const setDocumentError = () => {
    if (tokenPaymentProofDocFiles?.length === 0) {
      setAdditionalDocumentError({
        error: true,
        message: translate("ra.notification.uploadTokenProof"),
      })
    }
  }

  useEffect(() => {
    tokenPaymentProofDocFiles?.length > 0 && setAdditionalDocumentError({ error: false, message: null });
    tokenPaymentProofDocFiles?.length === 0 && additionalDocumentError?.message === null && setDocumentError();
  }, [tokenPaymentProofDocFiles?.length])

  const handleSubmit = () => {
    let formData = new FormData();
    let fileDataList;
    fileDataList = [...tokenPaymentProofDocFiles];

    fileDataList.map((val) => {
      formData.append('file', val)
    })

    let data = {
      project_id: state?.project_id,
      unit_ids: state?.units?.map(unit => unit?.officE_SECTION_ID)?.join(",")
    }

    formData.append('payload', JSON.stringify(data))

    if (tokenPaymentProofDocFiles?.length === 0) {
      setDocumentError();
    }
    else {
      attachTokenProofsService(formData,
        setAttachTokenProofLoading,
        notification, navigate, JSON.stringify(headerPayload), state?.units, state?.project_id, bookingFailedPopup,
        setBookingFailedPopup)
    }
  }

  return (
    <>
      {(attachTokenProofLoading || updateLoading ) && <Loader />}
      <Container className="attach-proof-section">
        <h3>{translate("ra.pageTitle.adjustTokenPaymnetProof")}</h3>
        <Grid className="upload-section" container spacing={0}>
          <MultipleDocumentUpload
            fileType="additional"
            documentFilesArray={tokenPaymentProofDocFiles}
            setDocumentFilesArray={setTokenPaymentProofDocFiles}
            documentObjectArray={tokenPaymentProofDocumentArray}
            setDocumentObjectArray={setTokenPaymentProofDocumentArray}
            notification={notification}
            error={additionalDocumentError?.error}
            message={additionalDocumentError?.message}
          />
        </Grid>
        <Container className="token-payment-button-panel">
          <Grid container className="company-details-button token-payment-button-section" spacing={0}>
            <Button className="mobile-only" variant="outlined" onClick={() => onClickBack()}>
              {translate("ra.button.back")}</Button>
            <Button variant="contained"
              onClick={() => handleSubmit()}
            >
              {translate("ra.button.submit")}
            </Button>
          </Grid>
        </Container>
      </Container>
    </>
  );
};
