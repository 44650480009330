const passwordPattern =
  /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[`!@#$%^&*()_+=-{}[\]\\|;:'",.<>/?])(?=.*[a-zA-Z]).{6,15}$/
const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const numberTextPattern = /^[a-zA-Z0-9]*$/
const textPattern = /^[a-zA-Z]*$/
const textWithSpacePattern = /^[a-zA-Z\s]*$/
const companyNamePattern =
  /^(?!\s)(?!.*\s$)[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?\s]*$/

export {
  passwordPattern,
  emailPattern,
  numberTextPattern,
  textPattern,
  companyNamePattern,
  textWithSpacePattern,
}
