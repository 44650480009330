import React from "react";
import { Typography, MenuItem, TextField } from "@mui/material";
import { useTranslate } from "react-admin";

const Title = ({
  defaultValue,
  value,
  name,
  register,
  watch,
  errors,
  dropdownValue,
  title,
  className,
  labelName,
  onChange,
  hide = false,
  id,
  inputProps,
  message,
  sx={
    "& legend": { display: "none" },
    width: "100%",
  },
  labelhide= false,
  indexValue,
  setOpenDrop
}) => {
  const translate = useTranslate();

  return (
    <>
    {title &&
      <Typography variant="subtitle1">
        {title}
        {!hide && <span style={{ color: "#EF4349" }}>*</span>}
      </Typography>
    }
    <TextField
      select
      id={id}
      defaultValue={defaultValue}
      value={value}
      variant="outlined"
      label={!labelhide ? ((watch(name) || value) ? "" : labelName) : ""}
      InputLabelProps={{ shrink: false }}
      name={name}
      className={`${className} dropdown-icon-wrapper`}
      inputProps={inputProps}
      {...register(name, {
        required: !value && (message || translate("ra.validation.selectAny")),
      })}
      sx={sx}
      error={Boolean(errors[name])}
      helperText={errors[name]?.message}
      SelectProps={{
        IconComponent : () => <i className="iconwasalt icon-arrow-down" />,
        onOpen : () => setOpenDrop && setOpenDrop(true),
        onClose : () => setOpenDrop && setOpenDrop(false)
      }}
    >
     {dropdownValue.map(
       (item, idx) => {
         return (
           <MenuItem
             key={idx}
             value={indexValue ? (idx + 1) : (item?.value || item)}
             onClick={() => onChange ? onChange({
              target : {name : name, value : item?.value || item}
             }) : ""}
             {...register(name)}
           >
             {item?.name || item}
           </MenuItem>
         );
       }
     )}
    </TextField>
    </>
  );
};

export default Title;
