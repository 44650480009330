import React, { useEffect, useState } from "react";
import {
  useList,
  ListContextProvider,
  Datagrid,
  TextField,
  useTranslate,
  useRedirect,
  WrapperField,
  FunctionField,
  TextInput,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  useMediaQuery,
  Container,
} from "@mui/material";
import Loader from "Components/Loader";
import { useLocation } from "react-router-dom";
import FilterSearch from "Pages/FilterSection/FilterSearch";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import InfiniteScroll from "react-infinite-scroll-component";
import { getSalesperson } from "Services/leadService";
import { getBookingList } from "Services/bookingService";
import { removeLocalStorage } from "Utils/removeStorage";
import { takeActionDetails } from "Pages/MyBooking/takeAction";

const BookingHistoryDetails = ({
  currentPage,
  userData,
  notification,
  setTotalCount,
}) => {
  const [filterList, setFilterList] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [value, setValue] = useState("1");
  const [infiniteLoading, setInfintieLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [salesPersonList, setSalesPersonList] = useState([]);
  const [bookingDetails, setBookingDetails] = useState([]);
  const [bookingLoading, setBookingLoading] = useState(true);
  const [tabChange, setTabChange] = useState(null);
  const [bookingScroll, setBookingScroll] = useState(false);
  const listContext = useList({ data: bookingDetails });
  const translate = useTranslate();
  const redirect = useRedirect();
  const navigate = useNavigate();
  const location = useLocation();
  const param = new URLSearchParams(location?.search);
  const params = Object.fromEntries(param.entries());
  const matches = useMediaQuery("(max-width:600px)");
  const tabDetail = parseInt(param?.get("tab")) || null;

  const bookingDataApi = async () => {
    const salesList = await getSalesperson(
      "",
      setSalesPersonList,
      notification
    );
    let tabName = "";
    if (currentPage === "my-bookings") {
      if (!tabDetail) {
        tabName = 1;
        setTabChange(1);
        setValue("1");
      }
      if (tabDetail == "2") {
        tabName = 2;
        setTabChange(2);
        setValue("2");
      }
      if (tabDetail == "3") {
        tabName = "";
        setTabChange(null);
        setValue("3");
      }
    }
    bookingListCall(
      setBookingLoading,
      0,
      params,
      setFilterList,
      salesList,
      "",
      tabName
    );
  };

  const bookingListCall = (
    setLoadingData,
    page,
    queryData,
    setFilter,
    salesList,
    loadType,
    tabName
  ) => {
    getBookingList(
      setLoadingData,
      bookingDetails,
      setBookingDetails,
      setTotalCount,
      setCount,
      page,
      notification,
      queryData,
      setFilter,
      salesList,
      loadType,
      translate,
      tabName,
      "booking"
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setHasMore(true);
    let searchParam = param;
    let newParams = "";
    if (newValue === "1") {
      searchParam.delete("tab");
      newParams = searchParam.toString();

      bookingListCall(setBookingLoading, 0, params, "", "", "", 1);
      setTabChange(1);
    }
    if (newValue === "2") {
      searchParam.set("tab", "2");
      newParams = searchParam.toString();

      bookingListCall(setBookingLoading, 0, params, "", "", "", 2);
      setTabChange(2);
    }
    if (newValue === "3") {
      searchParam.set("tab", "3");
      newParams = searchParam.toString();

      bookingListCall(setBookingLoading, 0, params);
      setTabChange(null);
    }
    if (newValue === "4") {
      searchParam.set("tab", "4");
      newParams = searchParam.toString();
      bookingListCall(setBookingLoading, 0, params, "", "", "", 3);
      setTabChange(4);
    }

    navigate(`/my-bookings?${newParams}`, { replace: true });
  };

  const getBookingData = async () => {
    if (!infiniteLoading) {
      if (bookingDetails && bookingDetails?.length < count) {
        bookingListCall(
          setInfintieLoading,
          page + 1,
          params,
          "",
          "",
          "infiniteLoad",
          tabChange
        );
        setPage(page + 1);
      } else {
        setHasMore(false);
      }
    }
  };

  const tableRedirectPath = (record) => {
    localStorage.setItem("booking_page", `/${currentPage}${location?.search}`);
    redirect(`/booking-details?id=${record?.booking_id}`);
  };

  // setting header section sticky to top when scroll
  useEffect(() => {
    removeLocalStorage();
    localStorage.removeItem("page");
    localStorage.removeItem("params");
    localStorage.removeItem("page-redirect");
    localStorage.removeItem("customerType");
    localStorage.removeItem("booking_page");
    bookingDataApi();

    window.addEventListener("scroll", function () {
      let bookingId = document.getElementById("booking-tab-id");

      if (bookingId) {
        if (currentPage === "my-bookings" && matches) {
          if (document.documentElement.scrollTop >= 150) {
            bookingId.classList.add("booking-tab-sticky");
          } else {
            bookingId.classList.remove("booking-tab-sticky");
          }
        }
        if (currentPage === "my-bookings" && !matches) {
          if (document.documentElement.scrollTop >= 130) {
            bookingId.classList.add("booking-desktop-tab-sticky");
            setBookingScroll(true);
          } else {
            bookingId.classList.remove("booking-desktop-tab-sticky");
            setBookingScroll(false);
          }
        }
      }
    });
  }, []);
  return (
    <>
      {bookingLoading && <Loader />}
      <Grid
        container
        className={`booking-history-search contact-form-section 
        ${currentPage !== "my-bookings" ? "booking-list-margin" : ""} 
        ${filterList?.length > 0 ? "contact-padding-adjust" : ""}`}
        spacing={0}
      >
        <FilterSearch
          contactList={bookingDetails}
          filterList={filterList}
          setFilterList={setFilterList}
          className="filter-autocomplete"
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          salesPersonList={salesPersonList}
          setContactLoading={setBookingLoading}
          pageName="booking"
          userData={userData}
          path={currentPage}
          detailsApi={bookingListCall}
          setPage={setPage}
          setHasMore={setHasMore}
          params={params}
          tabChange={tabChange}
        />
      </Grid>
      <Grid className="unit-list-panel contacts-table-view booking-table-view booking-details-section">
        {!bookingLoading &&
          (filterList?.length > 0 || searchValue) &&
          count > 0 && (
            <Typography
              variant="h3"
              className="search-result-no"
              component="h3"
            >
              {count} {translate("ra.fieldName.bookingsFound")}
            </Typography>
          )}
        {currentPage === "my-bookings" && (
          <Container
            id="booking-tab-id"
            className="company-details customer-details-panel"
          >
            <Grid
              className="form-panel profile-form-panel"
              sx={{ width: "100%" }}
            >
              <Grid
                container
                className="profile-bottom-panel booking-details-tab"
              >
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "#E9E9E9" }}>
                      <TabList
                        onChange={handleChange}
                        aria-label="details tab"
                        className="booking-tab-list-heading"
                      >
                        <Tab
                          label={translate("ra.fieldName.pending")}
                          value="1"
                        />
                        <Tab
                          label={translate("ra.fieldName.completed")}
                          value="2"
                        />
                        <Tab
                          label={translate("ra.fieldName.Cancelled")}
                          value="4"
                        />

                        <Tab label={translate("ra.fieldName.all")} value="3" />
                      </TabList>
                    </Box>
                  </TabContext>
                </Box>
              </Grid>
            </Grid>
          </Container>
        )}
        {!bookingLoading && (
          <InfiniteScroll
            dataLength={bookingDetails && bookingDetails?.length}
            next={getBookingData}
            scrollThreshold={0.7}
            hasMore={hasMore}
            loader={
              infiniteLoading && (
                <div className="my-team-loader">
                  {translate("ra.fieldName.loadMore")}
                </div>
              )
            }
          >
            {bookingDetails?.length > 0 ? (
              <>
                {!matches ? (
                  <Grid
                    className={`my-team-panel unit-list-view ${
                      bookingScroll ? "booking-table-padding" : ""
                    }`}
                  >
                    <ListContextProvider value={listContext}>
                      <Datagrid
                        bulkActionButtons={false}
                        className="hover-unit-class table-header-font"
                        setSort={false}
                        rowClick={(id, resource, record) =>
                          tableRedirectPath(record)
                        }
                      >
                        <TextField
                          label={translate("ra.fieldName.bookingDetails")}
                          source="title"
                        />

                        <WrapperField label={translate("ra.fieldName.status")}>
                          <FunctionField
                            render={(record) => {
                              const cancellation_count =
                                record?.cancellation_count;

                              return (
                                <>
                                  <TextField
                                    className={`payment-info ${
                                      record?.statusDetail === "Completed"
                                        ? "payment-info-success"
                                        : ""
                                    }`}
                                    source={
                                      cancellation_count > 0 &&
                                      (value === "4" || value === "3")
                                        ? "cancellation_count_message"
                                        : "status"
                                    }
                                  />
                                </>
                              );
                            }}
                          />
                          <div>
                            {translate("ra.fieldName.lastUpdated")}{" "}
                            <TextField source="last_updated" emptyText="-" />
                          </div>
                        </WrapperField>
                        <FunctionField
                          label={translate("ra.fieldName.units")}
                          render={(record) =>
                            `${record?.unit} ${
                              record?.unit > 1
                                ? translate("ra.fieldName.units")
                                : translate("ra.fieldName.unit")
                            }`
                          }
                        ></FunctionField>
                        <FunctionField
                          label={translate("ra.fieldName.actions")}
                          render={(record) => (
                            <Button
                              onClick={(e) =>
                                takeActionDetails(
                                  e,
                                  record,
                                  translate,
                                  navigate,
                                  currentPage,
                                  location
                                )
                              }
                              disabled={
                                record?.buttonBlock === "Completed" ||
                                (record?.cancellation_count === record?.unit &&
                                  (value === "4" || value === "3"))
                              }
                              className={`booking-take-action ${
                                record?.buttonBlock === "Completed" ||
                                (record?.cancellation_count === record?.unit &&
                                  (value === "4" || value === "3"))
                                  ? "booking-disable-button"
                                  : ""
                              }`}
                            >
                              {translate("ra.button.takeAction")}
                            </Button>
                          )}
                        ></FunctionField>
                      </Datagrid>
                    </ListContextProvider>
                  </Grid>
                ) : (
                  <Grid className="unit-list-mobile booking-list-mobile booking-history-mobile-new">
                    {bookingDetails?.map((list, idx) => (
                      <Typography
                        key={idx}
                        className="unit-list-info"
                        component="div"
                        onClick={() => tableRedirectPath(list)}
                      >
                        <Typography className="heading-info" component="div">
                          <Typography variant="h2" component="h2">
                            {list?.title}
                          </Typography>
                          <Typography className="my-contact-unit-info">
                            {list?.unit > 1 && (
                              <span className="unit-info">
                                {list?.unit} {translate("ra.fieldName.units")}
                              </span>
                            )}
                            <i className="iconwasalt icon-chevron-right" />
                          </Typography>
                        </Typography>
                        <Typography
                          variant="h3"
                          component="h3"
                          className={`payment-info ${
                            list?.statusDetail === "Completed"
                              ? "payment-info-success"
                              : ""
                          }`}
                        >
                          <span>{list?.status}</span>
                        </Typography>
                        <Typography
                          className="update-info"
                          variant="h3"
                          component="h3"
                        >
                          <span>
                            {translate("ra.fieldName.lastUpdated")}{" "}
                            {list?.last_updated}
                          </span>
                        </Typography>
                      </Typography>
                    ))}
                  </Grid>
                )}
              </>
            ) : (
              <Grid className="no-data-section">
                {translate("ra.noData.booking")}
              </Grid>
            )}
          </InfiniteScroll>
        )}
      </Grid>
    </>
  );
};

export default BookingHistoryDetails;
