import React, { useEffect, useState } from 'react'
import { useRedirect, useTranslate } from 'react-admin'
import {
  Grid,
  Container,
  Typography,
  Pagination,
  useMediaQuery,
} from '@mui/material'
import Header from 'Components/Header'
import CampaignHeader from 'Components/CampaignHeader'
import CardWithSlider from 'Components/CardWithSlider'
import SearchFilter from 'Components/SearchFilter'
import { bannerDetails } from 'Services/bannerService'
import Loader from 'Components/Loader'
import { filterDetails, projectListDetails } from 'Services/projectService'
import {
  filterProjectList,
  projectListCount,
  getProjectListDetails,
} from 'Services/Salesforce/projectService'
import styles from './styles.module.scss'
import userDetails from 'Services/userDetailsServices'
import { useLocation, useNavigate } from 'react-router-dom'
import { createQueryParams } from 'Utils/queryValues'
import { currencyStatus } from 'Utils/currencyCheck'
import gtagEventFire from 'Utils/ga4EventTrigger'
import useWebVitals from 'Utils/useWebVitals'
import { Button } from '@material-ui/core'
import { clearLocalStorageValues } from 'Utils/removeStorage'

const Home = ({ notification }) => {
  const [projectDetails, setProjectDetails] = useState([])
  const [bannerData, setBannerData] = useState([])
  const [filterData, setFilterData] = useState([])
  const [bannerLoading, setBannerLoading] = useState(true)
  const [projectLoading, setProjectLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [filterLoading, setFilterLoading] = useState(false)
  const [userData, setUserData] = useState(null)
  const [page, setPage] = useState(1)
  const [filterList, setFilterList] = useState([])
  const [projectCount, setProjectCount] = useState(0)
  const [totalNoOfRecords, setTotalNoOfRecords] = useState(null)
  const pageCount = 12 // count per page view

  const redirect = useRedirect()
  const navigate = useNavigate()
  const location = useLocation()
  const translate = useTranslate()
  const param = new URLSearchParams(location?.search)
  const params = Object.fromEntries(param.entries())
  // changing pagination design based on mobile and desktop
  const matches = useMediaQuery('(max-width:600px)')
  const pageType = 'Home'


  const endCountVlaue = Math.min(page * pageCount, totalNoOfRecords)

  useWebVitals(pageType)

  const listingDetails = async () => {
    const userDetail = await userDetails(
      setLoading,
      setUserData,
      '',
      notification
    )
    projectListApi(userDetail, params, setProjectCount)
  }

  const projectListApi = async (userDetail, params, setCount) => {
    const pageNo = parseInt(params?.page) || 1
    setPage(pageNo)
    getProjectListDetails(
      setProjectLoading,
      setProjectDetails,
      userDetail,
      params,
      notification,
      setCount,
      setTotalNoOfRecords
    )
  }

  const projectDataCount = (setLoader, setDataCount, urlParam) => {
    projectListCount(setLoader, setDataCount, userData, urlParam, notification)
  }

  useEffect(() => {

    //clearing local storage value
    clearLocalStorageValues();

    bannerDetails(setBannerLoading, setBannerData, notification)
    filterProjectList(
      setFilterLoading,
      setFilterData,
      notification,
      params,
      setFilterList
    )

    listingDetails()
    document.body.classList.add('home-page')
    return () => {
      document.body.classList.remove('home-page')
    }
  }, [])

  const handlePageChange = async (e, page) => {
    setPage(page)
    const filterUrl = createQueryParams(
      [],
      params?.sort,
      page,
      params?.countrysearch,
      params
    )

    navigate(
      {
        pathname: '/home',
        search: filterUrl?.url,
        hash: '#',
      },
      { replace: true }
    )
    projectListApi(userData, filterUrl?.params)
  }

  const redirectPath = (id, name) => {
    gtagEventFire('property-card-click', {
      property_name: name,
      user_name: userData?.email,
    })
    redirect({
      pathname: '/property-details',
      search: `projectid=${id}`,
      hash: '#',
    });
    

  }


  return (
    <>
      <Header
        picture={userData?.profile}
        pictureLoading={loading}
        page="home"
        notification={notification}
        userData={userData}
        path="Home"
      />
      {(bannerLoading || filterLoading || projectLoading || loading) && (
        <Loader />
      )}


      {!bannerLoading && !filterLoading && (
        <>
          <CampaignHeader bannerData={bannerData} />
          <SearchFilter
            filterData={filterData}
            userData={userData}
            projectListApi={projectListApi}
            params={params}
            filterList={filterList}
            setFilterList={setFilterList}
            getDataCount={projectDataCount}
            count={projectCount}
            translate={translate}
            notification={notification}
          />
          <Typography
            className="available-panel home-available-panel for-mobile"
            component="div"
          >
            <Typography variant="h2" component="h2" className="total-result">
              {translate('ra.fieldName.availableProject')} (
              {totalNoOfRecords})
            </Typography>
          </Typography>
          <Container className="card-panel home-card-panel card-height-600">
            <Typography className="available-panel for-desktop" component="div">
              <Typography variant="h2" component="h2" className="total-result">
                {translate('ra.fieldName.availableProject')} (
                {totalNoOfRecords})
              </Typography>
            </Typography>
            {!projectLoading &&
              (projectDetails?.length > 0 ? (
                <>
                  <Grid className="grid-3 pointer-info" id="project">
                    {projectDetails?.map((project, idx) => (
                      <CardWithSlider
                        key={project?.project_id}
                        userData={userData}
                        id={project?.project_id}
                        location={project?.location}
                        project_name={project?.project_name}
                        start_price={project?.start_price}
                        images={project?.images}
                        redirectPath={redirectPath}
                        currency={
                          currencyStatus(userData?.currency)
                            ? userData?.currency
                            : project?.currency
                        }
                        type="project-list"
                        translate={translate}
                      />
                    ))}
                  </Grid>
                  {totalNoOfRecords > pageCount && (
                    <>
                      <Pagination
                        className={styles.pagination_class}
                        variant="outlined"
                        count={Math.ceil(totalNoOfRecords / pageCount)}
                        siblingCount={1}
                        boundaryCount={matches ? 0 : 2}
                        page={page}
                        onChange={handlePageChange}
                      />
                      <Typography className="pagination-counter">
                        {translate('ra.fieldName.pageCount', {
                          startCount: (page - 1) * pageCount + 1,
                          // endCount: page * pageCount,  //old
                          endCount: endCountVlaue,
                          totalCount: totalNoOfRecords,
                        })}
                      </Typography>
                    </>
                  )}
                </>
              ) : (
                <Typography className="no_data">
                  {translate('ra.noData.project')}
                </Typography>
              ))}
          </Container>
        </>
      )}
    </>
  )
}

export default Home
