import React, { useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import { Grid, Container, Typography, Box, Tab } from "@mui/material";
import Header from "Components/Header";
import BackButton from "Components/BackButton";
import Loader from "Components/Loader";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useLocation, useNavigate } from "react-router-dom";
import BookingDetailsTab from "../BookingDetailsTab";
import DownloadsTab from "../DownloadsTab";
import StatusTab from "../StatusTab";
import { getBookingDetails } from "Services/bookingService";
import Error from "Pages/Error";
import { removeLocalStorage } from "Utils/removeStorage";
import { projectDetails } from "Services/projectService";
import useWebVitals from "Utils/useWebVitals";
const BookingData = ({
  notification,
  userLoading,
  userData,
  bookingFailedPopup,
  setBookingFailedPopup,
}) => {
  const [value, setValue] = useState("2");
  const [loading, setLoading] = useState(true);
  const [projectLoading, setProjectLoading] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [projectInfoDetails, setProjectInfoDetails] = useState([]);
  const [docSeen, setDocSeen] = useState(false);
  const translate = useTranslate();
  const location = useLocation();
  const param = new URLSearchParams(location?.search);
  const id = parseInt(param?.get("id"));
  const tabDetail = parseInt(param?.get("tab"));
  const navigate = useNavigate();

  const projectData = (res) => {
    projectDetails(
      setProjectLoading,
      setProjectInfoDetails,
      notification,
      res?.projectInfo?.projectId
    );
  };
  const pageType = "booking-details";
  useWebVitals(pageType);
  useEffect(() => {
    removeLocalStorage();
    localStorage.removeItem("page");
    localStorage.removeItem("params");
    localStorage.removeItem("page-redirect");
    localStorage.removeItem("customerType");
    getBookingDetails(
      setLoading,
      id,
      setBookingDetails,
      notification,
      projectData,
      bookingFailedPopup,
      setBookingFailedPopup,
      setDocSeen
    );

    if (tabDetail == 1) {
      setValue("1");
    }
    if (tabDetail == 2) {
      setValue("2");
    }
    if (tabDetail == 3) {
      setValue("3");
    }

    document.body.classList.add("profile-page");
    return () => {
      document.body.classList.remove("profile-page");
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabSwitchHandler = (tabChange) => {
    navigate(`/booking-details?id=${id}&tab=${tabChange}`, { replace: true });
  };

  return (
    <>
      {(userLoading || loading || projectLoading) && <Loader />}
      {!id ? (
        <Error />
      ) : (
        <>
          <Header
            path="My Bookings"
            picture={userData?.profile}
            pictureLoading={userLoading}
            notification={notification}
            userData={userData}
          />
          <BackButton />
          <Container className="company-container my-contacts-section  booking-details-page booking-details-wrapper pt-0 mt-0">
            <Container className="company-details customer-details-panel booking-details-info">
              {!loading && (
                <Grid
                  className="form-panel profile-form-panel"
                  sx={{ width: "100%" }}
                >
                  <Grid
                    container
                    className="profile-top-panel booking-details-heading"
                  >
                    <Typography className="profile-top-info" variant="div">
                      <Typography variant="h3">
                        {bookingDetails?.units?.length > 1
                          ? bookingDetails?.projectInfo?.name
                          : bookingDetails?.units?.[0]?.unit_no}
                      </Typography>
                      <Typography
                        variant="h4"
                        className="booking-details-subheading"
                      >
                        {bookingDetails && (
                          <>
                            {bookingDetails?.units?.length < 2
                              ? `${bookingDetails?.projectInfo?.name},`
                              : ""}{" "}
                            {bookingDetails?.projectInfo?.city},{" "}
                            {bookingDetails?.projectInfo?.country}
                          </>
                        )}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid container className="profile-bottom-panel">
                    <Box sx={{ width: "100%", typography: "body1" }}>
                      <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "#E9E9E9" }}>
                          <TabList
                            className="tabs-list-for-msite"
                            onChange={handleChange}
                            aria-label="details tab"
                          >
                            <Tab
                              onClick={() => tabSwitchHandler(1)}
                              label={translate("ra.fieldName.status")}
                              value="1"
                            />
                            <Tab
                              onClick={() => tabSwitchHandler(2)}
                              label={translate("ra.fieldName.bookingDetails")}
                              value="2"
                            />
                            <Tab
                              onClick={() => tabSwitchHandler(3)}
                              className="booking-doc-tab"
                              label={`${translate("ra.fieldName.document")}`}
                              icon={
                                !bookingDetails?.units
                                  ?.map((unit) => unit?.kyc_status === 0)
                                  ?.includes(true) && !docSeen ? (
                                  <span className="document-tab-list"></span>
                                ) : (
                                  ""
                                )
                              }
                              iconPosition="end"
                              value="3"
                            />
                          </TabList>
                        </Box>
                        <TabPanel className="booking-status-tab" value="1">
                          <StatusTab
                            details={bookingDetails}
                            loading={loading}
                            userData={userData}
                          />
                        </TabPanel>
                        <TabPanel
                          className="flex-column booking-details-allinfo booking-details-tab"
                          value="2"
                        >
                          <BookingDetailsTab
                            projectInfoDetails={projectInfoDetails}
                            details={bookingDetails}
                            loading={loading}
                            userData={userData}
                          />
                        </TabPanel>
                        <TabPanel
                          className="flex-column booking-documents-allinfo booking-downloads-tab"
                          value="3"
                        >
                          <DownloadsTab
                            details={bookingDetails}
                            loading={loading}
                            notification={notification}
                            docSeen={docSeen}
                            setDocSeen={setDocSeen}
                          />
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Container>
          </Container>
        </>
      )}
    </>
  );
};

export default BookingData;
