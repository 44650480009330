import React, { useState } from "react";
import { useTranslate } from "react-admin";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Cropper from "react-easy-crop";
import getCroppedImg from "Utils/cropImage";
import { DialogTitle } from "@material-ui/core";
import "./cropperComponent.scss";
import DialogBox from "Components/DialogBox";

const CropperComponent = ({
  uploadPicture,
  showCropper,
  setShowCropper,
  croppedImage,
  setCroppedImage,
  image,
  setImage,
  showDialog,
  setShowDialog,
  setPicture,
  picture,
  inputRef,
  notification
}) => {
  const translate = useTranslate();
  const [loading, setLoading] = useState(null);
  const [croppedArea, setCroppedArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  //create file object from the cropped image base64 URL
  const createFile = async (newImage) => {
    let response = await fetch(newImage);
    let data = await response.blob();
    let metadata = {
      type: inputRef?.current?.files[0]?.type,
    };
    let file = new File([data], inputRef?.current?.files[0]?.name, metadata);
    return file;
  };

  const onUpload = async () => {
    if (image) {
      setLoading(true);
      setShowCropper(false);
      const newImage = await getCroppedImg(image, croppedArea);
      setCroppedImage(newImage);
      setImage(null);
      setShowDialog(false);
      setLoading(false);
      const file = await createFile(newImage);
      setPicture(file);
      if (uploadPicture) uploadPicture(file);
    } else {
      if (!picture && !croppedImage) {
        notification("ra.notification.uploadImage", { type: "error" });
      } else {
        setShowDialog(false);
        setShowCropper(false);
      }
    }
  };

  const triggerFileSelectPopup = () => {
    inputRef.current.click();
  };

  const onClear = () => {
    if (picture) {
      if (image) {
        setImage(null);
      } else {
        setPicture("");
        uploadPicture && uploadPicture("");
        croppedImage && setCroppedImage(null);
      }
    } else {
      setImage(null);
    }
    setShowCropper(false);
    setShowDialog(false);
  };

  const onClickCancel = () => {
    if (image) {
      setOpenConfirmationPopup(true);
    }
    else {
      setShowDialog(false);
    }
  }

  const handleYes = () => {
    setOpenConfirmationPopup(false); 
    setShowDialog(false); 
    setImage(null);
  }

  return (
    <>
      <Dialog className="dialog upload-modal-section" open={showDialog}>
        <DialogTitle className="upload-text-section">
          <IconButton
            aria-label="close"
            onClick={() => {
              onClickCancel();
            }}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            {translate("ra.fieldName.uploadPicture")}
            <CloseOutlinedIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="upload-pic-section">
          {loading && <CircularProgress />}
          {(showCropper || picture) && (
            <div className="cropper">
              <Cropper
                image={
                  image ||
                  (typeof picture === "string" && picture) ||
                  croppedImage
                }
                croppedArea={croppedArea}
                crop={crop}
                aspect={1}
                cropShape="round"
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                zoom={1}
              />
              <div className="dialog-div"></div>
            </div>
          )}
        </DialogContent>
        <DialogActions className="upload-button-section">
          <Typography className="delete-section">
            <Button
              className="dialog-button"
              variant="outlined"
              onClick={onClear}
            >
              {picture
                ? (image
                  ? translate("ra.button.clear")
                  : translate("ra.button.removePic"))
                : translate("ra.button.clear")}
            </Button>
          </Typography>
          <Typography className="delete-bottom-section">
            <Button
              className="dialog-button"
              variant="contained"
              onClick={() => {
                onClickCancel();
              }}
            >
              {translate("ra.button.cancel")}
            </Button>

            <Button
              className="dialog-button"
              variant="contained"
              onClick={
                picture ? (image ? onUpload : triggerFileSelectPopup) : onUpload
              }
            >
              {picture
                ? (image
                  ? translate("ra.button.upload")
                  : translate("ra.button.change"))
                : translate("ra.button.upload")}
            </Button>
          </Typography>
        </DialogActions>
      </Dialog>
      {openConfirmationPopup && (
        <DialogBox
          openPopup={openConfirmationPopup}
          setOpenPopup={setOpenConfirmationPopup}
          path={"profile"}
          content={translate("ra.notification.changesLost")}
          handleYes={() => handleYes()}
        />
      )}
    </>
  );
};

export default CropperComponent;
