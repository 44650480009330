import React from 'react'
import {
  Grid
} from "@mui/material";
import formatNumber from 'Utils/conversionFunction';
import {PriceFormat} from 'Utils/conversionFunction';
export const UnitsDetailsAccordian = ({ unit, translate, userData }) => {
  return (
    <Grid className="unit-info-li">      
      <p className="unit-info-no">{translate("ra.fieldName.unit_no")} <span>{unit?.unit_no}</span></p>
      <Grid className="unit-info-para-details accordion-wrapper">
        <p>{translate("ra.fieldName.area")}({userData?.unit_type}) <span>{unit?.saleable_area_sqft && unit?.saleable_area_sqft}</span></p>
        <p>{translate("ra.fieldName.bedroom")} <span>{unit?.bedrooms}</span></p>
        <p>{translate("ra.fieldName.price")}({unit?.currency_Code}) <span>{unit?.price && PriceFormat(unit?.price)}</span></p>
      </Grid>
    </Grid>
  )
}
