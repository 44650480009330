import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Grid,
  Container,
  Button,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Header from 'Components/Header'
import { useTranslate, useStore, useStoreContext } from 'react-admin'
import userDetails from 'Services/userDetailsServices'
import BackButton from 'Components/BackButton'
import { UnitListDetailsForReview , unitAvailabilityCheck } from 'Services/Salesforce/unitService'
import { currencyStatus } from 'Utils/currencyCheck'
import { formatNumbers } from 'Utils/thousandSeperators'
import DialogBox from 'Components/DialogBox'
import { getProjectDetails } from 'Services/Salesforce/projectService'
import SelectionDialogBox from 'Components/Common/SelectionDialogBox'
import CustomerTypeSelection from './CustomerTypeSelecton'
import Loader from 'Components/Loader'
import formatNumber from 'Utils/conversionFunction'
import createBookingId, { blockUnits } from 'Services/createBookingId'
import { queryReviewPage } from 'Utils/queryValues'

export const ReviewUnits = ({ notification }) => {
  const [deleteId, setDeleteId] = useStore('deleteId', [])
  const [deleteCode, setDeleteCode] = useStore('deleteCode', [])
  const [userData, setUserData] = useState(null)
  const [availabilityLoading, setAvailabilityLoading] = useState(false)
  const [unitDetailsLoading, setUnitDetailsLoading] = useState(true)
  const [blockLoading, setBlockLoading] = useState(false)
  const [projectDetailsLoading, setProjectDetailsLoading] = useState(false)
  const [bookingIdLoading, setBookingIdLoading] = useState(false)
  const [userDetailsLoading, setuserDetailsLoading] = useState(false)
  const [propertyDetails, setPropertyDetails] = useState()
  const [details, setDetails] = useState([])
  const [openDialog, setOpenDialog] = useState(null)
  const [openNextDialog, setOpenNextDialog] = useState(null)
  const [error, setError] = useState(null)
  const [customerExistence, setCustomerExistence] = useState('existing')
  const [customerType, setCustomerType] = useState(null)
  const [failedUnits, setFailedUnits] = useState()
  const [failedUnitsPopup, setFailedUnitsPopup] = useState(false)
  const [customerSelectionPopup, setCustomerSelectionPopup] = useState(false)
  const [deleteData, setDeleteData] = useState(null)
  const [amenities, setAmenities] = useState([])
  const [documentsArray, setDocumentsArray] = useState([])
  const location = useLocation()
  const navigate = useNavigate()
  const translate = useTranslate()
  const store = useStoreContext()
  let id =
    typeof location?.state?.id === 'string'
      ? [location?.state?.id]
      : [...(location?.state?.id || '')]
  let unit_no = location?.state?.no || []
  const project_id = location?.state?.project_id
  const unitType = userData?.unit_type?.toUpperCase()
  const unit_type = userData?.unit_type
  const currency = userData?.currency
  const params =
    location?.state?.params || JSON.parse(localStorage?.getItem('params'))
  const page = location?.state?.page || localStorage?.getItem('page')
  const matches = useMediaQuery('(max-width:600px)')
  const portal_booking_id = localStorage.getItem('portalBookingId') || null
  const formStatus = sessionStorage.getItem('formStatus') || null
  const bookingState = JSON.parse(localStorage.getItem('bookingState')) || null

  useEffect(() => {
    if (!page && !formStatus) {
      navigate('/home')
    }
   
    // if (portal_booking_id && formStatus) {
    //   navigate(formStatus, { state: bookingState })
    // } else {
      store.removeItem('ownership')
      localStorage.removeItem('tabs')
      localStorage.removeItem('company_id')
      if (params) {
        const paramUnitId = id?.filter((list) => !deleteId?.includes(list))
        localStorage?.setItem(
          'params',
          JSON.stringify({ ...params, id: paramUnitId })
        )
      }
      if (page) localStorage?.setItem('page', page)
    // }

    userDetails(setuserDetailsLoading, setUserData, '', notification)
    const unitIdList = id?.filter((list) => !deleteId?.includes(list))
    

    if (project_id || params?.projectid) {
      getProjectDetails(
        setProjectDetailsLoading,
        setPropertyDetails,
        notification,
        project_id || params?.projectid,
        setAmenities,
        setDocumentsArray
      )
      UnitListDetailsForReview(
        setUnitDetailsLoading,
        '',
        '',
        userData,
        params,
        notification,
        '',
        '',
        unitIdList,
        setDetails,
        setFailedUnits,
        unit_no,
        setFailedUnitsPopup,
        'review-unit',
        deleteCode,
        setDeleteCode
      )
    } else {
      setUnitDetailsLoading(false)
      setuserDetailsLoading(false)
      setProjectDetailsLoading(false)
    }
    document.body.classList.add('profile-page')
    document.body.classList.add('change-pass-page')
    document.body.classList.add('reservation-form')
    return () => {
      document.body.classList.remove('profile-page')
      document.body.classList.remove('change-pass-page')
      document.body.classList.remove('reservation-form')
    }
  }, [])

  const continueClickHandler = () => {
    unitAvailabilityCheck(
      setAvailabilityLoading,
      notification,
      details?.map((unit) => unit?.unitCode
    )?.join(','),
      setCustomerSelectionPopup,
      setFailedUnitsPopup,
      setFailedUnits,
      details,
      setDetails,
      deleteCode,
      setDeleteCode
    )
  }

  const onclickDialogContinue = () => {
    if (!customerType) {
      setError(translate('ra.validation.selectType'))
    } else {
      setCustomerSelectionPopup(false)
      const apiRequesForBlock = {
        project_id: params?.projectid || project_id,
        unit_ids: details?.map((unit) => unit?.unitCode)?.join(','),
        is_initial: 1,
      }
      
      blockUnits(
        setBlockLoading,
        apiRequesForBlock,
        notification,
        createBookingId,
        setBookingIdLoading,
        customerExistence,
        handleSubmit
      )
    }
  }

  const handleSubmit = () => {
    if (customerExistence && customerType) {
      navigate(
        customerExistence === 'existing'
          ? '/choose-contacts'
          : '/booking-reservation-form',
        {
          state: {
            project_id: params?.projectid || project_id,
            units: details,
          },
        }
      )
    }
  }

  const handleYes = () => {
    setOpenDialog(false)
    setOpenNextDialog(true)
    const ids = [...deleteId]
    const idCode = [...deleteCode]
    if (ids?.includes(deleteData?.id)) {
      setDeleteId(ids)
      setDeleteCode(idCode)
    } else {
      ids.push(deleteData?.id)
      idCode.push(deleteData?.no)
      setDeleteId(ids)
      setDeleteCode(idCode)
    }
    const unitIdList = id?.filter((list) => !ids?.includes(list))
    const unitCode = unit_no?.filter((list) => !idCode?.includes(list))

    localStorage?.setItem(
      'params',
      JSON.stringify({ ...params, id: unitIdList, no: unitCode })
    )
    setDetails(
      details?.filter((unit) => !ids?.includes(unit?.officE_SECTION_ID))
    )
    setDeleteData(null)
  }

  const handleNo = () => {
    setDeleteId(deleteId || [])
    setOpenDialog(false)
    setDeleteData(null)
  }

  const onClickDelete = (id_delete, code) => {
    setOpenDialog(true)
    setDeleteData({ id: id_delete, no: code })
  }

  const closeBtnHandler = () => {
    setFailedUnitsPopup(false)

    let textToast =
      failedUnits?.length > 1
        ? 'ra.notification.unitno_Toast'
        : 'ra.notification.unitnoToast'
    notification(
      `${failedUnits?.length} ${translate(textToast)} ${translate(
        'ra.notification.removedUnitToast'
      )}`,
      { type: 'info' }
    )
  }

  const onClickBack = () => {
    const paramValue = JSON.parse(localStorage?.getItem('params'))
    const stateValue = queryReviewPage(paramValue)

    if (page === 'wishlist') {
      navigate(`/wishlist?${params?.paramData || ''}`, { state: stateValue })
    } else if (page === 'unit-list') {
      navigate(`/unit-listing?${params?.paramData || ''}`, {
        state: stateValue,
      })
    } else if (page === 'unit-details') {
      navigate(`/unitlisting-details?unitid=${id}`, { state: stateValue })
    } else {
      navigate(`/wishlist-property-details?unitid=${id}`, { state: stateValue })
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', function () {
      if (matches) {
        let headerId = document?.getElementById('review-page-id')
        if (headerId) {
          if (document.documentElement.scrollTop > 1) {
            headerId?.classList?.add('sticky-review-page')
          } else {
            headerId?.classList?.remove('sticky-review-page')
          }
        }
      }
    })
  }, [])
  
 console.log(propertyDetails,"propertyDetails");
 
  return (
    <>
      {(availabilityLoading || blockLoading || bookingIdLoading) && <Loader />}
      <Header
        path="My Bookings"
        picture={userData?.profile}
        pictureLoading={userDetailsLoading}
        notification={notification}
      />
      <Typography className="for-desktop">
        <BackButton close={() => onClickBack()} />
      </Typography>
      {(projectDetailsLoading || unitDetailsLoading || userDetailsLoading) && (
        <Loader />
      )}
      {!unitDetailsLoading && !userDetailsLoading && !projectDetailsLoading && (
        <>
          <Container className="company-container my-contacts-section review-units">
            <Container className="my-contacts-panel">
              <Grid className="form-panel contact-panel" container>
                <Typography
                  id="review-page-id"
                  variant="h2"
                  className="page-heading review-unit-page"
                >
                  {translate('ra.pageTitle.reviewUnits')}
                </Typography>
                {propertyDetails &&
                details?.length &&
                !unitDetailsLoading &&
                !userDetailsLoading &&
                !projectDetailsLoading ? (
                  <>
                    <Grid className="complete-booking-info">
                      <i className="iconwasalt icon-clock"></i>
                      <Typography
                        variant="h4"
                        className="review-text-transform"
                      >
                        <span>{translate('ra.content.bookingHours')}</span>
                        {translate('ra.content.tokenProof')}
                      </Typography>
                    </Grid>
                    <Grid className="info-with-image">
                      <img
                        style={{ width: '100px' }}
                        src={propertyDetails?.images[0]?.imageURL}
                      ></img>
                      <p>
                        <span>{propertyDetails?.projecT_NAME}</span>{' '}
                        {propertyDetails?.city}, {propertyDetails?.location}
                      </p>
                    </Grid>
                    <Typography variant="h3" className="review-unit-count">
                      {`${details?.length} ${
                        details?.length > 1
                          ? translate('ra.pageTitle.units')
                          : translate('ra.fieldName.unit')
                      } ${translate('ra.placeholder.selected')}`}
                    </Typography>
                    <Grid className="unit-list-panel contacts-table-view for-desktop">
                      <table>
                        <thead>
                          <tr>
                            <th>{translate('ra.fieldName.unit_no')}</th>
                            <th>
                              {translate('ra.fieldName.area')} ({unit_type})
                            </th>
                            <th>{translate('ra.fieldName.bedroom')}</th>
                            <th>
                              {translate('ra.fieldName.price')}(
                              {propertyDetails?.currency})
                            </th>
                            <th>{translate('ra.fieldName.actions')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {details?.map((unit, index) => {
                            return (
                              <tr key={index}>
                                <td>{unit?.unitCode}</td>
                                <td>
                                  {unit?.[`saleableArea${unitType?.toLowerCase()}`]
                                    ? `${unit?.[`saleableArea${unitType?.toLowerCase()}`]}
                                       `
                                    : '-'}
                                </td>
                                <td>{unit?.apartmentType || '-'}</td>
                                <td>
                                  
                                  {
                                  currencyStatus(currency)
                                    ? formatNumber(
                                        unit?.[`unitPrice${currency}`]
                                      )|| formatNumber(unit?.[`unitPrice`])
                                    : formatNumber(unit?.[`unitPrice`])}
                                </td>
                                <td>
                                  <Button
                                    onClick={() => {
                                      onClickDelete(
                                        unit?.officE_SECTION_ID,
                                        unit?.officE_SECTION_CODE
                                      )
                                    }}
                                  >
                                    <i className="iconwasalt icon-delete"></i>
                                  </Button>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </Grid>
                    <Grid className="unit-list-panel contacts-table-view for-mobile">
                      <Grid className="unit-list-mobile">
                        {details?.map((unit, index) => {
                          return (
                            <Typography
                              key={index}
                              className="unit-list-info"
                              component="div"
                            >
                              <Typography
                                className="heading-info"
                                component="div"
                              >
                                <Typography variant="h2" component="h2">
                                  <span>
                                    {translate('ra.fieldName.unit_no')}
                                  </span>{' '}
                                  {unit?.unitCode}
                                </Typography>
                                <Typography className="select-all">
                                  <Button
                                    onClick={() =>
                                      onClickDelete(
                                        unit?.officE_SECTION_ID,
                                        unit?.officE_SECTION_CODE
                                      )
                                    }
                                  >
                                    <i className="iconwasalt icon-delete"></i>
                                  </Button>
                                </Typography>
                              </Typography>
                              <Typography className="area-info" component="div">
                                <Typography variant="h3" component="h3">
                                  <span>
                                    {translate('ra.fieldName.area')} (
                                    {unit_type})
                                  </span>
                                  <span>
                                    {unit?.[`saleablE_AREA_${unitType}`]
                                      ? `${formatNumbers(
                                          parseFloat(
                                            unit?.[`saleablE_AREA_${unitType}`]
                                          )?.toFixed(2)
                                        )} `
                                      : '-'}
                                  </span>
                                </Typography>
                                <Typography variant="h3" component="h3">
                                  <span>
                                    {translate('ra.fieldName.bedroom')}
                                  </span>
                                  <span>{unit?.apartmenT_TYPE || '-'}</span>
                                </Typography>
                                <Typography variant="h3" component="h3">
                                  <span>
                                    {translate('ra.fieldName.price')}(
                                    {unit?.currency})
                                  </span>
                                  <span>
                                    {currencyStatus(currency)
                                      ? formatNumber(
                                          unit?.[`propertY_PRICE_${currency}`]
                                        )
                                      : formatNumber(unit?.[`propertY_PRICE`])}
                                  </span>
                                </Typography>
                              </Typography>
                            </Typography>
                          )
                        })}
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container className="review-units-nodata" spacing={0}>
                      <h5>{translate('ra.noData.unitFound')}</h5>
                      <p>{translate('ra.content.addUnits')}</p>
                    </Grid>
                  </>
                )}
                <Grid
                  container
                  className={`review-button-panel ${
                    details?.length <= 0 && 'add-unit-button'
                  }`}
                  spacing={0}
                >
                  {details?.length > 0 && (
                    <Button
                      variant="outlined"
                      className="for-mobile"
                      onClick={() => onClickBack()}
                    >
                      {translate('ra.button.back')}
                    </Button>
                  )}
                  <Button
                    className="new-color-button"
                    onClick={() =>
                      details?.length > 0
                        ? continueClickHandler()
                        : onClickBack()
                    }
                  >
                    {details?.length > 0
                      ? translate('ra.button.continue')
                      : translate('ra.button.addUnits')}
                  </Button>
                </Grid>
                <DialogBox
                  openPopup={openDialog}
                  setOpenPopup={setOpenDialog}
                  cls="delete-unit"
                  handleNo={() => handleNo()}
                  content={translate('ra.content.deleteUnit')}
                  handleYes={() => handleYes()}
                />
                <DialogBox
                  openPopup={openNextDialog}
                  setOpenPopup={setOpenNextDialog}
                  cls="delete-unit"
                  closeBtnClass="review-close-button"
                  content={translate('ra.content.deltedUnitSuccess')}
                  closeBtn={true}
                  closeBtnHandler={() => setOpenNextDialog(false)}
                />
                {customerSelectionPopup && (
                  <SelectionDialogBox
                    openPopup={customerSelectionPopup}
                    closeFn={() => {
                      setCustomerSelectionPopup(false)
                      setCustomerExistence(null)
                      setCustomerType(null)
                      setError(null)
                    }}
                    className="book-for-customer"
                    customerExistence={customerExistence}
                    title={translate('ra.pageTitle.bookForCustomer')}
                    handleSubmit={onclickDialogContinue}
                  >
                    <CustomerTypeSelection
                      error={error}
                      customerExistence={customerExistence}
                      setCustomerExistence={setCustomerExistence}
                      className="book-for-customer"
                      customerType={customerType}
                      setCustomerType={setCustomerType}
                      setError={setError}
                    />
                  </SelectionDialogBox>
                )}

                {failedUnitsPopup && (
                  <DialogBox
                    openPopup={failedUnitsPopup}
                    setOpenPopup={setFailedUnitsPopup}
                    closeBtn={true}
                    closeBtnName={
                      details?.length === 0
                        ? translate('ra.button.addUnits')
                        : translate('ra.button.okay')
                    }
                    icon={'icon-error'}
                    closeBtnHandler={() => closeBtnHandler()}
                    content={`${
                      failedUnits?.length > 1
                        ? translate('ra.fieldName.units')
                        : translate('ra.fieldName.unit')
                    } ${translate('ra.notification.notAvailable')}`}
                    innerDialogContent={`${translate('ra.fieldName.unit_no')}`}
                    unit_no={`${failedUnits?.join(', ')}`}
                    innerDialogContent1={`${
                      failedUnits?.length > 1
                        ? translate('ra.notification.pNoLongerAvailable')
                        : translate('ra.notification.sNoLongerAvailable')
                    }`}
                    dialogClass="unit-not-available"
                    innerContentClass="unit-inner-content"
                  />
                )}
              </Grid>
            </Container>
          </Container>
        </>
      )}
    </>
  )
}
