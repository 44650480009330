import React, { useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import { Grid, Container, Typography } from "@mui/material";
import Header from "Components/Header";
import BookingHistoryDetails from "Pages/MyContacts/BookingDetails";
import useWebVitals from "Utils/useWebVitals";
const MyBookings = ({ notification, userLoading, userData }) => {
  const [totalCount, setTotalCount] = useState(0);
  const translate = useTranslate();
  const pageType = "my-bookings";
  useWebVitals(pageType);
  useEffect(() => {
    document.body.classList.add("profile-page");
    return () => {
      document.body.classList.remove("profile-page");
    };
  }, []);

  return (
    <>
      <Header
        path="My Bookings"
        picture={userData?.profile}
        pictureLoading={userLoading}
        notification={notification}
        userData={userData}
      />
      <div className="contact-sticky-table-wrapper" />
      <Container className="company-container my-contacts-section my-booking-padding">
        <Container className="my-contacts-panel booking-heightwrapper">
          <Grid className="form-panel contact-panel" container>
            <Grid className="contact-panel-heading">
              <Typography variant="h2">
                {translate("ra.pageTitle.bookings")}
                <span>({totalCount})</span>
              </Typography>
            </Grid>
            <BookingHistoryDetails
              className="booking-table-section"
              currentPage="my-bookings"
              userData={userData}
              notification={notification}
              setTotalCount={setTotalCount}
            />
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default MyBookings;
