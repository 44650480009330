import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function RadioButtonsGroup({ translate, checkedValue, setCheckedValue }) {
    return (
        <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">{translate("ra.content.chooseOffline")}</FormLabel>
            <RadioGroup
                className="offline-details-info"
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="POS"
                name="radio-buttons-group"
                onChange={(e) => setCheckedValue(e.target.value)}>
                <FormControlLabel value="POS" control={<Radio />} label="POS" />
                <FormControlLabel value="Wire Transfer" control={<Radio />} label="Wire Transfer" />
                <FormControlLabel value="Cheque" control={<Radio />} label="Cheque" />
                <FormControlLabel value="Other" control={<Radio />} label="Other" />
            </RadioGroup>
        </FormControl>
    );
}