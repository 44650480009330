import React, { useState, useEffect } from "react";
import { useStore } from "react-admin";
import BackButton from "Components/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "Components/Header";
import userDetails from "Services/userDetailsServices";
import { Grid, Container, Button, Typography } from "@mui/material";
import { useTranslate } from "react-admin";
import getCustomerUnits, { assignOwnership } from "Services/assignOwnershipServices";
import { projectDetails } from "Services/projectService";
import ControlledAccordions from "./ControlledAccordions";
import Loader from "Components/Loader";
import DialogBox from "Components/DialogBox";
import NewAccordion from "./NewControlledAccordions/NewAccordion";

export const AssignOwnerShipPercentage = ({ notification, bookingFailedPopup, setBookingFailedPopup }) => {
  const [userData, setUserData] = useState(null);
  const [ownership, setOwnership] = useStore("ownership", {}); 
  const [data, setData] = useState([]);
  const [userDetailsLoading, setuserDetailsLoading] = useState(false);
  const [assignOwnerShipLoading, setAssignOwnershipLoading] = useState(false);
  const [customerLoading, setCustomerLoading] = useState(true);
  const [displayError, setDisplayError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const translate = useTranslate();
  const [projectDetailsLoading, setProjectDetailsLoading] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState();
  const [units, setUnits] = useState();
  const [openPopup, setOpenPopup] = useState();
  const [primary, setPrimary] = useState([]);
  const tabs = JSON.parse(localStorage?.getItem("tabs"));
  const portalBookingId = localStorage.getItem("portalBookingId") || null;
  let formStatus = sessionStorage.getItem("formStatus") || null;
  const bookingState = JSON.parse(localStorage.getItem("bookingState")) || null;
  const state = location?.state || bookingState;

  console.log(state,"statestate");
  
  // const mappedunitPercentageObj=state?.units?.map(_=>{
  //   return{
  //       unitCode:"",
  //       customerId:"",
  //       percentageValue:"",

  //     }})

  const [unitPercentageObj,setunitPercentageObj]=useState([]);


  const customerId = state?.tabs?.map(tab => tab?.id) || [];

  let apiRequestData = {
    customerIds: [...customerId],
    projectId: state?.project_id,
    unitIds: state?.units?.map(unit => unit?.unitCode)
  };

  let headerPayload = {
    project_id: state?.project_id,
    unit_ids: state?.units?.map(unit => unit?.unitCode)?.join(","),
    portal_booking_id: portalBookingId
  }
// console.log(state,"statestate");

  useEffect(() => {
    if (!formStatus) {
      navigate("/home");
    }

    if (formStatus && formStatus !== "/assign-ownership-percentage") {
      navigate(formStatus, { state });
    }

    if (formStatus === "/assign-ownership-percentage") {
      userDetails(setuserDetailsLoading, setUserData, "", notification);
      getCustomerUnits(setCustomerLoading, setData, apiRequestData, notification, displayData, false, "", JSON.stringify(headerPayload), bookingFailedPopup, setBookingFailedPopup);
      projectDetails(setProjectDetailsLoading, setPropertyDetails, "", state?.project_id);
    }

    document.body.classList.add(
      'profile-page'
    );
    document.body.classList.add(
      'change-pass-page'
    );
    document.body.classList.add(
      'reservation-form'
    );
    return () => {
      document.body.classList.remove(
        'profile-page'
      );
      document.body.classList.remove(
        'change-pass-page'
      );
      document.body.classList.remove(
        'reservation-form'
      );
    };
  }, []);

  function removeDuplicates(arr) {
    return arr.filter((item,
      index) => arr.indexOf(item) === index);
  }

  const displayData = (res) => {
    let allUnits = res?.map((val) => {
      return val?.unit_no
    })
    let removeDuplicateunits = removeDuplicates(allUnits);
    let sortedDataAsUnits = [];
    let primaryArray = [...primary];
    let ownershipObject = {};
    for (const unitNo of removeDuplicateunits) {
      const filterData = res?.filter((item) => item?.unit_no === unitNo);
      const isPrimaryUser = filterData?.filter(unit => unit?.is_primary_user === 1)[0]?.customer_id;
      const primaryUser = isPrimaryUser ? isPrimaryUser : filterData[0]?.customer_id;
      let primaryUserObject = {
        unit_no: unitNo,
        customerId: primaryUser
      }
      primaryArray.push(primaryUserObject)
      setPrimary(primaryArray);
      const ownershipArray = filterData?.map((unit) => { return { id: unit?.id, type: unitNo, sliderValue: unit?.ownership_percentage } });
      let ownershipSum = ownershipArray?.map((ownership) => ownership?.sliderValue)?.reduce((total, num) => total + num);
      let ownerKewy = `${unitNo}_sum`;
      ownershipObject = {
        ...ownershipObject,
        [unitNo]: ownershipArray,
        [ownerKewy]: ownershipSum
      }
      setOwnership({ ...ownershipObject });
      sortedDataAsUnits = [...sortedDataAsUnits, { [unitNo]: [...filterData] }]
    }
    setUnits(sortedDataAsUnits)
  }

  const onClickReview = () => {
    setDisplayError(true);
    let payload = data?.map((unitData, index) => {
      console.log(unitData,"unitData");
      
      return {
        id: unitData?.customer_unit_id,
        customer_id: unitData?.customer_id,
        unit_id: unitData?.unit_id,
        project_id: unitData?.project_id,
        ownership_percentage: unitData?.ownership_percentage,
        is_primary_user: primary?.find(list => (list?.customerId === unitData?.customer_id) && (list?.unit_no === unitData?.unit_no)) ? 1 : 0,
        customer_type: unitData?.customer_type,
        unit_no: unitData?.unit_no
      }
    })
    console.log(payload,"payload");
    
    if (primary?.map((unit) => !unit?.customerId)?.includes(true)) {
      notification("ra.notification.primaryCustomer", { type: "error" });
    } else if (data?.map((unit) => !unit?.ownership_percentage).includes(true)) {
      notification("ra.notification.assignOwner", { type: "error" });
    }
    //  else if (Object.values(ownership)?.filter((val) => typeof val !== "object")?.map(val => val === 100)?.includes(false)) {
    //   setDisplayError(true);
    //   notification("ra.notification.ownershipPercentageExceed", { type: "error" })
    // } 
    else {
      assignOwnership(setAssignOwnershipLoading, payload, notification, navigate, state?.units, state?.project_id, JSON.stringify(headerPayload), bookingFailedPopup, setBookingFailedPopup);
    }
    let units=location?.state?.units;
    let project_id=location?.state?.project_id;
    
    // navigate("/review-customer-information", { state: { units, project_id } });
    
    localStorage.setItem("units",units);
    localStorage.setItem("project_id",project_id);

    // console.log(location,"location");
    
  }

  const createNewTab = () => {
    tabs.push({
      value: (tabs?.length + 1).toString()
    })
    localStorage.setItem("tabs", JSON.stringify(tabs))
    onClickBack();
  }

  const onClickBack = () => {
    sessionStorage.setItem("formStatus", "/booking-reservation-form");
    navigate("/booking-reservation-form", { state: { units: state?.units, project_id: state?.project_id } });
  }

 const [expanded,setExpanded]=useState("");
// console.log(state?.units,"state?.units",tabs);

  return (
    <>
      <Header
            path="My Bookings"
            picture={userData?.profile}
            pictureLoading={userDetailsLoading}
            notification={notification}
        />
      <BackButton close={() => onClickBack()} />

      <Grid className="booking-reservation-info assign-ownership-page">
   <Container className="company-container new-fix-container lead-panel-section lead-white-section mt-0">
         <Container className="company-details">
           <Grid className="form-panel profile-form-panel add-team-panel lead-form-panel booking-reservation-panel" sx={{ width: "100%" }}>
             <Grid className="reservation-top-section assign-percentage-wrapper">
               <Typography variant="h3">{translate("ra.pageTitle.assignOwnerShip")}</Typography>
               <Grid>
                 <Grid className="reservation-image-section">
                   {tabs?.map((tab, index) => {
                    return (
                      <Typography key={index} component="p" className="lead-pic success assign">
                        <strong>{tab?.name}</strong>
                        <Typography component="span"><i className="iconwasalt icon-check"></i></Typography>
                      </Typography>
                    )
                  })}
                  <Button variant="outlined" className="add-button" onClick={() => setOpenPopup(true)}>
                    <i className="iconwasalt icon-plus" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>

           <Grid className="assign-ownership-unit-details">
              <Typography component="div" className="assign-ownership-image-section">
                <img style={{height:40,width:100}} src={propertyDetails?.ProjectImage[0]?.mobile_ImageURL}></img>
                <p>{propertyDetails?.reraProjectName} <span>{propertyDetails?.projectCity}{propertyDetails?.district ? `, ${propertyDetails?.district}` : ""}</span></p>
              </Typography>
              <Typography variant="h4">
               
                 {state?.units?.length > 1
                   ? `${state?.units?.length} Units`
                   : `${state?.units?.length} Unit`}
              </Typography>
            </Grid>
            <Grid className="assign-ownership-accordian">
              {
              state?.units?.length > 0 &&
               (state?.units?.map((val, index) =>
                <>
                  {/* <ControlledAccordions
                    ownership={ownership}
                    setOwnership={setOwnership}
                    val={val}
                    key={index}
                    data={data}
                    setData={setData}
                    primary={primary}
                    setPrimary={setPrimary}
                    idx={index}
                    notification={notification}
                  /> */}
                   <NewAccordion key={index} expanded={expanded} setExpanded={setExpanded}
                    index={index} val={val} data={data} setData={setData} percentageObj={unitPercentageObj} setunitPercentageObj={setunitPercentageObj}/>
                  {displayError &&!data[index]?.ownership_percentage
                  // && ownership[`${Object.keys(val)[0]}_sum`] != 100
                   &&
                    <span className="assign-ownership-errormassage">{translate("ra.notification.assignOwner")}</span>}
                   
                </>
              ))}
            </Grid>

             <Grid className="assign-ownership-button-panel desktop-only">
              <Button variant="contained" onClick={() => onClickReview()} >{translate("ra.button.review")}</Button>
            </Grid>
            <Grid container className="mobile-only" spacing={0}>
              <Grid container className="company-details-button" spacing={0}>
                <Button
                  variant="outlined"
                  onClick={() => onClickBack()}
                >
                  {translate("ra.button.back")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => onClickReview()} >
                  {translate("ra.button.review")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Grid>
    <div>
        {openPopup &&
          <DialogBox
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            icon="add-buyer"
            content={translate("ra.content.addMoreBuyer")}
            dialogAction="question"
            handleNo={() => setOpenPopup(false)}
            handleYes={() => {
              setOpenPopup(false);
              createNewTab();
            }}
          />
        }
      </div>
    </>
  
  );
};





 // <>
    //   {(customerLoading || projectDetailsLoading || userDetailsLoading || assignOwnerShipLoading) && <Loader />}
    //   <Header
    //     path="My Bookings"
    //     picture={userData?.profile}
    //     pictureLoading={userDetailsLoading}
    //     notification={notification}
    //   />
    //   <BackButton close={() => onClickBack()} />
    //   <Grid className="booking-reservation-info assign-ownership-page">
    //     <Container className="company-container new-fix-container lead-panel-section lead-white-section mt-0">
    //       <Container className="company-details">
    //         <Grid className="form-panel profile-form-panel add-team-panel lead-form-panel booking-reservation-panel" sx={{ width: "100%" }}>
    //           <Grid className="reservation-top-section assign-percentage-wrapper">
    //             <Typography variant="h3">{translate("ra.pageTitle.assignOwnerShip")}</Typography>
    //             <Grid>
    //               <Grid className="reservation-image-section">
    //                 {tabs?.map((tab, index) => {
    //                   return (
    //                     <Typography key={index} component="p" className="lead-pic success assign">
    //                       <strong>{tab?.name}</strong>
    //                       <Typography component="span"><i className="iconwasalt icon-check"></i></Typography>
    //                     </Typography>
    //                   )
    //                 })}
    //                 <Button variant="outlined" className="add-button" onClick={() => setOpenPopup(true)}>
    //                   <i className="iconwasalt icon-plus" />
    //                 </Button>
    //               </Grid>
    //             </Grid>
    //           </Grid>

    //           <Grid className="assign-ownership-unit-details">
    //             <Typography component="div" className="assign-ownership-image-section">
    //               <img style={{height:40,width:100}} src={"https://media.architecturaldigest.com/photos/59a8391ba8a79c392f89ebbb/master/w_1600%2Cc_limit/GettyImages-821596.jpg"||propertyDetails?.images[0]?.imageURL}></img>
    //               <p>Les Vagues by Elie Saab{propertyDetails?.projecT_NAME} <span>{propertyDetails?.city}{propertyDetails?.location ? `, ${propertyDetails?.location}` : ""}Doha, Qatar</span></p>
    //             </Typography>
    //             <Typography variant="h4">
    //              2
    //               {state?.units?.length > 1
    //                 ? `${state?.units?.length} Units`
    //                 : `${state?.units?.length} Unit`}
    //             </Typography>
    //           </Grid>
    //           <Grid className="assign-ownership-accordian">
    //             {
    //             units?.length > 0 &&
    //              (units?.map((val, index) =>
    //               <>
    //                 <ControlledAccordions
    //                   ownership={ownership}
    //                   setOwnership={setOwnership}
    //                   val={val}
    //                   key={index}
    //                   data={data}
    //                   setData={setData}
    //                   primary={primary}
    //                   setPrimary={setPrimary}
    //                   idx={index}
    //                   notification={notification}
    //                 />
    //                 {displayError && ownership[`${Object.keys(val)[0]}_sum`] != 100 &&
    //                   <span className="assign-ownership-errormassage">{translate("ra.notification.ownershipPercentageExceed")}</span>}
    //               </>
    //             ))}
    //           </Grid>
    //           <Grid className="assign-ownership-button-panel desktop-only">
    //             <Button variant="contained" onClick={() => onClickReview()} >{translate("ra.button.review")}</Button>
    //           </Grid>
    //           <Grid container className="mobile-only" spacing={0}>
    //             <Grid container className="company-details-button" spacing={0}>
    //               <Button
    //                 variant="outlined"
    //                 onClick={() => onClickBack()}
    //               >
    //                 {translate("ra.button.back")}
    //               </Button>
    //               <Button
    //                 variant="contained"
    //                 onClick={() => onClickReview()} >
    //                 {translate("ra.button.review")}
    //               </Button>
    //             </Grid>
    //           </Grid>
    //         </Grid>
    //       </Container>
    //     </Container>
    //   </Grid>
    //   <div>
    //     {openPopup &&
    //       <DialogBox
    //         openPopup={openPopup}
    //         setOpenPopup={setOpenPopup}
    //         icon="add-buyer"
    //         content={translate("ra.content.addMoreBuyer")}
    //         dialogAction="question"
    //         handleNo={() => setOpenPopup(false)}
    //         handleYes={() => {
    //           setOpenPopup(false);
    //           createNewTab();
    //         }}
    //       />
    //     }
    //   </div>
    // </>
    