import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Grid, Typography, Link } from "@mui/material";
import Loader from "Components/Loader";

const ProfileName = ({ name, designation, image, title, email, phone }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (name) {
      setLoading(false);
    }
  }, [name]);

  return (
    <>
      {loading && <Loader />}
      <Grid container className="profile-top-panel">
        {image ? (
          <Typography className="profile-pic">
            <img src={image} alt={name} onLoad={() => setLoading(false)} />
          </Typography>
        ) : (
          name && (
            <Typography className="profile-pic" />
          ) 
        )}
        <Typography className="profile-top-info" variant="div">
          <Typography variant="h3">{`${title || ""} ${name || ""}`}</Typography>
          <Typography variant="h4">{designation}</Typography>
          <Typography className="bdm-contact-link" variant="div">
          {email &&
            <Link
              sx={{
                textTransform: "none",
              }}
              className="profile-link email-link"
              href={"mailto:" + email}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="iconwasalt icon-email" />
              {email}
            </Link>
          }
          {phone &&
            <Link
              sx={{
                textTransform: "none",
              }}
              className="profile-link email-link"
              href={"tel:" + phone}
            >
              <i className="iconwasalt icon-call-outline" />
              {phone}
            </Link>
          }
          </Typography>
        </Typography>
      </Grid>
    </>
  );
};

export default ProfileName;
