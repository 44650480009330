import { apiServiceRequest } from 'Utils/axiosInstance'
import { baseURL } from 'Utils/baseUrl'
import { getUserToken } from 'Utils/tokenProvider'
const paymentPlanService = async (
  setPaymentPlan,
  setPaymentPlanLoading,
  notify,
  unitId
) => {
  setPaymentPlanLoading(true)
  const res = await apiServiceRequest({
    method: 'POST', // Specify the POST method
    url: `${baseURL}GetBrokerPaymentPlanbyPropertyID`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
    data: {
      unitCode: unitId,
    },
  })

  if (res?.successStatus) {
    const paymentPlanData = res?.response?.map((item) => ({
      // Map each item in the response to the desired format
      // Modify the properties as needed
      tasK_NUMBER: item.name,
      percentage: item.percent,
      duE_DATE: item.eventDate,
      ...item,
    }))
    // Update the state with modified data
    setPaymentPlan(paymentPlanData)
  }
  setPaymentPlanLoading(false)
}

export { paymentPlanService }
