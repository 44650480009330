import React from "react";
import { useTranslate } from "react-admin";
import { Grid, Typography } from "@mui/material";
import { statusComplete } from "Utils/constantValues";

const BookingStatus = ({ booking_status, percentage_paid, spa_signed }) => {
  const translate = useTranslate();

  return (
    <Grid container className="booking-details-allinfo" spacing={0}>
      <Grid container className="booking-details" spacing={0}>
        <Typography variant="subtitle1">
          {translate("ra.fieldName.bookStatus")}
        </Typography>
        <Typography>
          {statusComplete.includes(booking_status)
            ? translate("ra.fieldName.completed")
            : booking_status === "cancelled"
            ? translate("ra.fieldName.cancelled")
            : translate("ra.fieldName.pending")}
        </Typography>
      </Grid>
      <Grid container className="booking-details" spacing={0}>
        <Typography variant="subtitle1">
          {translate("ra.fieldName.percentagePaid")}
        </Typography>

        <Typography>
          {booking_status === "completed"
            ? translate("ra.fieldName.completed")
            : statusComplete.includes(booking_status) && percentage_paid
            ? `${percentage_paid}%`
            : "-"}
        </Typography>
      </Grid>
      <Grid container className="booking-details" spacing={0}>
        <Typography variant="subtitle1">
          {translate("ra.fieldName.spaSign")}
        </Typography>
        <Typography>
          {statusComplete.includes(booking_status) && spa_signed == "1"
            ? translate("ra.fieldName.completed")
            : "-"}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BookingStatus;
