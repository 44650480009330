import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import ErrorComponent from 'Components/Common/ErrorComponent';
import { formatSizeUnits } from 'Utils/commonFunctions';
import { docTypes, docFileTypes } from "Utils/constantValues";
import { useTranslate } from "react-admin";

const MultipleDocumentUpload = ({
  documentFilesArray,
  setDocumentFilesArray,
  maxFile,
  documentObjectArray,
  setDocumentObjectArray,
  fileType,
  error,
  message,
  notification,
  setErrorDisplay,
  deleteId,
  setDeleteId,
  clearErrors
}) => {

  const [docValue, setDocValue] = useState(null);
  const translate = useTranslate();

  const setDocument = (e) => {
    const currentFile = e.target.files;
    //checking for file type
    if (currentFile?.length) {
      if (docTypes.includes(currentFile[0].type) || docFileTypes.includes(currentFile[0].doc_extension)) {
        // checking for file size
        if (Math.round(currentFile[0].size / 1024) <= 10240) {
          const array = [...documentFilesArray];
          array.push(e.target.files[0]);
          setDocumentFilesArray(array);
          const documentsArray = [...documentObjectArray];
          documentsArray.push({
            doc_name: fileType === "passport" ? "Passport Docs" : fileType === "trading_license" ? "Trading Docs" : "Additional Docs",
            file_name: e.target.files[0]?.name,
            doc_type: fileType
          })
          if (clearErrors) {
            clearErrors("passportDocFiles");
            clearErrors("additionalDocFiles");
            clearErrors("tradeDocFiles");
          }
          setDocumentObjectArray(documentsArray)
          setDocValue("");
        } else {
          notification("ra.notification.fileSizeCheck", { type: "error" });
        }
      }
      else {
        notification("ra.notification.fileTypeCheck", { type: "error" });
      }
    }
  }

  const deleteFile = (ind, file) => {
    let documentArray = [...documentFilesArray];
    let deleteArray = deleteId && [...deleteId];
    let documents = [...documentObjectArray];

    documents = documents?.filter((_, index) => ind !== index);
    documentArray = documentArray?.filter((_, index) => ind !== index);
    file?.id && !deleteArray.includes(file?.id) && deleteArray?.push(file?.id);

    setDeleteId && setDeleteId(deleteArray);
    setDocumentFilesArray(documentArray);
    setDocumentObjectArray(documents);
    setErrorDisplay && setErrorDisplay(true);
  }

  const handleClick = event => {
    const { target = {} } = event || {};
    target.value = "";
  };

  return (
    <div className="new-select-class">
      {documentFilesArray?.length > 0 &&
        documentFilesArray?.map((file, index) =>
          <Grid key={index} className="document-add-panel">
            <h6><a>{file?.name || file?.file_name}</a></h6>
            <p><span>{formatSizeUnits(file?.size ? file?.size : "")}</span>
              <i className="iconwasalt icon-delete" onClick={() => deleteFile(index, file)} />
            </p>
          </Grid>
        )}
      <>
        {maxFile ?
          documentFilesArray?.length < maxFile &&
          (
            <Grid className="muibutton-upload input-hide">
              <i className="iconwasalt icon-add-file" /> {translate("ra.fieldName.uploadDoc")}
              <input
                onClick={handleClick}
                value={docValue}
                onChange={(e) => setDocument(e)}
                type="file"
                accept="application/pdf, image/png, image/jpeg"
              />
            </Grid>
          )
          :
          <Grid className="muibutton-upload input-hide">
            <i className="iconwasalt icon-add-file" /> {translate("ra.fieldName.uploadDoc")}
            <input
              onClick={handleClick}
              style={{ color: "transparent" }}
              value={docValue}
              onChange={(e) => setDocument(e)}
              type="file"
              accept="application/pdf, image/png, image/jpeg"
            />
          </Grid>
        }
      </>
      {error && <ErrorComponent errors={message} />}
    </div >
  )
}

export default MultipleDocumentUpload;