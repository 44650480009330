const titleValues = ["Dr.", "Miss", "Mr.", "Mrs.", "Ms.", "Sir"];
const roleValues = [
  { name: "Salesperson", value: "sales_person" },
  { name: "Admin", value: "admin" },
];
const activeValues = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 0 },
];
const navItems = [
  { title: "Home", link: "/home" },
  { title: "My Contacts", link: "/contacts" },
  { title: "My Bookings", link: "/my-bookings" },
];
const unit_types = [
  { name: "Sqft", value: "sqft" },
  { name: "Sqm", value: "sqm" },
];
const sortData = [
  { name: "Price: High to Low", value: "price-desc" },
  { name: "Price: Low to High", value: "price-asc" },
  { name: "Size: Largest to smallest", value: "size-desc" },
  { name: "Size: Smallest to largest", value: "size-asc" },
];
const licensed = [
  { name: "Real Estate Licensed", value: "L" },
  { name: "Real Estate Non-Licensed", value: "NL" },
];
const transferCodeValues = ["Sort Code", "IBAN Number", "IFSC Code", "Others"];
const transferCodePlaceholder = [
  "Sort Code",
  "IBAN Number",
  "IFSC Code",
  "Enter other code",
];
const documentNames = [
  "Brochure",
  "Sales Offer",
  "Unit Layout",
  "Marketing Assets",
  "Payment Plan",
];
const roleCheck = ["root", "authorized_signatory"];
const docTypes = ["application/pdf", "image/png", "image/jpeg", "image/jpg"];
const docFileTypes = ["pdf", "png", "jpeg", "jpg"];
const contactStatus = [
  { name: "All", value: "All" },
  { name: "Prospect", value: "Prospect" },
  { name: "Customer", value: "Customer" },
];
const bookingStatus = [
  { name: "All", value: "All" },
  { name: "Pending", value: "Pending" },
  { name: "Completed", value: "Completed" },
];
const percentPaid = [
  { name: "All", value: "All" },
  { name: "Less than 10%", value: "Less than 10%" },
  { name: "10%", value: "10%" },
  { name: "20%", value: "20%" },
  { name: "Above 20%", value: "Above 20%" },
];
const timeStatus = [
  { name: "Recent", value: "Recent" },
  { name: "Last 3 months", value: "Last 3 months" },
  { name: "Last 6 months", value: "Last 6 months" },
  { name: "Last 1 year", value: "Last 1 year" },
  { name: "Custom Range", value: "Custom Range" },
];
const roleData = [...roleCheck, "admin"];

const statusComplete = [
  "raise_invoice_at_10",
  "raise_invoice_at_20",
  "already_raise_invoice_at_10",
  "already_raise_invoice_at_20",
  "completed",
];

const invoiceStatus = [
  "raise_invoice_at_10",
  "raise_invoice_at_20",
  "already_raise_invoice_at_10",
  "already_raise_invoice_at_20",
];

const pendingStatus = ["awaiting_payment_milestone", "completed"];

const statusButtonBlock = [
  "awaiting_payment_milestone",
  "raise_invoice_at_10",
  "raise_invoice_at_20",
  "already_raise_invoice_at_10",
  "already_raise_invoice_at_20",
  "completed",
];

const modifiedStatusButtonBlock = ["cancelled", "booking_in_progress"];

const bookingTableText = {
  pending: "ra.fieldName.pending",
  assign_ownership_percentage: "ra.fieldName.ownership",
  review_customer_information: "ra.fieldName.reviewCustomer",
  choose_token_payment_method: "ra.fieldName.chooseToken",
  attach_token_payment_proof: "ra.fieldName.attachToken",
  booking_in_progress: "ra.fieldName.uploadForm",
  upload_signed_reservation_form: "ra.fieldName.uploadForm",
  awaiting_payment_milestone: "ra.fieldName.awaitingPay",
  raise_invoice_at_10: "ra.fieldName.10_invoice",
  raise_invoice_at_20: "ra.fieldName.20_invoice",
  already_raise_invoice_at_10: "ra.fieldName.10_invoice_raised",
  already_raise_invoice_at_20: "ra.fieldName.20_invoice_raised",
  completed: "ra.fieldName.paymentDone",
  cancelled: "ra.fieldName.uploadForm",
};

const bookingStatusText = {
  pending: "ra.fieldName.pending",
  assign_ownership_percentage: "ra.fieldName.ownership",
  review_customer_information: "ra.fieldName.reviewCustomer",
  choose_token_payment_method: "ra.fieldName.chooseToken",
  attach_token_payment_proof: "ra.fieldName.attachToken",
  booking_in_progress: "ra.fieldName.uploadForm",
  upload_signed_reservation_form: "ra.fieldName.uploadForm",
  awaiting_payment_milestone: "ra.fieldName.awaitingPay",
  raise_invoice_at_10: "ra.fieldName.paidOff",
  raise_invoice_at_20: "ra.fieldName.paidOff",
  already_raise_invoice_at_10: "ra.fieldName.invoiceRequested",
  already_raise_invoice_at_20: "ra.fieldName.invoiceRequested",
  completed: "ra.fieldName.paymentDone",
};

const bookingStatusSubText = {
  assign_ownership_percentage: "ra.fieldName.updateOwnership",
  review_customer_information: "ra.fieldName.reservationInfo",
  choose_token_payment_method: "ra.fieldName.shareToken",
  attach_token_payment_proof: "ra.fieldName.procureToken",
  booking_in_progress: "ra.fieldName.signedCopy",
  upload_signed_reservation_form: "ra.fieldName.signedCopy",
  awaiting_payment_milestone: "ra.fieldName.noPayment",
  raise_invoice_at_10: "ra.fieldName.10_Percent",
  raise_invoice_at_20: "ra.fieldName.20_Percent",
  already_raise_invoice_at_10: "ra.fieldName.10_Percent_raised",
  already_raise_invoice_at_20: "ra.fieldName.20_Percent_raised",
  completed: "ra.fieldName.completed",
};

const bookingAccordionText = {
  awaiting_payment_milestone: "ra.fieldName.awaitingPay",
  raise_invoice_at_10: "ra.fieldName.10_invoice",
  raise_invoice_at_20: "ra.fieldName.20_invoice",
  already_raise_invoice_at_10: "ra.fieldName.10_invoice_raised",
  already_raise_invoice_at_20: "ra.fieldName.20_invoice_raised",
  completed: "ra.fieldName.paymentDone",
};


const sfBookingstatusArray = [
  "Pre-reserved",
  "Reserved",
  "Restricted",
  "Available",
  "Unreleased",
  "Sold",
  "End Dated"
];

export {
  titleValues,
  roleValues,
  activeValues,
  navItems,
  unit_types,
  sortData,
  licensed,
  transferCodeValues,
  transferCodePlaceholder,
  documentNames,
  roleCheck,
  docTypes,
  docFileTypes,
  contactStatus,
  bookingStatus,
  percentPaid,
  timeStatus,
  roleData,
  statusComplete,
  invoiceStatus,
  pendingStatus,
  statusButtonBlock,
  bookingTableText,
  bookingStatusText,
  bookingStatusSubText,
  bookingAccordionText,
  modifiedStatusButtonBlock,
  sfBookingstatusArray,
};
