import React, { useEffect, useState } from 'react'
import { useRedirect, useTranslate, useStoreContext } from 'react-admin'
import {
  Grid,
  Container,
  Typography,
  Pagination,
  useMediaQuery,
  Checkbox,
  FormControlLabel,
} from '@mui/material'
import Header from 'Components/Header'
import SearchFilter from 'Components/SearchFilter'
import PropertyCard from 'Components/PropertyCard'
import BackButton from 'Components/BackButton'
import Popup from 'Components/Popup/Popup'
import NoticePopUp from 'Components/Popup/NoticePopUp'
import ButtonComponent from 'Components/Common/ButtonComponent'
import { addRemoveFromArray } from 'Utils/formArrayValues'
import {
  assignWishList,
  deleteWishList,
  getWishListData,
  wishlistCount,
} from 'Services/Salesforce/wishlistService'

import Loader from 'Components/Loader'
import userDetails from 'Services/userDetailsServices'
import { useLocation, useNavigate } from 'react-router-dom'
import { filterProjectList } from 'Services/Salesforce/projectService'
import { createQueryParams } from 'Utils/queryValues'
import bdmDetails from 'Services/bdmDetailService'
import { unitPageShareList } from 'Utils/documentFormation'
import UnitPageListView from './UnitPageListView'
import { brokerDocuments } from 'Services/Salesforce/projectService'
import gtagEventFire from 'Utils/ga4EventTrigger'
import { removeLocalStorage } from 'Utils/removeStorage'

/** SalesForce */
import {
  unitListInfo,
  unitListCount,
  brokerUnitLayout,
  brokerSaleOfferService,
  brokerUnitLayoutSaleOfferService,
} from 'Services/Salesforce/unitService'
const UnitListing = ({ path, notification }) => {
  const [projectData, setProjectData] = useState([])
  const [count, setCount] = useState(null)
  const [totalNoOfRecords, setTotalNoOfRecords] = useState(null)

  const [changeView, setChangeView] = useState(false)
  const [selectedProject, setSelectedProject] = useState([])
  const [wishlistProject, setWishlistProject] = useState([])
  const [openPopup, setOpenPopup] = useState(false)
  const [openNoticePopUp, setOpenNoticePopup] = useState(false)

  const [loading, setLoading] = useState(true)
  const [wishlistloading, setwishlistLoading] = useState(true)
  const [unitLoading, setUnitLoading] = useState(false)
  const [filterLoading, setFilterLoading] = useState(false)
  const [userData, setUserData] = useState(null)
  const [page, setPage] = useState(1)
  const [filterData, setFilterData] = useState([])
  const [filterList, setFilterList] = useState([])
  const [bdmLoading, setBdmLoading] = useState(null)
  const [bdm, setBdm] = useState(null)
  const [unitDocument, setUnitDocument] = useState([])
  const [documentsLoading, setDocumentsLoading] = useState(false)
  const [saleLoading, setSaleLoading] = useState(false)
  const [showSold, setShowSold] = useState(false)
  const [salesOffer, setSalesOffer] = useState([])
  const [docList, setDocList] = useState([])
  const [unitPlanLoading, setUnitPlanLoading] = useState(false)
  const [unitPlanDocument, setUnitPlanDocument] = useState([])
  const pageCount = 12 // count per page view

  const redirect = useRedirect()
  const navigate = useNavigate()
  const location = useLocation()
  const translate = useTranslate()
  const store = useStoreContext()
  const param = new URLSearchParams(location?.search)
  const params = Object.fromEntries(param.entries())

  // changing pagination design based on mobile and desktop
  const matches = useMediaQuery('(max-width:600px)')
  const endCount = Math.min(page * pageCount, totalNoOfRecords)

  const listingDetails = async () => {
    const userDetail = await userDetails(
      setLoading,
      setUserData,
      '',
      notification
    )
    unitListApi(userDetail, params)
    bdmDetails(
      setBdm,
      setBdmLoading,
      notification,
      userDetail?.business_development_manager
    )
  }

  const unitListApi = (
    userDetail,
    params,
    unloadPage,
    projectValue,
    wishlistProject
  ) => {
    const pageNo = parseInt(params?.page) || 1
    if (!unloadPage) {
      setPage(pageNo)
      setCount(0)
    }
    setSelectedProject([])

    if (path === 'wishlist') {
      getWishListData(
        setwishlistLoading,
        setProjectData,
        setCount,
        setTotalNoOfRecords,
        setWishlistProject,
        userDetail,
        pageNo,
        notification,
        pageCount,
        unloadPage,
        projectValue,
        wishlistProject,
        setUnitLoading,
        callingDocApi,
        params
      )
    } else {
      unitListInfo(
        setwishlistLoading,
        setProjectData,
        setCount,
        setTotalNoOfRecords,
        userDetail,
        params,
        setWishlistProject,
        callingDocApi
      )
    }
  }

  const unitCount = (setLoader, setDataCount, urlParam) => {
    if (path) {
      wishlistCount(setLoader, setDataCount, userData, urlParam, notification)
    } else {
      unitListCount(setLoader, setDataCount, userData, urlParam, notification)
    }
  }

  const callingDocApi = async (resData, projectId) => {
    
    if (resData?.length) {
      brokerDocuments(
        setDocList,
        setDocumentsLoading,
        notification,
        projectId?.toString()
      )
      await brokerUnitLayoutSaleOfferService(
        setSalesOffer,
        setSaleLoading,
        setUnitPlanDocument,
        setUnitPlanLoading,
        notification,
        '',
        '',
        '',
        '',
        resData
      )
    }
  }

  useEffect(() => {
    localStorage?.removeItem('params')
    setChangeView(params?.view || false)
    setFilterList([])
    listingDetails()
    filterProjectList(
      setFilterLoading,
      setFilterData,
      notification,
      params,
      setFilterList
    )
  }, [path])

  const changeViewHandler = () => {
    gtagEventFire(`${changeView ? 'grid' : 'list'}-view`, {
      user_email: userData?.email,
    })
    setChangeView(!changeView)

    const filterUrl = createQueryParams(
      [],
      params?.sort,
      page > 1 ? page : null,
      params?.countrysearch,
      params,
      'unitlist',
      !changeView
    )
    navigate(
      {
        pathname: path ? '/wishlist' : '/unit-listing',
        search: filterUrl?.url,
        hash: '#',
      },
      { replace: true }
    )
  }

  const projectSelectHandler = (e, id) => {
    e.stopPropagation()
    const selectedData = [...selectedProject]
    addRemoveFromArray(id, selectedData, setSelectedProject)
  }

  const selectHandler = (e) => {
    let value = []
    if (e.target.checked) {
      value = projectData?.map((list) => list?.unit_id)
    }
    setSelectedProject(value)
  }

  const removeWishList = (unitId, index) => {
    if (index >= 0) {
      const selectedWishlist = [...wishlistProject]
      selectedWishlist.splice(index, 1)
      setWishlistProject(selectedWishlist)
    } else {
      const projectDataValue = [...projectData]
      const index = projectDataValue.findIndex(
        (list) => list?.unit_id === unitId
      )
      projectDataValue.splice(index, 1)
      setProjectData(projectDataValue)
      setCount(count - 1)

      const pageNo = parseInt(params?.page) || 1
      let pageValue = 1
      let unLoad = false

      if (projectDataValue?.length === 0) {
        // if no data in current page - switching to previous page
        pageValue = pageNo > 1 ? pageNo - 1 : pageNo
      } else if (
        (count - 1) / pageCount >= pageNo &&
        projectDataValue?.length === 11
      ) {
        // loading next page data into current page if wishlist is deleted
        pageValue = pageNo
        unLoad = true
      }

      if (
        projectDataValue?.length === 0 ||
        ((count - 1) / pageCount >= pageNo && projectDataValue?.length === 11)
      ) {
        const filterUrl = createQueryParams(
          [],
          params?.sort,
          pageValue,
          params?.countrysearch,
          params,
          'unitlist',
          params?.view
        )
        navigate(
          {
            pathname: '/wishlist',
            search: filterUrl?.url,
            hash: '#',
          },
          { replace: true }
        )
        unitListApi(
          userData,
          filterUrl?.params,
          unLoad,
          projectDataValue,
          wishlistProject
        )
      }
    }
    notification('ra.notification.unitRemove', {
      type: 'success',
    })
  }

  const wishlistHandler = (unitId, list) => {
    if (path === 'wishlist') {
      deleteWishList(notification, unitId, removeWishList, -1, setFilterLoading)
    } else {
      const selectedWishlist = [...wishlistProject]
      const index = selectedWishlist.indexOf(unitId)

      if (index > -1) {
        deleteWishList(
          notification,
          unitId,
          removeWishList,
          index,
          setFilterLoading
        )
      } else {
        assignWishList(
          notification,
          unitId,
          list,
          selectedWishlist,
          setWishlistProject,
          setFilterLoading
        )
        gtagEventFire('move-to-wishlist', {
          user_email: userData?.email,
          property_name: list?.title,
          unit_id: list?.unit_no,
        })
      }
    }
  }

  const handleBulkShare = async () => {
    setOpenPopup(true)
    const filterProjectList = projectData?.filter((data) =>
      selectedProject?.includes(data?.unit_no)
    )
    filterProjectList?.map((project) => {
      {
        gtagEventFire('unit_share', {
          user_email: userData?.email,
          property_name: project?.title,
          unit_id: project?.unit_no,
        })
      }
    })
    const docData = [...salesOffer, ...unitPlanDocument]
    const unitShare = unitPageShareList(filterProjectList, docList, docData)
    setUnitDocument(unitShare)
  }

  const handleBulkBook = () => {
    setOpenNoticePopup(true)
    // const filterProjectList = projectData?.filter((data) =>
    //   selectedProject?.includes(data?.unit_no)
    // )
    // const differentProject = filterProjectList?.filter(
    //   (unit, index) => unit?.project_id !== filterProjectList[0]?.project_id
    // )
    // if (differentProject?.length) {
    //   notification('ra.notification.selectSingleProject', { type: 'error' })
    //   differentProject?.map((unit) => filterProjectList?.pop(unit))
    //   setSelectedProject([])
    // } else {
    //   removeLocalStorage()
    //   localStorage.removeItem('lockIds')
    //   store.removeItem('deleteId')
    //   store.removeItem('deleteCode')
    //   navigate('/review-units', {
    //     state: {
    //       id: filterProjectList?.map((unit) => unit?.unit_id),
    //       no: filterProjectList?.map((unit) => unit?.unit_no),
    //       page: path === 'wishlist' ? 'wishlist' : 'unit-list',
    //       params: {
    //         projectid: filterProjectList[0]?.project_id,
    //         country: filterProjectList[0]?.location,
    //         page: params?.page,
    //         paramData: param?.toString(),
    //         id: filterProjectList?.map((unit) => unit?.unit_id),
    //         no: filterProjectList?.map((unit) => unit?.unit_no),
    //       },
    //     },
    //   })
    // }
  }

  const closeShare = () => {
    setUnitDocument([])
    setOpenPopup(false)
  }
  const closeNoticePopUp = () => {
    setOpenNoticePopup(false)
  }

  const handlePageChange = async (e, page) => {
    setPage(page)
    const filterUrl = createQueryParams(
      [],
      params?.sort,
      page,
      params?.countrysearch,
      params,
      'unitlist',
      params?.view
    )
    navigate(
      {
        pathname: path ? '/wishlist' : '/unit-listing',
        search: filterUrl?.url,
        hash: '#',
      },
      { replace: true }
    )
    unitListApi(userData, filterUrl?.params)
  }

  const redirectPath = (unitId, projectName, unitNo) => {
    gtagEventFire('unit_card_click', {
      user_email: userData?.email,
      property_name: projectName,
      unit_id: unitNo,
    })
    if (path === 'wishlist') {
      redirect(`/wishlist-property-details?unitid=${unitId}`)
    } else {
      redirect(`/unitlisting-details?unitid=${unitId}`)
    }
  }

  useEffect(() => {
    const projectPage = localStorage.getItem('projectPage')
    if (projectPage && projectData?.length === 0) {
      setShowSold(true)
    } else {
      setShowSold(false)
    }
  }, [projectData])

  return (
    <>
      <Header
        path={path}
        picture={userData?.profile}
        pictureLoading={loading}
        page="unit"
        notification={notification}
        userData={userData}
      />
      {(loading ||
        filterLoading ||
        unitLoading ||
        wishlistloading ||
        (openPopup &&
          (documentsLoading || saleLoading || unitPlanLoading))) && <Loader />}
      {!loading && (
        <>
          <BackButton page="unit" />
          <SearchFilter
            filterData={filterData}
            userData={userData}
            projectListApi={unitListApi}
            params={params}
            filterList={filterList}
            setFilterList={setFilterList}
            page="unitlist"
            changeViewHandler={changeViewHandler}
            changeView={changeView}
            path={path ? '/wishlist' : '/unit-listing'}
            getDataCount={unitCount}
            count={totalNoOfRecords}
            translate={translate}
            notification={notification}
          />
          <Container
            className={`card-panel unit-list-page ${
              selectedProject?.length > 0 ? 'pb150' : ''
            }`}
          >
            {!wishlistloading && (
              <Typography
                className={`available-panel ${
                  params?.view ? 'remove-marign-table' : ''
                }`}
                component="div"
              >
                <Typography
                  variant="h2"
                  component="h2"
                  className="total-result"
                >
                  {path
                    ? translate('ra.fieldName.savedUnit')
                    : translate('ra.fieldName.availableUnit')}{' '}
                  ({count})
                </Typography>
                {count > 0 && (
                  <Typography className="select-all">
                    <FormControlLabel
                      value="start"
                      control={
                        <Checkbox
                          checked={
                            projectData?.length &&
                            selectedProject?.length === projectData?.length
                          }
                          onClick={(e) => selectHandler(e)}
                        />
                      }
                      label={translate('ra.fieldName.selectAll')}
                      labelPlacement="start"
                    />
                  </Typography>
                )}
              </Typography>
            )}
            {!wishlistloading &&
              (projectData?.length > 0 ? (
                <>
                  {changeView ? (
                    <UnitPageListView
                      projectData={projectData}
                      selectedProject={selectedProject}
                      projectSelectHandler={projectSelectHandler}
                      redirectPath={redirectPath}
                      translate={translate}
                    />
                  ) : (
                    <Grid className="grid-3">
                      {projectData?.map((list, idx) => (
                        <PropertyCard
                          key={idx}
                          selectedProject={selectedProject}
                          projectSelectHandler={projectSelectHandler}
                          wishlistHandler={wishlistHandler}
                          wishlistProject={wishlistProject}
                          redirectPath={redirectPath}
                          path={path}
                          bdmInfo={bdm}
                          list={list}
                          userData={userData}
                        />
                      ))}
                    </Grid>
                  )}
                  {totalNoOfRecords > pageCount && (
                    <>
                      <Pagination
                        variant="outlined"
                        count={Math.ceil(totalNoOfRecords / pageCount)}
                        siblingCount={1}
                        boundaryCount={matches ? 0 : 2}
                        page={page}
                        onChange={handlePageChange}
                      />
                      <Typography className="pagination-counter">
                        {translate('ra.fieldName.pageCount', {
                          startCount: (page - 1) * pageCount + 1,
                          endCount:endCount,
                          totalCount: totalNoOfRecords,
                        })}
                      </Typography>
                    </>
                  )}
                </>
              ) : (
                <Typography className="no_data no-data-wishlist">
                  {path
                    ? translate('ra.noData.wishlist')
                    : showSold
                    ? translate('ra.noData.soldOut')
                    : translate('ra.noData.unit')}
                </Typography>
              ))}
            {selectedProject?.length > 0 && (
              <>
                <Grid className="share-section unit-share-button-panel">
                  {/*Selected Units Capsules */}
                  <Grid className="selected-units-section">
                    {selectedProject?.map((list, index) => (
                      <Typography key={index} className="filter-capsule">
                        {list}
                        <i
                          onClick={(e) => projectSelectHandler(e, list)}
                          className="iconwasalt icon-close-filled"
                        />
                      </Typography>
                    ))}
                  </Grid>
                  <Grid className="selected-button-section">
                    <ButtonComponent
                      onClick={handleBulkShare}
                      alt_name="share"
                      title1={translate('ra.fieldName.share')}
                      container_class="share-button outlined-button"
                      
                    />
                    <ButtonComponent
                      onClick={handleBulkBook}
                      alt_name="book"
                      title1={translate('ra.fieldName.unitPageBook')}
                      container_class="share-button"
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Container>
          {openPopup && (
            <Popup
              openPopup={openPopup}
              setOpenPopup={closeShare}
              documentArray={unitDocument}
              type="multiple"
              email={userData?.email}
            />
          )}

          {openNoticePopUp && (
            <NoticePopUp
              openNoticePopUp={openNoticePopUp}
              setOpenNoticePopUp={closeNoticePopUp}
              icon={'icon-error'}
              dialogClass="unit-not-available"
              innerContentClass="unit-inner-content"
             />
          )}
        </>
      )}
    </>
  )
}

export default UnitListing
