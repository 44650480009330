import React, { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button } from "@mui/material";
import { handleLogout } from "Utils/logout";
import { useRedirect, useTranslate } from "react-admin";
import { isinDraft } from "Utils/ui-lock-status";
import DialogBox from "Components/DialogBox";

const LogoutButton = ({ notification, os }) => {
  const [openDialog, setOpenDialog] = useState(null);
  const translate = useTranslate();
  const redirect = useRedirect();
  return (
    <>
      <Button
        className="logout-button"
        onClick={() => { isinDraft(os) ? setOpenDialog(true) : handleLogout(notification, redirect); }}
      >
        <LogoutIcon />
        {translate("ra.button.logout")}
      </Button>
      <DialogBox
        openPopup={openDialog}
        setOpenPopup={setOpenDialog}
        content={translate("ra.content.logoutMessage")}
        handleYes={() => handleLogout(notification, redirect)}
      />
    </>
  );
};

export default LogoutButton;
