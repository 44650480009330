import React from "react";
import { Typography, TextField, Autocomplete, InputAdornment } from "@mui/material";
import isRead from "Utils/ui-lock-status";
import { useTranslate } from "react-admin";

const AutoCompleteComponent = ({
  title,
  name,
  value,
  onChange,
  options,
  isEdit = false,
  placeholder,
  errors,
  children,
  register,
  readOnly,
  getOptionLabel,
  hide = false,
  os,
  message,
  className
}) => {
  const translate = useTranslate();

  return (
    <>
      {title && 
        <Typography variant="subtitle1">
          {title}
          {!hide && !isEdit && <span style={{ color: "#EF4349" }}>*</span>}
        </Typography>
      }

      {children}

      <Autocomplete
        sx={{
          "& legend": { display: "none" },
          width: "100%",
          marginBottom: "20px",
        }}
        readOnly={(!isEdit && isRead(os)) || readOnly}
        value={value}
        autoHighlight
        disablePortal
        getOptionLabel={getOptionLabel}
        onChange={onChange}
        options={options}
        className={className}
        popupIcon={
          <div className="select-drop-arrow" >
            <i className="iconwasalt icon-arrow-down" />
          </div>
        }
        renderInput={(params) => (
          <TextField
            variant="outlined"
            value={value}
            {...params}
            {...register(name, {
              required: !hide && !value && (message || translate("ra.validation.validInput"))
            })}
            placeholder={placeholder}
            error={Boolean(errors)}
            helperText={errors?.message}
          />
        )}
      />
    </>
  );
};

export default AutoCompleteComponent;
