import React, { useState } from "react";
import { useRedirect, useTranslate } from "react-admin";
import { useForm } from "react-hook-form";
import { Button, CardContent, Typography, Grid, TextField } from "@mui/material";
import image from "../../images/brokers_image.png";
import logo from "../../images/logo.svg";
import Loader from "Components/Loader";
import { emailPattern } from "Utils/regex-patterns";
import forgotPasswordService from "Services/forgotPasswordService";
import InputField from "Components/InputField";
import gtagEventFire from "Utils/ga4EventTrigger";

const ForgotPassword = ({ notification }) => {
  const [loading, setLoading] = useState(false);
  const [isDisableButton, setIsDisableButton] = useState(false);
  const redirect = useRedirect();
  const translate = useTranslate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleBack = () => {
    redirect("/login");
  };

  const onSubmit = async (data) => {
    forgotPasswordService(
      setIsDisableButton,
      setLoading,
      data,
      notification,
      redirect
    );
    gtagEventFire("forgot-password", {
      user_email: data?.email
    });
  };

  return (
    <>
      {loading && <Loader />}
      <Grid
        className="form-panel height100 form-fixed"
        container
        sx={{ minHeight: "100vh", width: "100%" }}
      >
        <Grid
          xs={{ display: "none" }}
          style={{ backgroundImage: `url(${image})` }}
          md={6}
        ></Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <CardContent className="forgot-password">
            <img src={logo} className="login-logo" alt="Dar logo" />
            <Typography className="form-user-name text-center">
              {translate("ra.pageTitle.forgotPassword")}
            </Typography>
            <Grid container spacing={0}>
              <InputField 
                name={translate("ra.fieldName.emailAddress")}
                titleClass="form-label"
                className="form-control"
                register={register} 
                errors={errors} 
                type="email"
                state="email"
                message={translate("ra.validation.emailError")}
                pattern={{
                  value: emailPattern,
                  message: translate("ra.validation.validEmail")
                }}
                placeholder={translate("ra.placeholder.email")}
                hide={true}
              />
            </Grid>
            <Button
              variant="contained"
              className={isDisableButton && "disabled"}
              onClick={handleSubmit(onSubmit)}
            >
              <Typography>{translate("ra.button.submit")}</Typography>
            </Button>
            <Button variant="outlined" onClick={handleBack}>
              {translate("ra.button.back")}
            </Button>
          </CardContent>
        </Grid>
      </Grid>
    </>
  );
};

export default ForgotPassword;
