import React, { useEffect, useState } from "react";
import {
    useList,
    ListContextProvider,
    Datagrid,
    TextField,
    WrapperField,
    FunctionField,
    useTranslate
} from 'react-admin';
import {
    Grid,
    Container,
    Typography,
    Checkbox,
    FormControlLabel
} from "@mui/material";
import { formatNumbers } from "Utils/thousandSeperators";
import { currencyStatus } from "Utils/currencyCheck";
import formatNumber from "Utils/conversionFunction";
import CheckBoxField from "Components/Common/CheckboxField";

const UnitPageListView = ({
    projectData,
    selectedProject,
    projectSelectHandler,
    redirectPath,
    userData
}) => {
    const listContext = useList({ data: projectData });
    const [showBorder, setShowBorder] = useState(false);
    const translate = useTranslate();

    const postRowStyle = (record, index) => ({
        backgroundColor: selectedProject?.includes(record?.unit_no) ? "#EEEFEA" : "",
    });

    useEffect(() => {
        let el = document.getElementById("unit-page-table");
        let elTop = el?.getBoundingClientRect()?.top - document.body.getBoundingClientRect()?.top;
        
        // adding spacing to top of table header and making it sticky
        window.addEventListener("scroll", function(){
            if (document.documentElement.scrollTop + 235 >= elTop){
                el.classList.add("table-sticky-position");
                setShowBorder(true);
            } else {
                el.classList.remove("table-sticky-position");
                setShowBorder(false);
            }
        });
      }, [])

    return (
        <Container className="unit-list-panel">
            <Grid className="my-team-panel unit-list-view">
                <div className={`sticky-table-wrapper ${showBorder ? "sticky-table-border" : ""}`}></div>
                <ListContextProvider value={listContext}>
                    <Datagrid id="unit-page-table" className="hover-unit-class" hover={false}
                        rowStyle={postRowStyle} bulkActionButtons={false}
                        setSort={false} rowClick={(id, resource, record) => redirectPath(record?.unit_id)}
                    >
                        {/*showing 2 row in one column with wrapperfield*/}
                        <WrapperField label={translate("ra.fieldName.project")} >
                            <TextField source="title" emptyText="-" />
                            <TextField className="unit_word_break" source="unit_no" emptyText="-" />
                        </WrapperField>
                        <TextField source="property_type" label={translate("ra.fieldName.propertyType")} emptyText="-" />

                        <FunctionField label={`${translate("ra.fieldName.area")}${userData?.unit_type ? `(${userData?.unit_type})` : ""}`}
                            emptyText="-" render={(record) => formatNumbers(record?.area)
                            } />
                        <TextField source="bedroom" label={translate("ra.fieldName.bedroom")} emptyText="-" />
                        <FunctionField label={`${translate("ra.fieldName.price")}${currencyStatus(userData?.currency) ? `(${userData?.currency})` : ""}`}
                            emptyText="-" render={(record) => <>{!currencyStatus(userData?.currency) ? record?.currency : ""} {formatNumber(record?.price)}</>
                            } />
                        <CheckBoxField
                            label={translate("ra.fieldName.selectUnit")}
                            selectedProject={selectedProject}
                            projectSelectHandler={projectSelectHandler}
                        />
                    </Datagrid>
                </ListContextProvider>
            </Grid>
            <Grid className="unit-list-mobile">
                {projectData?.map((list, idx) =>
                    <Typography
                        key={idx}
                        className={`unit-list-info ${selectedProject?.includes(list?.unit_no) ? "active-class" : ""}`}
                        component="div"
                        onClick={() => redirectPath(list?.unit_id)}
                    >
                        <Typography className="heading-info" component="div">
                            <Typography variant="h2" component="h2">
                                {list?.title}
                            </Typography>
                            <Typography className="select-all">
                                <FormControlLabel
                                    value="start"
                                    control={
                                        <Checkbox
                                            onClick={(e) => projectSelectHandler(e, list?.unit_no)}
                                            checked={selectedProject?.includes(list?.unit_no)}
                                        />
                                    }
                                />
                            </Typography>
                        </Typography>
                        <Typography variant="h3" component="h3">
                            <span>{list?.unit_no || "-"}</span>
                            <span className="seperator">|</span>
                            <span>{list?.property_type || "-"}</span>
                        </Typography>
                        <Typography className="area-info" component="div">
                            <Typography variant="h3" component="h3">
                                <span>{translate("ra.fieldName.area")}({list?.units})</span>
                                <span>{list?.area ? formatNumbers(list?.area) : "-"}</span>
                            </Typography>
                            <Typography variant="h3" component="h3">
                                <span>{translate("ra.fieldName.bedroom")}</span>
                                <span>{list?.bedroom || "-"}</span>
                            </Typography>
                            <Typography variant="h3" component="h3">
                                <span>{translate("ra.fieldName.price")}({currencyStatus(userData?.currency) ? userData?.currency : list?.currency})</span>
                                <span>{formatNumber(list?.price)}</span>
                            </Typography>
                        </Typography>
                    </Typography>
                )}
            </Grid>
        </Container>
    );
};

export default UnitPageListView;
