import { apiServiceRequest } from "Utils/axiosInstance";
import { getUserToken } from "Utils/tokenProvider";

//Bdm Details Service
const getBdmDetails = async(setBdm, setBdmLoading, notify)=>{
  const res = await apiServiceRequest({
    method: "Get",
    url: `/BDM-details`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });
  if(res?.code === "success") {
    setBdm(res?.data);
  }
  setBdmLoading(false);
  
}

export default getBdmDetails;
