import { apiServiceRequest } from "Utils/axiosInstance";
import { queryReviewPage } from "Utils/queryValues";
import { removeLocalStorage } from "Utils/removeStorage";
import { getUserToken } from "Utils/tokenProvider";

const createBookingId = async (setLoading, data, notify, handleSubmit) => {
    setLoading(true);
    const res = await apiServiceRequest({
        url: `/create-booking-id`,
        headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
        method: "post",
        data: data,
        notify: notify
    })
    if (res?.code === "success") {
        localStorage?.setItem("portalBookingId", res?.data?.portalBookingId);
        sessionStorage?.setItem("formStatus", "/booking-reservation-form");
        localStorage.setItem("editCustomer", false);
        localStorage.removeItem("editPath");
        localStorage.removeItem("booking_page");
        handleSubmit && handleSubmit();
    }
    else {
        notify(res?.message, { type: "error" });
    }
    setLoading(false);
}

export const blockUnits = async (
    setLoading, 
    data, 
    notify, 
    createBookingId, 
    setBookingIdLoading, 
    customerExistence, 
    handleSubmit,
    redirectUrl,
    navigate,
    logout,
    units,
    from
) => {
    if(setLoading) setLoading(true);
    const res = await apiServiceRequest({
        url: `/block-units`,
        headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
        method: "post",
        data: data,
        notify: notify
    })
    if (res?.code === "success") {
        if(customerExistence) {
            localStorage.setItem("lockIds", JSON.stringify(res?.data?.lockIds));
            localStorage.setItem("previous_url", "/review-units");

            const apiData = {
                project_id: data?.project_id, unit_ids: data?.unit_ids, lock_id: res?.data?.lockIds.join(",")
            }
            customerExistence === "new" ?
                createBookingId(setBookingIdLoading, apiData, notify, handleSubmit)
                : handleSubmit() && handleSubmit();
        }
        if(data?.is_initial === 0) {
            const booking_page = localStorage.getItem("booking_page") || null;
            const params = JSON.parse(localStorage.getItem("params")) || null;
            const customerType = localStorage.getItem("customerType");
            const tabs = localStorage.getItem("tabs");
            if(redirectUrl) {
                if (redirectUrl === "/logout") {
                    window.location.reload(); // remove all api which runs in previous page
                    logout();
                    notify("ra.notification.logout", { type: "success" });
                } 
                if (booking_page) {
                    navigate(booking_page);
                } else if (redirectUrl === "/review-units") {
                    navigate("/review-units", { state: queryReviewPage(params) });
                    window.location.reload();
                }else if(redirectUrl=='/review-customer-information'){
                    let pickedUnits=localStorage.getItem("units");
                    let pickedProjectIds=localStorage.getItem("project_id");
                    
                    navigate("/review-customer-information", { state: { pickedUnits, pickedProjectIds } });
                } else {
                    navigate(redirectUrl);
                    window.location.reload();
                }
            }
            removeLocalStorage();
        }
    }
    if(setLoading) setLoading(false);
}

export default createBookingId;