import React from "react";
import {
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography,
} from "@mui/material";
import ErrorComponent from "../ErrorComponent";

const RadioConditionCheck = ({
    title,
    hide = false,
    defaultValue,
    name,
    className,
    readOnly,
    requiredField,
    onChangeField,
    value1,
    disabled1,
    checked1,
    label1,
    value2,
    disabled2,
    checked2,
    label2,
    errors,
    formClass,
    register,
    errorClass
}) => {

    return (
        <>
            <Typography variant="subtitle1">
                {title}
                {!hide && <span style={{ color: "#EF4349" }}>*</span>}
            </Typography>
            <FormControl>
                <RadioGroup
                    row
                    defaultValue={defaultValue}
                    name="radio-buttons-group"
                    className={className}
                    inputProps={{
                        readOnly: readOnly
                    }}
                    disabled={readOnly}
                    {...register(name, {
                        required: requiredField,
                        onChange: onChangeField
                    })}
                >
                    <FormControlLabel
                        value={value1}
                        disabled={disabled1}
                        checked={checked1}
                        control={<Radio />}
                        label={label1}
                        {...register(name)}
                        className={formClass}
                    />
                    <FormControlLabel
                        value={value2}
                        disabled={disabled2}
                        checked={checked2}
                        control={<Radio />}
                        label={label2}
                        {...register(name)}
                        className={formClass}
                    />
                </RadioGroup>
            </FormControl>

            {errors && (
                <ErrorComponent className={`Mui-error ${errorClass}`} errors={errors} />
            )}
        </>
    );
};

export default RadioConditionCheck;
