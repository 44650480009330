import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Styles from "./styles.module.scss";

const DoubleRangeSlider = ({
  min = 0,
  max = 100,
  priceRange,
  setPriceRange,
  scaleAmount = 10000,
  minDistance = 10000,
  minLabel = 0,
  maxLabel,
  setValue
}) => {

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    let value = [];

    if (activeThumb === 0) {
      value = [Math.min(newValue[0] * scaleAmount, priceRange[1] - minDistance), priceRange[1]]
    } else {
      value = [priceRange[0], Math.max(newValue[1] * scaleAmount, priceRange[0] + minDistance)]
    }
    
    if(setValue) {
      setValue(value)
    } else {
      setPriceRange(value);
    }
  };

  return (
    <Box className="doublerange-info">
      <Slider
        min={min}
        max={max}
        defaultValue={[priceRange[0], priceRange[1]]}
        value={[priceRange[0] / scaleAmount, priceRange[1] / scaleAmount]}
        onChange={handleChange}
        disableSwap
      />
      <div className="min-max-value-text">
        {minLabel && <span>{minLabel}</span>}
        {maxLabel && <span>{new Intl.NumberFormat('en-US').format(maxLabel)}</span>}
      </div>
    </Box>
  );
}

export default DoubleRangeSlider;