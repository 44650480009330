import { apiServiceRequest } from "Utils/axiosInstance";

//forgot password Service
const forgotPasswordService = async (
  setIsDisableButton,
  setLoading,
  email,
  notify,
  redirect
) => {
  setLoading(true);
  const res = await apiServiceRequest({
    url: "/forgotPassword",
    method: "post",
    data: email,
    notify: notify,
  });
  if (res?.code === "success") {
    notify(res?.message, { type: "success" });
    setIsDisableButton(true);
    setTimeout(() => {
      redirect("/login");
    }, 1000);
  } else {
    notify(res?.message, { type: "error" });
  }
  setLoading(false);
};

export default forgotPasswordService;
