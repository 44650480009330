import { apiServiceRequest } from "Utils/axiosInstance";
import { getUserToken } from "Utils/tokenProvider";

const updateUnitBookingStatus = async (
  setLoading,
  data,
  notify,
  postApiCallFunction,
  bookingFailedPopup,
  setBookingFailedPopup,
  setProceed,
) => {
  setLoading(true);
  const res = await apiServiceRequest({
    url: "/update-unit-booking-status",
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    method: "put",
    data: data,
    notify: notify,
  });

  if (res?.code === "success") {
    postApiCallFunction && postApiCallFunction();
    setProceed && setProceed(true);
  } else {
    if (res.message.includes("booking time")) {
      if (!bookingFailedPopup) setBookingFailedPopup(true);
    } else {
      setProceed && setProceed(false);
      notify(res.message, { type: "error" });
    }
  }
  setLoading(false);
};

export default updateUnitBookingStatus;
