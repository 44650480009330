import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  FormControl,
  Container,
  Button
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useRedirect, useStoreContext, useStore, useTranslate } from "react-admin";
import registerService from "Services/registerService";
import Loader from "Components/Loader";
import isRead from "Utils/ui-lock-status";
import AutoCompleteComponent from "Components/Common/AutoComplete";
import userDetails from "Services/userDetailsServices";
import country from "Services/countryService";
import currency from "Services/currencyService";
import { transferCodePlaceholder, transferCodeValues } from "Utils/constantValues";
import { numberTextPattern } from "Utils/regex-patterns";
import InputField from "Components/InputField";
import Title from "Components/Title/Title";
import HeaderButton from "Components/HeaderButton";

import {useNavigate, useLocation } from 'react-router-dom';
import { isKsaPresent } from "Utils/commonFunctions";

const BankDetails = ({ pagePath, notification }) => {
  const location=useLocation();
   const navigate=useNavigate();
  const history_url=JSON.parse(localStorage.getItem("history_url"));
  const prevRedirectPage=localStorage.getItem("prevRedirectPage");
  const [os, setOs] = useState();
  const [formData, setFormData] = useStore();
  const [countryListLoading, setCountryListLoading] = useState(false);
  const [currencyListLoading, setCurrencyListLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countryState, setCountryState] = useState(null);
  const [ccode, setccode] = useState(null);
  const [currencyState, setCurrencyState] = useState(null);
  const [currencyCode, setCurrencyCode] = useState(null);

  const redirect = useRedirect();
  const store = useStoreContext();
  const translate = useTranslate();
  const form = useForm({
    mode: "onChange",
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    clearErrors,setError
  } = form;
  let user_id = store.getItem("user_id");
  let user_type = store.getItem("user_type");
  const step = 4;

  //set Oracle Status
  const setOracleStatus = (resData) => {
    resData?.oracle_status ? setOs(resData?.oracle_status) : setOs("-");
  };

  useEffect(() => {
    // !history_url?.some(path=>path==location.pathname)&&navigate(prevRedirectPage);
    (!(history_url?.some(path=>path==location.pathname)))&&navigate(prevRedirectPage);

    currency(setCurrencyList, setCurrencyListLoading, notification);
    country(setCountryList, setCountryListLoading, notification);
    userDetails(setLoading, setFormData, setOracleStatus, notification);
  }, []);

  const onSubmit = async (data) => {
    if (!user_type) {
      user_type = Number(localStorage.getItem("user_type"));
    }
    if (!user_id) {
      user_id = Number(localStorage.getItem("user_id"));
    }
    let {
      bank_acc_name,
      bank_acc_number,
      bank_name,
      bank_branch,
      bank_city,
      bank_swift_code,
      transfer_code,
      name_of_transfer_code,
      transfer_code_value,
    } = data;

    let apiRequestData = {
      step,
      user_type,
      user_id,
      bank_acc_name: bank_acc_name?.trim(),
      bank_acc_number,
      bank_name: bank_name?.trim(),
      bank_branch: bank_branch?.trim(),
      bank_city: bank_city?.trim(),
      bank_country: ccode,
      bank_currency: currencyCode,
      bank_swift_code: bank_swift_code?.trim(),
      transfer_code: parseInt(transfer_code),
      transfer_code_value: transfer_code_value?.trim(),
    };

    if (parseInt(transfer_code) === 4) {
      apiRequestData.name_of_transfer_code = name_of_transfer_code?.trim();
    }
   if(!transfer_code){
    setError('transfer_code', {type:"required",message:'Please enter a valid input'} )
    return
   }
    const redirectPath = pagePath ? "/register-agency/upload" : isKsaPresent(location?.pathname)?"/individual-ksa-licensed/upload":"/register-myself/upload";

    registerService(
      isRead,
      os,
      setLoading,
      apiRequestData,
      notification,
      redirect,
      redirectPath
    );
  };

  const transferC = () => {
    const transfer = watch("transfer_code");

    const transer_value = transferCodePlaceholder?.find((item, index) => {
      if (transfer == index + 1) {
        return <Typography key={index}>{item}</Typography>;
      }
    });
    return transer_value || "";
  };
 const backNavigation=()=>{
  
  let isAgency=location.pathname?.split('/')?.some(_=>_=="register-agency");
  isAgency? 
  navigate('/register-agency/personnel'):
  navigate(isKsaPresent(location?.pathname)?'/individual-ksa-licensed/contact':'/register-myself/contact')
 }
  useEffect(() => {
    if (formData?.bank_country) {
      const country = countryList?.find(
        (list) => list.country_name === formData?.bank_country
      );
      setCountryState(country);
      setccode(formData?.bank_country);
    }
  }, [formData?.bank_country, countryList]);

  useEffect(() => {
    if (formData?.bank_currency) {
      const currency = currencyList?.find(
        (currency) => currency.code === formData?.bank_currency
      );
      setCurrencyState(currency);
      setCurrencyCode(formData?.bank_currency);
    }
  }, [formData?.bank_currency, currencyList]);

  return (
    <>
      {(loading || countryListLoading || currencyListLoading) && <Loader />}
      {formData && (
        <Container className="company-container new-fix-container">
          <Container className="company-details">
            <HeaderButton notification={notification} os={os}  releaseBlockUnits={backNavigation}/>
            <Grid className="form-panel" sx={{ width: "100%" }}>              
              <Typography variant="h3">{translate("ra.pageTitle.bankDetails")}</Typography>

              {/* Name */}
              <Grid container spacing={0}>
                <InputField 
                  name={translate("ra.fieldName.accountName")}
                  register={register} 
                  errors={errors} 
                  defaultValue={formData?.bank_acc_name || ""}
                  id="bank_acc_name"
                  readOnly={isRead(os)}
                  state="bank_acc_name"
                  placeholderHide={true}
                />
              </Grid>

              {/* Account Number */}
              <Grid container spacing={0}>
                <InputField 
                  name={translate("ra.fieldName.accountNumber")}
                  register={register} 
                  errors={errors} 
                  defaultValue={formData?.bank_acc_number || ""}
                  id="bank_acc_number"
                  type="number"
                  onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                  readOnly={isRead(os)}
                  state="bank_acc_number"
                  placeholderHide={true}
                  message={translate("ra.validation.validNumber")}
                />
              </Grid>

              {/* Bank Name*/}
              <Grid container spacing={0}>
                <InputField 
                  name={translate("ra.fieldName.bankName")}
                  register={register} 
                  errors={errors} 
                  defaultValue={formData?.bank_name || ""}
                  id="bank_name"
                  readOnly={isRead(os)}
                  state="bank_name"
                  placeholderHide={true}
                />
              </Grid>

              {/* Bank Brach */}
              <Grid container spacing={0}>
                <InputField 
                  name={translate("ra.fieldName.branch")}
                  register={register} 
                  errors={errors} 
                  defaultValue={formData?.bank_branch || ""}
                  id="bank_branch"
                  readOnly={isRead(os)}
                  state="bank_branch"
                  placeholderHide={true}
                />
              </Grid>

              {/* Addres  */}
              <Grid
                className="full-section checkbox-panel bank-alignment new-select-class"
                container
                spacing={0}
              >
                <AutoCompleteComponent
                  title={translate("ra.fieldName.bankAddress")}
                  value={countryState}
                  onChange={(e, newInputValue) => {
                    setCountryState(newInputValue);
                    setccode(newInputValue?.country_name);
                    clearErrors("bank_country");
                  }}
                  options={countryList}
                  getOptionLabel={(option) => option?.country_name || ""}
                  name="bank_country"
                  placeholder={translate("ra.placeholder.country")}
                  errors={errors?.bank_country}
                  register={register}
                  os={os}
                >
                  <InputField 
                    register={register} 
                    errors={errors} 
                    defaultValue={formData?.bank_city || ""}
                    id="bank_city"
                    readOnly={isRead(os)}
                    state="bank_city"
                    className="bank-city"
                    placeholder={translate("ra.placeholder.city")}
                  />
                </AutoCompleteComponent>
              </Grid>

              {/* Currency */}
              <Grid container className="new-select-class" spacing={0}>
                <AutoCompleteComponent
                  title={translate("ra.fieldName.currency")}
                  value={currencyState}
                  onChange={(e, newInputValue) => {
                    setCurrencyState(newInputValue);
                    setCurrencyCode(newInputValue?.code);
                    clearErrors("bank_currency");
                  }}
                  options={currencyList}
                  getOptionLabel={(option) => option?.name || ""}
                  name="bank_currency"
                  placeholder={translate("ra.placeholder.currency")}
                  errors={errors?.bank_currency}
                  register={register}
                  os={os}
                />
              </Grid>

              {/* Swift Code */}
              <Grid container spacing={0}>
                <InputField 
                  name={translate("ra.fieldName.swiftCode")}
                  register={register} 
                  errors={errors} 
                  defaultValue={formData?.bank_swift_code || ""}
                  id="bank_swift_code"
                  readOnly={isRead(os)}
                  state="bank_swift_code"
                  placeholderHide={true}
                  message={translate("ra.validation.validSwiftCode")}
                />
              </Grid>

              {/* transfer_code */}
              <Grid
                className="full-section checkbox-panel new-transfer-code"
                container
                spacing={0}
              >
                <Typography variant="subtitle1">
                  {translate("ra.fieldName.transferCode")}
                  <span style={{ color: "#EF4349" }}>*</span>
                </Typography>
                <FormControl
                  className="new-select-class"
                  fullWidth
                  sx={ pagePath ? { mb: 2 } : { marginBottom: "20px" }}
                >
                  <Title 
                    name="transfer_code"
                    id="demo-simple-select"
                    defaultValue={formData?.transfer_code || ""}
                    dropdownValue={transferCodeValues} 
                    register={register} 
                    errors={errors} 
                    className="full-width ml0 mb0"
                    inputProps={{
                      readOnly: isRead(os),
                    }}
                    sx={{
                      mb: pagePath ? 2 : "",
                      "& legend": { display: "none" },
                      width: "100%",
                      marginBottom: "20px",
                    }}
                    labelhide={true}
                    indexValue={true}
                    message={translate("ra.validation.validInput")}
                  />
                </FormControl>

               {parseInt(watch("transfer_code")) === 4 &&
                  <InputField 
                    name={translate("ra.fieldName.otherCodeName")}
                    titleClass="mobileMarginTop"
                    register={register} 
                    errors={errors} 
                    defaultValue={formData?.name_of_transfer_code || ""}
                    id="name_of_transfer_code"
                    readOnly={isRead(os)}
                    state="name_of_transfer_code"
                    placeholder={translate("ra.placeholder.name")}
                  />
                }
                <Typography className="new-label-add" variant="subtitle1">
                  {transferC() ? (
                    <>
                      {transferC()} <span style={{ color: "#EF4349" }}>*</span>
                    </>
                  ) : (
                    ""
                  )}
                </Typography>
                <InputField 
                  register={register} 
                  errors={errors} 
                  defaultValue={formData?.transfer_code_value || ""}
                  id="transfer_code_value"
                  readOnly={isRead(os)}
                  state="transfer_code_value"
                  placeholder={translate("ra.placeholder.code")}
                  pattern= {{
                    value: numberTextPattern,
                    message: translate("ra.validation.validInput")
                  }}
                />
              </Grid>

              {/* Buttons */}
              <Grid container className={`company-details-button ${!pagePath ? "ss" : ""}`} spacing={0}>                
                <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                  {translate("ra.button.next")}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Container>
      )}
    </>
  );
};

export default BankDetails;
