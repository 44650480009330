import React from "react";
import {
  Container,
  Button,
  Typography
} from "@mui/material";
import { useTranslate } from "react-admin";

const DocumentError = () => {  
  const translate = useTranslate();

  const windowClose = () => {
    window.close();
  }

  return (
    <Container className={`error-panel`}>
      <Container className="error-info">
        <Typography className="icon"><i className="iconwasalt icon-broken-link" ></i></Typography>
        <Typography variant="h2">{translate("ra.content.docNotFound")}</Typography>
        <Typography variant="h3">{translate("ra.content.404")}</Typography>
        <Typography>{translate("ra.content.docErrorHome")}</Typography>
        <Button onClick={windowClose} className="outline-button">{translate("ra.button.closeWindow")}</Button>
      </Container>
    </Container>
  );
};

export default DocumentError;
