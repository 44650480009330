import React, { useEffect, useState, useRef } from "react";
import { Grid, Container, Button, Typography, TextField } from "@mui/material";
import { useTranslate } from "react-admin";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Attachmentpic from "images/attachment.svg";
import Attachmentemail from "images/attach_email.svg";
import { OfflinePayment } from "./OfflinePayment";
import RadioButtonsGroup from "./RadioComponent";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Copyicon from "images/copy-icon.svg";
import adjustPaymentToken from "Services/adjustPaymentTokenService";
import Loader from "Components/Loader";
import onlinePaymentMail, { setPaymentMethod } from "Services/onlineAndOfflinePaymentMailService";
import UnitsPayment from "./UnitsPayment";
import ExitCustomerPopup from "../ExitCustomerPopup";
import { formatNumbers } from "Utils/thousandSeperators";
import updateUnitBookingStatus from "Services/updateCustomerStatus";
import tinyUrl from "Services/createTinyUrl";

export const PaymentMethodTab = ({ units, bankDetailsAndLink, setTabValue, notification, userData, data, setSliderValue, primary, headerPayload, bookingFailedPopup,
  setBookingFailedPopup, portal_booking_id }) => {
  const translate = useTranslate();
  const [value, setValue] = useState("1");
  const [proceed, setProceed] = useState(true);
  const [tinyurl, setTinyUrl] = useState();
  const [tinyUrlLoading, setTinyUrlLoading] = useState(false);
  const [showAccountDetails, setShowAccountDetails] = useState(false);
  const [adjustPaymentLoading, setAdjustPaymentLoading] = useState(false);
  const [showBookingIncomplete, setShowBookingIncomplete] = useState(false);
  const [sum, setSum] = useState(null)
  const [onlinePaymentLoading, setOnlinePaymentLoading] = useState(false);
  const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [checkedValue, setCheckedValue] = useState("POS");
  const offline = useRef(null);
  const online = useRef(null);
  let updatedLink;

  const closePopup = () => {
    setShowAccountDetails(false);
    setShowBookingIncomplete(false);
  };

  const copyLinked = (type) => {
    const range = document.createRange();
    type === "offline" ? range.selectNode(offline.current) : range.selectNode(online.current);

    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    notification(type === "online"
      ? translate("ra.notification.PaymentLinkCopied")
      : translate("ra.notification.AccountDetailsCopied")
      , { type: "info" }
    );
  }

  const callApi = (tab) => {
    const data = {
      "isOnline": tab == "online" ? 1 : 0,
    };
    data.paymentUrl = updatedLink;
    data.amount = sum.toString();
    data.units = units?.map(item => item?.unitNo).join(",");
    onlinePaymentMail(data, setOnlinePaymentLoading, notification, translate, JSON.stringify(headerPayload), setShowBookingIncomplete, bookingFailedPopup,
      setBookingFailedPopup);
  }

  useEffect(() => {
    const sum = units?.reduce((sum, currentVal) => sum + JSON.parse(currentVal.slider), 0);
    setSum(sum)
  }, [units]);

  useEffect(() => {
    tinyUrl(setTinyUrl, setTinyUrlLoading, notification, updatedLink)
  }, [bankDetailsAndLink, sum])

  const handleChangePaymentTab = (event, newValue) => {
    setValue(newValue);
  };

  const urlParam = btoa(`firstName=${primary?.company_name || primary?.first_name}&lastName=${primary?.last_name ||
    ""}&emailAddress=${primary?.email || primary?.authorised_email}&country=${primary?.country}&mobileCountryCode=${primary?.mobile_no_country_code || primary?.authorised_mobile_no_country_code}&mobileNumber=${primary?.mobile_no || primary?.authorised_mobile_no}&projectName=${primary?.property_name}&purposeOfPayment=deposit&source=portal&amount=${sum}&units=${units?.map(item => item?.unitNo).join(",")}`)

  updatedLink = bankDetailsAndLink?.payment_url ? `${bankDetailsAndLink?.payment_url}?${urlParam}` : "";

  const onClickContinue = async () => {
    const data = {
      isOnline: value === "1" ? 1 : 0,
      paymentMethod: checkedValue
    }
    let adjustTokenPaymentData = [];
    units?.map(unit => {
      let obj = {
        unit_id: unit?.unit_id,
        project_id: unit?.project_id,
        paid_token_amount: unit?.slider
      };
      unit?.customer_unit_id?.map(id => {
        adjustTokenPaymentData.push({ id: id, ...obj })
      })
    })
    await adjustPaymentToken(setAdjustPaymentLoading, adjustTokenPaymentData, notification, null, JSON.stringify(headerPayload), "", bookingFailedPopup,
      setBookingFailedPopup, setProceed);
    await setPaymentMethod(setPaymentMethodLoading, data, notification, JSON.stringify(headerPayload), setProceed);
    const apiData = {
      data: [{
        where: {
          portal_booking_id: portal_booking_id,
          customer_id: units[0]?.customer_ids?.join(","),
          unit_id: units?.map(unit => unit?.unit_id).join(","),
        },
        set: {
          booking_status: "attach_token_payment_proof"
        }
      }]
    }
    updateUnitBookingStatus(setUpdateLoading, JSON.stringify(apiData), notification, "", bookingFailedPopup,
      setBookingFailedPopup, setProceed);
    proceed && setTabValue("2");
  }

  return (
    <>
      {(onlinePaymentLoading || updateLoading || paymentMethodLoading || adjustPaymentLoading || tinyUrlLoading) && <Loader />}
      <>
        <Grid className="token-payment-main-panel">
          <Typography variant="h2">
            {translate("ra.pageTitle.tokenPaymentDetails")}
          </Typography>
          <p className="main-info">{translate("ra.content.setUpTokenAmount")}</p>

          {/* units paymnet */}
          <Container className="token-payment-unit-info">
            <Grid className="token-payment-unit-top"><p><span>{translate("ra.content.totalUnits")}</span> <span>{units?.length}</span></p></Grid>
            <Grid className="token-payment-unit-all-section">
              {units?.map((item, index) => {
                return (
                  <UnitsPayment
                    headerPayload={headerPayload}
                    index={index}
                    key={index}
                    userData={userData}
                    item={item}
                    notification={notification}
                    data={data}
                    setSliderValue={setSliderValue}
                    bookingFailedPopup={bookingFailedPopup}
                    setBookingFailedPopup={setBookingFailedPopup}
                  />
                );
              })}
            </Grid>
            <Grid className="token-payment-unit-top token-payment-unit-bottom">
              <p><span>{translate("ra.content.amountPayable")}</span> <span>{units?.[0]?.currency}<strong>{formatNumbers(sum) || 0}</strong></span></p>
            </Grid>
          </Container>

          {/* offline/online paymnent tab */}
          <Container className="token-payment-unit-info token-payment-unit-info-last">
            <Box>
              <TabContext value={value}>
                <Box className="token-payment-tab-info">
                  <TabList
                    onChange={handleChangePaymentTab}
                    aria-label="lab API tabs">
                    <Tab label="Online Payment " value="1" />
                    <Tab label="Offline Payment" value="2" />
                  </TabList>
                </Box>
                {/* Online Payment Tab content */}
                <TabPanel className="token-payment-tab-details online-payment-tab-section" value="1">
                  <p>{translate("ra.content.copyAndSharePaymentLink")}</p>
                  <TextField
                    fullWidth
                    ref={online}
                    id="outlined-payment-url"
                    value={tinyurl}
                  />
                  <Typography variant="div" className="token-payment-tab-buttons">
                    <Button variant="outlined" onClick={() => copyLinked("online")}>
                      <img src={Attachmentpic}></img> {translate("ra.button.copyLink")}
                    </Button>
                    <Button variant="outlined" onClick={() => callApi("online")}>
                      <img src={Attachmentemail}></img> {translate("ra.button.sendLink")}
                    </Button>
                  </Typography>
                </TabPanel>
                {/* Offline Payment Tab content */}
                <TabPanel className="token-payment-tab-details offline-details" value="2">
                  <RadioButtonsGroup translate={translate} checkedValue={checkedValue} setCheckedValue={setCheckedValue} />
                  <Button className="offline-details-button" onClick={() => setShowAccountDetails(true)}>
                    {translate("ra.button.viewAccountDetails")}
                  </Button>
                  <p className="upload-secureReservation-info mobile-only">{translate("ra.content.secureReservation")} </p>
                </TabPanel>
              </TabContext>
            </Box>
          </Container>
        </Grid>
        <Container className="token-payment-button-panel">
          <Grid container className="company-details-button token-payment-button-section" spacing={0}>
            <Button variant="outlined" onClick={() => setShowBookingIncomplete(true)}>{translate("ra.button.exit")}</Button>
            <Button variant="contained" onClick={() => onClickContinue()}>{translate("ra.button.continue")}</Button>
          </Grid>
          <p className="desktop-only">{translate("ra.content.secureReservation")} </p>
        </Container>
      </>

      {showAccountDetails && (
        <Dialog
          open={showAccountDetails}
          className={`new-popup-box add-contact-popup popup-share account-details-popup popup-bottom-align`}
        >
          <DialogTitle className="share-heading">
            <span>{translate("ra.pageTitle.accountDetails")}</span>
            <i
              className="iconwasalt icon-close cross-button"
              onClick={closePopup}
            />
          </DialogTitle>
          <DialogContent>
            <OfflinePayment
              sum={sum}
              bankDetailsAndLink={bankDetailsAndLink?.bank_details}
              offline={offline}
              translate={translate}
              userData={userData}
              units={units}
            />
          </DialogContent>
          <DialogActions className="padding-0 button-panel token-payment-tab-buttons">
            <Button variant="outlined" onClick={() => copyLinked("offline")}><img src={Copyicon}></img> {translate("ra.button.copyDetails")}</Button>
            <Button variant="outlined" onClick={() => callApi("offline")}><img src={Attachmentemail}></img> {translate("ra.button.sendDetails")}</Button>
          </DialogActions>
        </Dialog>
      )}

      {showBookingIncomplete && (
        <ExitCustomerPopup
          showBookingIncomplete={showBookingIncomplete}
          setShowBookingIncomplete={setShowBookingIncomplete}
          onlinePaymentLoading={onlinePaymentLoading}
          callApi={callApi}
          value={value}
        />
      )}
    </>
  );
}
