const theme = {
  palette: {
    type: "light",
    primary: {
      main: "#8c99a1",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#8c99a1",
      contrastText: "#ffffff",
    },
    background: {
      default: "#ffffff",
    },
    text: {
      primary: "#081c2b",
      secondary: "#8c99a1",
    },
  },
  typography: {
    fontFamily: "Syne",
    h1: {
      fontSize: "32px",
      lineHeight: "38px",
      fontWeight: 700
    },
    h2: {
      fontSize: "28px",
      lineHeight: "34px",
      fontWeight: 700
    },
    h3: {
      fontSize: "24px",
      lineHeight: "28.8px",
      fontWeight: 400
    },
    h4: {
      fontSize: "22px",
      lineHeight: "28px",
      fontWeight: 700
    },
    body1: {
      fontFamily: "Inter",
    },
    body2: {
      fontFamily: "Inter",
    },
    button: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "24px",
    },
    overline: {
      fontFamily: "Inter",
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 0,
  },
};
export default theme;