import React, { useState, useEffect } from 'react'
import {
  useRedirect,
  useStoreContext,
  useStore,
  useTranslate,
} from 'react-admin'
import { useForm } from 'react-hook-form'
import { FormControl, Container, Grid, Button, Typography } from '@mui/material'
import Loader from 'Components/Loader'
import isRead from 'Utils/ui-lock-status'
import Divider from '@mui/material/Divider'
import AutoCompleteComponent from 'Components/Common/AutoComplete'
import PhoneInputComponent from 'Components/Common/PhoneInput'
import DatePickerComponent from 'Components/Common/DatePicker'
import { getDateFormat } from 'Utils/dateFormat'
import { expiryDate, issueDate, DOBDate } from 'Utils/maxMinDate'
import userDetails from 'Services/userDetailsServices'
import country from 'Services/countryService'
import nationality from 'Services/nationalityService'
import { titleValues } from 'Utils/constantValues'
import { emailPattern, numberTextPattern } from 'Utils/regex-patterns'
import registerService from 'Services/registerService'
import InputField from 'Components/InputField'
import Title from 'Components/Title/Title'
import RadioConditionCheck from 'Components/Common/RadioConditionCheck'
import HeaderButton from 'Components/HeaderButton'
import { manageFlagClass } from 'Utils/utils'
import CountryPicker from 'Components/CountryCodePicker/CountryCodePicker'
import { useNavigate ,useLocation} from "react-router-dom";

const CompanyPersonnel = ({ notification }) => {
  const navigate=useNavigate();
  const location=useLocation();
  const history_url=JSON.parse(localStorage.getItem("history_url"));
  const prevRedirectPage=localStorage.getItem("prevRedirectPage");

  const [loading, setLoading] = useState(false)
  const [os, setOs] = useState(null)
  const [countryListLoading, setCountryListLoading] = useState(false)
  const [nationalityListLoading, setnationalityLoading] = useState(false)
  const [formData, setFormData] = useStore()
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState()

  const [countryCode, setCountryCode] = useState()
  const [companyMobilePhoneNumber, setCompanyMobilePhoneNumber] = useState()
  const [
    companyMobilePhoneNumberCountryCode,
    setCompanyMobilePhoneNumberCountryCode,
  ] = useState()
  const [telePhoneNumber, setTelePhoneNumber] = useState()
  const [telePhoneNumberCountryCode, setTelePhoneNumberCountryCode] = useState()
  const [phoneErrors, setPhoneErrors] = useState(false)
  const [countryList, setCountryList] = useState([])
  const [nationalityList, setNationalityList] = useState([])
  const [countryState, setCountryState] = useState(null)
  const [ccode, setccode] = useState(null)
  const [nationalityState, setNationalityState] = useState(null)
  const [companyNationalityState, setCompanyNationalityState] = useState(null)
  const [nationalityCode, setNationalityCode] = useState(null)
  const [companyNationalityCode, setCompanyNationalityCode] = useState(null)

  const [auth_mobile_no_with_code, setAuth_mobile_no_with_code] = useState()
  const [dd_alt_mobile_no, setDd_alt_mobile_no] = useState()

  const [company_mobile_no_with_code, setCompany_mobile_no_with_code] =
    useState()
  const [dd_company_mobile_no, setDd_company_mobile_no] = useState()
  const [company_telephone_no_with_code, setCompany_telephone_no_with_code] =
    useState()
  const [dd_company_telephone_no, setDd_company_telephone_no] = useState()
  const minDateForDOB=new Date(new Date().setFullYear(new Date().getFullYear() - 100));


  const redirect = useRedirect()
  const store = useStoreContext()
  const translate = useTranslate()
  let user_type = store.getItem('user_type')
  const step = 3
  let user_id = store.getItem('user_id')
  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    formState: { errors },
    clearErrors,
    control,
  } = useForm()

  const setDetails = (resData) => {
    resData?.oracle_status ? setOs(resData?.oracle_status) : setOs('-')
    setMobilePhoneNumber(resData.mobile_no)
    setAuth_mobile_no_with_code(resData.auth_mobile_no_with_code)
    setDd_alt_mobile_no(resData.dd_alt_mobile_no)
    setDd_company_mobile_no(resData.dd_company_mobile_no)
    setDd_company_telephone_no(resData.dd_company_telephone_no)
    setCompany_telephone_no_with_code(resData.company_telephone_no_with_code)
    setCompany_mobile_no_with_code(resData.company_mobile_no_with_code)
    setCountryCode(resData.country_code)
    setCompanyMobilePhoneNumber(resData.company_mobile_no)
    setCompanyMobilePhoneNumberCountryCode(
      resData.company_mobile_no_country_code
    )
    setTelePhoneNumber(resData.company_telephone_no)
    setTelePhoneNumberCountryCode(resData.company_telephone_no_country_code)
  }
 
const onError = async (err)=>{
  console.log(err,"error");
  
}

  const onSubmit = async (data) => {
    
    if (!user_type) {
      user_type = Number(localStorage.getItem('user_type'))
    }
    if (!user_id) {
      user_id = Number(localStorage.getItem('user_id'))
    }

    let {
      authorized_signatory_corporate_title,
      authorized_signatory_corporate_first_name,
      authorized_signatory_corporate_last_name,
      agent_title,
      authorized_DOB,
      agent_first_name,
      agent_last_name,
      designation,
      email_address,
      passport_number,
      company_DOB,
      id_type,
      issue_date,
      expiry_date,
      national_residence_card_number,
      company_postal_code,
      company_po_box,
      address,
      city,
    } = data

    if (isRead(os)) {
      id_type = formData?.id_type
    }

    let national_id_number

    if (id_type === '2') {
      national_id_number = passport_number
    }

    const formated_issue_date = getDateFormat(issue_date)
    const formated_expiry_date = getDateFormat(expiry_date)
    const formated_DOB = getDateFormat(authorized_DOB)
    const formated_company_DOB = getDateFormat(company_DOB)

    let apiRequestData = {
      step,
      user_id,
      user_type,
      authorized_DOB: formated_DOB,
      authorized_signatory_corporate_title,
      authorized_signatory_corporate_first_name:
        authorized_signatory_corporate_first_name?.trim(),
      authorized_signatory_corporate_last_name:
        authorized_signatory_corporate_last_name?.trim(),
      designation: designation?.trim(),
      country_code: countryCode,
      mobile_no: mobilePhoneNumber,
      dd_alt_mobile_no: dd_alt_mobile_no,
      dd_company_mobile_no: dd_company_mobile_no,
      dd_company_telephone_no: dd_company_telephone_no,
      auth_mobile_no_with_code: auth_mobile_no_with_code,
      company_mobile_no_with_code: company_mobile_no_with_code,
      company_telephone_no_with_code: company_telephone_no_with_code,
      email_address: email_address?.trim(),
      nationality: nationalityCode,
      company_nationality: companyNationalityCode,
      id_type,
      company_DOB: formated_company_DOB,
      passport_number: passport_number?.trim(),
      national_id_number: national_id_number?.trim(),
      issue_date: formated_issue_date,
      expiry_date: formated_expiry_date,
      country_registration: formData?.country_registration,
      agent_title,
      agent_first_name: agent_first_name?.trim(),
      agent_last_name: agent_last_name?.trim(),
      company_mobile_no: companyMobilePhoneNumber,
      company_mobile_no_country_code: companyMobilePhoneNumberCountryCode,
      company_telephone_no: telePhoneNumber,
      company_telephone_no_country_code: telePhoneNumberCountryCode,
      address: address?.trim(),
      city: city?.trim(),
      company_postal_code: company_postal_code?.trim(),
      company_po_box: company_po_box?.trim(),
      company_country: ccode,
    }

    if (nationalityCode === 'Omani' || formData?.country_registration === 'Oman') {
      apiRequestData.national_residence_card_number =
        national_residence_card_number?.trim()
    }

    email_address !== formData?.email_address &&
      localStorage?.setItem('email_address', formData?.email_address)

    registerService(
      isRead,
      os,
      setLoading,
      apiRequestData,
      notification,
      redirect,
      '/register-agency/bank'
    )
  }

  useEffect(() => {
    
    // !history_url?.some(path=>path==location.pathname)&&navigate(prevRedirectPage);
    (!(history_url?.some(path=>path==location.pathname)))&&navigate(prevRedirectPage);

    
    nationality(setNationalityList, setnationalityLoading, notification)
    country(setCountryList, setCountryListLoading, notification)
    userDetails(setLoading, setFormData, setDetails, notification)
    manageFlagClass()
  }, [])
  const validatePhone = (
    fieldName,
    mobileNumber,
    message = translate('ra.validation.validMobileNo'),
    idToScrollIntoView = ''
  ) => {
     
    if (!mobileNumber || mobileNumber?.length < 6) {
      setError(fieldName, { type: 'required', message: message })
      idToScrollIntoView &&
        document.getElementById(idToScrollIntoView)?.scrollIntoView()
    } else {
      clearErrors(fieldName)
      setPhoneErrors(false)
    }
  }

  const handlePhoneNumberValidation = (value, data) => {
    // console.log(value,data.countryCode);
    const rawnumber = value.slice(data.dialCode.length)
    const dialCode = data.dialCode
    let countryName = data.name
    let countryNameWithCode = data?.SF_Code
    validatePhone('mobile_no', rawnumber)
    setMobilePhoneNumber(rawnumber)
    setCountryCode(dialCode)
    setAuth_mobile_no_with_code(countryNameWithCode)
    setDd_alt_mobile_no(data.countryCode)
  }


  const handleCompanyPhoneNumberValidation = (value, data) => {
    const rawnumber = value.slice(data.dialCode.length)
    const dialCode = data.dialCode
    let countryName = data.name
    let countryNameWithCode = data?.SF_Code
    validatePhone('company_mobile_no', rawnumber)
    setCompanyMobilePhoneNumber(rawnumber)
    setCompanyMobilePhoneNumberCountryCode(dialCode)
    setCompany_mobile_no_with_code(countryNameWithCode)
    setDd_company_mobile_no(data.countryCode)
  }

  const handleCompanyTelePhoneNumberValidation = (value, data) => {
    const rawnumber = value.slice(data.dialCode.length)
    const dialCode = data.dialCode
    let countryName = data.name
    let countryNameWithCode = data?.SF_Code
    validatePhone(
      'company_telephone_no',
      rawnumber,
      translate('ra.validation.validTelephoneNo')
    )
    setTelePhoneNumber(rawnumber)
    setTelePhoneNumberCountryCode(dialCode)
    setCompany_telephone_no_with_code(countryNameWithCode)
    setDd_company_telephone_no(data.countryCode)
  }

  useEffect(() => {
    if (phoneErrors) {
      validatePhone('mobile_no', mobilePhoneNumber)
      validatePhone('company_mobile_no', companyMobilePhoneNumber)
      validatePhone(
        'company_telephone_no',
        telePhoneNumber,
        translate('ra.validation.validTelephoneNo')
      )
    }
  }, [phoneErrors])

  // Setting and Clearing Id type errors
  const idErrors = () => {
    if (!watch('passport_number')) {
      watch('id_type') === '1'
        ? setError('passport_number', {
            type: 'required',
            message: translate('ra.validation.validPassportNo'),
          })
        : setError('passport_number', {
            type: 'required',
            message: translate('ra.validation.validNationalId'),
          })
    } else {
      clearErrors('passport_number')
    }
  }

  useEffect(() => {
    if (formData?.nationality) {
      const nation = nationalityList?.find(
        (nation) => nation.code == formData?.nationality
      )
      setNationalityState(nation)
      setNationalityCode(formData?.nationality)
    }
    if (formData?.company_nationality) {
      const nation = nationalityList?.find(
        (nation) => nation.code == formData?.company_nationality
      )
      setCompanyNationalityState(nation)
      setCompanyNationalityCode(formData?.company_nationality)
    }
  }, [formData?.nationality, formData?.company_nationality, nationalityList])

  useEffect(() => {
    if (formData?.company_country) {
      const country = countryList?.find(
        (country) => country.country_code == formData?.company_country
      )
      setCountryState(country)
      setccode(formData?.company_country)
    }
  }, [formData?.company_country, countryList])

  useEffect(() => {
    if (nationalityCode === 'Omani' || formData?.country_registration === 'Oman') {
      setValue('id_type', '1')
      clearErrors('id_type')
      idErrors()
    }
    if (
      formData?.country_registration !== 'Oman' &&
      !nationalityCode &&
      !formData?.id_type &&
      !formData?.nationality
    ) {
      setValue('id_type', null)
      clearErrors('passport_number')
    }
  }, [nationalityCode, formData])

  const onGettingMobileNumber=(selectedObj,mobileNo,label)=>{
    // setMobilePhoneNumber(mobileNo);
    // setCountryCode(selectedObj?.Code);
    
    switch (label) {
      case "AuthorizedSignatory":
         handlePhoneNumber(selectedObj,mobileNo);
        break;
    
      case "CompanyMobile":
        handleCompanyPhoneNumber(selectedObj,mobileNo);
      break;
        
      case "CompanyTelephone":
        handleCompanyTelePhoneNumber(selectedObj,mobileNo);
      break;
    }
    // formData?.country_code=selectedObj?.Code;
    // formData?.mobile_no=
  }
 const handlePhoneNumber=(data,mobileNumber)=>{
    const dialCode = data.Code
    let countryName = data.Country
    let countryNameWithCode = data?.SF_Code
    validatePhone('mobile_no', mobileNumber)
    setMobilePhoneNumber(mobileNumber)
    setCountryCode(dialCode)
    setAuth_mobile_no_with_code(countryNameWithCode)
    setDd_alt_mobile_no(data.Short_Country?.toLowerCase())
 }
 const handleCompanyPhoneNumber=(data,mobileNumber)=>{
    const dialCode = data.Code
    let countryName = data.Country
    let countryNameWithCode = data?.SF_Code
    validatePhone('company_mobile_no', mobileNumber)
    setCompanyMobilePhoneNumber(mobileNumber)
    setCompanyMobilePhoneNumberCountryCode(dialCode)
    setCompany_mobile_no_with_code(countryNameWithCode)
    setDd_company_mobile_no(data.Short_Country?.toLowerCase())
 }
 const handleCompanyTelePhoneNumber=(data,mobileNumber)=>{
    const dialCode = data.Code
    let countryName = data.Country
    let countryNameWithCode = data?.SF_Code
    validatePhone(
      'company_telephone_no',
      mobileNumber,
      translate('ra.validation.validTelephoneNo')
    )
    setTelePhoneNumber(mobileNumber)
    setTelePhoneNumberCountryCode(dialCode)
    setCompany_telephone_no_with_code(countryNameWithCode)
    setDd_company_telephone_no(data.Short_Country?.toLowerCase())
 }
 const backNavigation=()=>{
  navigate('/register-agency/personal');
 }
  return (
    <>
      {(loading || countryListLoading || nationalityListLoading) && <Loader />}
      {formData && (
        <Container className="company-container new-fix-container">
          <Container className="company-details">
            <HeaderButton notification={notification} os={os} releaseBlockUnits={backNavigation} />
            <Grid className="form-panel" sx={{ width: '100%' }}>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  marginBottom: '20px',
                }}
                variant="h3"
              >
                {translate('ra.pageTitle.companyPersonnel')}
              </Typography>

              <Typography
                sx={{
                  textAlign: 'left',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '18px',
                  marginBottom: '20px',
                }}
                variant="h5"
              >
                {translate('ra.pageTitle.authrizedSignatory')}
              </Typography>

              {/*   Authorized Signatory for Corporate  */}
              <Grid
                className="full-section checkbox-panel"
                container
                spacing={0}
              >
                <Typography variant="subtitle1">
                  {translate('ra.fieldName.authrizedSignatory')}
                  <span style={{ color: '#EF4349' }}>*</span>
                </Typography>
                <FormControl className="mb15" fullWidth>
                  <Title
                    name="authorized_signatory_corporate_title"
                    defaultValue={
                      formData?.authorized_signatory_corporate_title || ''
                    }
                    dropdownValue={titleValues}
                    register={register}
                    watch={watch}
                    errors={errors}
                    className="full-width ml0 mt0 mb0 new-prefix"
                    inputProps={{
                      readOnly: isRead(os),
                    }}
                    sx={{
                      '& legend': { display: 'none' },
                      width: '100%',
                      marginBottom: '20px',
                    }}
                    message={translate('ra.validation.pleaseChoose')}
                    labelName={translate('ra.placeholder.prefix')}
                  />
                </FormControl>
                <InputField
                  register={register}
                  errors={errors}
                  defaultValue={
                    formData?.authorized_signatory_corporate_first_name || ''
                  }
                  id="authorized_signatory_corporate_first_name"
                  className="mb15 mt0"
                  readOnly={isRead(os)}
                  state="authorized_signatory_corporate_first_name"
                  placeholder={translate('ra.placeholder.firstName')}
                />
                <InputField
                  register={register}
                  errors={errors}
                  defaultValue={
                    formData?.authorized_signatory_corporate_last_name || ''
                  }
                  id="authorized_signatory_corporate_last_name"
                  className="mt0"
                  readOnly={isRead(os)}
                  state="authorized_signatory_corporate_last_name"
                  placeholder={translate('ra.placeholder.lastName')}
                />
              </Grid>

              {/*  Designation */}
              <Grid container spacing={0}>
                <InputField
                  name={translate('ra.fieldName.designation')}
                  register={register}
                  errors={errors}
                  defaultValue={formData?.designation || ''}
                  id="designation"
                  readOnly={isRead(os)}
                  state="designation"
                  sx={{ width: '100%', marginBottom: '20px' }}
                  placeholderHide={true}
                />
              </Grid>

              {/* Mobile Number */}
              <Grid container spacing={0}  style={{position:"unset"}}>
                {/* <PhoneInputComponent
                  id="Mobile-No-Typography"
                  title={translate('ra.fieldName.mobileNo')}
                  value={[formData?.country_code, formData?.mobile_no].join()}
                  onChange={handlePhoneNumberValidation}
                  errors={errors?.mobile_no?.message}
                  os={os}
                  countryCode={formData?.dd_alt_mobile_no}
                  country={formData?.dd_alt_mobile_no || 'gb'}
                /> */}
                <div >
                  <Typography variant="subtitle1">
                    {translate('ra.fieldName.mobileNo')}
                    <span style={{ color: '#EF4349' }}>*</span>
                  </Typography>

                  <CountryPicker  errors={errors?.mobile_no?.message} label="AuthorizedSignatory" isReadOnly={isRead(os)} countryCode={formData?.country_code} mobile={formData?.mobile_no} onGettingValue={onGettingMobileNumber} 
                  short_Counrty={formData?.dd_alt_mobile_no?formData?.dd_alt_mobile_no?.toUpperCase():"GB"}
                  />
                  {/* <CountryPicker  errors={errors?.mobile_no?.message} label="AuthorizedSignatory" isReadOnly={isRead(os)} countryCode={countryCode} mobile={mobilePhoneNumber} onGettingValue={onGettingMobileNumber}/> */}
                </div>
              </Grid>

              {/*  Email Address */}
              <Grid container spacing={0}>
                <InputField
                  name={translate('ra.fieldName.emailAddress')}
                  register={register}
                  errors={errors}
                  defaultValue={formData?.email_address || ''}
                  id="email_address"
                  type="email"
                  readOnly={isRead(os)}
                  state="email_address"
                  sx={{ width: '100%', marginBottom: '20px' }}
                  message={translate('ra.validation.validEmail')}
                  pattern={{
                    value: emailPattern,
                    message: translate('ra.validation.validEmail'),
                  }}
                  placeholderHide={true}
                />
              </Grid>

              {/*  Nationality */}
              <Grid container className="new-select-class" spacing={0}>
                <AutoCompleteComponent
                  title={translate('ra.fieldName.nationality')}
                  value={nationalityState}
                  onChange={(e, newInputValue) => {
                    setNationalityState(newInputValue)
                    setNationalityCode(newInputValue?.code)
                    clearErrors('nationality')
                  }}
                  options={nationalityList}
                  getOptionLabel={(option) => option?.name || ''}
                  name="nationality"
                  placeholder={translate('ra.placeholder.nationality')}
                  errors={errors?.nationality}
                  register={register}
                  os={os}
                />
              </Grid>

              {/* national residence card  */}
              {(nationalityCode === 'Omani' ||
                formData?.country_registration === 'Oman') && (
                <Grid container spacing={0}>
                  <InputField
                    name={translate('ra.fieldName.civilCardNo')}
                    register={register}
                    errors={errors}
                    defaultValue={
                      formData?.national_residence_card_number || ''
                    }
                    id="outlined-basic-residence-civil-card-number"
                    readOnly={isRead(os)}
                    state="national_residence_card_number"
                    pattern={{
                      value: numberTextPattern,
                      message: translate('ra.validation.validInput'),
                    }}
                    placeholderHide={true}
                  />
                </Grid>
              )}

              {/* DOB */}
              <Grid container spacing={0}>
                <DatePickerComponent
                  title={translate('ra.fieldName.dob')}
                  control={control}
                  name="authorized_DOB"
                  value={formData?.authorized_DOB}
                  maxDate={DOBDate}
                  minDate={minDateForDOB}
                  errors={errors?.authorized_DOB}
                  os={os}
                />
              </Grid>

              {/* Id type */}
              <Grid container spacing={0}>
                
                <RadioConditionCheck
                  title={translate('ra.fieldName.idType')}
                  name="id_type"
                  register={register}
                  errors={errors?.id_type?.message}
                  defaultValue={
                    formData?.id_type
                      ? formData?.id_type.toString()
                      : (nationalityCode === 'Omani' ||
                          formData?.country_registration === 'Oman') &&
                        '1'
                  }
                  className="radio_control personeel-radio_control"
                  readOnly={!isRead(os)}
                  requiredField={
                    !isRead(os) &&
                    watch('nationality') !== 'Oman' &&
                    formData?.country_registration !== 'Oman' &&
                    translate('ra.validation.selectOne')
                  }
                  onChangeField={idErrors}
                  value1="1"
                  disabled1={isRead(os)}
                  checked1={
                    (nationalityCode === 'Omani' ||
                      formData?.country_registration === 'Oman' ||
                      watch('id_type') === '1' ||
                      formData?.id_type == '1') &&
                    watch('id_type') != '2'
                  }
                  label1={translate('ra.placeholder.passport')}
                  formClass="radio_text"
                  value2="2"
                  disabled2={
                    isRead(os) ||
                    nationalityCode === 'Omani' ||
                    formData?.country_registration === 'Oman'
                  }
                  checked2={
                    nationalityCode !== 'OM' &&
                    formData?.country_registration !== 'Oman' &&
                    watch('id_type') != '1' &&
                    (watch('id_type') === '2' || formData?.id_type == '2')
                  }
                  label2={translate('ra.placeholder.nationalId')}
                  errorClass="marginAdjust"
                />
              </Grid>

              {/* Id number */}
              <Grid container spacing={0}>
                <InputField
                  name={translate('ra.fieldName.passport')}
                  register={register}
                  errors={errors}
                  defaultValue={formData?.passport_number || ''}
                  readOnly={isRead(os)}
                  state="passport_number"
                  pattern={{
                    value: numberTextPattern,
                    message: translate('ra.validation.validInput'),
                  }}
                  onChangeField={(e) => idErrors()}
                  placeholderHide={true}
                />
              </Grid>

              {/*   ID Issue Date */}
              <Grid container spacing={0}>
                <DatePickerComponent
                  title={translate('ra.fieldName.idIssueDate')}
                  control={control}
                  name="issue_date"
                  value={formData?.issue_date}
                  maxDate={issueDate}
                  errors={errors?.issue_date}
                  os={os}
                />
              </Grid>

              {/*   ID Expiry Date */}
              <Grid container spacing={0}>
                <DatePickerComponent
                  title={translate('ra.fieldName.idExpiryDate')}
                  control={control}
                  name="expiry_date"
                  value={formData?.expiry_date}
                  minDate={expiryDate}
                  errors={errors?.expiry_date}
                  os={os}
                />
              </Grid>

              <Divider className="devider" />

              {/* Company Address and Contact Details  */}
              <Typography
                sx={{
                  textAlign: 'left',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '18px',
                  marginBottom: '20px',
                  clear: 'both',
                }}
                variant="h5"
              >
                {translate('ra.pageTitle.companyAddress')}
              </Typography>

              {/* Compny Name */}
              <Grid
                className="full-section checkbox-panel"
                container
                spacing={0}
              >
                <Typography variant="subtitle1">
                  {translate('ra.fieldName.adminName')}
                  <span style={{ color: '#EF4349' }}>*</span>
                </Typography>
                <FormControl className="mb15" fullWidth>
                  <Title
                    name="agent_title"
                    defaultValue={formData?.agent_title || ''}
                    dropdownValue={titleValues}
                    register={register}
                    watch={watch}
                    errors={errors}
                    className="full-width ml0 mt0 mb0 new-prefix"
                    inputProps={{
                      readOnly: isRead(os),
                    }}
                    sx={{
                      '& legend': { display: 'none' },
                      width: '100%',
                      marginBottom: '20px',
                    }}
                    message={translate('ra.validation.pleaseChoose')}
                    labelName="Prefix"
                  />
                </FormControl>
                <InputField
                  register={register}
                  errors={errors}
                  defaultValue={formData?.agent_first_name || ''}
                  id="agent_first_name"
                  className="mb15 mt0"
                  readOnly={isRead(os)}
                  state="agent_first_name"
                  placeholder={translate('ra.placeholder.firstName')}
                  sx={{ width: '100%', marginBottom: '20px' }}
                />
                <InputField
                  register={register}
                  errors={errors}
                  defaultValue={formData?.agent_last_name || ''}
                  id="agent_last_name"
                  className="mt0"
                  readOnly={isRead(os)}
                  state="agent_last_name"
                  placeholder={translate('ra.placeholder.lastName')}
                  sx={{ width: '100%', marginBottom: '20px' }}
                />
              </Grid>
                {/* <div style={{display:"flex",flexDirection:"row"}}> */}

                  {/* Mobile No */}
                 <Grid container spacing={0}  
                //  style={{position:"unset"}}
                 >
                    {/* <PhoneInputComponent
                      title={translate('ra.fieldName.mobileNo')}
                      value={[
                        formData?.company_mobile_no_country_code,
                        formData?.company_mobile_no,
                      ].join()}
                      onChange={handleCompanyPhoneNumberValidation}
                      errors={errors?.company_mobile_no?.message}
                      os={os}
                      country={formData?.dd_company_mobile_no || 'gb'}
                    /> */}
                    <div >
                      <Typography variant="subtitle1">
                        {translate('ra.fieldName.mobileNo')}
                        <span style={{ color: '#EF4349' }}>*</span>
                      </Typography>

                      {/* <CountryPicker errors={errors?.company_mobile_no?.message} isReadOnly={isRead(os)}  label="CompanyMobile" countryCode={companyMobilePhoneNumberCountryCode} mobile={companyMobilePhoneNumber} onGettingValue={onGettingMobileNumber}/> */}
                      <CountryPicker errors={errors?.company_mobile_no?.message} isReadOnly={isRead(os)}  label="CompanyMobile" 
                      countryCode={formData?.company_mobile_no_country_code} mobile={formData?.company_mobile_no} 
                      onGettingValue={onGettingMobileNumber} 
                      short_Counrty={formData?.dd_company_mobile_no?formData?.dd_company_mobile_no?.toUpperCase():"GB"}/>
                    </div>
                  </Grid>

                  {/*  Telephone No  */}
                  <Grid container spacing={0} >
                    {/* <PhoneInputComponent
                      title={translate('ra.fieldName.telephoneNo')}
                      value={[
                        formData?.company_telephone_no_country_code,
                        formData?.company_telephone_no,
                      ].join()}
                      onChange={handleCompanyTelePhoneNumberValidation}
                      errors={errors?.company_telephone_no?.message}
                      os={os}
                      country={formData?.dd_company_telephone_no || 'gb'}
                    /> */}
                    
                    <div >
                      <Typography variant="subtitle1">
                        {translate('ra.fieldName.telephoneNo')}
                        <span style={{ color: '#EF4349' }}>*</span>
                      </Typography>
                      {/* <CountryPicker errors={errors?.company_telephone_no?.message} isReadOnly={isRead(os)}  label="CompanyTelephone"  countryCode={telePhoneNumberCountryCode} mobile={telePhoneNumber} onGettingValue={onGettingMobileNumber}/> */}
                      <CountryPicker errors={errors?.company_telephone_no?.message} isReadOnly={isRead(os)}  label="CompanyTelephone"  
                      countryCode={formData?.company_telephone_no_country_code} mobile={formData?.company_telephone_no} 
                      onGettingValue={onGettingMobileNumber}  short_Counrty={formData?.dd_company_telephone_no?formData?.dd_company_telephone_no?.toUpperCase():"GB"}/>
                    </div>
                  </Grid>
                {/* </div> */}
              {/*  Nationality */}
              <Grid container className="new-select-class" spacing={0}>
                <AutoCompleteComponent
                  title={translate('ra.fieldName.nationality')}
                  value={companyNationalityState}
                  onChange={(e, newInputValue) => {
                    setCompanyNationalityState(newInputValue)
                    setCompanyNationalityCode(newInputValue?.code)
                    clearErrors('company_nationality')
                  }}
                  options={nationalityList}
                  getOptionLabel={(option) => option?.name || ''}
                  name="company_nationality"
                  placeholder={translate('ra.placeholder.nationality')}
                  errors={errors?.company_nationality}
                  register={register}
                  os={os}
                />
              </Grid>

              {/* DOB */}
              <Grid container spacing={0}>
                <DatePickerComponent
                  title={translate('ra.fieldName.dob')}
                  control={control}
                  name="company_DOB"
                  value={formData?.company_DOB}
                  maxDate={DOBDate}
                  minDate={minDateForDOB}
                  errors={errors?.company_DOB}
                  os={os}
                />
              </Grid>

              {/*   Mailing Address */}
              <Grid
                className="full-section checkbox-panel"
                container
                spacing={0}
              >
                <InputField
                  name={translate('ra.fieldName.mailingAddress')}
                  register={register}
                  errors={errors}
                  defaultValue={formData?.address || ''}
                  id="address"
                  className="mb15"
                  readOnly={isRead(os)}
                  state="address"
                  placeholder={translate('ra.placeholder.address')}
                  sx={{ width: '100%', marginBottom: '20px' }}
                />
                <InputField
                  register={register}
                  errors={errors}
                  defaultValue={formData?.city || ''}
                  id="city"
                  className="mt15"
                  readOnly={isRead(os)}
                  state="city"
                  placeholder={translate('ra.placeholder.city')}
                  sx={{ width: '100%', marginBottom: '20px' }}
                />
              </Grid>

              {/*  Postal Code */}
              <Grid container spacing={0}>
                <InputField
                  name={translate('ra.fieldName.postalCode')}
                  register={register}
                  errors={errors}
                  defaultValue={formData?.company_postal_code || ''}
                  id="company_postal_code"
                  readOnly={isRead(os)}
                  state="company_postal_code"
                  placeholder={translate('ra.placeholder.postalCode')}
                  message={translate('ra.validation.validPostalCode')}
                  pattern={{
                    value: numberTextPattern,
                    message: translate('ra.validation.validInput'),
                  }}
                  sx={{ width: '100%', marginBottom: '20px' }}
                />
              </Grid>

              {/* PO Box */}
              <Grid container spacing={0}>
                <InputField
                  name={translate('ra.fieldName.poBox')}
                  register={register}
                  errors={errors}
                  defaultValue={formData?.company_po_box || ''}
                  id="company_po_box"
                  readOnly={isRead(os)}
                  state="company_po_box"
                  placeholder={translate('ra.placeholder.poBox')}
                  message={translate('ra.validation.validPoBox')}
                  pattern={{
                    value: numberTextPattern,
                    message: translate('ra.validation.validInput'),
                  }}
                  sx={{ width: '100%', marginBottom: '20px' }}
                />
              </Grid>

              {/* company_country */}
              <Grid container className="new-select-class" spacing={0}>
                <AutoCompleteComponent
                  title={translate('ra.fieldName.country')}
                  value={countryState}
                  onChange={(e, newInputValue) => {
                    setCountryState(newInputValue)
                    setccode(newInputValue?.country_code)
                    clearErrors('company_country')
                  }}
                  options={countryList}
                  getOptionLabel={(option) => option?.country_name || ''}
                  name="company_country"
                  placeholder={translate('ra.placeholder.country')}
                  errors={errors?.company_country}
                  register={register}
                  os={os}
                />
              </Grid>

              {/* Buttons */}
              <Grid container className="company-details-button" spacing={0}>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleSubmit(onSubmit,onError)()
                    setPhoneErrors(true)
                  }}
                  sx={{
                    width: '100%',
                    boxShadow: 'none',
                    height: '48px',
                    marginBottom: '50px',
                  }}
                >
                  {translate('ra.button.next')} 
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Container>
      )}
    </>
  )
}

export default CompanyPersonnel
