import React, { useEffect, useState } from 'react';

const ViewDocument = () => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    // Retrieve the imageUrl from localStorage
    const storedImageUrl = localStorage.getItem('imageUrl');
    if (storedImageUrl) {
      setImageUrl(storedImageUrl);
    }

    // Clean up localStorage after use
    localStorage.removeItem('imageUrl');
  }, []);

  return (
    <div style={{ width: '100vw', height: '100vh', margin: 0, padding: 0 }}>
      {imageUrl && <iframe src={imageUrl} title="Document" style={{ width: '100%', height: '100%', border: 'none' }} />}
    </div>
  );
};

export default ViewDocument;
