import React, { useEffect, useState } from "react";
import {
  Container
} from "@mui/material";
import Styles from "./CampaignHeader.scss";

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 

const CampaignHeader = ({bannerData}) => {

  return (
    <Container className="campaign-header-section">
      <Carousel showArrows={false} showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
        {
          bannerData?.map((list, idx) => 
            <div key={idx} dangerouslySetInnerHTML={{__html: list?.htmldata}} ></div>
          )
        }
      </Carousel>
    </Container>
  );
};

export default CampaignHeader;
