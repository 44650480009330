import { docTypes } from "./constantValues";

export const additionalDocHandler = (file, fileName, addDoc, setAddCoc, notify) => {

    if(file){
        // checking for file type
        if (docTypes?.includes(file?.type)) {
            if (file?.size) {
                let fileSizeValue = file?.size || 0;
                // checking for file size
                if (Math.round(fileSizeValue / 1024) > 10240) {
                    notify("ra.notification.fileSizeCheck", { type: "error" });
                    return false;
                }
            }

            const fileObj = {
                file: file,
                doc_name: fileName || file.name,
                file_name: file.name,
                doc_type: "additional",
            };

            let index = addDoc.findIndex(
                (data) => data?.file_name === file.name
            );

            let docFiles = [...addDoc, fileObj];

            if (index === -1) {
                setAddCoc(docFiles);
            } else {
                notify("ra.notification.duplicate", { type: "error" });
            }
        } else {
            notify("ra.notification.fileTypeCheck", { type: "error" });
        }
    }
};

export const docListForm = (
    resData,
    setAddtionalDocument,
    setAdditionalDocumentsFiles,
    setValue,
    setPassport_array,
    setResidence_array,
    setQatar_array,
    setEmiratesId_array,
    setBrokerCertificate_array,
    setNationalityId_array
) => {

    let temAddDocuments = [];
    let tempPassport, tempResidence, tempQatar,tempEmiratesId,tempBrokerCertificateId ,tempNationalityId= {};
    

    resData?.attachments?.forEach((list) => {
      
      
        if (list?.doc_type === "passport") {
            tempPassport = {
                id: list.id,
                name: list.doc_name,
                doc_name: list.doc_name,
                doc_type: list.doc_type,
                doc_url: list.doc_url,
                doc_extension: list.doc_extension,
                doc_number: list.doc_number,
            };
        }
        if (list?.doc_type === "residence") {
            tempResidence = {
                id: list.id,
                name: list.doc_name,
                doc_type: list.doc_type,
                doc_url: list.doc_url,
                doc_extension: list.doc_extension,
                doc_number: list.doc_number,
            };
        }
        if (list?.doc_type === "qatarId") {
            tempQatar = {
                id: list.id,
                name: list.doc_name,
                doc_type: list.doc_type,
                doc_url: list.doc_url,
                doc_extension: list.doc_extension,
                doc_number: list.doc_number,
            };
        }
        if (list.doc_type === "additional") {
            const add = {
                id: list.id,
                doc_name: list.doc_name,
                name: list.doc_name,
                file_name: list.file_name,
                doc_type: list.doc_type,
                doc_url: list.doc_url,
                doc_extension: list.doc_extension,
                isdelete: false,
                doc_number: list.doc_number,
            };
            temAddDocuments.push(add);
        }
        if(list?.doc_type === "emiratesID"){
          tempEmiratesId = {
            id: list.id,
            name: list.doc_name,
            doc_type: list.doc_type,
            doc_url: list.doc_url,
            doc_extension: list.doc_extension,
            doc_number: list.doc_number,
        };
        }
        if(list?.doc_type === "brokerIDCertificate"){
            tempBrokerCertificateId = {
              id: list.id,
              name: list.doc_name,
              doc_type: list.doc_type,
              doc_url: list.doc_url,
              doc_extension: list.doc_extension,
              doc_number: list.doc_number,
          };
          }
          if(list?.doc_type === "saudiIqama"){
            tempNationalityId = {
              id: list.id,
              name: list.doc_name,
              doc_type: list.doc_type,
              doc_url: list.doc_url,
              doc_extension: list.doc_extension,
              doc_number: list.doc_number,
          };
          }
          
        

    });

    setAddtionalDocument(temAddDocuments);
    setAdditionalDocumentsFiles(temAddDocuments);
    if (tempPassport) {
        setPassport_array(tempPassport);
        setValue(
            "passportNationalIdDocument",
            Object.keys(tempPassport).length > 0 ? [tempPassport] : []
        );
    }
    if (tempResidence) {
        setValue(
            "residenceCivilCard",
            Object.keys(tempResidence).length > 0 ? [tempResidence] : []
        );
        setResidence_array(tempResidence);
    }
    if (tempQatar) {
        setValue(
            "qatarId",
            Object.keys(tempQatar).length > 0 ? [tempQatar] : []
        );
        setQatar_array(tempQatar);
    }
    if(tempEmiratesId){
      setValue(
        "emiratesID",
        Object.keys(tempEmiratesId).length > 0 ? [tempEmiratesId] : []
    );
    setEmiratesId_array(tempEmiratesId);
    }
    if(tempBrokerCertificateId){
        setValue(
          "brokerIDCertificate",
          Object.keys(tempBrokerCertificateId).length > 0 ? [tempBrokerCertificateId] : []
      );
      setBrokerCertificate_array(tempBrokerCertificateId);
      }
    if(tempNationalityId){
        setValue(
          "saudiIqama",
          Object.keys(tempNationalityId).length > 0 ? [tempNationalityId] : []
      );
      setNationalityId_array(tempNationalityId);
      }

}

export const companyDocListForm = (
    resData,
    setAdditionalDocumentsFiles,
    setAdditonalDocument,
    setResponseData,
    setValue,
    setPassport_array,
    setIncorportion_array,
    setBrokerId_array,
    setBrokerageCertificate_array,
    setVat_array,
    setResidence_array,
    setEmiratesId_array,
    setNationalityId_array
) => {

    let tempAdd = [];
    let tempPassport,
        tempVat,
        tempIncorporation,
        tempResidence,
        tempBrokerageCertificate,
        tempBrokerId ,tempEmiratesId ,tempNationalityId= {};

    resData?.attachments.forEach((list) => {
      
        if (list?.doc_type === "passport") {
            tempPassport = {
                id: list.id,
                doc_name: list.doc_name,
                name: list.doc_name,
                doc_type: list.doc_type,
                doc_url: list.doc_url,
                doc_extension: list.doc_extension,
                doc_number: list.doc_number,
            };
        }
        if (list?.doc_type === "residence") {
            tempResidence = {
                id: list.id,
                doc_name: list.doc_name,
                name: list.doc_name,
                doc_type: list.doc_type,
                doc_url: list.doc_url,
                doc_extension: list.doc_extension,
                doc_number: list.doc_number,
            };
        }
        if (list?.doc_type === "brokerID") {
            tempBrokerId = {
                id: list.id,
                name: list.doc_name,
                doc_type: list.doc_type,
                doc_url: list.doc_url,
                doc_extension: list.doc_extension,
                doc_number: list.doc_number,
            };
        }
        if (list?.doc_type === "brokerIDCertificate") {
            tempBrokerageCertificate = {
                id: list.id,
                name: list.doc_name,
                doc_name: list.doc_name,
                doc_type: list.doc_type,
                doc_url: list.doc_url,
                doc_extension: list.doc_extension,
                doc_number: list.doc_number,
            };
        }
        if (list?.doc_type === "incorporation") {
            tempIncorporation = {
                id: list.id,
                name: list.doc_name,
                doc_type: list.doc_type,
                doc_url: list.doc_url,
                doc_extension: list.doc_extension,
                doc_number: list.doc_number,
            };
        }
        if (list?.doc_type === "vatCertificate") {
            tempVat = {
                id: list.id,
                name: list.doc_name,
                doc_type: list.doc_type,
                doc_url: list.doc_url,
                doc_extension: list.doc_extension,
                doc_number: list.doc_number,
            };
        }
        if(list?.doc_type === "emiratesID"){
          tempEmiratesId = {
            id: list.id,
            name: list.doc_name,
            doc_type: list.doc_type,
            doc_url: list.doc_url,
            doc_extension: list.doc_extension,
            doc_number: list.doc_number,
        };
        }
        if(list?.doc_type === "saudiIqama"){
            tempNationalityId = {
              id: list.id,
              name: list.doc_name,
              doc_type: list.doc_type,
              doc_url: list.doc_url,
              doc_extension: list.doc_extension,
              doc_number: list.doc_number,
          };
          }
        
        if (list.doc_type === "additional") {
            const add = {
                id: list.id,
                name: list.doc_name,
                doc_name: list.doc_name,
                file_name: list.file_name,
                doc_type: list.doc_type,
                doc_url: list.doc_url,
                doc_extension: list.doc_extension,
                isdelete: false,
                doc_number: list.doc_number,

            };
            tempAdd.push(add);
        }
    });

    setAdditionalDocumentsFiles(tempAdd);
    setAdditonalDocument(tempAdd);
    setResponseData(resData);
    if (tempPassport) {
        setPassport_array(tempPassport);
        setValue(
            "passportNationalIdDocument",
            Object.keys(tempPassport).length > 0 ? [tempPassport] : []
        );
    }
    if (tempIncorporation) {
        setIncorportion_array(tempIncorporation);
        setValue(
            "incorporationDocument",
            Object.keys(tempIncorporation).length > 0 ? [tempIncorporation] : []
        );
    }
    if (tempBrokerId) {
        setBrokerId_array(tempBrokerId);
        setValue(
            "brokerIdDocument",
            Object.keys(tempBrokerId).length > 0 ? [tempBrokerId] : []
        );
    }
    if (tempBrokerageCertificate) {
        setBrokerageCertificate_array(tempBrokerageCertificate);
        setValue(
            "brokerageCertificateDocument",
            Object.keys(tempBrokerageCertificate).length > 0
                ? [tempBrokerageCertificate]
                : []
        );
    }
    if (tempVat) {
        setVat_array(tempVat);
        setValue(
            "vatCertificateDocument",
            Object.keys(tempVat).length > 0 ? [tempVat] : []
        );
    }
    if (tempResidence) {
        setResidence_array(tempResidence);
        setValue(
            "residenceCivilCard",
            Object.keys(tempResidence).length > 0 ? [tempResidence] : []
        );
    }
    if(tempEmiratesId){
      setValue(
        "emiratesID",
        Object.keys(tempEmiratesId).length > 0 ? [tempEmiratesId] : []
    );
    setEmiratesId_array(tempEmiratesId);
    }
    if(tempNationalityId){
        setValue(
          "saudiIqama",
          Object.keys(tempNationalityId).length > 0 ? [tempNationalityId] : []
      );
      setNationalityId_array(tempNationalityId);
      }
    
}

export const docForm = (data, docType) => {
    return {
        doc_name: data?.[0]?.name,
        file_name: data?.[0]?.name,
        doc_type: docType,
        file: data?.[0],
    }
}