import React from "react";
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl
} from "@mui/material";
import { useTranslate } from "react-admin";
import CustomerType from "./CustomerType";

const CustomerTypeSelection = ({
    error,
    customerExistence,
    setCustomerExistence,
    customerType,
    setCustomerType,
    setError,
}) => {
    const translate = useTranslate();

    const handleChange = (e) => {
        setCustomerExistence(e.target.value);
    };

    const setCustomer = (type) => {
        setCustomerType(type);
        localStorage.setItem("customerType", type);
    }

    const onClickType = (type) => {
        setCustomer(type);
        setError(null);
    }

    return (
        <>
            <FormControl className="book-for-customer-inner">
                <RadioGroup
                    aria-labelledby="radio-button-group"
                    name="controlled-radio-buttons-group"
                    value={customerType}
                    onChange={handleChange}
                >
                    <FormControlLabel
                        value="existing"
                        control={<Radio />}
                        label={translate("ra.fieldName.existing")}
                        checked={customerExistence === "existing"}
                    />
                    {customerExistence === "existing" &&
                        <CustomerType customerType={customerType} onClickType={onClickType} error={error} />
                    }
                    <FormControlLabel
                        value="new"
                        control={<Radio />}
                        label={translate("ra.fieldName.new")}
                        checked={customerExistence === "new"}
                    />
                    {customerExistence === "new" &&
                        <CustomerType customerType={customerType} onClickType={onClickType} error={error} />
                    }
                </RadioGroup>
            </FormControl>
        </>
    );
}

export default CustomerTypeSelection;