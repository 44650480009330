import React, { useState } from "react";
import { 
  Container, 
  List, 
  ListItem, 
  ListItemButton, 
  ListItemText 
} from "@mui/material" ;
import { sortData } from "Utils/constantValues";
import { useNavigate } from "react-router-dom"
import { createQueryParams } from "Utils/queryValues";
import DropdownArrow from "images/dropdown-arrow.svg";
import gtagEventFire from "Utils/ga4EventTrigger";

const SortOptions = ({
  sortValue, 
  setSortValue, 
  setShowSortOptions,
  projectListApi,
  params,
  userData,
  page,
  path
}) => {
  const navigate = useNavigate();
  
  const sortDetails = (item,name) => {
    if(sortValue === item) {
      setSortValue(null)
      queryApiCall(null)
    } else {
      setSortValue(item);
      gtagEventFire(page==="unitlist" ? "unit_sort" : "home_sort", {
        user_email: userData?.email,
        sort_option: name
      }); 
      queryApiCall(item);
    }
    setShowSortOptions(false);
  }

  const queryApiCall = (sort) => {
    const filterUrl = createQueryParams([], sort, null, params?.countrysearch, params, page, params?.view);
    navigate({
      pathname: page ? path : "/home",
      search: filterUrl?.url,
      hash: "#",
    }, {replace: true});
    projectListApi(userData, filterUrl?.params);
  }

  useState(() => {
    if(params?.sort) {
      setSortValue(params?.sort)
    }
  }, [params])


  return (
    <>
    <Container className="filter-panels sort-option">
    <img className="dropdown-arrow" src={DropdownArrow} alt="Dropdown arrow" />
    <List>
        {sortData.map((item, idx) => (
          <ListItem key={idx} disablePadding onClick={() => sortDetails(item?.value, item?.name)}>
            <ListItemButton className={`${(item?.value === sortValue) && "active"}`} sx={{ textAlign: 'left' }}>
              <ListItemText primary={item?.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      </Container>
    </>
  );
};

export default SortOptions;
