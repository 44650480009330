import { Checkbox, Radio } from '@material-ui/core';
import React from 'react'
import { useRecordContext } from 'react-admin';

const CheckboxGrid = ({ onSelect, customerType, tabs }) => {
    const record = useRecordContext();

    return (
        <>
            {customerType === "Individual" ?
                <Checkbox checked={tabs?.find(data => data?.id === record?.id) ? true : false} onClick={(e) => onSelect(e, record)} source="actions" />
                :
                <Radio checked={tabs?.id === record?.id ? true : false} onClick={(e) => onSelect(e, record)} />
            }
        </>
    )
}

export default CheckboxGrid