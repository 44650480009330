import React, { useState, useEffect } from 'react'
import { FormControl, Grid, Typography, Container, Button } from '@mui/material'
import { useForm } from 'react-hook-form'
import {
  useRedirect,
  useStoreContext,
  useStore,
  useTranslate,
} from 'react-admin'
import Loader from 'Components/Loader'
import isRead from 'Utils/ui-lock-status'
import AutoCompleteComponent from 'Components/Common/AutoComplete'
import DatePickerComponent from 'Components/Common/DatePicker'
import { getDateFormat } from 'Utils/dateFormat'
import { DOBDate, expiryDate, issueDate } from 'Utils/maxMinDate'
import userDetails from 'Services/userDetailsServices'
import country from 'Services/countryService'
import nationality from 'Services/nationalityService'
import projectList from 'Services/projectListServices'
import { ProjectDestinationsInterested } from 'Services/Salesforce/projectService'
import { licensed, titleValues } from 'Utils/constantValues'
import {
  numberTextPattern,
  textPattern,
  textWithSpacePattern,
} from 'Utils/regex-patterns'
import registerService from 'Services/registerService'
import Title from 'Components/Title/Title'
import InputField from 'Components/InputField'
import SelectComponent from 'Components/Common/SelectComponent'
import RadioCheckboxComponent from 'Components/Common/RadioCheckboxComponent'
import { addRemoveFromArray } from 'Utils/formArrayValues'
import RadioConditionCheck from 'Components/Common/RadioConditionCheck'
import HeaderButton from 'Components/HeaderButton'
import { isKsaPresent } from 'Utils/commonFunctions'

import { useNavigate,useLocation } from 'react-router-dom';

const PersonalDetails = ({ notification }) => {
  const location=useLocation();
  const navigate=useNavigate();
  const history_url=JSON.parse(localStorage.getItem("history_url"));
  const prevRedirectPage=localStorage.getItem("prevRedirectPage");
  const [formData, setFormData] = useStore()
  const [os, setOs] = useState(null)
  const [loading, setLoading] = useState(false)
  const [bdm, setBdm] = useState()
  const [nationalityList, setNationalityList] = useState([])
  const [projectInterestedList, setProjectInterestedList] = useState()
  const [countryList, setCountryList] = useState([])
  const [nationalityState, setNationalityState] = useState(null)
  const [nationalityCode, setNationalityCode] = useState(null)
  const [countryState, setCountryState] = useState(null)
  const [ccode, setccode] = useState(null)
  const [nationalityLoading, setNationalityLoading] = useState(false)
  const [countryLoading, setCountryLoading] = useState(false)
  const [bdmLoading, setBdmLoading] = useState(false)
  const [projectListLoading, setProjectListLoading] = useState(false)

  const minDateForDOB=new Date(new Date().setFullYear(new Date().getFullYear() - 100));

  const translate = useTranslate()
  const redirect = useRedirect()
  const store = useStoreContext()
  const form = useForm({
    mode: 'onChange',
  })
  const {
    register,
    handleSubmit,
    setError,
    formState,
    watch,
    setValue,
    clearErrors,
    control,
  } = form
  const { errors } = formState
  let user_id = store.getItem('user_id')
  let user_type = store.getItem('user_type')
  const step = 2

  //set Oracle Status
  const setOracleStatus = (resData) => {
    resData?.oracle_status ? setOs(resData?.oracle_status) : setOs('-')
  }

  useEffect(() => {

    (!(history_url?.some(path=>path==location.pathname)))&&navigate(prevRedirectPage)
    
    ProjectDestinationsInterested(
      setProjectInterestedList,
      setProjectListLoading,
      notification,
      false
    )
    nationality(setNationalityList, setNationalityLoading, notification)
    country(setCountryList, setCountryLoading, notification)
    userDetails(setLoading, setFormData, setOracleStatus, notification)
  }, [])

  const projectDestinationHandler = (e) => {
    const projectData = formData?.project_destinations_interested || []
    const destination = [...projectData]
    addRemoveFromArray(
      e.target.value,
      destination,
      setFormData,
      'project_destinations_interested'
    )
  }

  // Setting and Clearing Id type errors
  const idErrors = () => {
    if (!watch('passport_number')) {
      watch('id_type') === '1'
        ? setError('passport_number', {
            type: 'required',
            message: translate('ra.validation.validPassportNo'),
          })
        : setError('passport_number', {
            type: 'required',
            message: translate('ra.validation.validNationalId'),
          })
    } else {
      clearErrors('passport_number')
    }
  }

  const onSubmit = async (data) => {
    if (!user_type) {
      user_type = Number(localStorage.getItem('user_type'))
    }
    if (!user_id) {
      user_id = Number(localStorage.getItem('user_id'))
    }
    let {
      title,
      first_name,
      last_name,
      DOB,
      id_type,
      issue_date,
      expiry_date,
      place_id_issue,
      designation,
      company_name,
      passport_number,
      national_residence_card_number,
      pdi,
      qatar_id_number,
      business_development_manager,
      license_type,
    } = data

    if (isRead(os)) {
      pdi = formData?.project_destinations_interested
      id_type = formData?.id_type
    } else {
      pdi = Array.isArray(pdi) ? [...pdi] : [pdi]
    }

    const project_destinations_interested = pdi.join(',')

    id_type =
      nationalityCode === 'Omani' ||
      ccode === 'Oman' ||
      nationalityCode === 'Qatari' ||
      ccode === 'Qatar'
        ? 1
        : id_type
    let national_id_number
    if (id_type === '2') {
      national_id_number = passport_number
    }

    const formated_DOB = getDateFormat(DOB)
    const formated_issue_date = getDateFormat(issue_date)
    const formated_expiry_date = getDateFormat(expiry_date)

    setLoading(true)
    let apiRequestData = {
      step,
      user_type,
      user_id,
      title,
      first_name: first_name?.trim(),
      last_name: last_name?.trim(),
      DOB: formated_DOB,
      id_type,
      country_residence: ccode,
      issue_date: formated_issue_date,
      expiry_date: formated_expiry_date,
      nationality: nationalityCode,
      place_id_issue: place_id_issue?.trim(),
      designation: designation?.trim(),
      national_id_number: national_id_number?.trim(),
      passport_number: passport_number?.trim(),
      company_name: company_name?.trim(),
      business_development_manager,
      // qatar_id_number,
      project_destinations_interested,
      license_type:license_type
    }

    if(qatar_id_number&&(ccode=="Qatar"||nationalityCode=="Qatari")){
      apiRequestData["qatar_id_number"]=qatar_id_number
    }

    if (nationalityCode === 'Omani' || ccode === 'Oman') {
      apiRequestData.national_residence_card_number =
        national_residence_card_number?.trim()
    }

    registerService(
      isRead,
      os,
      setLoading,
      apiRequestData,
      notification,
      redirect,
      isKsaPresent(location?.pathname)?'/individual-ksa-licensed/contact':'/register-myself/contact'
    )
  }

  useEffect(() => {
    if (formData?.nationality) {
      const nation = nationalityList?.find(
        (nation) => nation.code == formData?.nationality
      )
      setNationalityState(nation)
      setNationalityCode(formData?.nationality)
    }
  }, [formData?.nationality, nationalityList])

  useEffect(() => {
    if (formData?.country_residence) {
      const country = countryList?.find(
        (country) => country?.country_code == formData?.country_residence
      )
      setCountryState(country)
      setccode(formData?.country_residence)
    }
  }, [formData?.country_residence, countryList])

  useEffect(() => {
    if (
      nationalityCode === 'Omani' ||
      ccode === 'Oman' ||
      nationalityCode === 'Qatari' ||
      ccode === 'Qatar'
    ) {
      setValue('id_type', '1')
      clearErrors('id_type')

      if (!watch('passport_number')) {
        setError('passport_number', {
          type: 'required',
          message: translate('ra.validation.validPassportNo'),
        })
      } else {
        clearErrors('passport_number')
      }
    }
  }, [nationalityCode, ccode])

  const backNavigation=()=>{
    navigate(isKsaPresent(location?.pathname)?"/individual-ksa-licensed":"/register-myself");
   }
  
  return (
    <>
      {(loading ||
        nationalityLoading ||
        projectListLoading ||
        bdmLoading ||
        countryLoading) && <Loader />}

      {formData && (
        <Container className="company-container new-fix-container">
          <Container className="company-details">
            <HeaderButton
              notification={notification}
              os={os}
              classname={'only-logout'}
              releaseBlockUnits={backNavigation}
            />
            <Grid className="form-panel" sx={{ width: '100%' }}>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontStyle: 'normal',
                  marginBottom: '20px',
                }}
                variant="h3"
              >
                {translate('ra.pageTitle.PersonalDetails')}
              </Typography>

              {/* Name */}
              <Grid
                className="full-section checkbox-panel"
                container
                spacing={0}
              >
                <Typography variant="subtitle1">
                  {translate('ra.fieldName.fullName')}
                  <span style={{ color: '#EF4349' }}>*</span>
                </Typography>
                <FormControl className="mb15" fullWidth>
                  <Title
                    name="title"
                    defaultValue={formData?.title || ''}
                    dropdownValue={titleValues}
                    register={register}
                    errors={errors}
                    watch={watch}
                    className="full-width ml0 mt0 mb0 new-prefix"
                    inputProps={{
                      readOnly: isRead(os),
                    }}
                    sx={{
                      '& legend': { display: 'none' },
                      width: '100%',
                      marginBottom: '20px',
                    }}
                    message={translate('ra.validation.validInput')}
                    labelName={translate('ra.placeholder.prefix')}
                  />
                </FormControl>
                <InputField
                  register={register}
                  errors={errors}
                  defaultValue={formData?.first_name || ''}
                  id="outlined-basic-fname"
                  className=" mt0 mb15"
                  readOnly={isRead(os)}
                  state="first_name"
                  placeholder={translate('ra.placeholder.firstName')}
                />
                <InputField
                  register={register}
                  errors={errors}
                  defaultValue={formData?.last_name || ''}
                  id="outlined-basic-lname"
                  className="mb15 mtop"
                  readOnly={isRead(os)}
                  state="last_name"
                  placeholder={translate('ra.placeholder.lastName')}
                />
                <div className='personalCalendar'>
                    <DatePickerComponent
                        title={translate('ra.fieldName.dob')}
                        control={control}
                        name="DOB"
                        value={formData?.DOB}
                        maxDate={DOBDate}
                        minDate={minDateForDOB}
                        errors={errors?.DOB}
                        os={os}
                      />
                </div>
                
              </Grid>

              {/* DOB */}
              {/* <Grid  container spacing={0}>
                <DatePickerComponent
                  title={translate('ra.fieldName.dob')}
                  control={control}
                  name="DOB"
                  value={formData?.DOB}
                  maxDate={DOBDate}
                  minDate={minDateForDOB}
                  errors={errors?.DOB}
                  os={os}
                />
              </Grid> */}

              {/* Country of Residence */}
              <Grid className="new-select-class" container spacing={0}>
                <AutoCompleteComponent
                  title={translate('ra.fieldName.countryResidence')}
                  className="ml0"
                  value={countryState}
                  onChange={(e, newInputValue) => {
                    setCountryState(newInputValue)
                    setccode(newInputValue?.country_code)
                    clearErrors('country_residence')
                  }}
                  options={countryList}
                  getOptionLabel={(option) => option?.country_name || ''}
                  name="country_residence"
                  placeholder={translate('ra.placeholder.country')}
                  errors={errors?.country_residence}
                  register={register}
                  os={os}
                />
              </Grid>

              <Grid
                className="new-select-class new-select-label-space"
                container
                spacing={0}
              >
                <Typography
                  className="checkbox_text"
                  variant="subtitle1"
                  align="left"
                >
                  {translate('ra.fieldName.licensedOption')}
                  <span style={{ color: '#EF4349' }}>*</span>
                </Typography>
                <FormControl fullWidth sx={{ mb: 4 }}>
                  <Title
                    name="license_type"
                    defaultValue={isKsaPresent(location?.pathname)?"L":formData?.license_type||""}
                    dropdownValue={licensed}
                    register={register}
                    errors={errors}
                    className="full-width ml0 mb0"
                    inputProps={{
                      readOnly:isRead(os)||isKsaPresent(location?.pathname),
                    }}
                    sx={{
                      '& legend': { display: 'none' },
                      width: '100%',
                      marginBottom: '0',
                    }}
                    labelhide={true}
                    message={translate('ra.validation.pleaseChoose')}
                  />
                </FormControl>
              </Grid>

              {/* Nationality */}
              <Grid className="new-select-class" container spacing={0}>
                <AutoCompleteComponent
                  title={translate('ra.fieldName.nationality')}
                  value={nationalityState}
                  onChange={(e, newInputValue) => {
                    setNationalityState(newInputValue)
                    setNationalityCode(newInputValue?.code)
                    clearErrors('nationality')
                  }}
                  options={nationalityList}
                  getOptionLabel={(option) => option?.name || ''}
                  name="nationality"
                  placeholder={translate('ra.placeholder.nationality')}
                  errors={errors?.nationality}
                  register={register}
                  os={os}
                />
              </Grid>

              {/* Id type */}
              <Grid className="id-type" container spacing={0}>
                <RadioConditionCheck
                  title={translate('ra.fieldName.idType')}
                  name="id_type"
                  register={register}
                  errors={errors?.id_type?.message}
                  defaultValue={
                    formData?.id_type
                      ? formData?.id_type?.toString()
                      : (nationalityCode === 'Omani' ||
                          ccode === 'Oman' ||
                          nationalityCode === 'Qatari' ||
                          ccode === 'Qatar') &&
                        '1'
                  }
                  className="radio_control"
                  readOnly={!isRead(os)}
                  requiredField={
                    !isRead(os) &&
                    watch('nationality') !== 'OM' &&
                    watch('country_residence') !== 'Oman' &&
                    watch('nationality') !== 'QA' &&
                    watch('country_residence') !== 'Qatar' &&
                    translate('ra.validation.selectOne')
                  }
                  onChangeField={idErrors}
                  value1="1"
                  disabled1={isRead(os)}
                  checked1={
                    (nationalityCode === 'Omani' ||
                      ccode === 'Oman' ||
                      nationalityCode === 'Qatari' ||
                      ccode === 'Qatar' ||
                      watch('id_type') === '1' ||
                      formData?.id_type == '1') &&
                    watch('id_type') != '2'
                  }
                  label1={translate('ra.placeholder.passport')}
                  formClass="radio_text"
                  value2="2"
                  disabled2={
                    isRead(os) ||
                    nationalityCode === 'Omani' ||
                    ccode === 'Oman' ||
                    nationalityCode === 'Qatari' ||
                    ccode === 'Qatar'
                  }
                  checked2={
                    nationalityCode !== 'Oman' &&
                    ccode !== 'Oman' &&
                    nationalityCode !== 'QA' &&
                    ccode !== 'Qatar' &&
                    watch('id_type') != '1' &&
                    (watch('id_type') === '2' || formData?.id_type == '2')
                  }
                  label2={translate('ra.placeholder.nationalId')}
                />
              </Grid>

              {/* id number */}
              <Grid container spacing={0}>
                <InputField
                  name={translate('ra.fieldName.passport')}
                  register={register}
                  errors={errors}
                  defaultValue={formData?.passport_number || ''}
                  id="outlined-basic-passport-national-id"
                  className="mt0"
                  readOnly={isRead(os)}
                  state="passport_number"
                  pattern={{
                    value: numberTextPattern,
                    message: translate('ra.validation.validInput'),
                  }}
                  onChangeField={(e) => idErrors()}
                  placeholderHide={true}
                />
              </Grid>

              {/* residence card number*/}
              {(nationalityCode === 'Omani' || ccode === 'Oman') && (
                <Grid container spacing={0}>
                  <InputField
                    name={translate('ra.fieldName.civilCardNo')}
                    register={register}
                    errors={errors}
                    defaultValue={
                      formData?.national_residence_card_number || ''
                    }
                    id="outlined-basic-residence-civil-card-number"
                    readOnly={isRead(os)}
                    state="national_residence_card_number"
                    pattern={{
                      value: numberTextPattern,
                      message: translate('ra.validation.validInput'),
                    }}
                    placeholderHide={true}
                  />
                </Grid>
              )}

              {/* Qatar id number*/}
              {(nationalityCode === 'Qatari' || ccode === 'Qatar') && (
                <Grid container spacing={0}>
                  <InputField
                    name={translate('ra.fieldName.qatarIdNumber')}
                    register={register}
                    errors={errors}
                    defaultValue={formData?.qatar_id_number || ''}
                    id="outlined-basic-qatar-id-card-number"
                    readOnly={isRead(os)}
                    state="qatar_id_number"
                    pattern={{
                      value: numberTextPattern,
                      message: translate('ra.validation.validInput'),
                    }}
                    placeholderHide={true}
                  />
                </Grid>
              )}

              {/* Issue Date */}
              <Grid container spacing={0}>
                <DatePickerComponent
                  title={translate('ra.fieldName.issueDate')}
                  control={control}
                  name="issue_date"
                  value={formData?.issue_date}
                  maxDate={issueDate}
                  errors={errors?.issue_date}
                  os={os}
                />
              </Grid>

              {/* Expiry date */}
              <Grid container spacing={0}>
                <DatePickerComponent
                  title={translate('ra.fieldName.expiryDate')}
                  control={control}
                  name="expiry_date"
                  value={formData?.expiry_date}
                  minDate={expiryDate}
                  errors={errors?.expiry_date}
                  os={os}
                />
              </Grid>

              {/* place of id */}
              <Grid container spacing={0}>
                <InputField
                  name={translate('ra.fieldName.idPlaceIssue')}
                  register={register}
                  errors={errors}
                  defaultValue={formData?.place_id_issue || ''}
                  id="outlined-basic-place"
                  readOnly={isRead(os)}
                  state="place_id_issue"
                  pattern={{
                    value: textPattern,
                    message: translate('ra.validation.validInput'),
                  }}
                  placeholderHide={true}
                />
              </Grid>

              {/* Designation */}
              <Grid container spacing={0}>
                <InputField
                  name={translate('ra.fieldName.jobDesignation')}
                  register={register}
                  errors={errors}
                  defaultValue={formData?.designation || ''}
                  id="outlined-basic-designation"
                  readOnly={isRead(os)}
                  state="designation"
                  placeholderHide={true}
                />
              </Grid>

              {/* Company */}
              <Grid container spacing={0}>
                <InputField
                  name={translate('ra.fieldName.company')}
                  register={register}
                  errors={errors}
                  defaultValue={formData?.company_name || ''}
                  id="outlined-basic-company-name"
                  readOnly={isRead(os)}
                  state="company_name"
                  placeholderHide={true}
                />
              </Grid>

              {/* Project Destinations Interested */}
              <Grid
                container
                className="full-section checkbox-panel"
                spacing={0}
              >
                <RadioCheckboxComponent
                  title={translate('ra.fieldName.projectInterest')}
                  name="pdi"
                  radioValues={projectInterestedList}
                  register={register}
                  errors={errors?.pdi?.message}
                  defaultValue={formData?.project_destinations_interested}
                  className="personal"
                  readOnly={isRead(os)}
                  requiredField={translate('ra.validation.selectOptions')}
                  onChange={(e) => projectDestinationHandler(e)}
                  controlClass="checkbox_total-panel ml0"
                  formClass="radio_text"
                  dataValue={formData?.project_destinations_interested || []}
                  dataName="name"
                />
              </Grid>

              <Grid container spacing={0}>
                <InputField
                  name={translate('ra.fieldName.businessManager')}
                  register={register}
                  errors={errors}
                  defaultValue={formData?.business_development_manager || ''}
                  placeholder={translate('ra.placeholder.businessManager')}
                  id="business_development_manager"
                  readOnly={isRead(os)}
                  state="business_development_manager"
                  sx={{ width: '100%', marginBottom: '20px' }}
                  required={false}
                  hide={true}
                  pattern={{
                    value: textWithSpacePattern,
                    message: translate('ra.validation.validInput'),
                  }}
                />
              </Grid>

              {/* Button */}
              <Grid
                container
                className="company-details-button justify-right"
                spacing={0}
              >
                <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                  {translate('ra.button.next')}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Container>
      )}
    </>
  )
}

export default PersonalDetails
