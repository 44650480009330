export const takeActionDetails = (e, record, translate, navigate, currentPage, location) => {
    e.stopPropagation();
    localStorage.setItem("portalBookingId", record?.booking_id);
    localStorage.setItem("customerType", record?.lead_type);
    localStorage.setItem("editCustomer", true);
    localStorage.setItem("editPath", true);
    localStorage.setItem("booking_page", `/${currentPage}${location?.search}`);

    let tabs = [];

    record?.units[0]?.customer_data?.map((data, index) => {
        let arr = [];
        tabs.push({
            id: data?.customer_id,
            value: index + 1,
            unitId: record?.units?.map((unit) => {
                unit?.customer_data?.map((singlet) => {
                    if (singlet?.customer_id === data?.customer_id) arr.push(singlet?.customer_unit_id);
                })
                return arr;
            })?.[0],
            name: record?.lead_type === 1
                ? `${data?.first_name?.charAt(0)?.toUpperCase()}${data?.last_name?.charAt(0)?.toUpperCase()}`
                : `${data?.company_name?.slice(0, 2).toUpperCase()}`
        })
    });
    localStorage.setItem("tabs", JSON.stringify(tabs));

    const units = Object.values(record?.units)?.map(unit => {
        return {
            officE_SECTION_ID: unit?.unit_id,
            officE_SECTION_CODE: unit?.unit_no,
            propertY_PRICE: unit?.total_unit_price,
            saleablE_AREA_SQFT: unit?.saleable_area_sqft,
            flooR_PARCEL_ID: unit?.floor_parcel_id,
            releasE_ID: unit?.release_id,
            buildinG_LAND_ID: unit?.building_land_id,
            organizatioN_ID: unit?.org_id
        }
    });

    if (record.status === translate("ra.fieldName.ownership") || record.status === translate("ra.fieldName.reviewCustomer")) {
        if (record.status === translate("ra.fieldName.ownership")) {
            sessionStorage.setItem("formStatus", "/assign-ownership-percentage");
            navigate("/assign-ownership-percentage", { state: { units, project_id: record?.project_id } })
        } else {
            sessionStorage.setItem("formStatus", "/review-customer-information");
            navigate("/review-customer-information", { state: { units, project_id: record?.project_id, from: "mybooking" } })
        }
    } else if (record.status === translate("ra.fieldName.chooseToken") || record.status === translate("ra.fieldName.attachToken")) {
        sessionStorage.setItem("formStatus", "/token-payment-details");
        record.status === translate("ra.fieldName.chooseToken")
            ? navigate("/token-payment-details", { state: { units, project_id: record.project_id, value: "1" } })
            : navigate("/token-payment-details", { state: { units, project_id: record.project_id, value: "2" } })
    } else if (record.status === translate("ra.fieldName.uploadForm") || record?.status === "booking_in_progress") {
        const unit_reservation = Object.values(record?.units)?.map((unit) => {
            return {
                unit_no: unit?.unit_no, reservation_id: unit?.reservation_id
            }
        })
        localStorage.setItem("bookingId", record?.booking_id)
        localStorage.setItem("unit-reservation", JSON.stringify(unit_reservation))
        if (record?.units?.map((unit) => unit?.kyc_status === 0)?.includes(true)) {
            sessionStorage.setItem("formStatus", "/reservation-successfull")
            navigate("/reservation-successfull", { state: { units, project_id: record.project_id } })
        }
        else {
            sessionStorage.setItem("formStatus", "/upload-reservation")
            navigate("/upload-reservation", { state: { units, project_id: record.project_id } })
        }
    } else if (record?.unit > 1 && record?.buttonBlock === "Pending") {
        navigate(`/booking-details?id=${record?.booking_id}&tab=1`);
    }
    localStorage.setItem("bookingState", JSON.stringify({ units, project_id: record.project_id }));
}

export const takeActionData = (details, navigate, translate) => {
    localStorage.setItem("portalBookingId", details?.units[0]?.portal_booking_id);
    localStorage.setItem("customerType", details?.units[0]?.customer_type);
    localStorage.setItem("editCustomer", true);
    localStorage.setItem("editPath", true);

    let tabs = [];

    if (Object.keys(details?.units[0]?.primary_buyer)?.length) {
        let arr = [];
        tabs.push({
            id: details?.units[0]?.primary_buyer?.id,
            value: 1,
            unitId: details?.units?.map((unit) => {
                if (details?.units[0]?.primary_buyer?.id === unit?.primary_buyer?.id) {
                    arr.push(unit?.primary_buyer?.customer_unit_id)
                }
                unit?.joint_buyer?.map((singlet) => {
                    if (singlet?.id === details?.units[0]?.primary_buyer?.id) arr.push(singlet?.customer_unit_id);
                })

                return arr;
            })?.[0],
            name: details?.units[0]?.customer_type === 1
                ? `${details?.units[0]?.primary_buyer?.first_name?.charAt(0)?.toUpperCase()}${details?.units[0]?.primary_buyer?.last_name?.charAt(0)?.toUpperCase()}`
                : `${details?.units[0]?.primary_buyer?.company_name?.slice(0, 2).toUpperCase()}`
        })
    }
    if (details?.units[0]?.joint_buyer?.length) {
        let arr = [];
        details?.units[0]?.joint_buyer?.map((buyer, index) => {
            tabs.push({
                id: buyer?.id,
                value: Object.keys(details?.units[0]?.primary_buyer)?.length ? index + 2 : index + 1,
                unitId: details?.units?.map((unit) => {
                    if (buyer?.id === unit?.primary_buyer?.id) {
                        arr.push(unit?.primary_buyer?.customer_unit_id)
                    }
                    unit?.joint_buyer?.map((singlet) => {
                        if (singlet?.id === buyer?.id) arr.push(singlet?.customer_unit_id);
                    })
                    return arr;
                })?.[0],
                name: details?.units[0]?.customer_type === 1
                    ? `${buyer?.first_name?.charAt(0)?.toUpperCase()}${buyer?.last_name?.charAt(0)?.toUpperCase()}`
                    : `${buyer?.company_name?.slice(0, 2).toUpperCase()}`
            })
        })
    }
    localStorage.setItem("tabs", JSON.stringify(tabs));
    const units = Object.values(details?.units)?.map(unit => {
        return {
            officE_SECTION_ID: unit?.unit_id,
            officE_SECTION_CODE: unit?.unit_no,
            propertY_PRICE: unit?.total_unit_price || "0",
            saleablE_AREA_SQFT: unit?.saleable_area_sqft,
            flooR_PARCEL_ID: unit?.floor_parcel_id,
            releasE_ID: unit?.release_id,
            buildinG_LAND_ID: unit?.building_land_id,
            organizatioN_ID: unit?.org_id
        }
    });
    if (details?.units[0]?.booking_status === "assign_ownership_percentage" || details?.units[0]?.booking_status === "review_customer_information") {
        if (details?.units[0]?.booking_status === "assign_ownership_percentage") {
            sessionStorage.setItem("formStatus", "/assign-ownership-percentage");
            navigate("/assign-ownership-percentage", { state: { units, project_id: details?.units[0]?.project_id } })
        } else {
            sessionStorage.setItem("formStatus", "/review-customer-information");
            navigate("/review-customer-information", { state: { units, project_id: details?.units[0]?.project_id } })
        }
    } else if (details?.units[0]?.booking_status === "choose_token_payment_method" || details?.units[0]?.booking_status === "attach_token_payment_proof") {
        sessionStorage.setItem("formStatus", "/token-payment-details");
        details?.units[0]?.booking_status === "choose_token_payment_method"
            ? navigate("/token-payment-details", { state: { units, project_id: details?.units[0]?.project_id, value: "1" } })
            : navigate("/token-payment-details", { state: { units, project_id: details?.units[0]?.project_id, value: "2" } })
    } else if (details?.units[0]?.booking_status === "upload_signed_reservation_form" || details?.units[0]?.booking_status === "booking_in_progress") {
        const unit_reservation = Object.values(details?.units)?.map((unit) => {
            return {
                unit_no: unit?.unit_no, reservation_id: unit?.oracle_reservation_id
            }
        })
        localStorage.setItem("bookingId", details?.booking_id)
        localStorage.setItem("unit-reservation", JSON.stringify(unit_reservation))
        if (details?.units?.map((unit) => unit?.kyc_status === 0)?.includes(true)) {
            sessionStorage.setItem("formStatus", "/reservation-successfull")
            navigate("/reservation-successfull", { state: { units, project_id: details?.units[0]?.project_id } })
        } else {
            sessionStorage.setItem("formStatus", "/upload-reservation")
            navigate("/upload-reservation", { state: { units, project_id: details?.units[0]?.project_id } })
        }
    }
    localStorage.setItem("bookingState", JSON.stringify({ units, project_id: details?.units[0]?.project_id }));
}