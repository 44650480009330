import React from "react";
import { Grid, Button, Link } from "@mui/material";
import { useLogout, useTranslate, useRedirect} from 'react-admin';
import LogoutIcon from "@mui/icons-material/Logout";
import styles from "./styles.module.scss";
import gtagEventFire from "Utils/ga4EventTrigger";
import { removeLocalStorage } from "Utils/removeStorage";

const ProfileSection = ({ imgSrc, showProfile, notification, email, redirectLinkPath, pagePath= null, releaseBlockUnits }) => {
  const formStatus = sessionStorage.getItem("formStatus") || null;
  const previous_url = localStorage.getItem("previous_url") || null;
  const logout = useLogout();
  const translate = useTranslate();
  const redirect = useRedirect();

  const handleClick = (e) => {
    if(pagePath === "form-booking") {
      redirectLinkPath(e, "/logout");
    } else {
      window.location.reload(); // remove all api which runs in previous page
      logout();
      notification("ra.notification.logout", { type: "success" });
    }
  }

  const redirectPath = (e, url) => {
    e.preventDefault();
    redirect(url);
    if(formStatus || previous_url) {
      window.location.reload();
      removeLocalStorage();
      localStorage.removeItem("page");
      localStorage.removeItem("params");
      localStorage.removeItem("page-redirect");
    }
  }

  return (
    <>
      <Grid className={`profile-dropdown ${showProfile ? "show-profile" : ""}`} >
          <Link 
            className="profile-icon" 
            onClick={(e) => releaseBlockUnits ? redirectLinkPath(e, "/profile") : redirectPath(e, "/profile")}
            href={(pagePath === "form-booking") ? null : "#/profile"} >
            <img src={imgSrc} alt="profile" /> {translate("ra.fieldName.profile")}
          </Link>
          <Link 
            className="profile-icon" 
            href={(pagePath === "form-booking") ? null : "#/bdm-details"} 
            onClick={(e) => {
              gtagEventFire("help-and-support", { user_email: email });
              releaseBlockUnits ? redirectLinkPath(e, "/bdm-details") : redirectPath(e, "/bdm-details");
            }}
          >
            <i className="iconwasalt icon-question" ></i> {translate("ra.fieldName.help")}
          </Link>
          <Button variant="text" onClick={handleClick}>
            <LogoutIcon />
            {translate("ra.button.logout")}
          </Button>
      </Grid>
    </>
  );
};

export default ProfileSection;
