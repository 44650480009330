import { apiServiceRequest } from "Utils/axiosInstance";
import gtagEventFire from "Utils/ga4EventTrigger";
import { getUserToken } from "Utils/tokenProvider";

//forgot password Service
const fileUploadService = async (
  formData,
  setLoading,
  notify,
  redirect,
  length,
  uploadedLength,
  os,
  type,
  user_email
) => {
  setLoading(true);
  const res = await apiServiceRequest({
    url: "/multi_file_upload",
    method: "post",
    data: formData,
    headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });
  if (res?.code === "success") {
    localStorage.clear();
    setLoading(false);
    gtagEventFire(`${type}_registration`, { user_email: user_email });
    redirect("/information-submitted");
  }
  setLoading(false);
};

export default fileUploadService;
