import { apiServiceRequest } from 'Utils/axiosInstance'
import { baseURL as mapBaseURL } from 'Utils/baseUrl'
import { currencyStatus, staticCurrencies  } from 'Utils/currencyCheck'
import { dataValueFromQuery } from 'Utils/filterData'
import { getQueryFromParams } from 'Utils/queryValues'
import { formatDocName } from 'Utils/documentFormation'
import { getUserToken } from 'Utils/tokenProvider'
import { unitApiProjectDetailsModificationData } from './projectDataFormation'

/**
 * Fetches project list data and filters it based on parameters.
 * Sets loading state, updates project data, currency list, and filter list accordingly.
 *
 * @param {function} setLoading - Function to set loading state.
 * @param {function} setData - Function to set project data.
 * @param {function} notify - Function to display notifications.
 * @param {object} params - Parameters for filtering project list.
 * @param {function} setFilterList - Function to set filter list.
 * @param {function} setCurrencyList - Function to set currency list.
 */
export const filterProjectList = async (
  setLoading,
  setData,
  notify,
  params,
  setFilterList,
  setCurrencyList
) => {
  setLoading(true)
  const res = await apiServiceRequest({
    url: `${mapBaseURL}GetLookups`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  })

  if (res?.successStatus) {
    const projectsWithNameResponse = {
      ...res.response,
      projectList: res.response.projectList
        .filter((project) => project.reraProjectName !== null)
        .map((project) => ({
          ...project,
          projecT_NAME: project.reraProjectName,
        })),
    }

    setData && setData(projectsWithNameResponse)
    const currencyList = res?.response?.currencies?.map((currency) => {
      return { code: currency?.id, name: currency?.name }
    })
    setCurrencyList && setCurrencyList(currencyList)
  }
  // setting values in filter from params
  if (res?.response) {
    dataValueFromQuery(params, res?.response, setFilterList)
  }
  setLoading(false)
}

/**  SalesForce Implementaion */
export const getProjectListDetails = async (
  setLoading,
  setData,
  userDetail,
  location,
  notify,
  setCount,
  setTotalNoOfRecords,
  pagePath
) => {
  try {
    setLoading(true)
    let query = {}

    const currency = userDetail?.currency?.toUpperCase() || ''
    const unit_type = userDetail?.unit_type?.toUpperCase() || ''
    if (location) {
      query = getQueryFromParams(location, currency, unit_type)
    }
    const res = await apiServiceRequest({
      url: `${mapBaseURL}GetBrokerProjects?jsonParam=${btoa(
        JSON.stringify({
          Limit: 12,
          Page: parseInt(query?.page) || 1,
          Conditions: query?.conditions || [],
          Sorts: query?.sort || [],
        })
      )}`,
      headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
      notify: notify,
    })

    if (res?.successStatus) {
      const resData = res?.response?.objList?.map((item, idx) => {
        return {
          project_id: item.projectCode,
          location: item.country,
          project_name: item.reraProjectName,
          start_price: currencyStatus(currency)
            ? item[`starT_PRICE_${currency}`]
            : item[`starT_PRICE_${item?.currency}`] || item.starT_PRICE,
          images: item.ProjectImage,
          currency: item.projectCurrency,
        }
      })
      setData(resData || [])
      if (setCount) setCount(res?.response?.totalNoOfRecords || 0)
      if(setTotalNoOfRecords)  setTotalNoOfRecords(res?.response?.totalNoOfRecords || 0)
    }
    setLoading(false)
  } catch (error) {
    // Handle errors here
    console.error('Error fetching project data:', error)
    setLoading(false)
    // Optionally, notify the user about the error
    notify('Error fetching project data. Please try again later.', 'error')
  }
}
export const getProjectDetails = async (
  setLoading,
  setData,
  notify,
  id,
  setAmenities,
  setDocumentsArray
) => {
  try {
    setLoading(true)
    const token = await getUserToken(notify)
    const res = await apiServiceRequest({
      url: `${mapBaseURL}GetBrokerProjects?jsonParam=${btoa(
        JSON.stringify({
          Conditions: [
            {
              Field: 'propertY_ID',
              Value: `${id}`,
              Filter: 'equal',
            },
          ],
        })
      )}`,
      headers: { Authorization: `Bearer ${token}` },
      notify: notify,
    })

    if (res?.successStatus) {
      const project = res?.response?.objList
      if (project?.length) {
        const resData = unitApiProjectDetailsModificationData(project[0])
        setData(resData)
        // Check if amenities exist before modifying and setting them
        if (resData?.Amenities && Array.isArray(resData.Amenities)) {
          // Modify amenities key name
          const modifiedAmenities = resData?.Amenities?.map((amenity) => ({
            propertyID: id,
            amenity: amenity.Name,
            // Add more modified properties if needed
          }))
          setAmenities(modifiedAmenities)
        } else {
          // Set an empty array if amenities do not exist
          setAmenities([])
        }
        // Check if there are project documents available before modifying and setting them
        if (
          resData?.ProjectDocument &&
          Array.isArray(resData.ProjectDocument)
        ) {
          // Modify document key name

          const modifiedDocuments = resData?.ProjectDocument?.map(
            (document) => ({
              propertyID: id,
              documentName: formatDocName(document?.documentName),
              documentLink:
                document?.documentLinkTiny || document?.documentLink,
              language: document?.language,
              propertyName: resData?.projecT_NAME,
            })
          )
          setDocumentsArray(modifiedDocuments)
        } else {
          // No project documents exist or they're not in the expected format
          setDocumentsArray([])
        }
      } else {
        throw new Error('No project data found in the response.')
      }
    } else {
      throw new Error('API request was not successful.')
    }
    setLoading(false)
  } catch (error) {
    // Handle errors here
    console.error('Error fetching project details:', error)
    setLoading(false)
    notify(`Error fetching project details: ${error.message}`, 'error')
  }
}

/**
 * Fetches amenities and documents for a specific project unit.
 *
 * @param {function} setAmenities - Function to set the amenities data.
 * @param {function} setDocuments - Function to set the documents data.
 * @param {function} notify - Function to display notifications.
 * @param {string} id - The ID of the project unit.
 * @param {function} translate - Function to translate messages.
 */
export const projectUnitDocumentsAmenities = async (
  setAmenities,
  setDocuments,
  notify,
  id,
  translate
) => {
  try {
    const token = await getUserToken(notify)
    const res = await apiServiceRequest({
      url: `${mapBaseURL}GetBrokerProjects?jsonParam=${btoa(
        JSON.stringify({
          Conditions: [
            {
              Field: 'propertY_ID',
              Value: `${id}`,
              Filter: 'equal',
            },
          ],
        })
      )}`,
      headers: { Authorization: `Bearer ${token}` },
      notify: notify,
    })

    if (res?.successStatus) {
      const project = res?.response?.objList

      if (project?.length) {
        const resData = unitApiProjectDetailsModificationData(project[0])
        // Check if amenities exist before modifying and setting them
        if (resData?.Amenities && Array.isArray(resData.Amenities)) {
          // Modify amenities key name
          const modifiedAmenities = resData?.Amenities?.map((amenity) => ({
            propertyID: id,
            amenity: amenity.Name,
            // Add more modified properties if needed
          }))
          setAmenities(modifiedAmenities)
        } else {
          // Set an empty array if amenities do not exist
          setAmenities([])
        }
        // Check if there are project documents available before modifying and setting them
        if (
          resData?.ProjectDocument &&
          Array.isArray(resData.ProjectDocument)
        ) {
          // Modify document key name
          const modifiedDocuments = resData?.ProjectDocument?.map(
            (document) => ({
              propertyID: id,
              documentLink: document?.documentLinkTiny || document?.documentLink,
              documentName: formatDocName(document?.documentName),
              language: document?.language,
              propertyName: resData?.projecT_NAME,
             
            })
          )
          setDocuments(modifiedDocuments)
        } else {
          // No project documents exist or they're not in the expected format
          setDocuments([])
        }
      } else {
        throw new Error(translate('ra.noData.noprojectdatafound'))
      }
    } else {
      throw new Error(translate('ra.noData.apiunsuccessfull'))
    }
  } catch (error) {
    // Handle errors here
    notify(`Error fetching project details: ${error.message}`, {
      type: 'error',
    })
  }
}

/**
 * Fetches the count of project lists asynchronously.
 * @param {function} setLoading - Function to set loading state.
 * @param {function} setData - Function to set data.
 * @param {object} userDetail - User details.
 * @param {string} location - Location details.
 * @param {function} notify - Function to notify.
 * @returns {Promise<void>} - A promise that resolves when the project list count is fetched.
 */

export const projectListCount = async (
  setLoading,
  setData,
  userDetail,
  location,
  notify
) => {
  setLoading(true)
  let query = {}

  const currency = userDetail?.currency?.toUpperCase() || ''
  const unit_type = userDetail?.unit_type?.toUpperCase() || ''

  if (location) {
    query = getQueryFromParams(location, currency, unit_type)
  }

  /* eslint-disable */
  const res = await apiServiceRequest({
    url: `${mapBaseURL}GetBrokerProjects?jsonParam=${btoa(
      JSON.stringify({
        Conditions: query?.conditions || [],
      })
    )}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  })

  if (res?.successStatus) {
    const resData = res?.response?.totalNoOfRecords
    setData(resData || 0)
  }
  setLoading(false)
}

/**
 * Fetches the list of project destinations interested asynchronously.
 *
 * @param {function} setProjectInterestedList - Function to set the list of project destinations interested.
 * @param {function} setProjectInterestedLoading - Function to set the loading state.
 * @param {function} notify - Function to notify the user.
 * @param {boolean} multiple - Indicates whether multiple project destinations are involved.
 */
export const ProjectDestinationsInterested = async (
  setProjectInterestedList,
  setProjectInterestedLoading,
  notify,
  multiple
) => {
  setProjectInterestedLoading(true)
  const res = await apiServiceRequest({
    url: `/project-interested-countries`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  })

  if (res?.code === 'success') {
    multiple
      ? setProjectInterestedList(res?.data?.map((entry) => entry?.name))
      : setProjectInterestedList(res?.data)
  }
  setProjectInterestedLoading(false)
}

export const brokerDocuments = async (
  setDocuments,
  setDocumentsLoading,
  notify,
  id,
  unitNo
) => {
  setDocumentsLoading(true)
  const token = await getUserToken(notify)
  const res = await apiServiceRequest({
    url: `${mapBaseURL}GetBrokerProjects?jsonParam=${btoa(
      JSON.stringify({
        Conditions: [
          {
            Field: 'propertY_ID',
            Value: `${id}`,
            Filter: 'equal',
          },
        ],
      })
    )}`,
    headers: { Authorization: `Bearer ${token}` },
    notify: notify,
  })

  if (res?.successStatus) {
    const project = res?.response?.objList[0]
    if (project) {
      const resData = project
      const modifiedDocuments = resData?.ProjectDocument?.map((value) => ({
        documentLink: value?.documentLinkTiny || value?.documentLink,
        documentName: formatDocName(value?.documentName),
        language: value?.language,
        propertyID: id,
        propertyName: resData?.reraProjectName,
        unitNo: unitNo || '',
      }))
      setDocuments(modifiedDocuments)
      setDocumentsLoading(false)
      return resData
    }
  }
  setDocumentsLoading(false)
}

export const getCurrencyList = async (setLoading, setCurrencyList) => {
  setLoading(true);
  
  const currencyList = staticCurrencies.map(currency => {
    return { code: currency.id, name: currency.name };
  });

  if (setCurrencyList) {
    setCurrencyList(currencyList);
  }
  
  setLoading(false);
};
