import React, { useEffect, useState } from "react";
// import Container from "@mui/material/Container";
// import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Styles from "./PropertydetailsLocation.scss";
import MapContainer from "Components/MapContainer";
import Loader from "Components/Loader";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const PropertydetailsLocation = ({
  location,
  city,
  name,
  latitude,
  longitude,
  translate
}) => {
  const [openPopup, setOpenPopup] = React.useState(false);

  const CommonMapContainer = () => {
    return (
      <MapContainer
        location={location}
        name={name}
        latitude={latitude}
        longitude={longitude}
        handleClickOpen={() => setOpenPopup(true)}
        translate={translate}
      />
    );
  };

  return (
    <>
      <Grid className="project-info-panel location-panel" id="location">
        <Typography variant="h2">{translate("ra.fieldName.location")}</Typography>
        <Typography variant="h3">
          {city}, {location}
        </Typography>
        <Grid className="map-section">
          <CommonMapContainer />
        </Grid>
      </Grid>

      <Dialog className="location-map-popup" open={openPopup}>
        <DialogContent>
          <Grid className="project-info-panel location-panel" id="location">
            <Typography variant="h2">{translate("ra.fieldName.location")}</Typography>

            <Typography variant="h3">
              {city}, {location}
            </Typography>
            <span className="close-icon">
              <IconButton
                aria-label="close"
                onClick={() => {
                  setOpenPopup(false);
                }}
              >
                <CloseOutlinedIcon />
              </IconButton>
            </span>
            <Grid className="map-section">
              <CommonMapContainer />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PropertydetailsLocation;
