import formatNumber from './conversionFunction'

export const filterArrayData = (
  countryValues,
  projectValues,
  propertyTypeValues,
  bedroomValues,
  priceRange,
  sizeRange,
  statusValues,
  priceValue,
  sizeValue,
  setCountFilter
) => {
  const filterArray = []

  if (countryValues?.length > 0) {
    filterArray.push({
      filterName: 'Country',
      value: countryValues,
    })
  }
  if (projectValues?.length > 0) {
    filterArray.push({
      filterName: 'Project',
      value: projectValues,
    })
  }
  if (propertyTypeValues?.length > 0) {
    filterArray.push({
      filterName: 'Type',
      value: propertyTypeValues,
    })
  }
  if (bedroomValues?.length > 0) {
    filterArray.push({
      filterName: 'Bedroom',
      value: bedroomValues,
    })
  }
  if (
    (priceRange?.length &&
      (priceRange[0] !== 0 || priceRange[1] !== 50000000)) ||
    priceValue?.length
  ) {
    filterArray.push({
      filterName: 'Price',
      value: priceRange?.length ? priceRange : priceValue,
    })
  }
  if (
    (sizeRange?.length && (sizeRange[0] !== 0 || sizeRange[1] !== 1000000)) ||
    sizeValue?.length
  ) {
    filterArray.push({
      filterName: 'Size',
      value: sizeRange?.length ? sizeRange : sizeValue,
    })
  }
  if (statusValues?.length > 0) {
    filterArray.push({
      filterName: 'Project Status',
      value: statusValues,
    })
  }
  if (setCountFilter) {
    setCountFilter(filterArray)
  }

  return filterArray
}

export const dataValueFromQuery = (
  params,
  filterData,
  setFilterList,
  setCountryValues,
  setProjectValues,
  setPropertyTypeValues,
  setBedroomValues,
  setStatusValues,
  setPriceRange,
  setSizeRange
) => {
  let filterCountry = []
  let filterProject = []
  let price = []
  let size = []
  let projectListValue = filterData?.projectList?.length
    ? filterData?.projectList
    : []
  if (params?.country) {
    filterData?.projectCountries?.filter((data) => {
      if (params?.country?.split(',').includes(data?.name)) {
        filterCountry.push(data)
      }
    })
    if (setCountryValues) setCountryValues(filterCountry || [])
  }
  if (params?.project) {
    projectListValue?.filter((data) => {
      if (params?.project?.split(',').includes(data?.projecT_NAME)) {
        filterProject.push(data)
      }
    })
    if (setProjectValues) setProjectValues(filterProject || [])
  }
  if (params?.projectid) {
    projectListValue?.filter((data) => {
      if (params?.projectid?.split(',').includes(data?.propertY_ID)) {
        filterProject.push(data)
      }
    })
    if (setProjectValues) setProjectValues(filterProject || [])
  }
  if (params?.type) {
    if (setPropertyTypeValues)
      setPropertyTypeValues(params?.type?.split(',') || [])
  }
  if (params?.bed) {
    if (setBedroomValues) setBedroomValues(params?.bed?.split(',') || [])
  }
  if (params?.status) {
    if (setStatusValues) setStatusValues(params?.status?.split(',') || [])
  }
  if (params?.min_price && params?.max_price) {
    price = [parseInt(params?.min_price), parseInt(params?.max_price)]
    if (setPriceRange) setPriceRange(price)
  }
  if (params?.min_size && params?.max_size) {
    size = [parseInt(params?.min_size), parseInt(params?.max_size)]
    if (setSizeRange) setSizeRange(size)
  }

  if (setFilterList) {
    const filterValue = filterArrayData(
      filterCountry,
      filterProject,
      params?.type?.split(',') || [],
      params?.bed?.split(',') || [],
      [],
      [],
      params?.status?.split(',') || [],
      price,
      size
    )

    setFilterList(filterValue || [])
  }
}

export const filterDisplayValues = (data, userData) => {
  if (data?.filterName === 'Price' || data?.filterName === 'Size') {
    return `${formatNumber(data?.value[0])} - ${formatNumber(data?.value[1])} ${
      data?.filterName === 'Size' ? userData?.unit_type : ''
    }`
  } else {
    const displayName =
      data?.value[0]?.name ||
      data?.value[0]?.projecT_NAME ||
      data?.value[0]?.reraProjectName ||
      data?.value[0] ||
      ''
    return displayName
  }
}

export const bookingContactFilter = (
  bookingStatus,
  percentStatus,
  timeValue,
  salesPerson,
  startDate,
  endDate,
  setFilterList,
  pageName,
  path
) => {
  const filterArray = []
  if (bookingStatus && path !== 'my-bookings') {
    filterArray.push({
      filterName: 'By Booking Status',
      value: bookingStatus,
    })
  }
  if (percentStatus && pageName === 'booking') {
    filterArray.push({
      filterName: 'By Percent Paid',
      value: percentStatus,
    })
  }
  if (timeValue && timeValue !== 'Custom Range') {
    filterArray.push({
      filterName: 'By Time',
      value: timeValue,
    })
  }
  if (timeValue === 'Custom Range' && startDate && endDate) {
    filterArray.push({
      filterName: 'By Time',
      value: timeValue,
      startDate: startDate,
      endDate: endDate,
    })
  }
  if (timeValue === 'Custom Range' && !startDate && endDate) {
    const start = new Date(endDate)
    let day = new Date(endDate).getDate()
    start.setDate(day - 1)

    filterArray.push({
      filterName: 'By Time',
      value: timeValue,
      startDate: start,
      endDate: endDate,
    })
  }
  if (timeValue === 'Custom Range' && startDate && !endDate) {
    filterArray.push({
      filterName: 'By Time',
      value: timeValue,
      startDate: startDate,
      endDate: new Date(),
    })
  }
  if (timeValue === 'Custom Range' && !startDate && !endDate) {
    filterArray.push({
      filterName: 'By Time',
      value: timeValue,
      startDate: new Date(),
      endDate: new Date(),
    })
  }
  if (salesPerson) {
    filterArray.push({
      filterName: 'By Salesperson',
      value: salesPerson?.name,
      id: salesPerson?.user_id,
    })
  }

  if (setFilterList) {
    setFilterList(filterArray)
  }

  return filterArray
}

export const bookingContactFromQuery = (
  params,
  salesPersonList,
  setFilterList,
  setBookingValue,
  setPercentValue,
  setTimeValue,
  setStartDate,
  setEndDate,
  setSalesPerson,
  pageName
) => {
  let salespersonDetail = ''

  if (params?.status) {
    if (setBookingValue) setBookingValue(params?.status)
  }
  if (params?.paid) {
    if (setPercentValue) setPercentValue(params?.paid)
  }
  if (params?.time) {
    if (setTimeValue) setTimeValue(params?.time)
  }
  if (
    params?.time === 'Custom Range' &&
    params?.start_date &&
    params?.end_date
  ) {
    if (setStartDate) setStartDate(new Date(params?.start_date))
    if (setEndDate) setEndDate(new Date(params?.end_date))
  }
  if (params?.salesperson_id) {
    salespersonDetail = salesPersonList?.find(
      (list) => list?.user_id == params?.salesperson_id
    )
    if (setSalesPerson) setSalesPerson(salespersonDetail)
  }

  if (setFilterList) {
    bookingContactFilter(
      params?.status,
      params?.paid,
      params?.time,
      salespersonDetail,
      params?.start_date,
      params?.end_date,
      setFilterList,
      pageName
    )
  }
}
