
import React, { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import {
  Grid,
  Container,
  Button,
  Typography
} from "@mui/material";
import userDetails from "Services/userDetailsServices";
import Header from "Components/Header";
import Loader from "Components/Loader";
import { useNavigate, useLocation } from "react-router-dom";
import { removeLocalStorage } from "Utils/removeStorage";

const ReservationFormSigned = ({ notification }) => {
  const [userDetailsLoading, setuserDetailsLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  let formStatus = sessionStorage.getItem("formStatus") || null;
  const bookingState = JSON.parse(localStorage.getItem("bookingState")) || null;
  const translate = useTranslate();
  // const navigate = useNavigate();
  // const location = useLocation();
  // const state = location.state || bookingState;

  useEffect(() => {
    // if(!formStatus) {
    //   navigate("/home");
    // } 
    
    // if(formStatus && formStatus !== "/reservation-form-signed") {
    //   navigate(formStatus, { state });
    // }
    
    // userDetails(setuserDetailsLoading, setUserData, "", notification);
    // document.body.classList.add(
    //   'profile-page'
    // );
    // document.body.classList.add(
    //   'form-signed'
    // );
    // return () => {
    //   document.body.classList.remove(
    //     'profile-page'
    //   );
    //   document.body.classList.remove(
    //     'form-signed'
    //   );
    // };
  }, []);

  const redirectPath = () => {
    removeLocalStorage();
    localStorage.removeItem("page");
    localStorage.removeItem("params");
    localStorage.removeItem("page-redirect");
    // navigate("/my-bookings");
  }

  return (
    <>
    {/* {userDetailsLoading && <Loader />} */}
      <Header 
        path="My Bookings"
        picture={userData?.profile}
        pictureLoading={userDetailsLoading}
        notification={notification}
      />
      <Container className="company-container new-fix-container lead-panel-section invoice-submitted-panel reservation-form-submitted mt-0">
        <Container className="company-details">
          <Grid className="form-panel profile-form-panel add-team-panel invoice-submitted" sx={{ width: "100%" }}>
          <div style={{display:"flex",justifyContent:"center"}}>
                  <Typography className="icon customeSuccessTick">
                    <i className="iconwasalt icon-check customesuccessTickIcon" />
                  </Typography>
              </div>

            {/* <Typography className="icon" ><i className="iconwasalt icon-check" /></Typography> */}
            <Typography variant="h2" style={{textAlign:"center"}}> {translate("ra.pageTitle.reservationFormSigned")}</Typography>
            <Typography component="p" style={{textAlign:"center"}}>{translate("ra.content.signedReservationForm")}</Typography>
            <Grid container spacing={0} className="invoice-success-button defaultButtonSpace">
              <Button
                variant="outlined"
                onClick={() => redirectPath()}
                className=""
              >
                {translate("ra.button.gotoBookings")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  )
}

export default ReservationFormSigned