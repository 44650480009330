export const userDetailResponse = (resData, setDetails) => {
  if (resData?.role === "root" && resData?.user_type === 1) {
    setDetails({
      name: `${resData?.title || ""} ${resData?.first_name || ""} ${
        resData?.last_name || ""
      }`,
      mobile: resData?.country_code
        ? `+(${resData?.country_code})${resData?.mobile_no || ""}`
        : `${resData?.mobile_no || ""}`,
      email: resData?.email,
      role: "Authorized Signatory",
    });
  }
  if (resData?.user_type === 2) {
    if (resData?.role === "root") {
      setDetails({
        name: `${resData?.agent_title || ""} ${
          resData?.agent_first_name || ""
        } ${resData?.agent_last_name || ""}`,
        mobile: resData?.company_mobile_no_country_code
          ? `+(${resData?.company_mobile_no_country_code})${
              resData?.company_mobile_no || ""
            }`
          : `${resData?.company_mobile_no || ""}`,
        email: resData?.email,
        role: "Admin",
      });
    }
    if (resData?.role === "authorized_signatory") {
      setDetails({
        name: `${resData?.authorized_signatory_corporate_title || ""} ${
          resData?.authorized_signatory_corporate_first_name || ""
        } ${resData?.authorized_signatory_corporate_last_name || ""}`,
        mobile: resData?.country_code
          ? `+(${resData?.country_code})${resData?.mobile_no || ""}`
          : `${resData?.mobile_no || ""}`,
        email: resData?.email,
        role: "Authorized Signatory",
      });
    }
    if (resData?.role === "admin" || resData?.role === "sales_person") {
      setDetails({
        name: `${resData?.team_title || ""} ${resData?.team_first_name || ""} ${
          resData?.team_last_name || ""
        }`,
        mobile: resData?.team_country_code
          ? `+(${resData?.team_country_code})${resData?.team_mobile || ""}`
          : `${resData?.team_mobile || ""}`,
        email: resData?.email,
        role: resData?.role === "admin" ? "Admin" : "Sales Person",
      });
    }
  }
};

export const capitalFirstLetterOfWord = (word) => {
  let splitWord = word.trim().split(" ");
  for (let i = 0; i < splitWord.length; i++) {
    splitWord[i] = `${splitWord[i][0]?.toUpperCase()}${splitWord[i]
      ?.substr(1)
      ?.toLowerCase()}`;
  }
  splitWord = splitWord.join(" ");
  return splitWord;
};

export const userProfileName = (userData) => {
  let username = "";
  if (userData?.role === "root" && userData?.user_type === 1) {
    username =
      `${userData?.first_name || ""}
       ${userData?.last_name || "" }`
  }
  if (userData?.user_type === 2) {
    if (userData?.role === "root") {
      username =
        `${userData?.agent_first_name || ""}
         ${userData?.agent_last_name || ""}`
    }
    if (userData?.role === "authorized_signatory") {
      username =
        `${userData?.authorized_signatory_corporate_first_name || ""} 
         ${userData?.authorized_signatory_corporate_last_name || ""}`
    }
    if (userData?.role === "admin" || userData?.role === "sales_person") {
      username =
        `${userData?.team_first_name || ""} 
         ${userData?.team_last_name || ""}`
    }
  }
  return username;
};

export const userDesignation = (userData) => {
  return userData?.role === "admin" || userData?.role === "sales_person"
    ? userData?.team_designation
    : userData?.user_type === 2 && userData?.role === "root"
    ? "Admin"
    : userData?.designation
}

export function formatSizeUnits(bytes){
  if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
  else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
  else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(2) + " KB"; }
  else if (bytes > 1)           { bytes = bytes + " bytes"; }
  else if (bytes == 1)          { bytes = bytes + " byte"; }
  else                          { bytes = "0 bytes"; }
  return bytes;
}

export function isKsaPresent(path){
  return path?.split('/')?.some(_=>_=="individual-ksa-licensed");
}