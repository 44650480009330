import { Button, Container, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "Components/BackButton";
import Header from "Components/Header";
import React, { useEffect, useState } from "react";
import userDetails from "Services/userDetailsServices";
import { useTranslate } from "react-admin";
import Loader from "Components/Loader";
import updateUnitBookingStatus from "Services/updateCustomerStatus";
import { removeLocalStorage } from "Utils/removeStorage";

export const DownloadReservationForm = ({ notification, bookingFailedPopup, setBookingFailedPopup }) => {
  const [userDetailsLoading, setuserDetailsLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState();
  const unit_reservation = JSON.parse(localStorage?.getItem("unit-reservation"));
  const tabs = JSON.parse(localStorage.getItem("tabs"));
  const portal_booking_id = localStorage.getItem("portalBookingId");
  const companyId = localStorage.getItem("company_id") || null;
  let formStatus = sessionStorage.getItem("formStatus") || null;
  const bookingState = JSON.parse(localStorage.getItem("bookingState")) || null;

  const translate = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || bookingState;

  useEffect(() => {
    if (!formStatus) {
      navigate("/home");
    }

    if (formStatus && formStatus !== "/download-reservation-form") {
      navigate(formStatus, { state });
    }

    userDetails(setuserDetailsLoading, setUserData, "", notification);
    document.body.classList.add(
      'profile-page'
    );
    document.body.classList.add(
      'reservation-successfull'
    );
    document.body.classList.add(
      'sign-reservtion'
    );
    return () => {
      document.body.classList.remove(
        'profile-page'
      );
      document.body.classList.remove(
        'reservation-successfull'
      );
      document.body.classList.remove(
        'sign-reservtion'
      );
    };
  }, []);

  const callCustomerStatus = () => {
    const apiData = {
      data: [{
        where: {
          portal_booking_id: portal_booking_id,
          customer_id: companyId || tabs?.map((tab) => tab?.id).join(","),
          unit_id: state?.units?.map(unit => unit?.officE_SECTION_ID)?.join(","),
        },
        set: {
          booking_status: "upload_signed_reservation_form"
        }
      }]
    }

    const postApiRedirection = () => {
      navigate("/upload-reservation", { state });
      sessionStorage.setItem("formStatus", "/upload-reservation")
    }
    updateUnitBookingStatus(setUpdateLoading, JSON.stringify(apiData), notification, postApiRedirection, bookingFailedPopup,
      setBookingFailedPopup);
  }

  const redirectPath = () => {
    removeLocalStorage();
    localStorage.removeItem("page");
    localStorage.removeItem("params");
    localStorage.removeItem("page-redirect");
    navigate("/my-bookings");
  }

  const backNavigation = () => {
    navigate("/reservation-successfull", { state })
    sessionStorage.setItem("formStatus", "/reservation-successfull");
  }

  return (
    <>
      {(loading || userDetailsLoading || updateLoading) && <Loader />}
      <Header
        path="My Bookings"
        picture={userData?.profile}
        pictureLoading={userDetailsLoading}
        notification={notification}
      />
      <BackButton close={backNavigation} />
      <Container className="lead-panel-section invoice-submitted-panel reservation-form-submitted mt-0">
        <Container className="company-details">
          <Grid className="form-panel profile-form-panel add-team-panel invoice-submitted" sx={{ width: "100%" }}>
            <Grid container spacing={0} className="download-upload-section">
              <Typography variant="h3">{translate("ra.pageTitle.downloadReservationForm")}</Typography>
              <>
                <Grid container spacing={0} className="download-upload-info">
                  {unit_reservation?.map((unit, index) => {
                    return (
                      <Grid key={index} container className="documents-info-section">
                        <Typography variant="h4">{unit?.unit_no}<a target="_blank" rel="noreferrer" href={`/api/getOracleDocuments?Registration_ID=${unit?.reservation_id}&docType=URF}`}><i className="iconwasalt icon-download-new"/></a></Typography>
                      </Grid>
                    )
                  })}
                </Grid>
              </>
            </Grid>
            <Grid container spacing={0} className="invoice-success-button">
              <Button
                onClick={() => redirectPath()}
                variant="outlined"
              >
                {translate("ra.button.gotoBookings")}
              </Button>
              <Button
                variant="contained"
                // disabled={documents?.length < 1 ? false : true}
                onClick={() => callCustomerStatus()}
                className="new-color-button invoice-success-button"
              >
                {translate("ra.button.continue")}
              </Button>
            </Grid>
            <Typography className="button-bottom-info" component="p">{translate("ra.notification.proceedToSigned")}</Typography>
          </Grid>
        </Container>
      </Container >
    </>
  );
};

