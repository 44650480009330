import { apiServiceRequest } from "Utils/axiosInstance";
import { getUserToken } from "Utils/tokenProvider";

const getCustomerUnits = async (setLoading, setData, data, notify, displayData, tokenPayment, setBankDetailsAndLink, headerPayload, bookingFailedPopup, setBookingFailedPopup) => {
    const portalBrokerId = localStorage.getItem("portalBookingId");

    setLoading(true);
    const res = await apiServiceRequest({
        url: `/customer-units?tokenAmount=${tokenPayment}&bankDetails=${tokenPayment}&portalBookingId=${portalBrokerId}&projectId=${data?.projectId}&unitIds=${data?.unitIds?.join(",")}`,
        headers: { Authorization: `Bearer ${await getUserToken(notify)}`, payload: headerPayload },
        notify: notify
    })
    if (res?.code === "success") {
      

        setData(res?.data?.list);
        setBankDetailsAndLink && setBankDetailsAndLink({
            payment_url: res?.data?.payment_url,
            bank_details: res?.data?.bank_details
        })
        displayData && displayData(res?.data?.list)
    }
    else {
        if (res.message.includes("booking time")) {
            if (!bookingFailedPopup) setBookingFailedPopup(true);
        } else {
            notify(res.message, { type: "error" });
        }
    }
    setLoading(false);
}

export const assignOwnership = async (setLoading, data, notify, navigate, units, project_id, headerPayload, bookingFailedPopup, setBookingFailedPopup) => {
    setLoading(true);
    const res = await apiServiceRequest({
        url: `/assign-ownership`,
        method: "post",
        data: data,
        headers: { Authorization: `Bearer ${await getUserToken(notify)}`, payload: headerPayload },
        notify: notify
    })
    if (res?.code === "success") {
        sessionStorage.setItem("formStatus", "/review-customer-information");
        navigate("/review-customer-information", { state: { units, project_id } });
    }
    else {
        if (res.message.includes("booking time")) {
            if (!bookingFailedPopup) setBookingFailedPopup(true);
        } else {
            notify(res.message, { type: "error" });
        }
    }
    setLoading(false);
}

export default getCustomerUnits;