import React from "react";
import { Card, CardContent, Typography, CardActionArea } from "@mui/material";
import ImageSlider from "Components/ImageSlider";
import Styles from "./CardWithSlider.scss";

const CardWithSlider = ({
  id,
  userData,
  location,
  project_name,
  start_price,
  images,
  redirectPath,
  currency,
  type,
  translate
}) => {
  
  const onClickHandler = () =>{
    redirectPath(id,project_name)
  }
  return (
    <Card className="card-with-slider">
      <CardActionArea className="cards-section">
      <ImageSlider 
        imageList = {images}
        imageType="pL_ImageURL"
        showSlideNumber={false}
        clickhandler={() => onClickHandler()}
        autoPlay={true}
      />
        <CardContent className="property-info" onClick={() => onClickHandler()}>
          <Typography gutterBottom variant="h3" component="h3">
            {location}
          </Typography>
          <Typography gutterBottom variant="h4" component="h4">
            {project_name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {translate("ra.fieldName.startFrom")} {currency}{" "}
            {new Intl.NumberFormat("en-US").format(Math.round(start_price))}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardWithSlider;
