  // pushing and deleting data into array
  export const addRemoveFromArray = (value, state, setState, keyName) => {
    const index = state.indexOf(value)

    if(index > -1) {
      state.splice(index, 1)
    } else {
      state.push(value)
    }

    if(keyName) {
      setState((data) => ({
        ...data,
        [keyName]: state,
      }))
    } else {
      setState(state)
    }
  }
