import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Typography } from "@mui/material";
import { useTranslate } from 'react-admin';
import Loader from 'Components/Loader';

export default function DeleteCustomerPopup({ 
  first_name, 
  last_name, 
  deletePopup, 
  setDeletePopup, 
  deleteCustomer, 
  loading, 
  showSubtext = true,
}) {
  
  const translate = useTranslate();
  
  return (
    <Dialog
      open={deletePopup}
      onClose={() => setDeletePopup(false)}
      className={`new-popup-box remove-popup ${!showSubtext ? "delete-review-wrapper" : ""}`}
    >
      <i
        onClick={() => setDeletePopup(false)}
        className="iconwasalt icon-close cross-button"
      />
      <Grid className="reservation-image-section">
        <Typography component="p" className="lead-pic success assign">
          <strong>{first_name?.charAt(0).toUpperCase()}{last_name?.charAt(0).toUpperCase()}</strong>
        </Typography>
      </Grid>
      <DialogTitle>
        {translate("ra.content.removeCustomer")} {first_name} {last_name}
      </DialogTitle>
      {loading && <Loader/>}
      {showSubtext &&
        <DialogContent>
          <DialogContentText>
            {translate("ra.content.removeCustomerMsg")}
          </DialogContentText>
        </DialogContent>
      }
      <DialogActions>
        <Button className="outline-button remove-button" onClick={() => deleteCustomer()}>
          {translate("ra.button.remove")}
        </Button>
        <Button className="outline-button" onClick={() => setDeletePopup(false)}>
          {translate("ra.button.cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}