import React, { useState, useEffect } from "react";
import {
  useList,
  ListContextProvider,
  Datagrid,
  TextField,
  useTranslate,
  useRedirect
} from "react-admin";
import {
  Grid,
  Container,
  Button,
  Typography,
  useMediaQuery
} from "@mui/material";
import Header from "Components/Header";
import styles from "./styles.module.scss";
import FilterSearch from "Pages/FilterSection/FilterSearch";
import SelectionDialogBox from "Components/Common/SelectionDialogBox";
import ContactSelection from "../ContactSelection";
import Loader from "Components/Loader";
import { getLeadList, getSalesperson } from "Services/leadService";
import InfiniteScroll from 'react-infinite-scroll-component'
import { roleData } from "Utils/constantValues";
import { useNavigate, useLocation } from "react-router-dom";

const Contacts = ({ notification, userLoading, userData }) => {
  const [contactList, setContactList] = useState([]);
  const [contactLoading, setContactLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [showSelectContact, setShowSelectContact] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [filterList, setFilterList] = useState([]);
  const [leadType, setLeadType] = useState(null);
  const [error, setError] = useState(null);
  const [salesPersonList, setSalesPersonList] = useState([]);
  const [infiniteLoading, setInfintieLoading] = useState(false);
  const listContext = useList({ data: contactList });
  const matches = useMediaQuery("(max-width:800px)");
  const translate = useTranslate();
  const redirect = useRedirect();
  const navigate = useNavigate();
  const location = useLocation();
  const param = new URLSearchParams(location?.search);
  const params = Object.fromEntries(param.entries());

  useEffect(() => {
    leadAPiCall();

    if(!matches) {
      document.body.classList.add(
        "profile-page"
      );
      return () => {
        document.body.classList.remove(
          "profile-page"
        );
      };
    }
  }, []);

  const leadAPiCall = async() => {
    const salesList = await getSalesperson("", setSalesPersonList, notification);
    leadDetails(setContactLoading, 0, params, setFilterList, salesList);
  }

  const leadDetails = (setLoadingData, page, queryData, setFilter, salesList, loadType) => {
    getLeadList(
      setLoadingData, 
      contactList, 
      setContactList, 
      setTotalCount, 
      setCount, 
      page, 
      notification, 
      queryData, 
      setFilter,
      salesList,
      loadType
    );
  }

  const closePopup = () => {
    setShowSelectContact(false);
    setLeadType(null);
    setError(null);
  }

  const handleSubmit = () => {
    if(!leadType) {
      setError(translate("ra.validation.selectProspectType"));
    } else {
      closePopup();
      setTimeout(() => {
        if(leadType === "individual") {
          redirect("/individual-prospect-form");
        } else {
          redirect("/company-prospect-form");
        }
      }, 100)
    }
  }

  const redirectPath = (record) => {
    navigate(`/contact-details?id=${record?.id}`);
  }

  const getLeadListData = async () => {
    if (!infiniteLoading) {
        if (contactList && contactList?.length < count) {
            leadDetails(setInfintieLoading, page + 1, params, "" , "", "infiniteLoad");
            setPage(page + 1);
        } else {
            setHasMore(false);
        }
    }
  }

  // setting header section sticky to top when scroll
  useEffect(() => {
    let headerId = document.getElementById("heading-id");
    
    window.addEventListener("scroll", function () {
      if(matches) {
        if (document.documentElement.scrollTop >= 50) {
            headerId.classList.add("contact-filter-sticky");
        } else {
            headerId.classList.remove("contact-filter-sticky");
        }
      }
    });
  }, [])

  // when popup is opened, background scrolling is blocked
  useEffect(() => {
    if (!matches) {
      if (!showSelectContact) {
        document.body.style.overflow = "auto"
      } else {
        document.body.style.overflow = "hidden"
      }
    }
  }, [showSelectContact])

  return (
    <>
      {(userLoading || contactLoading) && <Loader />}
      <Header path="My Contacts" picture={userData?.profile} pictureLoading={userLoading} notification={notification} userData={userData} />
      <div className="contact-sticky-table-wrapper" />
      <Container className="company-container my-contacts-section contact-container-section">
        <Container className="my-contacts-panel contact-heightwrapper">
          <Grid className="form-panel contact-panel" container>
            <Grid id="heading-id" className="contact-panel-heading contact-header-sticky">
              <Typography variant="h2">{translate("ra.pageTitle.contacts")}<span>({totalCount})</span></Typography>
              <Button variant="text" onClick={() => setShowSelectContact(true)} >
                <i className="iconwasalt icon-plus" />
              </Button>
            </Grid>
            <Grid className={`contact-form-section ${filterList?.length > 0 ? "contact-padding-adjust" : ""}`}>
              <FilterSearch
                filterList={filterList}
                setFilterList={setFilterList}
                className="filter-autocomplete"
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                setShowSelectContact={setShowSelectContact}
                salesPersonList={salesPersonList}
                setContactLoading={setContactLoading}
                userData={userData}
                detailsApi={leadDetails}
                setPage={setPage}
                setHasMore={setHasMore}
                params={params}
              />
            </Grid>
            {!contactLoading &&
              <Grid className="unit-list-panel contacts-table-view contacts-wrapper">
                {(filterList?.length > 0 || searchValue) && count > 0 && 
                  <Typography variant="h3" className="search-result-no" component="h3">
                    {count} {translate("ra.fieldName.contactsFound")}
                  </Typography>
                }
                <InfiniteScroll
                  dataLength={contactList && contactList?.length}
                  next={getLeadListData}
                  scrollThreshold={0.7}
                  hasMore={hasMore}
                  loader={infiniteLoading && <div className="my-team-loader">{translate("ra.fieldName.loadMore")}</div>}
                >
                    {contactList?.length > 0 ?
                      <ListContextProvider value={listContext}>
                        <Datagrid bulkActionButtons={false} className="hover-unit-class"  setSort={false} 
                          rowClick={(id, resource, record) => redirectPath(record)} 
                        >
                          <TextField label={translate("ra.fieldName.name")} source="name" />
                          <TextField label={translate("ra.fieldName.contactDetails")} source="mobile" />
                          <TextField label={translate("ra.fieldName.status")} source="status" />
                          {userData?.user_type === 2 && roleData.includes(userData?.role) &&
                            <TextField label={translate("ra.fieldName.salesperson")} source="salesperson" />
                          }
                          <TextField label={translate("ra.fieldName.prospectType")} source="leadType" />
                        </Datagrid>
                      </ListContextProvider>
                    :
                      <Grid className="no-data-section">{translate("ra.noData.contact")}</Grid>
                    }
                </InfiniteScroll>
              </Grid>
            }
          </Grid>

          {showSelectContact &&
            <SelectionDialogBox 
              openPopup={showSelectContact}
              closeFn={closePopup}
              title={translate("ra.pageTitle.addContact")}
              handleSubmit={handleSubmit}
              className="contact-selection-box"
            >
              <ContactSelection
                error={error}
                setError={setError}
                leadType={leadType}
                setLeadType={setLeadType}
              />
            </SelectionDialogBox>
          }
        </Container>
      </Container>
    </>
  );
};

export default Contacts;