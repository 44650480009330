import React, { useEffect, useState } from "react";
import { Box, TextField, Button, Typography, Grid, Divider,CardContent } from "@mui/material";
import "./OTP.scss";
import image from "../../images/brokers_image.png";
import logo from "../../images/logo.svg";
import { useLogin, useRedirect, useTranslate } from "react-admin";
import { ResendOtp, ValidateMFA } from "Services/loginService";
import gtagEventFire from "Utils/ga4EventTrigger";
import { useNavigate } from 'react-router';

const OTP = ({setOracle_status,notification}) => {
  const [otp, setOtp] = useState(["", "", "", ""]);

  const [isValidateTrigged,setIsValidateTriggered]=useState(false);
  const translate = useTranslate();
  const email=localStorage.getItem("email");
  const password=localStorage.getItem("password");
  const rememberMe=JSON.parse(localStorage.getItem("rememberMe"));
  const OtpGenerate=JSON.parse(localStorage.getItem("OtpGenerate"));
  const login = useLogin();
  const navigate=useNavigate();
  const redirect = useRedirect();
  const handleChange = (value, index) => {

    const newOtp = [...otp];
    if (/^\d*$/.test(value)) {
        newOtp[index] = value;
        setOtp(newOtp);
    }
    // Auto-focus next input
    if (/^\d*$/.test(value)&&value && index < otp.length - 1) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let otpValue = otp?.join("");
    if (otpValue?.length == 4) {
      validateOTP(btoa(otpValue));
    }
  };
  
  const resendOtp=()=>{
    let accessToken=OtpGenerate?.access_token;
    let data={
      email:email,
      user_id:OtpGenerate?.user_id,
      username:OtpGenerate?.username
  }
    
    ResendOtp(data,notification,accessToken);
  }
 
  const validateOTP=async(code)=>{
    let data={
      email : email,
      code : code
      }
    setIsValidateTriggered(true);
     let isValid=await ValidateMFA(data,notification,OtpGenerate?.access_token);
  
  if(isValid){
  
      gtagEventFire("sign-in", {
        user_email: email
      })
      login({ email, password, rememberMe, setOracle_status, notification}).catch(
        (error) => notification(error?.message, {type : "error"})
      );
    }else{
      setOtp(["", "", "", ""]);
      setIsValidateTriggered(false);
     }
   }
const backtoSignIn=()=>{
    localStorage.clear();
    navigate('/login');
}

useEffect(()=>{
if(!OtpGenerate){
    backtoSignIn();
}
},[])

  return (
    <Grid
    className="form-panel height100 form-fixed"
    container
    sx={{ minHeight: "100vh", width: "100%" }}
  >
    <Grid
      xs={{ display: "none" }}
      style={{ backgroundImage: `url(${image})` }}
      md={6}
      className="text-heading-section"
    >
      <Typography className="main-heading">
        {translate("ra.content.welcome")} {<br />} {translate("ra.content.dargloabal")} {<br />}{" "}
        <span>{translate("ra.content.network")}{<br />} {translate("ra.content.agent")}</span>
      </Typography>
      <Typography className="divider-separator">
        <span></span>
      </Typography>
      <Typography className="sub-heading">
        {translate("ra.content.realEstate")}
      </Typography>
      <Typography className="main-info">
        {translate("ra.content.joinAgents")}
      </Typography>
    </Grid>
    <Grid
      item
      className="first-login-section"
      xs={12}
      md={6}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <CardContent>
        <Typography
          className="contact-link"
          onClick={() => {
            redirect("/contact-us");
            gtagEventFire("contact-us");
          }}
        >
          {translate("ra.content.contact")}
        </Typography>

        <img src={logo} className="login-logo" alt="Dar logo" />

        <Typography className="form-user-name">{translate("ra.content.portal")}</Typography>

        
        <Box className="otp-container">
        <Typography variant="h4" className="title">
            {translate("ra.content.otpTitle")}
        </Typography>
        
        <form className="otp-form">
            <Box className="otp-input-container">
            {otp.map((digit, index) => (
                <TextField
                key={index}
                id={`otp-${index}`}
                variant="outlined"
                value={digit}
                inputProps={{
                    maxLength: 1,
                    style: { textAlign: "center", fontSize: "1.5rem" },
                }}
                onChange={(e) => handleChange(e.target.value, index)}
                className="otp-input"
                />
            ))}
            </Box>
            
            <Grid className="py-1" style={{margin:"0.5rem 0"}}>
            <Typography variant="p" className="resendOtp">
                Dont receive the OTP?
                <Button style={{padding:"unset",margin:"0rem 0.5rem"}} onClick={()=>
                    resendOtp()
                    }>
                    <Typography className="resendButton"
                    >RESEND OTP</Typography></Button>
                    
                </Typography>
            </Grid>
            
            <Button
            onClick={(e)=>isValidateTrigged?"":
                otp?.join('')?.length==4?handleSubmit(e):""
            }
            className="verifyOtp"
            variant="contained"
            style={{backgroundColor:otp?.join('')?.length==4?"#081c2b":"#adadad",
                cursor:otp?.join('')?.length==4?"pointer":"not-allowed"
            }}
            >
            <Typography>{translate("ra.button.verificationCode")}</Typography>
            </Button>

            <Divider
                className="login-devider"
                sx={{
                    marginBottom: "20px",
                    marginTop: "15px",
                    marginLeft: "0",
                    marginRight: "0",
                }}
                variant="middle"
                >
                {translate("ra.fieldName.OR")}
                </Divider>
                <Button
                className="register-now-button"
                onClick={backtoSignIn}
                variant="outlined"
                >
                {translate("ra.button.signIn")}
                </Button>            
                    
        </form>
        </Box>
       
      </CardContent>
    </Grid>
  </Grid>

   
  );
};

export default OTP;
