import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import { useNavigate } from 'react-router-dom'
import {
  Grid,
  Typography,
  Button,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Loader from "Components/Loader";
import BookingStatus from "./bookingStatus";
import { ClickAwayListener } from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  bookingAccordionText,
  bookingStatusSubText,
  bookingStatusText,
  statusButtonBlock,
  statusComplete,
  modifiedStatusButtonBlock,
} from 'Utils/constantValues'
import { takeActionData } from '../takeAction'
import dollar from '../../../images/Dollar.svg'
import timer from '../../../images/Timer.svg'

const StatusTab = ({ details, loading, userData }) => {
  const [openTooltip, setOpenTooltip] = useState(false)
  const translate = useTranslate()
  const navigate = useNavigate()

  const unit_no = details?.units?.map((list) => list?.unit_no)
  const unit_id = details?.units?.map((list) => list?.unit_id)

  const cancelled_unit = details?.units
    .filter((unit) => unit.booking_status === 'cancelled')
    .map((pendingUnit) => pendingUnit.booking_status)

  const pending_unit = details?.units
    .filter((unit) => unit.booking_status === 'booking_in_progress')
    .map((pendingUnit) => pendingUnit.booking_status)

  const tooltipFunction = (reason) => {
    if (reason === 'tooltip') {
      const tooltipUnits = unit_no?.filter((data, idx) => idx > 6)
      return tooltipUnits?.join(', ')
    } else {
      const units = unit_no?.filter((data, idx) => idx < 7)
      return units?.join(', ')
    }
  }

  const handleTooltipClose = () => {
    setOpenTooltip(false)
  }

  const handleTooltipOpen = () => {
    setOpenTooltip(true)
  }

  const onClickraiseInvoice = (unit) => {
    navigate('/raise-invoice', {
      state: {
        unit_no: unit?.unit_no,
        project_id: unit?.project_id,
        project_name: unit?.property_name,
        unit_id: unit?.unit_id,
        portal_booking_id: unit?.portal_booking_id,
        reservation_id: unit?.oracle_reservation_id,
        customer_name:
          unit?.primary_buyer?.company_name ||
          `${unit?.primary_buyer?.first_name || ''} ${
            unit?.primary_buyer?.last_name || ''
          }`,
      },
    })
  }

  return (
    <>
      {loading && <Loader />}
      {/* When all booking's unit in pending*/}

      {details?.units?.length > 1 &&
        unit_no.length === pending_unit.length &&
        !statusButtonBlock.includes(details?.units?.[0]?.booking_status) && (
          <Grid container className="booking-details-allinfo" spacing={0}>
            <Grid container className="booking-details" spacing={0}>
              <Typography variant="subtitle1">
                {translate('ra.fieldName.units')}
              </Typography>
              <Typography variant="body1" className="more-units-p">
                {details?.units?.length < 8 ? (
                  unit_no?.join(', ')
                ) : (
                  <>
                    {tooltipFunction()}
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <span>
                        <Tooltip
                          className="tooltip-info"
                          open={openTooltip}
                          title={tooltipFunction('tooltip')}
                          arrow
                          onClose={handleTooltipClose}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                        >
                          <span
                            onClick={handleTooltipOpen}
                            onMouseEnter={handleTooltipOpen}
                            onMouseLeave={handleTooltipClose}
                          >
                            {`+${details?.units?.length - 7} more`}
                          </span>
                        </Tooltip>
                      </span>
                    </ClickAwayListener>
                  </>
                )}
              </Typography>
            </Grid>
            <Grid container className="booking-details" spacing={0}>
              <Typography variant="subtitle1">
                {translate('ra.fieldName.bookStatus')}
              </Typography>
              <Typography>
                {statusComplete.includes(details?.units?.[0]?.booking_status)
                  ? translate('ra.fieldName.completed')
                  : translate('ra.fieldName.pending')}
              </Typography>
            </Grid>
          </Grid>
        )}
      {details?.units?.length > 1 &&
        statusButtonBlock.includes(details?.units?.[0]?.booking_status) && (
          <Grid container className="status-unit-multiple" spacing={0}>
            <ul className="shareList ">
              <h3>
                {details?.units?.length} {translate('ra.fieldName.units')}
              </h3>
              <li className="li_margin_bottom">
                <div className="">
                  {details?.units?.map((data, i) => (
                    <li key={i} className="accordian-section">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <h3>{data?.unit_no}</h3>
                          <p>
                            {translate(
                              bookingAccordionText[data?.booking_status]
                            )}
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <BookingStatus
                            booking_status={data?.booking_status}
                            percentage_paid={data?.percentage_paid}
                            spa_signed={data?.SPA_signed}
                          />
                        </AccordionDetails>
                      </Accordion>
                      {statusComplete.includes(data?.booking_status) &&
                        data?.booking_status !== 'completed' &&
                        userData?.role !== 'sales_person' && (
                          <Button
                            variant="contained"
                            onClick={() => onClickraiseInvoice(data)}
                            disabled={
                              data?.booking_status ===
                                'already_raise_invoice_at_10' ||
                              data?.booking_status ===
                                'already_raise_invoice_at_10'
                            }
                          >
                            {translate('ra.button.raiseInvoice')}
                          </Button>
                        )}
                    </li>
                  ))}
                </div>
              </li>
            </ul>
          </Grid>
        )}

      {/* updated code */}
      {details?.units?.length > 1 &&
        !statusButtonBlock.includes(details?.units?.[0]?.booking_status) &&
        !modifiedStatusButtonBlock.includes(
          details?.units?.[0]?.booking_status
        ) && (
          <Grid container className="booking-details-allinfo" spacing={0}>
            <Grid container className="booking-details" spacing={0}>
              <Typography variant="subtitle1">
                {translate('ra.fieldName.units')}
              </Typography>
              <Typography variant="body1" className="more-units-p">
                {details?.units?.length < 8 ? (
                  unit_no?.join(', ')
                ) : (
                  <>
                    {tooltipFunction()}
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <span>
                        <Tooltip
                          className="tooltip-info"
                          open={openTooltip}
                          title={tooltipFunction('tooltip')}
                          arrow
                          onClose={handleTooltipClose}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                        >
                          <span
                            onClick={handleTooltipOpen}
                            onMouseEnter={handleTooltipOpen}
                            onMouseLeave={handleTooltipClose}
                          >
                            {`+${details?.units?.length - 7} more`}
                          </span>
                        </Tooltip>
                      </span>
                    </ClickAwayListener>
                  </>
                )}
              </Typography>
            </Grid>
            <Grid container className="booking-details" spacing={0}>
              <Typography variant="subtitle1">
                {translate('ra.fieldName.bookStatus')}
              </Typography>
              <Typography>
                {statusComplete.includes(details?.units?.[0]?.booking_status)
                  ? translate('ra.fieldName.completed')
                  : translate('ra.fieldName.pending')}
              </Typography>
            </Grid>
            <Grid className="status-info-details">
              <Typography variant="h4">
                <i className="iconwasalt icon-tooltip" />
                {translate(
                  bookingStatusText[details?.units?.[0]?.booking_status]
                )}
              </Typography>
              <Typography variant="subtitle1">
                {translate(
                  bookingStatusSubText[details?.units?.[0]?.booking_status]
                )}
              </Typography>
              <Button
                variant="contained"
                onClick={() => takeActionData(details, navigate, translate)}
              >
                {translate('ra.button.takeAction')}
              </Button>
            </Grid>
          </Grid>
        )}

      {details?.units?.length === 1 && (
        <BookingStatus
          booking_status={details?.units?.[0]?.booking_status}
          percentage_paid={details?.units?.[0]?.percentage_paid}
          spa_signed={details?.units?.[0]?.SPA_signed}
        />
      )}

      {((details?.units?.length > 1 &&
        unit_no.length === pending_unit.length &&
        !statusButtonBlock.includes(details?.units?.[0]?.booking_status)) ||
        (details?.units?.length === 1 &&
          cancelled_unit?.length != 1 &&
          ((userData?.role !== 'sales_person' &&
            details?.units?.[0]?.booking_status !== 'completed') ||
            (userData?.role === 'sales_person' &&
              !statusButtonBlock.includes(
                details?.units?.[0]?.booking_status
              ))))) && (
        <Grid className="status-info-details">
          <Typography
            variant="h4"
            className={
              details?.units?.[0]?.booking_status ===
                'already_raise_invoice_at_10' ||
              details?.units?.[0]?.booking_status ===
                'already_raise_invoice_at_10'
                ? 'green-color-icon'
                : ''
            }
          >
            {details?.units?.[0]?.booking_status ===
            'awaiting_payment_milestone' ? (
              <img src={timer} alt="timer pic" />
            ) : details?.units?.[0]?.booking_status === 'raise_invoice_at_10' ||
              details?.units?.[0]?.booking_status === 'raise_invoice_at_20' ? (
              <img src={dollar} alt="dollar pic" />
            ) : details?.units?.[0]?.booking_status ===
                'already_raise_invoice_at_10' ||
              details?.units?.[0]?.booking_status ===
                'already_raise_invoice_at_20' ? (
              <i className="iconwasalt green-color-icon icon-check-circle" />
            ) : (
              <i className="iconwasalt icon-tooltip" />
            )}
            {translate(bookingStatusText[details?.units?.[0]?.booking_status])}
          </Typography>
          <Typography variant="subtitle1">
            {translate(
              bookingStatusSubText[details?.units?.[0]?.booking_status]
            )}
          </Typography>
          {!statusButtonBlock.includes(details?.units?.[0]?.booking_status) && (
            <Button
              variant="contained"
              onClick={() => takeActionData(details, navigate, translate)}
            >
              {translate('ra.button.takeAction')}
            </Button>
          )}
          {statusComplete.includes(details?.units?.[0]?.booking_status) && (
            <Button
              variant="contained"
              onClick={() => onClickraiseInvoice(details?.units[0])}
              disabled={
                details?.units?.[0]?.booking_status ===
                  'already_raise_invoice_at_10' ||
                details?.units?.[0]?.booking_status ===
                  'already_raise_invoice_at_10'
              }
            >
              {translate('ra.button.raiseInvoice')}
            </Button>
          )}
        </Grid>
      )}

      {details?.units?.length > 1 &&
        unit_no.length > pending_unit.length &&
        modifiedStatusButtonBlock.includes(
          details?.units?.[0]?.booking_status
        ) && (
          <Grid container className="status-unit-multiple" spacing={0}>
            <ul className="shareList ">
              <h3>
                {details?.units?.length} {translate('ra.fieldName.units')}
              </h3>
              <li className="li_margin_bottom">
                <div className="">
                  {details?.units?.map((data, i) => (
                    <li key={i} className="accordian-section">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <h3>{data?.unit_no}</h3>
                          <p>
                            {translate(
                              bookingAccordionText[data?.booking_status]
                            )}
                          </p>
                        </AccordionSummary>
                        <AccordionDetails>
                          <BookingStatus
                            booking_status={data?.booking_status}
                            percentage_paid={data?.percentage_paid}
                            spa_signed={data?.SPA_signed}
                          />
                        </AccordionDetails>
                      </Accordion>
                      {statusComplete.includes(data?.booking_status) &&
                        data?.booking_status !== 'completed' &&
                        userData?.role !== 'sales_person' && (
                          <Button
                            variant="contained"
                            onClick={() => onClickraiseInvoice(data)}
                            disabled={
                              data?.booking_status ===
                                'already_raise_invoice_at_10' ||
                              data?.booking_status ===
                                'already_raise_invoice_at_10'
                            }
                          >
                            {translate('ra.button.raiseInvoice')}
                          </Button>
                        )}
                    </li>
                  ))}
                </div>
              </li>
            </ul>
          </Grid>
        )}
    </>
  )
}

export default StatusTab
