import { removePartLocalStorage } from "./removeStorage";

export const backNavigation = (url, tabs, previous_url) => {
    let editCustomer=localStorage.getItem("editCustomer");
    if(url === "/booking-reservation-form") {
        if(previous_url === "/choose-contacts") {
        //    if(!editCustomer) removePartLocalStorage();
            localStorage.setItem("previous_url", "/review-units");
        }
        return previous_url;
    } 
    if(url === "/review-customer-information") {
        sessionStorage.setItem("formStatus", tabs?.length > 1 ? "/assign-ownership-percentage" : "/booking-reservation-form");

        if(tabs?.length > 1) {
            return "/assign-ownership-percentage";
        } else {
            return "/booking-reservation-form";
        }
    } 
}