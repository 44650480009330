
import { apiServiceRequest } from "Utils/axiosInstance";
import { removeLocalStorage } from "Utils/removeStorage";
import { getUserToken } from "Utils/tokenProvider";

const uploadIDocumentsnOracle = async (setUploadDocnOracleLoading, data, notify, navigate, bookingFailedPopup, setBookingFailedPopup, state) => {
  setUploadDocnOracleLoading(true);
  const res = await apiServiceRequest({
    url: `/upload-signed-documents`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}`, "Content-Type": "multipart/form-data" },
    method: "post",
    data: data,
    notify: notify,
  });
  if (res?.code === "success") {
    navigate("/reservation-form-signed", { state });
    sessionStorage.setItem("formStatus", "/reservation-form-signed");
  }
  else {
    if (res.message.includes("booking time")) {
        if (!bookingFailedPopup) setBookingFailedPopup(true);
    } else {
        notify(res.message, { type: "error" });
    }
}
  setUploadDocnOracleLoading(false);
};

export default uploadIDocumentsnOracle;
