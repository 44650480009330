// Checking currency for empty or default
const currencyCheck = ['DEFAULT']

export const currencyStatus = (currency) => {
  if (currency) {
    return !currencyCheck.includes(currency) ? true : false
  }
  return false
}

export const staticCurrencies  = [
  {
      id: "DEFAULT",
      name: "DEFAULT"
  },
  {
      id: "USD",
      name: "USD"
  },
  {
      id: "SAR",
      name: "SAR"
  },
  {
      id: "QAR",
      name: "QAR"
  },
  {
      id: "OMR",
      name: "OMR"
  },
  {
      id: "GBP",
      name: "GBP"
  },
  {
      id: "EUR",
      name: "EUR"
  },
  {
      id: "AED",
      name: "AED"
  }
];
