import { apiServiceRequest } from "Utils/axiosInstance";

//currency Service
const resetPasswordLinkExpireService = async (
  requestId,
  setShowForm,
  setLoading,
  notify
) => {
  setLoading(true);
  const res = await apiServiceRequest({
    url: `isResetPassLinkExpire/${requestId}`,
    notify: notify,
  });
  if (res?.code === "success") {
    setShowForm(true);
  }
  setLoading(false);
};

export default resetPasswordLinkExpireService;
