import React from "react";
import { useRedirect, useTranslate } from "react-admin";
import {
  Container,
  Grid,
  Button,
  Typography
} from "@mui/material";

const InformationSubmitted = () => {
  const redirect = useRedirect();
  const translate = useTranslate();

  const handleClick = () => {
    redirect("/login");
  };

  return (
    <>
      <Container
        sx={{
          width: "100wh",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container className="company-details">
          <Grid className="form-panel information-page" sx={{ width: "100%" }}>
            <i className="iconwasalt icon-tick-outline tick-image" ></i>
            <Typography variant="h5" className="information-heading">
              {translate("ra.fieldName.info")}
            </Typography>
            <Typography className="information-info" variant="h3">
              {translate("ra.fieldName.verifyDetails")}
            </Typography>
            <Button className="information-button" variant="contained" onClick={handleClick}>
              {translate("ra.button.goBack")}
            </Button>
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default InformationSubmitted;
