import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { useTranslate } from 'react-admin';

const DialogBox = ({
  openPopup,
  setOpenPopup,
  content,
  handleYes,
  closeBtn = false,
  path,
  closeBtnHandler,
  dialogClass,
  closeBtnClass,
  cls,
  handleNo,
  icon,
  innerContent,
  closeBtnName,
  innerContentClass,
  innerDialogContent,
  innerDialogContent1,
  unit_no
}) => {
  const translate = useTranslate();

  const closeFunction = () => {
    if (closeBtnHandler) {
      closeBtnHandler();
    } else {
      setOpenPopup(false);
    }
  }

  return (
    <Dialog
      open={openPopup}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      className={`new-popup-box ${cls} ${dialogClass}`}
    >
      <i
        className="iconwasalt icon-close cross-button"
        onClick={closeFunction}
      />
      <DialogContent>
        {closeBtn
          ? icon ? <i className={`iconwasalt ${icon}`} /> 
          : <i className="iconwasalt icon-check-circle check-circle-icon" />
          : icon ? <i className={`iconwasalt icon-${icon} question-icon`} />
          : <i className="iconwasalt icon-question question-icon" />
        }
        <DialogContentText id="alert-dialog-slide-description">
          {content}
          {innerContent &&
            <DialogContent className={innerContentClass}>
              {innerContent}
            </DialogContent>
          }
          {innerDialogContent &&
            <DialogContent className={innerContentClass}>
              <div>{innerDialogContent} <span>{unit_no}</span> {innerDialogContent1}</div>
            </DialogContent>
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {closeBtn ?
          <Button onClick={() => closeBtnHandler()} className={closeBtnClass || "close-button-dialog"}>
            {closeBtnName ? closeBtnName : translate("ra.button.close")}
          </Button>
          :
          <>
            <Button className="outline-button" onClick={() => path ? handleYes() : handleNo ? handleNo() : setOpenPopup(false)}>
              {path ? translate("ra.button.yes") : translate("ra.button.no")}
            </Button>
            <Button onClick={() => !path ? handleYes() : handleNo ? handleNo() : setOpenPopup(false)}>
              {path ? translate("ra.button.no") : translate("ra.button.yes")}
            </Button>
          </>
        }
      </DialogActions>
    </Dialog>
  );
};

export default DialogBox;
