import React from "react";
import {
  Container,
  Button,
  Typography
} from "@mui/material";
import { useTranslate } from "react-admin";
import { useNavigate } from "react-router-dom";

const Error = () => {  
  const translate = useTranslate();
  const navigate = useNavigate();

  const redirectBack = () => {
    navigate(-1);
  }

  return (
    <Container className={`error-panel`}>
      <Container className="error-info">
        <Typography className="icon"><i className="iconwasalt icon-broken-link" ></i></Typography>
        <Typography variant="h2">{translate("ra.content.notFound")}</Typography>
        <Typography variant="h3">{translate("ra.content.404")}</Typography>
        <Typography>{translate("ra.content.returnHome")}</Typography>
        <Button onClick={redirectBack} className="outline-button">{translate("ra.button.goBack")}</Button>
      </Container>
    </Container>
  );
};

export default Error;
