import React from 'react'
import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Checkbox,
  Button,
} from '@mui/material'
import { sfBookingstatusArray } from '../../Utils/constantValues'
import ImageSlider from 'Components/ImageSlider'
import Styles from './PropertyCard.scss'
import BedIcon from '../../images/bed-icon.svg'
import AreaIcon from '../../images/area-icon.svg'
import Heart from '../../images/wishlist-icon.png'
import HeartActive from '../../images/heart-active.png'
import notavailable from '../../images/not-available.png'
import ButtonComponent from 'Components/Common/ButtonComponent'
import { formatNumbers } from 'Utils/thousandSeperators'
import gtagEventFire from 'Utils/ga4EventTrigger'

const PropertyCard = ({
  selectedProject,
  projectSelectHandler,
  wishlistHandler,
  wishlistProject,
  redirectPath,
  path,
  bdmInfo,
  list,
  userData,
}) => {
  const stopRedirect = (e) => {
    //Cancel parent onclick function when clicking button in child
    e.stopPropagation()
  }

  const clickHandler = (type, list) => {
    gtagEventFire(`unit_${type}_click`, {
      property_name: list?.title,
      unit_id: list?.unit_no,
      user_email: userData?.email,
    })
  }

  
  return (
    <Card key={list?.unit_id} className="card-with-slider property-card">
      <CardActionArea
        style={{
          opacity: path === "wishlist" && list?.inventoryStatus && list?.inventoryStatus !== 'Available' ? 0.5 : 1,
          pointerEvents: path === "wishlist" && list?.inventoryStatus && list?.inventoryStatus !== 'Available' ? 'none' : 'auto',
        }}
        className={`cards-section ${
          selectedProject?.includes(list?.unit_no) ? 'active-class' : ''
        }`}
      >
        <ImageSlider
          showSlideNumber={false}
          imageList={list?.images}
          imageType="uL_ImageURL"
          clickhandler={() =>
            redirectPath(list?.unit_id, list?.title, list?.unit_no)
          }
        />
        <Typography className="share-info" component="div">
          <ul>
    
   
            {path === "wishlist" && list?.inventoryStatus && list?.inventoryStatus!== 'Available' && (
              <p>
                <img src={notavailable} alt="Not available" className="soldimageContainer" />
              </p>
            )}

            <li className="wishlist-button-icon">
              <ButtonComponent
                onClick={() => wishlistHandler(list?.unit_id, list)}
                src={
                  wishlistProject?.includes(list?.unit_id) ? HeartActive : Heart
                }
                alt_name="wishlist"
                className={`button-icon ${
                  wishlistProject?.includes(list?.unit_id) && 'active'
                }`}
              />
            </li>
            <li>
              <ButtonComponent className="button-icon">
                <Checkbox
                  className="checkbox-panel"
                  onClick={(e) => projectSelectHandler(e, list?.unit_no)}
                  checked={selectedProject?.includes(list?.unit_no)}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                />
              </ButtonComponent>
            </li>
          </ul>
        </Typography>

        <CardContent
          className="property-info"
          onClick={() =>
            redirectPath(list?.unit_id, list?.title, list?.unit_no)
          }
        >
          <Typography variant="h3" component="h3">
            <span>{list?.title}</span>
          </Typography>
          <Typography variant="h4" component="h4">
            <span>{list?.unit_no || '-'}</span>{' '}
            <span className="separator">|</span>
            <span>{list?.property_type || '-'}</span>
          </Typography>
          <Typography className="unit-info" component="div">
            <ul>
              <li>
                <i className="iconwasalt icon-bedroom"></i>{' '}
                <span>{list?.bedroom || '-'}</span>
              </li>
              <li>
                <img src={AreaIcon} alt="area icon" />{' '}
                <span>
                  {list?.area
                    ? `${formatNumbers(list?.area)} (${list?.units})`
                    : '-'}
                </span>
              </li>
            </ul>
          </Typography>
          <Typography className="unit-bottom-section" component="div">
            <Typography variant="h3" component="h3">
              {list?.currency} <span> {formatNumbers(list?.price)} </span>
            </Typography>
            <ul onClick={(e) => stopRedirect(e)}>
              <li onClick={() => clickHandler('email', list)}>
                <a
                  href={`mailto: ${bdmInfo?.email || 'agents@darglobal.co.uk'}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button variant="text">
                    <i className="iconwasalt icon-email" />
                  </Button>
                </a>
              </li>
              <li onClick={() => clickHandler('call', list)}>
                <a
                  href={`tel: ${
                    bdmInfo?.bdM_PHONE_NUMBER || '+971 4 247 8990'
                  }`}
                >
                  <Button variant="text">
                    <i className="iconwasalt icon-call-outline" />
                  </Button>
                </a>
              </li>
            </ul>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default PropertyCard
