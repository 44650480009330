
import React, { useEffect, useMemo, useState } from 'react';
import nationality from '../../json/nationality.json';
import './CountryCodePicker.scss';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import FormControl from '@mui/material/FormControl';
import { TextField } from '@material-ui/core';
import ErrorComponent from 'Components/Common/ErrorComponent';

function CountryPicker({isReadOnly=false,label="", mobile = "", countryCode = 0,onGettingValue=()=>{},errors="",errorClass="",short_Counrty=""}) {
    const [selectedCountry, setSelectedCountry] = useState(230);
    const [mobileNumber,setMobileNumber]=useState("");

    const handleChangeCountryCode=(e)=>{
      setSelectedCountry(e?.target?.value);
      onGettingValue(nationality[e?.target?.value],mobileNumber,label);
    }
    const handleChangeMobileNumber=(e)=>{
      
        if(e?.target?.value?.length>18) return
        setMobileNumber(e?.target?.value);
        onGettingValue(nationality[selectedCountry],e?.target?.value,label);
    }
    const handleChange = (event) => {
      const { value } = event.target;
      if (/^\d*$/.test(value)) {
        handleChangeMobileNumber(event);
      }
    };
    useMemo(()=>{
        setMobileNumber(mobile);
       let foundedCountryCode=nationality?.findIndex(_=>_?.Short_Country==short_Counrty)
       if(foundedCountryCode!==-1){
        setSelectedCountry(foundedCountryCode||230);
       }else{
        setSelectedCountry(230);
       }
      
    },[mobile,short_Counrty])
    
    
    return (
       <> 
        <div style={{position:"relative",display:'flex',alignItems:"center",border:"solid 1px #adadad",height:"43px"}}>
          <Select 
            disabled={isReadOnly}
            className='customeSelect'
            // style={{all:"unset"}}
            sx={{
              all: 'unset',
              width: '100%',
              border: '0px solid #ddd', 
              borderRadius: '4px', 
              maxHeight: "200px",
            '& .MuiOutlinedInput-notchedOutline':{
              border:'0',
              outline:'0',
            },
            }}
            labelId = "demo-select-small-label"
            id = "demo-select-small"
            value = { selectedCountry }
            label = ""
            onChange = { handleChangeCountryCode }           
                >
                {
                  nationality?.map((_,index)=>{
                    return (
                        <MenuItem sx={{
                          border:'0',
                          outline:'0',
                        }} value={index} key={index}>
                        <div style={{display:"flex",alignItems:"center"}}>
                            <img src={_?.Flag} style={{width:"20px",height:"auto",margin:"0px"}}/>{" "}
                            <span className='countryname' 
                            style={{padding:"0 10px"}}
                            >{_?.Country}</span>{" "}
                            <span  className='countryCode' style={{color:"#6b6b6"}}
                             >{_?.Code}</span>{" "}
                        </div>
                        </MenuItem>
                    )
                   })
                }
          </Select >
          <TextField disabled={isReadOnly} type='tel' className='custome' style={{width:"78%",border:"0px",height:"auto",fontSize:"15px",display:"flex",flexDirection:"column",justifyContent:"center", position:'absolute',right:'0',
          // zIndex:'99'
        }} 
          value={mobileNumber} 
          onChange={handleChange}/>
       </div>
       {errors && (
        <ErrorComponent errors={errors} className={errorClass} />
      )}
       </>
    )
}

export default CountryPicker

{/* <div className='CountryPicker'>
            <Dropdown value={selectedCountry} style={{ width: "100px !important" }} onChange={(e) => { console.log(e.value); setSelectedCountry(e.value); handleCountryChange(e.value) }} options={nationality} optionLabel="english_name"
                valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}
            />
            <div style={{ width: "100%" }}>
                <input value={mobile} type='tel' min={0} placeholder='Enter here' onChange={(e) => { handleChange(e.target.value) }} ></input>
            </div>
        </div > */}