import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

const Loader = ({cls}) => {
 return (
  <div className={`loader ${cls}`}>
   <div className="loaderInner" >
    <CircularProgress />
   </div>
  </div>
 )
}

export default Loader;