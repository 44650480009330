import { apiServiceRequest } from "Utils/axiosInstance";
import { getUserToken } from "Utils/tokenProvider";

//currency Service
const changePassword = async (
  setChangePasswordLoading,
  notify,
  formData,
  navigate,
  updateError
) => {
  setChangePasswordLoading(true);
  const res = await apiServiceRequest({
    url: `/change-password`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    method: "post",
    data: formData,
    notify: notify,
  });
  if (res?.code === "success") {
    navigate(-1);
    notify("ra.notification.passwordChanged", {
      type: "success",
    });
  } else {
    updateError(res?.message);
  }
  setChangePasswordLoading(false);
};

export default changePassword;
