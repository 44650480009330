import React, { useState, useRef } from "react";
import { Button, Typography } from "@mui/material";
import Styles from "./ProfilePic.scss";
import CameraIcon from "images/camera.png";
import CropperComponent from "Components/CropperComponent/CropperComponent";

const ProfilePic = ({ picture, setPicture, uploadPicture, notification }) => {
  const [showCropper, setShowCropper] = useState(null);
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showDialog, setShowDialog] = useState(null);
  const inputRef = useRef();

  const onSelectFile = (e) => {
    setShowDialog(true);
    if (e.target.files && e.target.files.length > 0) {
      if (
        e.target.files[0].type === "image/jpg" ||
        e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/png"
      ) {
        setShowCropper(true);
        // Create an object out of FileReader to read file asynchronously
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]); // we need the file in the form of a data url
        reader.addEventListener("load", () => {
          setImage(reader.result);
        });
      } else {
        notification("ra.notification.validFormat", {
          type: "error",
        });
        setShowCropper(false);
        setShowDialog(false);
      }
    }
  };

  return (
    <>
      <Typography variant="div" className="profile-pic">
        {(croppedImage || picture) && (
          <img src={croppedImage ? croppedImage : picture} alt="profile" />
        )}
        <Button
          className="camera-image"
          variant="contained"
          component="label"
          onClick={() =>
            picture ? setShowDialog(true) : inputRef.current.click()
          }
        >
          <i alt="camera picture" className="iconwasalt icon-camera"></i>
        </Button>
        <input
          type="file"
          accept="image/jpeg, image/jpg, image/png"
          ref={inputRef}
          style={{ display: "none" }}
          onChange={onSelectFile}
        />
      </Typography>
      <CropperComponent
        uploadPicture={uploadPicture}
        showCropper={showCropper}
        croppedImage={croppedImage}
        setShowCropper={setShowCropper}
        setCroppedImage={setCroppedImage}
        image={image}
        setImage={setImage}
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        setPicture={setPicture}
        picture={picture}
        inputRef={inputRef}
        notification={notification}
      />
    </>
  );
};

export default ProfilePic;
