import React from 'react';
import {
  TextField,
  Typography
} from "@mui/material";
import { useTranslate } from "react-admin";

const InputField = ({
  name,
  type = "text",
  state,
  value,
  defaultValue,
  register,
  errors,
  pattern,
  onChange,
  hide = false,
  message,
  className,
  sx,
  id,
  readOnly,
  placeholderHide = false,
  onKeyDown,
  placeholder,
  titleClass,
  requiredField,
  onChangeField,
  validate,
  required = true
}) => {
  const translate = useTranslate();

  return (
    <>
    {name &&
      <Typography className={titleClass} variant="subtitle1">
        {name}
        {!hide && <span style={{ color: "#EF4349" }}>*</span>}
      </Typography>
    }
    <TextField
      id={id}
      defaultValue={defaultValue}        
      value={value}        
      variant="outlined"
      type={type}
      className={className}
      sx={sx}
      inputProps={{
        onChange:  onChange && onChange,
        readOnly: readOnly
      }}
      onKeyDown={onKeyDown}
      placeholder={!placeholderHide && (placeholder ? placeholder : `${translate("ra.placeholder.enter")} ${name}`)}
      {...register(state, {
        required: required && (requiredField || (!value && (message || translate("ra.validation.validInput")))),
        pattern: pattern,
        onChange: onChangeField,
        validate: validate
      })}
      error={Boolean(errors[state])}
      helperText={errors[state]?.message}
    />
    </>
  )
}

export default InputField