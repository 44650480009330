const unlock_status = ["DRAFT", "-"];

const isRead = (os) => {
  if (os) {
    return unlock_status.includes(os) ? false : true;
  }
  return false;
};

export default isRead;

export const isinDraft = (os) => {
  if (os) {
    return os === "DRAFT" ? true : false;
  }
  return false;
};
