import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  Button,
  Typography
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslate } from "react-admin";
import Header from "Components/Header";
import userDetails from "Services/userDetailsServices";
import Accordions from "Components/Accordian";
import { UnitsDetailsAccordian } from "./UnitsDetailsAccordian";
import { syncCustomer } from "Services/attachTokenProofsService";
import Loader from "Components/Loader";
import { removeLocalStorage } from "Utils/removeStorage";

export const ReservationSuccessfull = ({
  notification,
  bookingFailedPopup,
  setBookingFailedPopup
}) => {
  const translate = useTranslate();
  const [userDetailsLoading, setuserDetailsLoading] = useState(false);
  const [projectDetailsLoading, setProjectDetailsLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const portalID = localStorage?.getItem("portalBookingId") || null;
  let formStatus = sessionStorage.getItem("formStatus") || null;
  const bookingState = JSON.parse(localStorage.getItem("bookingState")) || null;

  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || bookingState;

  let headerPayload = {
    project_id: state?.project_id,
    unit_ids: state?.units?.map(unit => unit?.officE_SECTION_ID)?.join(","),
    portal_booking_id: portalID
  }

  useEffect(() => {
    // if (!formStatus) {
    //   navigate("/home");
    // }

    // if (formStatus && formStatus !== "/reservation-successfull") {
    //   navigate(formStatus, { state });
    // }

    // userDetails(setuserDetailsLoading, setUserData, "", notification);
    // portalID && syncCustomer(setLoading, setData, notification, portalID, setProjectDetailsLoading, setDetails, JSON.stringify(headerPayload), headerPayload, bookingFailedPopup,
    //   setBookingFailedPopup)
    // document.body.classList.add(
    //   'profile-page'
    // );
    // document.body.classList.add(
    //   'reservation-successfull'
    // );
    // return () => {
    //   document.body.classList.remove(
    //     'profile-page'
    //   );
    //   document.body.classList.remove(
    //     'reservation-successfull'
    //   );
    // };
  }, []);

  useEffect(() => {
    if (data?.kycStatus == 0) {
      notification("ra.notification.kycverification", { type: "info" })
    }
  }, [data])

  const parseDate = (date) => {
    return new Date(date).toLocaleString('default', { day: "numeric", month: 'long', year: "numeric" });
  }

  const onClickContinue = () => {
    navigate("/download-reservation-form", { state })
    sessionStorage.setItem("formStatus", "/download-reservation-form");
  }

  const redirectPath = () => {
    removeLocalStorage();
    localStorage?.removeItem("page-redirect");
    navigate("/my-bookings");
  }

  return (
    <>
      {/* {userDetailsLoading || projectDetailsLoading || loading && <Loader />} */}
      <Header
        path="My Bookings"
        picture={userData?.profile}
        pictureLoading={userDetailsLoading}
        notification={notification}
      />
      {/* {!userDetailsLoading && !projectDetailsLoading && !loading && */}
        <Container className="lead-panel-section invoice-submitted-panel reservation-form-submitted mt-0">
          <Container className="company-details">
            <Grid className="form-panel profile-form-panel add-team-panel invoice-submitted" sx={{ width: "100%" }}>
              <div style={{display:"flex",justifyContent:"center"}}>
                  <Typography className="icon customeSuccessTick">
                    <i className="iconwasalt icon-check customesuccessTickIcon" />
                  </Typography>
              </div>
              <Typography variant="h2">{translate("ra.pageTitle.reservationSuccessfylly")}</Typography>
              <Typography component="p"><em>{translate("ra.pageTitle.tokenSuccessfully")}</em> {translate("ra.pageTitle.bookingComplete")}</Typography>
              <Grid className="success-accordian-panel">
                <h5>{translate("ra.pageTitle.reservationDetails")}</h5>
                <p className="mb-2">{translate("ra.fieldName.bookingId")} <span>{data?.bookingId}</span></p>
                <p className="mb-2">{translate("ra.fieldName.date")} <span>{data?.bookingDate && parseDate(data?.bookingDate)}</span></p>
                {/* <Accordions
                  heading={translate("ra.fieldName.unitBook")}
                  headingValue={`${data?.units?.length || ""} ${data?.units?.length > 1 ? translate("ra.fieldName.units") : translate("ra.fieldName.unit")}`}
                  className="success-accordian-info"
                >
                  <Grid className="success-accordian-top-info">
                    <img
                      src={details?.images[0]?.imageURL}
                      style={{ width: "100px" }}></img>
                    <p><span>{details?.projecT_NAME}</span> {details?.city}, {details?.location}</p>
                  </Grid>
                  {data?.units.map((unit, index) => {
                    return <UnitsDetailsAccordian unit={unit} key={index} translate={translate} userData={userData} />;
                  })}
                </Accordions> */}
              </Grid>
              <Grid container spacing={0} className="invoice-success-button defaultButtonSpace">
                <Button
                  variant="outlined"
                  onClick={() => redirectPath()}
                >
                  {translate("ra.button.gotoBookings")}
                </Button>
                <Button
                  variant="contained"
                  className="new-color-button invoice-success-button"
                  disabled={data?.units?.map((unit) => unit?.kyc_status === 0)?.includes(true) ? true : false}
                  onClick={() => onClickContinue()}
                >
                  {translate("ra.button.viewRservationForm")}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Container>
      {/* } */}
    </>
  );
};
