  // Remove duplicates from array of objects
  export const removeDuplicates = (arr, keyName= "name") => {
    if(arr) {
      const unique = arr.filter((obj, index) => {
        return index === arr.findIndex(data => obj[keyName] === data[keyName]);
      });
  
      // removing object from an array where name is empty
      return unique.filter(list => list?.name !== "");
    } else {
      return [];
    }
  }