import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRedirect, useLogout, useTranslate } from "react-admin";
import { Grid, Container, Button, Typography } from "@mui/material";
import BackButton from "Components/BackButton";
import styles from "./change-password.scss";
import { passwordPattern } from "Utils/regex-patterns";
import changePassword from "Services/changePassword";
import DialogBox from "Components/DialogBox";
import Password from "Components/Password/Password";

const ChangePassword = ({ notification }) => {
  const redirect = useRedirect();
  const logout = useLogout();
  const navigate = useNavigate();
  const translate = useTranslate();
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);

  const form = useForm({
    mode: "onChange",
  });
  const { register, handleSubmit, watch, formState, setValue, setError } = form;
  const { errors } = formState;

  useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("change-pass-page");
    return () => {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("change-pass-page");
    };
  }, []);

  const onSubmit = async (data) => {
    changePassword(
      setChangePasswordLoading,
      notification,
      data,
      navigate,
      updateError
    );
  };

  const handleYes = () => {
    localStorage.clear();
    redirect("/forgot-password");
  };

  const updateError = (message) => {
    setError("old_password", {
      type: "manual",
      message: translate("ra.notification.incorrectPassword"),
    });
  };

  const onClickBackButton = () => {
    if (watch("password") || watch("c_password") || watch("old_password")) {
      setOpenConfirmationPopup(true);
    }
    else {
      navigate(-1);
    }
  }

  return (
    <>
      <BackButton close={onClickBackButton} />
      <Container className="company-container mt-0">
        <Container className="company-details">
          <Grid className="form-panel profile-form-panel change-password-panel">
            <Typography variant="h2">{translate("ra.fieldName.changeReset")}</Typography>
            <Grid container spacing={0}>
              <Typography variant="subtitle1">
                {translate("ra.fieldName.currentPassword")} <span style={{ color: "#EF4349" }}>*</span>
              </Typography>

              <Password
                id="current-password"
                state="old_password"
                message={translate("ra.validation.validInput")}
                register={register}
                errors={errors}
                watch={watch}
              />
            </Grid>

            <Grid container spacing={0}>
              <Typography variant="subtitle1">
                {translate("ra.fieldName.newPassword")} <span style={{ color: "#EF4349" }}>*</span>
              </Typography>

              <Password
                id="new-password"
                state="password"
                message={translate("ra.validation.passwordError")}
                value={passwordPattern}
                messageValidation={translate("ra.validation.passwordCheck")}
                register={register}
                errors={errors}
                watch={watch}
                passwordPattern={passwordPattern}
                validPassword={translate("ra.validation.passwordCheck")}
              />
            </Grid>

            <Grid container spacing={0}>
              <Typography variant="subtitle1">
                {translate("ra.fieldName.confirmPassword")} <span style={{ color: "#EF4349" }}>*</span>
              </Typography>

              <Password
                id="confirm-password"
                state="c_password"
                message={translate("ra.validation.confirmError")}
                messageValidation={translate("ra.validation.passwordCheck")}
                register={register}
                errors={errors}
                watch={watch}
                comparePassword="password"
              />
            </Grid>
            <Grid container className="forgot-password-text-panel">
              <Typography
                className="forgot-password-info"
                variant="subtitle1"
                onClick={() => {
                  setOpenPopup(true);
                }}
              >
                {translate("ra.fieldName.forgot_password")}
              </Typography>
            </Grid>

            <Grid
              container
              className="button-panel mobile-fixed-button"
              spacing={0}
            >
              <Button
                variant="contained"
                className="new-color-button"
                onClick={handleSubmit(onSubmit)}
              >
                {translate("ra.button.updatePassword")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <DialogBox
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        content={translate("ra.notification.loggedOut")}
        handleYes={handleYes}
      />
      {openConfirmationPopup && (
        <DialogBox
          openPopup={openConfirmationPopup}
          setOpenPopup={setOpenConfirmationPopup}
          content={translate("ra.notification.changesLost")}
          handleYes={() => navigate(-1)}
        />
      )}
    </>
  );
};

export default ChangePassword;
