import React from "react";
import { RWebShare } from "react-web-share";
import "./Popup.scss";

export default function ReactWebShare({ 
    handleClose,
    children,
    text,
    title,
    url
}) {

  return (
    <RWebShare
        data={{
            text: text,
            title: title,
            url: url
        }}
        onClick={handleClose}
        disableNative={true}
        sites={[
            "whatsapp", "twitter", "reddit", "telegram", "linkedin", "mail", "copy"
        ]}
    >
        {children}
    </RWebShare>
  );
}
