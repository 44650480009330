import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { useTranslate } from "react-admin";

const SelectionDialogBox = ({
  openPopup,
  closeFn,
  title,
  children,
  handleSubmit,
  buttonName,
  buttonNameForDetails = false,
  className
}) => {
  const translate = useTranslate();

  return (
    <div>
      <Dialog
        className={`new-popup-box add-contact-popup ${className}`}
        open={openPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="share-heading" id="alert-dialog-title">
          <span>{title}</span>
          <i
            className="iconwasalt icon-close cross-button"
            onClick={closeFn}
          />
        </DialogTitle>
        <DialogContent className="">
          {children}
        </DialogContent>
        <DialogActions className="padding-0 button-panel">
          {buttonNameForDetails ?
            (<>
              <Button
                className="share-button"
                onClick={handleSubmit}
              >
                {translate("ra.button.copyDetails")}
              </Button>
              <br />
              <Button
                className="share-button"
                onClick={handleSubmit}
              >
                {translate("ra.button.sendDetails")}
              </Button>
            </>)
            :
            (<>
              <Button
                className="share-button"
                onClick={handleSubmit}
              >
                {buttonName 
                  ? translate("ra.button.save") 
                  : translate("ra.button.continue")
                }
              </Button>
            </>)
          }
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SelectionDialogBox;