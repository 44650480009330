import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import "Pages/NewPages/new-style.scss";
import "Pages/MyContacts/contacts-style.scss";
import "Pages/MyBooking/booking-style.scss";
import "Pages/BookinFlow/booking-flow-style.scss";
import App from "./App";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-5G4Q696",
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);
