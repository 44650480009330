import React from 'react';
import { useRedirect, useTranslate } from 'react-admin';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FailedIcon from "../../../images/failed-icon.svg"
import { removeLocalStorage } from 'Utils/removeStorage';

const FailedDialogBox = ({openPopup , setOpenPopup}) => {

    const translate = useTranslate();
    const redirect = useRedirect();

    const redirectUrl = () => {
        removeLocalStorage();
        localStorage?.removeItem("page-redirect");
        setOpenPopup(false);
        redirect("/home");
    }

  return (
    <Dialog
        open={openPopup}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        className={`new-popup-box remove-popup exit-customer failed-popup`}
    >
        <i
            className="iconwasalt icon-close cross-button"
            onClick={() => redirectUrl()}
        />
        <DialogContent>
            <img src={FailedIcon} />
            <h2>{translate("ra.content.bookingFailed")}</h2>
            <DialogContentText id="alert-dialog-slide-description">
                <DialogContent>
                    <p>{translate("ra.content.exceedFourhours")}</p>
                </DialogContent>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button 
                className="close-button-dialog" 
                onClick={() => redirectUrl()}
            >
                {translate("ra.button.goHomePage")}
            </Button>
        </DialogActions>
    </Dialog>
    );
}


export default FailedDialogBox