import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";
import mapStyles from "./mapStyles.json";
import { Typography } from "@material-ui/core";

const customStyles = {
  position: "absolute",
  width: "100%",
  height: "100%",
};

// hiding all icons and text in map
const mapLoaded = (mapProps, map) => {
  map.setOptions({
    styles: mapStyles,
  });
};

const MapContainer = ({
  google,
  location,
  latitude,
  longitude,
  name,
  handleClickOpen,
  translate
}) => {
  const [selected, setSelected] = useState();

  return (
    <div className="map">
      <Map
        google={google}
        zoom={15}
        style={customStyles}
        mapTypeControl={false}
        fullscreenControl={false}
        initialCenter={{
          lat: latitude,
          lng: longitude,
        }}
        streetViewControl={false}
        onReady={(mapProps, map) => mapLoaded(mapProps, map)}
        onClick={() => handleClickOpen()}
      >
        <Marker
          name={location}
          position={{
            lat: latitude,
            lng: longitude,
          }}
          onClick={() =>
            setSelected({
              lat: latitude,
              lng: longitude,
            })
          }
        />

        <InfoWindow
          position={selected}
          visible={true}
          onClose={() => setSelected()}
        >
          <div className="infoWindow">
            <Typography variant="h3">{name}</Typography>
            <Typography>
              <span>{translate("ra.fieldName.lat")} : </span>
              <span>{latitude}</span>
            </Typography>
            <Typography>
              <span> {translate("ra.fieldName.lng")} : </span>
              <span>{longitude}</span>{" "}
            </Typography>
          </div>
        </InfoWindow>
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCgeB6NfI55546Z8KWtthfB9yJTN8ptbVU",
})(MapContainer);
