import React, { useState, useEffect } from "react";
import { useRedirect, useTranslate } from "react-admin";
import { useForm } from "react-hook-form";
import { Button, CardContent, Typography, Grid } from "@mui/material";
import image from "../../images/brokers_image.png";
import logo from "../../images/logo.svg";
import { passwordPattern } from "Utils/regex-patterns";
import Loader from "Components/Loader";
import resetPasswordLinkExpireService from "Services/resetPasswordLinkExpire";
import resetPasswordService from "Services/resetPasswordService";
import InputField from "Components/InputField";

const ResetPassword = ({ notification }) => {
  const [loading, setLoading] = useState(true);
  const [resetLoading, setResetLoading] = useState(false);
  const [isDisableButton, setIsDisableButton] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const url = new URL(window.location);
  const parse = url?.searchParams;
  const requestId = parse.get("randomId");

  const redirect = useRedirect();
  const translate = useTranslate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    resetPasswordLinkExpireService(requestId, setShowForm, setLoading, notification); // Check the reset password link's status
  }, []);

  const onSubmit = async (data) => {
    resetPasswordService(
      requestId,
      setIsDisableButton,
      setResetLoading,
      url,
      data,
      notification,
      redirect
    );
  };

  return (
    <>
      {(loading || resetLoading) && <Loader />}
      {showForm ? (
        <Grid
          className="form-panel height100 form-fixed"
          container
          sx={{ minHeight: "100vh", width: "100%" }}
        >
          <Grid
            xs={{ display: "none" }}
            style={{ backgroundImage: `url(${image})` }}
            md={6}
          ></Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <CardContent className="forgot-password">
              <img src={logo} className="login-logo" alt="Dar logo" />
              <Typography className="form-user-name text-center">
                {translate("ra.pageTitle.resetPassword")}
              </Typography>
              <Grid container spacing={0}>
                <InputField 
                  name={translate("ra.fieldName.newPassword")}
                  titleClass="form-label"
                  register={register} 
                  errors={errors} 
                  type="password"
                  state="password"
                  message={translate("ra.validation.passwordError")}
                  placeholder={translate("ra.placeholder.newPassword")}
                  pattern={{
                    value: passwordPattern,
                    message: translate("ra.validation.passwordCheck")
                  }}
                  hide={true}
                />
              </Grid>
              <Grid container spacing={0}>
                <InputField 
                  name={translate("ra.fieldName.confirmPassword")}
                  titleClass="form-label"
                  register={register} 
                  errors={errors} 
                  type="password"
                  state="c_password"
                  message={translate("ra.validation.confirmError")}
                  placeholder={translate("ra.placeholder.confirmPassword")}
                  hide={true}
                  validate={(val) => {
                    if (watch("password") !== val) {
                      return translate("ra.validation.passwordMatch");
                    }
                  }}
                />
              </Grid>
              <Button
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                className={isDisableButton && "disabled"}
              >
                <Typography>{translate("ra.button.submit")}</Typography>
              </Button>
            </CardContent>
          </Grid>
        </Grid>
      ) : (
        !loading && (
          <>
            <Grid
              item
              className="forgot-password"
              xs={12}
              md={6}
              sx={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Typography className="form-user-name reset-error-text">
                {requestId
                  ? translate("ra.content.linkExpired")
                  : translate("ra.content.accessBlock")}
              </Typography>
            </Grid>
          </>
        )
      )}
    </>
  );
};

export default ResetPassword;
