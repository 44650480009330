import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  Box,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTranslate } from 'react-admin';
import ButtonComponent from 'Components/Common/ButtonComponent';

export default function Popup({
  openNoticePopUp,
  setOpenNoticePopUp,
  icon,
  dialogClass,
  innerContentClass,
}) {
  const translate = useTranslate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const currentPath = location.pathname;
  const handleClose = () => {
    setOpenNoticePopUp(false);
  };

  return (
    <div>
      <Dialog
      open={openNoticePopUp}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          overflow: 'hidden',
        },
      }}
    >
      {/* Close button */}
      <IconButton
        edge="end"
        color="inherit"
        onClick={handleClose}
        aria-label="close"
      
        sx={currentPath!='/bdm-details' && { position: 'absolute', right: 8, top: 8 }}
        
       style={ currentPath === '/bdm-details' ? { marginRight: '0', marginLeft: 'auto'} :{}}
      >
        <CloseOutlinedIcon />
      </IconButton>

      {/* Icon at the top */}
      <DialogContent sx={{ textAlign: 'center', paddingTop: isMobile ? 4 : 4 }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          {/* Centered icon */}
          <i className={`iconwasalt ${icon}`} style={{ fontSize: isMobile ? 40 : 60 }} />
        </Box>
      </DialogContent>

      {/* Title */}
      <DialogTitle sx={{ textAlign: 'center' ,marginTop: -2  }}>
        <Typography variant="h6">{translate('ra.content.Notice')}</Typography>
      </DialogTitle>

      {/* Content */}
      <DialogContent dividers>
        <DialogContentText>
          {/* Use HTML content */}
          <div
            dangerouslySetInnerHTML={{
              __html: `
                <p>
                  Kindly be informed that our Agent Portal is presently undergoing an upgrade. To facilitate unit reservations,
                  track booking statuses, or add contacts, we kindly request you to contact your designated Relationship Manager.
                  Should your Relationship Manager be unavailable, please reach out to us via email at
                  <a href="mailto:agents@darglobal.co.uk">agents@darglobal.co.uk</a>.
                </p>
              `,
            }}
            style={{
              padding: isMobile ? '0 16px' : '0', // Add horizontal padding on mobile
              maxWidth: isMobile ? '100%' : 'none', // Adjust width based on screen size
            }}
          />
        </DialogContentText>
      </DialogContent>

      {/* Actions (OK button) */}
      <DialogActions sx={{ justifyContent: 'center' }}>
        <ButtonComponent
          onClick={handleClose}
          title1={translate('ra.button.ok')}
          container_class="share-button"
        />
      </DialogActions>
    </Dialog>
    </div>
  );
}
