import { apiServiceRequest } from "Utils/axiosInstance";

//currency Service
const loginService = async (data, notify) => {
  const res = await apiServiceRequest({
    url: `/login`,
    method: "post",
    data: data,
    notify: notify,
  });
  return res;
};



export const triggerOtp = async (data, notify,navigate,setIsEmailTriggered) => { 
  
  const res = await apiServiceRequest({
    url: `/generate-otp`,
    method: "post",
    data: data,
    notify: notify,
  });
  if(res?.statusCode==200&&res?.message==="success"){
    setIsEmailTriggered(true);
    localStorage.setItem("OtpGenerate",JSON.stringify(res?.data));
    localStorage.setItem("FreezeTime",JSON.stringify(new Date()));
    notify(res?.data?.message, { type: "success" });
    navigate('/verify-otp')
  }else if(res?.data?.statusCode==400){
    notify(res?.data?.message, { type: "error" });
    setIsEmailTriggered(false);
  }else{
    notify(res?.message, { type: "error" });
    setIsEmailTriggered(false);
  }
};

export const ValidateMFA = async (data, notify,token) => {
  const res = await apiServiceRequest({
    url: `/validate-otp`,
    method: "post",
    data: data,
    notify: notify,
    headers: { Authorization: `Bearer ${token}` },
  });
  if(res?.statusCode==200&&res?.message==="success"){
    notify(res?.data?.message, { type: "success" });
    return true;
  }else if(res?.data?.statusCode==400){
    notify(res?.data?.data?.message||"In-correct otp", { type: "error" });
  }
  else{
    notify(res?.message, { type: "error" });
    return false;
  }
 
};


export const ResendOtp = async (data, notify,token) => {
  const res = await apiServiceRequest({
    url: `/resend-otp`,
    method: "post",
    data: data,
    notify: notify,
    headers: { Authorization: `Bearer ${token}` },
  });
  if(res?.statusCode==200&&res?.message==="success"){
    notify(res?.data?.message, { type: "success" });
    localStorage.setItem("FreezeTime",JSON.stringify(new Date()));
    return true;
  }else if(res?.data?.statusCode==400){
    notify(res?.data?.data?.message, { type: "error" });
  }
  else{
    notify(res?.message, { type: "error" });
    return false;
  }
 
};

export default loginService;
