import { apiServiceRequest } from "Utils/axiosInstance";
import { getUserToken } from "Utils/tokenProvider";

const tinyUrl = async (
  setTinyUrl,
  setTinyUrlLoading,
  notify,
  url
) => {  
  setTinyUrlLoading(true);
  
  if(url) {
    const res = await apiServiceRequest({
      url: `/tiny-url?url=${url}`,
      headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
      notify: notify,
    });

    if (res?.code === "success") {
      setTinyUrl(res?.data?.url);
    }
    else{
      notify(res.message, { type: "error" });
    }
  }
  setTinyUrlLoading(false);
};

export default tinyUrl;
