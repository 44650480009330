import React, { useState } from "react";
import { Slider } from "@material-ui/core";
import { formatNumbers } from "Utils/thousandSeperators";

export const AccordionSlider = ({ defaultValue, max, min, currency, setSliderItemValue }) => {
  const [sliderVal, setSliderVal] = useState(defaultValue);

  return (
    <>
      <p className="currancy-range">
        {currency && (
          <span> {currency} <strong>{formatNumbers(sliderVal)}</strong></span>
        )}  
      </p>
      <Slider
        defaultValue={defaultValue}
        aria-label="Default"
        valueLabelDisplay="off"
        onChange={(event, value) => {
          setSliderItemValue(value);
          setSliderVal(value);
        }}
        max={max}
        min={min == max ? 0 : min}
      />
      <p className="currancy-min-max">
        {currency && (<span> {currency} {formatNumbers(min)}</span>)}
        {currency && (<span> {currency} {formatNumbers(max)}</span>)}
      </p>
    </>
  );
};
