const formatNumber = number => {
  if(number) {
    if (number < 1e3) return number;
    if (number >= 1e3 && number < 1e6) return +(number / 1e3).toFixed(2) + "K";
    if (number >= 1e6 && number < 1e9) return +(number / 1e6).toFixed(2) + "M";
    if (number >= 1e9 && number < 1e12) return +(number / 1e9).toFixed(2) + "B";
    if (number >= 1e12) return +(number / 1e12).toFixed(1) + "T";
  } else {
    return 0;
  }
};

export const convertBytes = function (bytes) {
  const sizes = ["Bytes", "Kb", "MB", "GB", "TB"]
  if (bytes == 0) {
    return "n/a"
  }
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  if (i == 0) {
    return bytes + " " + sizes[i]
  }
  return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i]
}

export default formatNumber;

export const convertTobase64 = (file) =>{
  return new Promise(resolve => {
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
}


export const PriceFormat = (number) => {
  // Check if the input is a valid number
  if (isNaN(number)) {
    return 'Invalid number';
  }
  
  // Convert the number to a string
  const numberString = String(number);
  
  // Split the string into integer and decimal parts
  const [integerPart, decimalPart] = numberString.split('.');

  // Add commas after every three characters in the integer part
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Add trailing zeros to the decimal part if it is missing or has less than three digits
  let formattedDecimal = decimalPart || '000'; // Default to three zeros if decimal part is missing
  formattedDecimal = formattedDecimal.padEnd(3, '0'); // Pad with zeros to ensure three digits

  // Combine the integer and decimal parts with the appropriate separator
  const formattedNumber = `${formattedInteger}.${formattedDecimal}`;

  return formattedNumber;
};