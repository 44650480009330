import React, { useEffect } from "react";
import { Button, Container } from "@mui/material";
import Styles from "./BackButton.scss";
import { useNavigate } from "react-router-dom";

const BackButton = ({ title, container_class, title_class, sx, close, page, releaseBlockUnits}) => {
  const navigate = useNavigate();

  const goBack = () => {

    if(page=="unit"){
      navigate('/home')
      return
    }
    if(releaseBlockUnits) {
      releaseBlockUnits();
    } else if (close) {
      close();
    } else {
      navigate(-1);
    }
    
  };

  useEffect(() => {
    if(page) {
      let el = document.getElementById("back-button");
      let elTop = el?.getBoundingClientRect()?.top - document.body.getBoundingClientRect().top;
  
      window.addEventListener("scroll", function(){
          if (document.documentElement.scrollTop >= elTop){
            el.classList.add("unitpage-position-sticky")
          } else {
            el.classList.remove("unitpage-position-sticky")
          }
      });
    }
  }, [])

  return (
    <Container id="back-button" className={`back-button-panel ${container_class}`}>
      <Button variant="text" sx={sx} onClick={goBack}>
        <i className="iconwasalt icon-arrow-back-new"></i>
        {title && <span className={title_class}>{title}</span>}
      </Button>
    </Container>
  );
};

export default BackButton;
