import React, { useEffect, useState } from 'react';
import { Box, TextField, MenuItem, useMediaQuery } from "@mui/material" ;
import styles from "./styles.module.scss";

const MultiRangeSlider = ({
  priceRange,
  setPriceRange,
  name,
  setOpenDrop,
  setValue,
  translate
}) => {

  const matches = useMediaQuery("(min-width:800px)");

  {/* Added range slider value and hidden with css inorder to show it in dropdown value*/}
  const min_price = [
    0,
    100000,
    200000,
    300000,
    400000,
    500000,
    600000,
    700000,
    800000,
    900000,
    1000000,
    2000000,
    3000000,
    4000000,
    5000000,
    6000000,
    7000000,
    8000000,
    9000000,
    10000000,
    20000000,
    30000000,
    40000000,
    50000000,
    priceRange[0]
  ]
  
  const max_price = [
    100000,
    200000,
    300000,
    400000,
    500000,
    600000,
    700000,
    800000,
    900000,
    1000000,
    2000000,
    3000000,
    4000000,
    5000000,
    6000000,
    7000000,
    8000000,
    9000000,
    10000000,
    20000000,
    30000000,
    40000000,
    50000000,
    priceRange[1]
  ]

  const min_size = [
    0,
    1000,
    5000,
    10000,
    20000,
    30000,
    40000,
    50000,
    60000,
    70000,
    80000,
    90000,
    100000,
    200000,
    300000,
    400000,
    500000,
    600000,
    700000,
    800000,
    900000,
    1000000,
    priceRange[0]
  ]
  
  const max_size = [
    1000,
    5000,
    10000,
    20000,
    30000,
    40000,
    50000,
    60000,
    70000,
    80000,
    90000,
    100000,
    200000,
    300000,
    400000,
    500000,
    600000,
    700000,
    800000,
    900000,
    1000000,
    priceRange[1]
  ]

  const default_min = name === "Size" ? min_size : min_price;
  const default_max = name === "Size" ? max_size : max_price;
 
  const handleChange = (e, label) => {
    let value = [];
    if(label === "min") {
      value = [e.target.value, priceRange[1]]
    } else {
      value = [priceRange[0], e.target.value]
    }
        
    if(setValue) {
      setValue(value)
    } else {
      setPriceRange(value);
    }
  }

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div className="multirange-info">
        <TextField
          id="outlined-min-price"
          select
          label={translate("ra.fieldName.min")}
          name="min-price"
          className="outlined-min-price dropdown-icon-wrapper"
          defaultValue={priceRange[0]}
          value={priceRange[0]}
          onChange={(e) => handleChange(e, "min")}
          SelectProps={{
            // restrict filter closing when opening and closing this
            onOpen : () => matches && setOpenDrop(true),
            onClose : () => matches && setOpenDrop(false),
            IconComponent : () => <i className="iconwasalt icon-arrow-down" />
          }}
        >
          {default_min?.map((amt, idx) =>
            <MenuItem 
              key={`${amt-idx}`}
              value={amt}
              className={((idx + 1) == default_min?.length ) ? styles.hidden_last_value : ""}
              disabled={amt >= priceRange[1]}
            >
              {new Intl.NumberFormat('en-US').format(amt)}
            </MenuItem>
          )}
        </TextField>

        <TextField
          id="outlined-max-price"
          select
          className="outlined-max-price dropdown-icon-wrapper"
          name="max-price"
          label={translate("ra.fieldName.max")}
          defaultValue={priceRange[1]}
          value={priceRange[1]}
          onChange={(e) => handleChange(e, "max")}
          SelectProps={{
            // restrict filter closing when opening and closing this
            onOpen : () => matches && setOpenDrop(true),
            onClose : () => matches && setOpenDrop(false),
            IconComponent : () => <i className="iconwasalt icon-arrow-down" />
          }}
        >
          {default_max?.map((amt, idx) => 
            <MenuItem 
              key={`${amt-idx}`} 
              value={amt}
              className={((idx + 1) == default_max?.length ) ? styles.hidden_last_value : ""}
              disabled={amt <= priceRange[0]}
            >
              {new Intl.NumberFormat('en-US').format(amt)}
            </MenuItem>
          )}
        </TextField>
      </div>
    </Box>
  );
}

export default MultiRangeSlider;