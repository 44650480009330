import React, { useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import styles from "../../Pages/NewPages/BDMDetails/styles.module.scss";
const FAQItem = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Accordion
      style={{
        border: "none",
        borderBottom: "1px solid #E9E9E9",
        boxShadow: "none",
      }}
      expanded={isExpanded}
      onChange={toggleExpansion}
      className="faqAccordion"
    >
      <AccordionSummary
        expandIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      >
        <Typography>
          <h5 className={styles.faqQuestion}>{question}</h5>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <p className={`${styles.faqAnswer} faqAnswer`}>{answer}</p>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default FAQItem;
