import React from 'react'
import { useTranslate } from 'react-admin';
import {
    Typography,
    Button
} from "@mui/material";
import ErrorComponent from "Components/Common/ErrorComponent";

const CustomerType = ({ customerType, onClickType, error }) => {
    const translate = useTranslate();
    return (
        <>
            <Typography className="select-type" component="div" >
                <Typography variant="p" component="p" >{translate("ra.fieldName.selectCustomer")}</Typography>
                <Button className={(customerType === 1) && "selected"} onClick={() => { onClickType(1) }}>{translate("ra.fieldName.individual")}</Button>
                <Button className={(customerType === 2) && "selected"} onClick={() => { onClickType(2) }}>{translate("ra.fieldName.company")}</Button>
            </Typography>
            {error &&
                <ErrorComponent className="customer-error" errors={error} />
            }
        </>
    )
}

export default CustomerType