import React, { useState, useEffect, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Grid, Container, Button, Typography } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import MultipleAutoCompleteComponent from 'Components/Common/MultipleAutoComplete'
import DoubleRangeSlider from 'Components/Common/RangeSlider/DoubleRangeSlider'
import MultiRangeSlider from 'Components/Common/MultiRangeSlider/MultiRangeSlider'
import BackButton from 'Components/BackButton'
import { addRemoveFromArray } from 'Utils/formArrayValues'
import { createQueryParams } from 'Utils/queryValues'
import { dataValueFromQuery, filterArrayData } from 'Utils/filterData'
import {
  countryBasedFilter,
  projectBasedFilter,
  SFProjectFilterManager,
} from 'Utils/projectFilter'
import { currencyStatus } from 'Utils/currencyCheck'
import DropdownArrow from 'images/dropdown-arrow.svg'
import MultipleButtonSelect from 'Components/Common/MultipleButtonSelect'
import { debounceFunction } from 'Utils/debounceFunction'
import gtagEventFire from 'Utils/ga4EventTrigger'

const Filter = ({
  filterData,
  userData,
  setFilterList,
  setShowFilters,
  params,
  page,
  setSearchField,
  setOpenDrop,
  getDataCount,
  count,
  callApiwithQuery,
  translate,
  notification,
}) => {
  const [countryDetails, setCountryDetails] = useState([])
  const [projectDetails, setProjectDetails] = useState([])
  const [projectFilterDetails, setProjectFilterDetails] = useState([])
  const [propertyTypeDetails, setPropertyTypeDetails] = useState([])
  const [bedroomDetails, setBedroomDetails] = useState([])
  const [statusDetails, setStatusDetails] = useState([])
  const [countryValues, setCountryValues] = useState([])
  const [projectValues, setProjectValues] = useState([])
  const [propertyTypeValues, setPropertyTypeValues] = useState([])
  const [bedroomValues, setBedroomValues] = useState([])
  const [statusValues, setStatusValues] = useState([])
  const [priceRange, setPriceRange] = useState([0, 50000000])
  const [sizeRange, setSizeRange] = useState([0, 1000000])
  const [openingCountry, setOpeningCountry] = useState(false)
  const [openingProject, setOpeningProject] = useState(false)
  const [countFilter, setCountFilter] = useState([])
  const [countLoading, setCountLoading] = useState(false)
  const [dataCount, setDataCount] = useState(0)

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  const { register } = useForm()

  useEffect(() => {
    if (filterData) {
      if (filterData?.projectList?.length) {
        setProjectDetails(filterData?.projectList || [])
        setProjectFilterDetails(filterData?.projectList || [])
      }
      setCountryDetails(filterData?.projectCountries || [])
    }
  }, [filterData])

  useEffect(() => {
    setDataCount(count)
  }, [])

  useEffect(() => {
    SFProjectFilterManager(
      projectValues,
      projectDetails,
      userData,
      filterData,
      page,
      propertyTypeValues,
      bedroomValues,
      statusValues,
      setPropertyTypeDetails,
      setBedroomDetails,
      setStatusDetails,
      setPropertyTypeValues,
      setBedroomValues,
      setStatusValues,
      notification
    )
  }, [projectDetails, projectValues, userData])

  useEffect(() => {
    //setting data in filters from query
    dataValueFromQuery(
      params,
      filterData,
      '',
      setCountryValues,
      setProjectValues,
      setPropertyTypeValues,
      setBedroomValues,
      setStatusValues,
      setPriceRange,
      setSizeRange
    )
  }, [])

  useEffect(() => {
    countryBasedFilter(
      countryValues,
      projectFilterDetails,
      setProjectDetails,
      projectValues,
      setProjectValues,
      filterData
    )
  }, [countryValues])

  const filterSelectHandler = (value, state, setState) => {
    const updatedState = [...state]
    addRemoveFromArray(value, updatedState, setState)
  }

  const filterArrayHandler = (setState) => {
    return filterArrayData(
      countryValues,
      projectValues,
      propertyTypeValues,
      bedroomValues,
      priceRange,
      sizeRange,
      statusValues,
      '',
      '',
      setState
    )
  }

  const gettingFilterCount = useCallback(
    debounceFunction(async function (countFilter) {
      const filterUrl = createQueryParams(
        countFilter,
        params?.sort,
        null,
        null,
        {},
        page,
        params?.view
      )
      getDataCount(setCountLoading, setDataCount, filterUrl?.params)
    }, 600),
    []
  )

  useEffect(() => {
    // calling count api whenever any filter value is selected
    filterArrayHandler(setCountFilter)
  }, [
    countryValues,
    projectValues,
    propertyTypeValues,
    bedroomValues,
    priceRange,
    sizeRange,
    statusValues,
  ])

  useEffect(() => {
   
    gettingFilterCount(countFilter)
  }, [countFilter])

  const gatag = (filterArrayList) => {
    const sizeval = filterArrayList?.filter(
      (val) => val?.filterName === 'Size'
    )?.value
    const priceval = filterArrayList?.filter(
      (val) => val?.filterName === 'Price'
    )?.value
    gtagEventFire(page === 'unitlist' ? 'unit_filter' : 'home_filter', {
      user_email: userData?.email,
      country: countryValues?.map((country) => country?.name).join(','),
      project: projectValues?.map((project) => project?.projecT_NAME).join(','),
      bedroom: bedroomValues?.join(','),
      price: priceval ? `min-${priceval[0]}, max-${priceval}` : '',
      size: sizeval ? `min-${sizeval[0]},  max-${sizeval[1]}` : '',
      status: statusValues?.join(','),
      type: propertyTypeValues?.join(','),
    })
  }

  const applyFilter = () => {
    const filterArrayList = filterArrayHandler()
    gatag(filterArrayList)
    localStorage.removeItem('projectPage')
    setFilterList(filterArrayList)
    setShowFilters(false)
    setSearchField('')
    callApiwithQuery(filterArrayList, null)
  }

  const clearAllHandler = () => {
    setFilterList([])
    setShowFilters(false)
    callApiwithQuery([], params?.countrysearch)
  }

  return (
    <>
      <Container className="filter-panels">
        <img
          className="dropdown-arrow"
          src={DropdownArrow}
          alt="Dropdown arrow"
        />
        <Grid
          className={`filter-panels-scroll ${
            openingCountry || openingProject ? `scroll-hidden` : ''
          }`}
        >
          <Grid className="for-mobile top-filter-panel">
            <BackButton close={() => setShowFilters(false)} />
            <Typography className="filter-mobile-section filter-heading-text">
              {translate('ra.fieldName.filter')}
            </Typography>
            <Button
              onClick={clearAllHandler}
              variant="outlined"
              className="reset-for-mobile"
              sx={{
                textTransform: 'none',
              }}
            >
              {translate('ra.button.reset')}
            </Button>
          </Grid>
          <MultipleAutoCompleteComponent
            id="checkboxes-country"
            title={translate('ra.fieldName.byCountry')}
            name="by_country"
            options={countryDetails}
            getOptionLabel={(option) => option?.name || ''}
            onClick={(option) => {
              filterSelectHandler(option, countryValues, setCountryValues)
            }}
            icon={icon}
            checkedIcon={checkedIcon}
            hide={true}
            register={register}
            setOpeningDropdown={setOpeningCountry}
            openingDropdown={openingCountry}
            count={countryValues?.length}
            placeholder={translate('ra.placeholder.selectCountry')}
            className="dropdown-select-panel"
            value={countryValues}
            selectedValues={countryValues?.map((list) => list.name)}
          />

          <MultipleAutoCompleteComponent
            id="checkboxes-project"
            title={translate('ra.fieldName.byProject')}
            name="by_project_name"
            options={projectDetails}
            getOptionLabel={(option) => option?.projecT_NAME || ''}
            onClick={(option) => {
              filterSelectHandler(option, projectValues, setProjectValues)
            }}
            icon={icon}
            checkedIcon={checkedIcon}
            hide={true}
            register={register}
            setOpeningDropdown={setOpeningProject}
            openingDropdown={openingProject}
            count={projectValues?.length}
            placeholder={translate('ra.placeholder.selectProject')}
            value={projectValues}
            selectedValues={projectValues?.map((list) => list.projecT_NAME)}
          />

          <Typography variant="subtitle1">
            {translate('ra.fieldName.price')}{' '}
            {currencyStatus(userData?.currency)
              ? `(${userData?.currency})`
              : `(USD)`}
          </Typography>
          <Grid className="filter-price-info">
            <MultiRangeSlider
              priceRange={priceRange}
              setPriceRange={setPriceRange}
              setOpenDrop={setOpenDrop}
              translate={translate}
            />
            <DoubleRangeSlider
              priceRange={priceRange}
              setPriceRange={setPriceRange}
              scaleAmount={500000}
              minDistance={100000}
              maxLabel={50000000}
            />
          </Grid>

          <Typography variant="subtitle1">
            {translate('ra.fieldName.size')}{' '}
            {userData?.unit_type ? `(${userData?.unit_type})` : ''}
          </Typography>
          <Grid className="filter-price-info">
            <MultiRangeSlider
              priceRange={sizeRange}
              setPriceRange={setSizeRange}
              name="Size"
              setOpenDrop={setOpenDrop}
              translate={translate}
            />
            <DoubleRangeSlider
              priceRange={sizeRange}
              setPriceRange={setSizeRange}
              scaleAmount={10000}
              minDistance={10000}
              maxLabel={1000000}
            />
          </Grid>
          {page === 'unitlist' && (
            <>
              {propertyTypeDetails?.length > 0 && (
                <MultipleButtonSelect
                  title={translate('ra.fieldName.propertyType')}
                  arrayData={propertyTypeDetails}
                  clickHandler={filterSelectHandler}
                  state={propertyTypeValues}
                  setState={setPropertyTypeValues}
                />
              )}
              {bedroomDetails?.length > 0 && (
                <MultipleButtonSelect
                  title={translate('ra.fieldName.bedroom')}
                  arrayData={bedroomDetails}
                  clickHandler={filterSelectHandler}
                  state={bedroomValues}
                  setState={setBedroomValues}
                />
              )}
            </>
          )}

          {statusDetails?.length > 0 && (
            <MultipleButtonSelect
              title={`${
                page
                  ? translate('ra.fieldName.unit')
                  : translate('ra.fieldName.project')
              } ${translate('ra.fieldName.status')}`}
              gridClass="last-filter-section"
              arrayData={statusDetails}
              clickHandler={filterSelectHandler}
              state={statusValues}
              setState={setStatusValues}
            />
          )}

          <Grid className="filter-button-panel" container spacing={0}>
            <Button
              onClick={clearAllHandler}
              variant="outlined"
              sx={{
                textTransform: 'none',
              }}
            >
              {translate('ra.button.reset')}
            </Button>

            <Button
              className="view-button"
              onClick={applyFilter}
              variant="contained"
            >
              {translate('ra.button.view')}{' '}
              {page !== 'unitlist'
                ? translate('ra.button.project')
                : translate('ra.button.unit')}{' '}
              ({dataCount})
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Filter
