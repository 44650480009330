import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { Button, ClickAwayListener, Tooltip } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useTranslate } from "react-admin";
import { Grid, Typography } from "@mui/material";
import nationality from "Services/nationalityService";
import country from "Services/countryService";
import { getLeadById } from 'Services/leadService';
import PdfPic from "images/pdf-file.svg";
import DeleteCustomerPopup from '../../Individual/DeleteCustomerPopup';
import DialogBox from 'Components/DialogBox';
import Loader from 'Components/Loader';
import { customerDelete, customerReviewSubmit } from 'Services/customerCreationandUpdation';
import { blockUnits } from 'Services/createBookingId';

const ReviewCompanyCustomer = ({ notification, id, customerType, onClickBack, bookingFailedPopup, setBookingFailedPopup, dialogboxcss }) => {
  const [userDetailsLoading, setuserDetailsLoading] = useState(false);
  const [nationalityLoading, setNationalityLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [nationalityList, setNationalityList] = useState([]);
  const [countryListLoading, setCountryListLoading] = useState();
  const [contactLoading, setContactLoading] = useState(true);
  const [formData, setFormData] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [nationalityValue, setNationalityValue] = useState();
  const [countryValue, setCountryValue] = useState();
  const [tradeCountryValue, setTradeCountryValue] = useState();
  const [dialogImage, setDialogImage] = useState();
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [bookingReleaseLoading, setBookingReleaseLoading] = useState(false);
  const portal_booking_id = localStorage.getItem("portalBookingId");

  const navigate = useNavigate();
  const location = useLocation();
  const translate = useTranslate();

  const units = location?.state?.units;
  const project_id = location?.state?.project_id;

  let headerPayload = {
    project_id,
    unit_ids: units?.map(unit => unit?.unitCode).join(","),
    portal_booking_id: portal_booking_id
  }

  useEffect(() => {
    nationality(setNationalityList, setNationalityLoading, notification);
    country(setCountryList, setCountryListLoading, notification);
    if(id) {
      getLeadById(setContactLoading, id, setFormData, notification, "company", "", portal_booking_id);
    } else {
      setContactLoading(false);
    }
    
    document.body.classList.add(
      'profile-page'
    );
    document.body.classList.add(
      'change-pass-page'
    );
    document.body.classList.add(
      'reservation-form'
    );
    return () => {
      document.body.classList.remove(
        'profile-page'
      );
      document.body.classList.remove(
        'change-pass-page'
      );
      document.body.classList.remove(
        'reservation-form'
      );
    };
  }, []);

  const editCustomer = () => {
    sessionStorage?.setItem("formStatus", "/booking-reservation-form");
    localStorage?.setItem("editPath",true);
    navigate("/booking-reservation-form", { state: { units, project_id } });
  }

  const callPostDelete = () => {
    setConfirmationPopup(false);
    localStorage?.removeItem("company_id");

    const apiRequestForBlock = {
      project_id: project_id,
      unit_ids: units?.map((unit) => unit?.unitCode)?.join(","),
      is_initial: 0
    }
    if(portal_booking_id) {
      apiRequestForBlock.portal_booking_id = parseInt(portal_booking_id)
    }
    blockUnits(setBookingReleaseLoading, apiRequestForBlock, notification, "", "", "", "", "/review-units", navigate, "", units, location?.state?.from);
  }

  const deleteCustomer = () => {
    customerDelete(setDeleteLoading, id, notification, setDeletePopup, setConfirmationPopup, JSON.stringify(headerPayload), bookingFailedPopup, setBookingFailedPopup,navigate)
  }

  useEffect(() => {
    const nation = nationalityList?.find((nationality) => {
      return nationality?.code == formData?.authorised_nationality
    })?.name
    setNationalityValue(nation);

    const country = countryList?.find((country) => {
      return country?.country_code == formData?.country
    })?.country_name
    setCountryValue(country);

    const tradeCountry = countryList?.find((country) => {
      return country?.country_code == formData?.trade_license_country
    })?.country_name
    setTradeCountryValue(tradeCountry);
  }, [formData, formData?.authorised_nationality, formData?.trade_license_country, formData?.country, nationalityList, countryList]);

  const onClickImage = (url, extension) => {
    if (extension === "pdf") {
      window.open(url);
    }
    else {
      setOpenImageDialog(true);
      setDialogImage(url);
    }
  }

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const onSubmitUserStatusApi = () => {
    customerReviewSubmit(setuserDetailsLoading, notification, units, project_id, portal_booking_id, JSON.stringify(headerPayload), navigate, bookingFailedPopup, setBookingFailedPopup)
  }

  return (
    <>
      {(deleteLoading || userDetailsLoading || nationalityLoading || countryListLoading || contactLoading || bookingReleaseLoading) && <Loader cls="form-loader-color" />}
      {!userDetailsLoading && !nationalityLoading && !countryListLoading && !contactLoading &&
        (<Grid className={`review-information ${dialogboxcss}`} sx={{ width: "100%" }}>
          <Grid className="booking-reservation booking-reservation-individual">
            <>
              <Grid className="booking-reservation-info">
                <h3>{translate("ra.fieldName.companyDetails")}</h3>
                <Typography component="div" className="authorised-details" >
                  <Grid>
                    <h6> {translate("ra.fieldName.companyName")}</h6>
                    <p>{formData?.company_name}</p>
                  </Grid>
                  <Grid>
                    <h6> {translate("ra.fieldName.company_address_line1")}</h6>
                    <p>{formData?.company_address_line_1}</p>
                  </Grid>
                  <Grid>
                    <h6> {translate("ra.fieldName.company_address_line2")}</h6>
                    <p>{formData?.company_address_line_2 || "-"}</p>
                  </Grid>
                  <Grid>
                    <h6>{translate("ra.fieldName.city")}</h6>
                    <p>{formData?.city}</p>
                  </Grid>
                  <Grid>
                    <h6>{translate("ra.fieldName.country")}</h6>
                    <p>{countryValue}</p>
                  </Grid>
                  <Grid>
                    <h6>{translate("ra.fieldName.postalPoBox")}</h6>
                    <p>{formData?.postal_code_po_box}</p>
                  </Grid>
                  <Grid>
                    <h6>{translate("ra.fieldName.tradeLicenseNumber")}</h6>
                    <p>{formData?.trade_license_number}</p>
                  </Grid>
                  <Grid>
                    <h6>{translate("ra.fieldName.tradeCountry")}</h6>
                    <p>{tradeCountryValue}</p>
                  </Grid>
                  <Grid className="documents-info-panel">
                    <h6>{translate("ra.fieldName.document")}</h6>
                    {formData?.documents?.map((document) => {
                      if (document?.doc_type === "trading_license") {
                        return (
                          <>
                            <a>
                              <img
                                onClick={() => onClickImage(document?.doc_url, document?.doc_extension)}
                                src={document?.doc_extension === "pdf" ? PdfPic : document?.doc_url}
                              />
                            </a>
                            <p><span>{document?.file_name}</span></p>
                          </>
                        )
                      }
                    })}
                  </Grid>
                </Typography>
                {formData?.documents?.filter(list => list?.doc_type === "additional")?.length > 0 &&
                  <Typography component="div" className="review-additional-details">
                    <h3>{translate("ra.fieldName.additionalDocDetails")}</h3>
                    <Typography component="div" className="authorised-details" >
                      <Grid className="documents-info-panel">
                        <h6>{translate("ra.fieldName.document")}</h6>
                        {formData?.documents?.map((document) => {
                          if (document?.doc_type === "additional") {
                            return (
                              <>
                                <a>
                                  <img
                                    onClick={() => onClickImage(document?.doc_url, document?.doc_extension)}
                                    src={document?.doc_extension === "pdf" ? PdfPic : document?.doc_url}
                                  />
                                </a>
                                <p><span>{document?.file_name}</span></p>
                              </>
                            )
                          }
                        })}
                      </Grid>
                    </Typography>
                  </Typography>
                }
              </Grid>
              <Grid className="booking-reservation-info">
                <h3>{translate("ra.fieldName.authorisedPersonsDetails")}</h3>
                <Typography component="div" className="authorised-details">
                  <Grid>
                    <h6>{translate("ra.fieldName.firstName")}</h6>
                    <p>{formData?.authorised_first_name || "-"}</p>
                  </Grid>
                  <Grid>
                    <h6>{translate("ra.fieldName.lastName")}</h6>
                    <p>{formData?.authorised_last_name || "-"}</p>
                  </Grid>
                  <Grid>
                    <h6>{translate("ra.fieldName.nationality") || "-"}</h6>
                    <p>{nationalityValue}</p>
                  </Grid>
                  <Grid>
                    <h6>{translate("ra.fieldName.mobileNo")}</h6>
                    <p>{formData?.authorised_mobile_no_country_code && "+"}{formData?.authorised_mobile_no_country_code} {formData?.authorised_mobile_no || "-"}</p>
                  </Grid>
                  <Grid>
                    <h6>{translate("ra.fieldName.alternativeNo")}</h6>
                    <p>{formData?.authorised_alt_mobile_no_country_code > 0 ? "+" : ""}{formData?.authorised_alt_mobile_no_country_code || ""} {formData?.authorised_alt_mobile_no || "-"}</p>
                  </Grid>
                  <Grid>
                    <h6>{translate("ra.fieldName.email")}</h6>
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <p className="email-text-info">
                        <Tooltip
                          arrow
                          open={openTooltip}
                          title={formData?.authorised_email}
                          onClose={handleTooltipClose}
                        >
                          <i
                            className="iconwasalt icon-eye"
                            onClick={handleTooltipOpen}
                            onMouseEnter={handleTooltipOpen}
                            onMouseLeave={handleTooltipClose}
                          />
                        </Tooltip>
                        <em>{formData?.authorised_email || "-"}</em>
                      </p>
                    </ClickAwayListener>
                  </Grid>
                  <Grid className="documents-info-panel">
                    <h6>{translate("ra.fieldName.document")}</h6>
                    {formData?.documents?.map((document) => {
                      if (document?.doc_type === "passport") {
                        return (
                          <>
                            <a>
                              <img
                                onClick={() => onClickImage(document?.doc_url, document?.doc_extension)}
                                src={document?.doc_extension === "pdf" ? PdfPic : document?.doc_url}
                              />
                            </a>
                            <p><span>{document?.file_name}</span></p>
                          </>
                        )
                      }
                    })}
                  </Grid>
                </Typography>
              </Grid>
              <Grid className="booking-reservation-info booking-reservation-info-last review-delete-panel">
                <h3>{translate("ra.fieldName.actions")}</h3>
                <Typography component="div" className="review-delete-section">
                  <Button onClick={() => editCustomer()}>
                    <i className="iconwasalt icon-edit-outline" />
                    {translate("ra.button.editCustomer")}
                  </Button>
                 <Button className="delete-button" onClick={() => setDeletePopup(true)}>
                    <i className="iconwasalt icon-delete" />
                    {translate("ra.button.deleteCustomer")}
                  </Button>
                </Typography>
              </Grid>
            </>
          </Grid>
          <Grid container className="company-details-button align-right" spacing={0}>
            <Button
              variant="outlined"
              onClick={() => onClickBack()}
              className="mobile-only"
            >
              {translate("ra.button.back")}
            </Button>
            <Button variant="contained" onClick={() => onSubmitUserStatusApi()}>
              {translate("ra.button.submit")}
            </Button>
          </Grid>
        </Grid>
        )}
      {deletePopup &&
        <DeleteCustomerPopup
          first_name={formData?.authorised_first_name}
          last_name={formData?.authorised_last_name}
          deleteCustomer={deleteCustomer}
          deletePopup={deletePopup}
          setDeletePopup={setDeletePopup}
          loading={deleteLoading}
          showSubtext={false}
        />
      }
      {confirmationPopup &&
        <DialogBox
          openPopup={confirmationPopup}
          setConfirmationPopup={setConfirmationPopup}
          closeBtn={true}
          closeBtnHandler={() => callPostDelete()}
          closeBtnClass="outline-button"
          dialogClass="delete-customer-popup"
          content={translate("ra.content.deletedCustomer")}
        />
      }

      {openImageDialog &&
        <Dialog
          aria-labelledby="customized-dialog-title"
          className="image-show-popup"
          keepMounted
          open={openImageDialog}
        >
          <i
            className="iconwasalt icon-close cross-button"
            onClick={() => setOpenImageDialog(false)}
          />
          <DialogContent>
            <img src={dialogImage} />
          </DialogContent>
        </Dialog>
      }
    </>
  )
}

export default ReviewCompanyCustomer;
