import React, { useState, useEffect } from "react";
import { Grid, Container, Button, Typography } from "@mui/material";
import BackButton from "Components/BackButton";
import DropdownArrow from "images/dropdown-arrow.svg";
import Title from "Components/Title/Title";
import { bookingStatus, contactStatus, percentPaid, roleData, timeStatus } from "Utils/constantValues";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { bookingContactFilter, bookingContactFromQuery } from "Utils/filterData";
import AutoCompleteComponent from "Components/Common/AutoComplete";
import { useLocation } from "react-router-dom";

const CommonFilter = ({ 
  setFilterList, 
  setShowFilter, 
  salesPersonList, 
  setSearchValue,
  setOpenDrop, 
  apiCallwithParams, 
  translate, 
  userData, 
  pageName, 
  path,
  tabChange
}) => {
  const [bookingValue, setBookingValue] = useState("All");
  const [percentValue, setPercentValue] = useState("All");
  const [timeValue, setTimeValue] = useState("Recent");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [salesPerson, setSalesPerson] = useState(null);

  const { register, formState: { errors } } = useForm();
  const location = useLocation();
  const param = new URLSearchParams(location?.search);
  const params = Object.fromEntries(param.entries());

  const changeHandler = (e, setState) => {
    setState(e.target.value);
  }

  useEffect(() => {
    bookingContactFromQuery(
      params,
      salesPersonList,
      setFilterList,
      setBookingValue,
      setPercentValue,
      setTimeValue,
      setStartDate,
      setEndDate,
      setSalesPerson,
      pageName
    )
  }, [])

  const clearAllHandler = () => {
    setFilterList([]);
    setShowFilter(false);
    apiCallwithParams([], params?.search, tabChange);
  }

  const applyFilter = () => {
    let filterData = bookingContactFilter(
      bookingValue,
      percentValue,
      timeValue,
      salesPerson,
      startDate,
      endDate,
      setFilterList,
      pageName,
      path
    );
    apiCallwithParams(filterData, null, tabChange);
    setSearchValue("");
    setShowFilter(false);
  }

  return (
    <Container className="filter-panels">
      <img className="dropdown-arrow" src={DropdownArrow} alt="Dropdown arrow" />
      <Grid className="filter-panels-scroll filter-padding-wrapper filter-panel-new-design">
        <Grid className="for-mobile top-filter-panel">
          <BackButton
            close={() => setShowFilter(false)}
          />
          <Typography className="filter-mobile-section filter-heading-text">{translate("ra.fieldName.filter")}</Typography>
          <Button
            onClick={clearAllHandler}
            variant="outlined"
            className="reset-for-mobile"
            sx={{
              textTransform: "none",
            }}
          >
            {translate("ra.button.reset")}
          </Button>
        </Grid>
        {path !== "my-bookings" &&
          <Title
            title={translate("ra.fieldName.bookingStatus")}
            name="booking_status"
            dropdownValue={pageName === "booking" ? bookingStatus : contactStatus}
            register={register}
            value={bookingValue}
            errors={errors}
            labelhide={true}
            hide={true}
            onChange={(e) => changeHandler(e, setBookingValue)}
            setOpenDrop={setOpenDrop}
          />
        }

        {pageName === "booking" &&
          <Title
            title={translate("ra.fieldName.percentPaid")}
            name="percent_paid"
            dropdownValue={percentPaid}
            register={register}
            value={percentValue}
            errors={errors}
            labelhide={true}
            hide={true}
            onChange={(e) => changeHandler(e, setPercentValue)}
            setOpenDrop={setOpenDrop}
          />
        }

        <Title
          title={translate("ra.fieldName.time")}
          name="time_status"
          dropdownValue={timeStatus}
          register={register}
          value={timeValue || "Recent"}
          errors={errors}
          labelhide={true}
          hide={true}
          onChange={(e) => changeHandler(e, setTimeValue)}
          setOpenDrop={setOpenDrop}
        />
        
        {timeValue === "Custom Range" &&
          <>
            <Typography className="start-end-text">
              {translate("ra.fieldName.customDate")}
            </Typography>
            <Grid className="start-end-panel">
              <Grid className="start-end-panel-info">
                <Typography variant="subtitle1">
                  {translate("ra.fieldName.startDate")}
                </Typography>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD/MM/YYYY"
                  maxDate={endDate}
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                />
              </Grid>
              <Grid className="start-end-panel-info end-year-dropdown">
                <Typography variant="subtitle1">
                  {translate("ra.fieldName.endDate")}
                </Typography>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  maxDate={new Date()}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="DD/MM/YYYY"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                />
              </Grid>
            </Grid>
          </>
        }

        {userData?.user_type === 2 && roleData.includes(userData?.role) &&
          <Grid className="new-select-class by-salesperson-dropdown" container spacing={0}>
            <AutoCompleteComponent
              title={translate("ra.fieldName.bySales")}
              value={salesPerson}
              onChange={(e, newInputValue) => {
                setSalesPerson(newInputValue);
              }}
              options={salesPersonList}
              className="filter-section-sales by-salesperson-dropdown"
              getOptionLabel={(option) => option?.name || ""}
              name="sales_person"
              placeholder={translate("ra.placeholder.salesSearch")}
              errors={errors?.sales_person}
              register={register}
              hide={true}
            />
          </Grid>
        }

        <Grid className="filter-button-panel new-filter-button-panel" container spacing={0}>
          <Button
            onClick={clearAllHandler}
            variant="outlined"
            sx={{
              textTransform: "none",
            }}
          >
            {translate("ra.button.reset")}
          </Button>

          <Button
            onClick={applyFilter}
            className="view-button"
            variant="contained"
          >
            {translate("ra.button.apply")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CommonFilter;
