import React, { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import {
  Button,
  Container,
  CardContent,
  Typography,
  Grid,
  Divider,
  Link,
} from "@mui/material";
import image from "../../images/brokers_image.png";
import TopHeader from "Components/Header/TopHeader";
import userDetails from "Services/userDetailsServices";
import BackButton from "Components/BackButton";
import styles from "./styles.module.scss";
import ContactDetails from "Components/ContactDetails";
import ProfileName from "Components/ProfileName";
import FAQItem from "Components/FAQ/FAQItem";
import faqList from "Services/faqServices";
import { useNavigate } from "react-router-dom";
import useWebVitals from "Utils/useWebVitals";
const Contactus = ({ path, notification }) => {
  const translate = useTranslate();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [faqData, setFaqData] = useState([]);
  const pageType = "contact-us";
  useWebVitals(pageType);
  useEffect(() => {
    const fetchData = async () => {
      //userDetails(setLoading, setUserData, "", notification);
      const faq = await faqList("contactUs");
      setFaqData(faq);
    };
    fetchData(); // Call the asynchronous function
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    document.body.classList.add("profile-page");
    return () => {
      document.body.classList.remove("profile-page");
    };
  }, []);
  const handleClick = (event) => {
    event.preventDefault();
    //window.location.href = '/home';
    navigate(-1);
  };
  return (
    <>
      <TopHeader />
      <Typography
        className="profile-top-info"
        variant="div"
        sx={{ display: "flex", alignItems: "left" }}
      >
        <Container
          id="back-button"
          className={`back-button-panel container-title contact-us-conatiner`}
        >
          <Button
            variant="text"
            sx={{
              textTransform: "none",
            }}
            onClick={handleClick}
          >
            <i className="iconwasalt icon-arrow-back-new"></i>
            <span className={"btn-title"}>{translate("ra.content.home")}</span>
          </Button>
        </Container>
      </Typography>

      <Container className="company-container mt-0 bdm-page-section">
        <Container className="company-details">
          <Grid
            className="form-panel profile-form-panel"
            sx={{ width: "100%" }}
          >
            <Grid container className="profile-top-panel contact-profile-panel">
              <Typography className="profile-top-info" variant="div">
                <Typography variant="h3">
                  {translate("ra.content.contact")}
                </Typography>
              </Typography>
            </Grid>

            <Grid container className={styles.profile_bottom_panel}>
              <Container className={styles.container_class}>
                <Grid className={styles.agent_wrapper}>
                  <Typography variant="div" className={styles.agent_subtext}>
                    {translate("ra.content.queries")}{" "}
                  </Typography>
                  <Typography
                    variant="div"
                    className={styles.agent_contact_text}
                  >
                    <Typography
                      variant="div"
                      className={styles.country_details}
                    >
                      <ContactDetails
                        country={translate("ra.content.supportTeam")}
                        email="agents@darglobal.co.uk"
                      />
                    </Typography>
                    <Typography
                      variant="div"
                      className={styles.country_details}
                    >
                      <ContactDetails
                        country={translate("ra.content.united")}
                        phone="+44 20 8156 5549"
                      />
                    </Typography>
                    <Typography
                      variant="div"
                      className={styles.country_details}
                    >
                      <ContactDetails
                        country={translate("ra.content.arab")}
                        phone="+971 4 247 8990"
                      />
                    </Typography>
                    <Typography
                      variant="div"
                      className={styles.country_details}
                    >
                      <ContactDetails
                        country={translate("ra.content.spain")}
                        phone="+34 951 12 12 25"
                      />
                    </Typography>
                  </Typography>
                </Grid>
                {faqData.length > 0 && (
                  <Grid className={styles.agent_wrapper}>
                    <Grid className={`${styles.FAQBanner}`}>
                      <h2>FAQs</h2>
                      <p>Find answers to commonly asked questions</p>
                    </Grid>
                    <Grid className={styles.FAQItem}>
                      {faqData.map((faqItem, index) => (
                        <FAQItem
                          key={index}
                          question={
                            <p
                              dangerouslySetInnerHTML={{
                                __html: faqItem.question,
                              }}
                            />
                          }
                          answer={
                            <p
                              dangerouslySetInnerHTML={{
                                __html: faqItem.answer,
                              }}
                            />
                          }
                        />
                      ))}
                    </Grid>
                  </Grid>
                )}
              </Container>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default Contactus;
