import { apiServiceRequest } from "Utils/axiosInstance";
import { getUserToken } from "Utils/tokenProvider";

const faqList = async (module) => {
  
  try {
    const response = await apiServiceRequest({
      url: `/faq/${module}`,
    });
    return response?.data;
  } catch (error) {
    console.error("Error fetching FAQ data:", error);
    throw error;
  }
};

export default faqList;
