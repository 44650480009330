import { apiServiceRequest } from "Utils/axiosInstance";
import { queryReviewPage } from "Utils/queryValues";
import { getUserToken } from "Utils/tokenProvider";

const customerIndivialService = async (
    setCustomerIndividualLoading,
    data,
    notify,
    setCustomerData,
    setTabIds,
    setOpenDialog,
    translate,
    headerPayload,
    bookingFailedPopup,
    setBookingFailedPopup
) => {
    
   const path =  localStorage.getItem("editPath");
     setCustomerIndividualLoading(true);
    const res = await apiServiceRequest({
        url: "/individual-customer",
        headers: { Authorization: `Bearer ${await getUserToken(notify)}`, "Content-Type": "multipart/form-data", payload: headerPayload },
        method: "post",
        data: data,
        notify: notify
    })
    if (res?.code === "success") {
        path && notify(translate("ra.notification.changesMade"), { type: "success" });
        let response = await setTabIds(res?.data?.id, res?.data);
        response && setOpenDialog(true);
        setCustomerData(res?.data);
        // localStorage.removeItem("editPath"); //jeeva   
    } else {
        if (res.message.includes("booking time")) {
            if (!bookingFailedPopup) setBookingFailedPopup(true);
        } else {
            notify(res.message, { type: "error" });
        }
    }
    setCustomerIndividualLoading(false);
}

const customerCompanyService = async (
    setCustomerCompanyLoading,
    data,
    notify,
    setCustomerData,
    customerData,
    navigate,
    units,
    project_id,
    translate,
    headerPayload,
    bookingFailedPopup,
    setBookingFailedPopup,
) => {
    setCustomerCompanyLoading(true);
    const companyId = localStorage.getItem("company_id") || null;
    const path =  localStorage.getItem("editPath");

    const res = await apiServiceRequest({
        url: "/company-customer",
        headers: { Authorization: `Bearer ${await getUserToken(notify)}`, "Content-Type": "multipart/form-data", payload: headerPayload },
        method: "post",
        data: data,
        notify: notify
    })
    if (res?.code === "success") {
        path && notify(translate("ra.notification.changesMade"), { type: "success" });
        navigate("/review-customer-information", { state: { units, project_id } });
        sessionStorage.setItem("formStatus", "/review-customer-information");
        localStorage.setItem("editCustomer", true);
        localStorage.removeItem("editPath");    

        if (!companyId && res?.data?.id) localStorage.setItem("company_id", res?.data?.id);
        setCustomerData(res?.data);
    }
    else {
        if (res.message.includes("booking time")) {
            if (!bookingFailedPopup) setBookingFailedPopup(true);
        } else {
            notify(res.message, { type: "error" });
        }
    }
    setCustomerCompanyLoading(false);
}

const customerDelete = async (setLoading, id, notify, setDeletePopup, setConfirmationPopup, headerPayload, bookingFailedPopup, setBookingFailedPopup
    ,navigate) => {
    const portalBookingID = localStorage.getItem("portalBookingId");
    const tabs=JSON.parse(localStorage.getItem("tabs"));
    const params = JSON.parse(localStorage.getItem("params")) || null;
    setLoading(true);

    const res = await apiServiceRequest({
        url: `/customer-units/${portalBookingID}/${id}`,
        method: "delete",
        headers: { Authorization: `Bearer ${await getUserToken(notify)}`, payload: headerPayload },
        notify: notify
    });

    if (res?.code === "success") {
        setDeletePopup(false);
        setConfirmationPopup && setConfirmationPopup(true);
        let reviewUnitState=JSON.parse(localStorage.getItem("reviewUnits"));
        if(tabs?.length==1||!tabs){
            navigate("/review-units",reviewUnitState );
        }
    }
    else {
        if (res.message.includes("booking time")) {
            if (!bookingFailedPopup) setBookingFailedPopup(true);
        } else {
            notify(res.message, { type: "error" });
        }
    }
    setLoading(false);
};

const customerReviewSubmit = async (setLoading, notify, units, project_id, portalBookingId, headerPayload, navigate,
    bookingFailedPopup, setBookingFailedPopup) => {
    setLoading(true);
    const res = await apiServiceRequest({
        url: `/submit-reservation/${portalBookingId}`,
        headers: { Authorization: `Bearer ${await getUserToken(notify)}`, "Content-Type": "multipart/form-data", payload: headerPayload },
        method: "put"
    })
    if (res?.code === "success") {
        navigate("/reservation-form-submitted", { state: { units, project_id } });
        sessionStorage.setItem("formStatus", "/reservation-form-submitted");
    }
    else {
        if (res.message.includes("booking time")) {
            if (!bookingFailedPopup) setBookingFailedPopup(true);
        } else {
            notify(res.message, { type: "error" });
        }
    }
    setLoading(false);
}

export { customerIndivialService, customerCompanyService, customerDelete, customerReviewSubmit }