import React, { useState, useEffect } from "react";
import { useTranslate } from "react-admin";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, Container, Button, Typography, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import Header from "Components/Header";
import BackButton from "Components/BackButton";
import InputField from "Components/InputField";
import Loader from "Components/Loader";
import ErrorComponent from "Components/Common/ErrorComponent";
import { docTypes } from "Utils/constantValues";
import createInvoice from "Services/createInvoice";
import { formatSizeUnits } from "Utils/commonFunctions";

const RaiseInvoice = ({ notification, userLoading, userData }) => {
  const [formData, setFormData] = useState(null);
  const [fileData, setFileData] = useState([]);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    clearErrors,
    watch,
    setError,
  } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state;
  const translate = useTranslate();

  useEffect(() => {
    if (!state) {
      navigate("/home");
    }
    document.body.classList.add("profile-page");
    return () => {
      document.body.classList.remove("profile-page");
    };
  }, []);

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const fileUploadHanlder = (e) => {
    if (e.target.files?.length) {
      let fileSizeValue = e.target.files[0]?.size || 0;

      //checking for file type
      if (docTypes.includes(e.target.files[0]?.type)) {
        // checking for file size
        if (Math.round(fileSizeValue / 1024) <= 10240) {
          setFileData((prev) => {
            return [...prev, e.target.files[0]];
          });
          clearErrors("attach_invoice");
        } else {
          notification("ra.notification.fileSizeCheck", { type: "error" });
        }
      } else {
        notification("ra.notification.fileTypeCheck", { type: "error" });
      }
    }
  };

  useEffect(() => {
    if (fileData?.length === 1) {
      clearErrors("attach_invoice");
    }
  }, [fileData?.length]);

  const onSubmit = (data) => {
    if (fileData?.length > 0) {
      const { request_purpose, description } = data;
      const formData = new FormData();

      formData.append("unit_id", state?.unit_id);
      formData.append("unit_no", state?.unit_no);
      formData.append("oracle_reservation_id", state?.reservation_id);
      formData.append("portal_booking_id", state?.portal_booking_id);
      formData.append("project_name", state?.project_name);
      formData.append("purpose_of_interest", request_purpose);
      formData.append("customer_name", state?.customer_name);
      description && formData.append("description", description);
      fileData?.map((file) => formData.append("file", file));

      createInvoice(
        setInvoiceLoading,
        formData,
        notification,
        navigate,
        state?.unit_no
      );
    } else {
      setError("attach_invoice", {
        type: "required",
        message: translate("ra.validation.valid_invoice"),
      });
    }
  };

  const deleteFile = (index) => {
    let files = [...fileData];
    files = files?.filter((file, i) => i !== index);
    setFileData(files);
  };

  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  return (
    <>
      <Header
        path="My Bookings"
        picture={userData?.profile}
        pictureLoading={userLoading}
        notification={notification}
        userData={userData}
      />
      {invoiceLoading && <Loader />}
      {!invoiceLoading && (
        <>
          <BackButton
            container_class={`container-title back-white-bg raise-back-button`}
            title_class="btn-title"
            sx={{
              textTransform: "none",
            }}
          />
          <Container className="company-container new-fix-container lead-panel-section raise-full-panel mt-0">
            <Container className="company-details raise-invoice-panel">
              <Grid
                className="form-panel profile-form-panel add-team-panel"
                sx={{ width: "100%" }}
              >
                <Grid className="raise-invoice-section">
                  <Typography variant="h2">
                    {translate("ra.fieldName.raiseInvoice")}
                  </Typography>
                  <Typography variant="subtitle1">
                    {translate("ra.fieldName.customerName")}
                  </Typography>
                  <Typography variant="h4">{state?.customer_name}</Typography>
                  <Typography variant="subtitle1">
                    {translate("ra.fieldName.unit_no")}
                  </Typography>
                  <Typography variant="h4">{state?.unit_no}</Typography>

                  <Grid className="hide-label new-input" container spacing={0}>
                    <InputField
                      name={translate("ra.fieldName.requestPurpose")}
                      register={register}
                      errors={errors}
                      value={translate("ra.fieldName.invoiceAgainst", {
                        unit: state?.unit_no,
                      })}
                      state="request_purpose"
                      onChange={changeHandler}
                      placeholderHide={true}
                      required={false}
                      hide={true}
                      readOnly={true}
                    />
                  </Grid>

                  <Grid className="hide-label new-input" container spacing={0}>
                    <Typography variant="subtitle1">
                      {translate("ra.fieldName.description")}
                    </Typography>
                    <textarea
                      name="description"
                      rows="4"
                      cols="50"
                      placeholder={translate(
                        "ra.placeholder.requestDescription"
                      )}
                      {...register("description")}
                      maxLength={75}
                    ></textarea>
                    <span
                      className={watch("description")?.length >= 75 && "error"}
                    >
                      {watch("description")?.length}/75
                    </span>
                  </Grid>

                  <Grid className="upload-section" container spacing={0}>
                    <Grid item xs={12}>
                      <Typography className="form_label" variant="subtitle1">
                        {translate("ra.fieldName.attachInvoice")}
                        <span style={{ color: "#EF4349" }}>*</span>
                      </Typography>
                      {fileData?.map((file, index) => {
                        return (
                          <Stack
                            key={index}
                            className="document-add-panel raise-doc-invoice mb20"
                          >
                            <Typography
                              className="form_label"
                              variant="subtitle1"
                              mb={1}
                            >
                              {file?.name}
                            </Typography>
                            <p className="raise-invoice-wrapper">
                              <span className="doc-file-size">
                                {formatSizeUnits(file?.size ? file?.size : "")}
                              </span>
                              <i
                                className={`iconwasalt icon-delete`}
                                onClick={() => {
                                  deleteFile(index);
                                }}
                              ></i>
                            </p>
                          </Stack>
                        );
                      })}
                      {fileData?.length < 10 && (
                        <Button variant="contained" component="label" fullWidth>
                          <i className="iconwasalt icon-add-file" />
                          {translate("ra.fieldName.invoiceDetail", {
                            unit: state?.unit_no,
                          })}
                          <input
                            type="file"
                            name="attach_invoice"
                            hidden
                            accept="application/pdf, image/png, image/jpeg"
                            onChange={fileUploadHanlder}
                            onClick={handleClick}
                          />
                        </Button>
                      )}
                    </Grid>
                    {errors?.attach_invoice?.message && (
                      <ErrorComponent
                        className="Mui-error"
                        errors={errors?.attach_invoice?.message}
                      />
                    )}
                  </Grid>

                  <Grid
                    container
                    className="company-details-button"
                    spacing={0}
                  >
                    <Button
                      variant="contained"
                      className="new-color-button"
                      onClick={handleSubmit(onSubmit)}
                    >
                      {translate("ra.button.submit")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </>
      )}
    </>
  );
};

export default RaiseInvoice;
