import { AccordionDetails, Checkbox, Slider, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslate } from 'react-admin';

function NewAccordionContent({
    data=[],
    isChecked=false,
    max=100,
    min=0,
    defaultValue=0,
    currency="",
    setData,
    setunitPercentageObj,
    percentageObj,
    unitCode
}) {
    const [sliderValue,setSliderValue]=useState(0);
    const translate = useTranslate();
    const onChangingCheckBox=(value,unitId,customerUnitId)=>{
        // new code
        let mappedValue=data?.map(_=>{
          if(_?.unit_id==unitId&&_?.customer_unit_id==customerUnitId){
           return{
            ..._,
            is_primary_user:value==1?0:1
           }
          }else{
            return{
              ..._,
              is_primary_user:0
             }
          }

         
        }
      )
      setData(mappedValue);
    }
    const onChangeSliderValue=(e,value,unitId,customerUnitId)=>{
      //   //new code
      // console.log(value,"eee");
      //   console.log(data,"datadata");
        let mappedValue=data?.map(_=>{
          if(_?.unit_id==unitId&&_?.customer_unit_id==customerUnitId){
           return{
            ..._,
            ownership_percentage:value
           }
          }
          if(_?.unit_id==unitId&&_?.customer_unit_id!=customerUnitId){
            return {
              ..._,
              ownership_percentage:(100-value)/(data?.length-1)
            }
          }
          return _
        }
      )
      setData(mappedValue);
    }
 

  return (
    <>
          {data?.filter(_=>_?.unit_id==unitCode)?.map((item, index) => {
          return (
            <AccordionDetails key={index}>
              <Typography component="div" className="assign-persentage-heading">
                <Typography component="p">
                  {item?.first_name} {item?.last_name}
                </Typography>
                <Typography component="p" onClick={() => onChangingCheckBox(item?.is_primary_user,item?.unit_id,item?.customer_unit_id)}>
                   <Checkbox
                        checked={ item?.is_primary_user? true : false}
                        onClick={() => onChangingCheckBox(item?.is_primary_user,item?.unit_id,item?.customer_unit_id)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        label="Primary"
                    />
                  {translate("ra.fieldName.primaryOnly")}
                </Typography>
              </Typography>
              <Typography component="div" className="assign-persentage-info">
              {currency && (<span> {currency} {sliderValue}</span>)}
                <Slider
                    defaultValue={defaultValue}
                    aria-label="Default"
                    valueLabelDisplay="off"
                    step={1}
                    value={item?.ownership_percentage}
                    onChange={(event, value) => {
                    onChangeSliderValue(event, value,item?.unit_id,item?.customer_unit_id);
                    }}
                    max={max}
                    min={min}
                />
                <span>
            {item?.ownership_percentage||0}%
            </span>
                {/* {currency && (
        <span>
            {currency} {min}
            </span>
            )}
            {currency && (
                <span>
                {currency} {max}
                </span>
            )}
            {!currency && (
                <span>
                {sliderValue}&#37;
                </span>
            )}     */}
              </Typography>
            </AccordionDetails>
            )})}
            </>
  )
}

export default NewAccordionContent