import React from "react";
import { useRecordContext } from "react-admin";
import { Checkbox } from "@material-ui/core";

const CheckBoxField = ({ projectSelectHandler, selectedProject }) => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <Checkbox 
            onClick={(e) => projectSelectHandler(e, record?.unit_no)}
            checked={selectedProject?.includes(record?.unit_no)}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }} 
        />
    )
  };
  
  export default CheckBoxField;