import { apiServiceRequest } from "Utils/axiosInstance";
import { getUserToken } from "Utils/tokenProvider";
import { blockUnits } from "./createBookingId";

// user Details Service
const userDetails = async (
  setuserDetailsLoading,
  setFormData,
  setUserDetails,
  notify
) => {
  const formStatus = sessionStorage.getItem("formStatus") || null;
  const editCustomer = JSON.parse(localStorage.getItem("editCustomer")) || false;
  const bookingState = localStorage.getItem("bookingState") ? JSON.parse(localStorage.getItem("bookingState")) : null;
  const portalBookingId = localStorage.getItem("portalBookingId") || null;
  const previous_url = localStorage.getItem("previous_url") || null;
  const booking_page = localStorage.getItem("booking_page") || null;

  if(setuserDetailsLoading) setuserDetailsLoading(true);
  const res = await apiServiceRequest({
    url: `/getUserDetails`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });
  const resData = res?.data?.details;
  if(resData?.oracle_status?.toLowerCase() === "inprocess"){
      
      //Modify the oracle_status property because product requirment 
      // inprocess will work as set_to_oracle
      resData.oracle_status = "sent_to_oracle";
  }
  
  if (setFormData) setFormData(resData);
  if (setUserDetails) setUserDetails(resData);
  if(setuserDetailsLoading) setuserDetailsLoading(false);

  if(!formStatus && !editCustomer && bookingState && (portalBookingId || previous_url === "/review-units")) {
    // blocked units are released when user navigates from booking form first time or closes tab
    const apiRequestForBlock = {
      project_id: bookingState?.project_id,
      unit_ids: bookingState?.units?.map((unit) => unit?.unitCode)?.join(","),
      is_initial: 0,
    }
    if(portalBookingId) {
      apiRequestForBlock.portal_booking_id = parseInt(portalBookingId)
    }
    blockUnits("", apiRequestForBlock, notify);
    localStorage.removeItem("page");
    localStorage.removeItem("params");
    localStorage.removeItem("lockIds");
  }

  if(!formStatus && editCustomer && !previous_url && !booking_page) {
    localStorage.setItem("editCustomer", false);
    localStorage.removeItem("bookingState");
  }

  if(!portalBookingId ) {
    sessionStorage.removeItem("formStatus");
    localStorage.removeItem("booking_page");
  }

  return resData;
};


export default userDetails;
