import jwt_decode from "jwt-decode";
import { apiServiceRequest } from "./axiosInstance";

// Setting token with timestamp
export const setUserToken = (token, refresh_token) => {
    if (token) {
        localStorage.setItem(
            "token",
            JSON.stringify({
                ts: Date.now(),
                token: token,
                refresh_token: refresh_token
            })
        );
    }
};

// Getting token with timestamp
export const getUserToken = async (notify) => {
    const token = localStorage.getItem("token");

    if (token !== null) {
        const parsedToken = JSON.parse(token);

        const decoded = jwt_decode(parsedToken?.token);
        // Checking whether token expires and calling refresh token
        if ((Date.now() - parsedToken.ts)  > decoded?.exp) {
            let tokenValue = await refreshToken(parsedToken?.refresh_token, notify)
            
            setUserToken(tokenValue?.token, tokenValue?.refresh_token);
            return tokenValue?.token;
        }
        return parsedToken.token;
    }
    return "";
};

export const refreshToken = async(token, notify) => {
    let payload = {
        token : token
    }
    const res = await apiServiceRequest({
        url: `refresh-token`,
        method: "post",
        data: payload,
        notify: notify
    });

    if(res?.code === "success") {
        return res?.data;
    } else {
        localStorage.clear();
        if(notify) notify("ra.apiError.logout", { type: "error" })
        setTimeout(() => {
            window.location.replace('/')
        }, 2000)
    }
}
