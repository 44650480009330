import React from "react";
import { useRecordContext } from "react-admin";

const DetailFeild = ({ nameClass, roleClass, boldClass }) => {
    const record = useRecordContext();
    if (!record) return null;

  return (
      <ul className="table-info-section">
        <li className={`font-bold ${nameClass}`}>{record?.first_name} {record?.last_name}</li>
        <li className={`${roleClass} ${boldClass}`}>{record?.designation}</li>
        <li className={roleClass}>{record?.role}</li>
      </ul>
    );
  };
  
  export default DetailFeild;