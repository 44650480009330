// utils/useWebVitals.js
import { useEffect } from "react";
import { reportWebVitals } from "./reportWebVitals";
import gtagEventFire from "./ga4EventTrigger";
import { eventsSaveInDB } from "./utils";
const useWebVitals = (pageType) => {
  useEffect(() => {
    reportWebVitals((metric) => sendToGoogleAnalytics(metric, pageType));
    reportWebVitals((metric) => eventsSaveInDB(metric, pageType));
  }, [pageType]);

  const sendToGoogleAnalytics = ({ name, delta }, pageType) => {
    const WebVitals = {
      [name]: Math.round(delta),
      url: window.location.href,
      PageType: pageType,
    };

    if (Object.keys(WebVitals).length > 0) {
      gtagEventFire("WebVitals", WebVitals);
    }
  };
};
export default useWebVitals;
