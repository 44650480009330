import * as React from 'react';
import { useRedirect, useTranslate } from 'react-admin';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Loader from 'Components/Loader';
import { removeLocalStorage } from 'Utils/removeStorage';

export default function ExitCustomerPopup({ 
    showBookingIncomplete, 
    setShowBookingIncomplete, 
    onlinePaymentLoading, 
    callApi, 
    value 
}) {
    const translate = useTranslate();
    const redirect = useRedirect();

    const callDetailsApi = () => {
        if(callApi) {
            callApi(value == "1" ? "online" : "offline");
        }
    }

    const redirectPath = () => {
        removeLocalStorage();
        localStorage.removeItem("page");
        localStorage.removeItem("params");
        localStorage.removeItem("page-redirect");
        redirect("/my-bookings");
    }

    return (
        <Dialog
            open={showBookingIncomplete}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            className={`new-popup-box remove-popup exit-customer`}
        >
            <i
                className="iconwasalt icon-close cross-button"
                onClick={() => setShowBookingIncomplete(false)}
            />
            {onlinePaymentLoading && <Loader />}
            <DialogContent>
                <i className="iconwasalt icon-error" />
                <DialogContentText id="alert-dialog-slide-description">
                    {translate("ra.pageTitle.bookingIncomplete")}
                    <DialogContent>
                        <p>{translate("ra.content.bookingIncompletemsg")}</p>
                    </DialogContent>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className="outline-button remove-button" onClick={() => redirectPath()}>
                    {translate("ra.button.exitBooking")}
                </Button>
                <Button className="outline-button" onClick={callDetailsApi}>
                    {translate("ra.button.shareBooking")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
