import React from "react";
import { Typography, TextField, Autocomplete, Checkbox, InputAdornment } from "@mui/material";
import { useTranslate } from "react-admin";

const MultipleAutoCompleteComponent = ({
  formData,
  id,
  title,
  name,
  value,
  onClick,
  options,
  placeholder,
  children,
  register,
  readOnly,
  getOptionLabel,
  getOptionDisabled,
  hide = false,
  icon,
  checkedIcon,
  count,
  openingDropdown,
  setOpeningDropdown,
  className,
  defaultValue,
  selectedValues
}) => {
  const translate = useTranslate();

  return (
    <>
      <Typography variant="subtitle1">
        {title}
        {!hide && <span style={{ color: "#EF4349" }}>*</span>}
      </Typography>

      {children}

      <Autocomplete
        multiple
        disableCloseOnSelect
        popupIcon={
          <div className="select-drop-arrow" >
            <i className="iconwasalt icon-arrow-down" />
          </div>
        }
        id={id}
        getOptionDisabled={getOptionDisabled}
        onOpen={() => setOpeningDropdown(true)}
        onClose={() => setOpeningDropdown(false)} 
        value={[]}
        options={options}
        readOnly={readOnly}
        getOptionLabel={getOptionLabel}
        {...register(name, {
          required: !hide && translate("ra.validation.validInput"),
        })}
        renderOption={(props, option, { selected }) => (
          <div 
            className={
              defaultValue?.includes(option) 
              ? "" : value?.includes(option) 
              ? "active-class" : ""
            }
            onClick={() => onClick(option)}
          >
            <ul key={option}>
              <li
                {...props}
                id={defaultValue?.includes(option) ? "disabled" : "active"}
              >
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={value?.includes(option)}
                  disabled={defaultValue?.includes(option)}
                  {...register(name)}
                />
                {option?.name || option?.projecT_NAME || option}
              </li>
            </ul>
          </div>
        )}
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={
              selectedValues?.length > 0 
              ? selectedValues?.join(", ") 
              : count && !openingDropdown 
              ? `${count} Selected` 
              : placeholder
            }
          />
        )}
      />
    </>
  );
};

export default MultipleAutoCompleteComponent;
