import React, { useState, useEffect } from "react";
import { useStore, useTranslate, useLogout } from "react-admin";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Header from "Components/Header";
import userDetails from "Services/userDetailsServices";
import BackButton from "Components/BackButton";
import IndividualCustomerForm from "./Individual/IndividualCustomerForm";
import CompanyCustomerForm from "./Company/CompanyCustomerForm";
import { CircularProgress, Container } from "@material-ui/core";
import LeadPic from "images/lead-pic.png";
import ReviewIndividualCustomer from "./Individual/IndividualCustomerForm/ReviewIndividualCustomer";
import ReviewCompanyCustomer from "./Company/ReviewCompanyCustomer";
import DialogBox from "Components/DialogBox";
import Loader from "Components/Loader";
import { blockUnits } from "Services/createBookingId";
import { backNavigation } from "Utils/backNavigation";

export default function TabStructure({ notification, path, bookingFailedPopup, setBookingFailedPopup }) {
  const location = useLocation();
  const navigate = useNavigate();
  const customerType = localStorage.getItem("customerType");
  const bookingState = JSON.parse(localStorage.getItem("bookingState")) || null;
  const project_id = location?.state?.project_id || bookingState?.project_id;
  const units = location?.state?.units || bookingState?.units;
  const state = location?.state || bookingState;
  const [userDetailsLoading, setuserDetailsLoading] = useState(true);
  const storageTabs = JSON.parse(localStorage.getItem("tabs"));
  const [userData, setUserData] = useState(null);
  const [selectedTab, setSelectedTab] = useState("1");
  const [openPopup, setOpenPopup] = useState(false);
  const [tabIndex, setTabIndex] = useState(1);
  const [changeIndex, setChangeIndex] = useState();
  const [tabs, setTabs] = useState(storageTabs?.length ? [] : [{ value: "1" }]);
  const [customerData, setCustomerData] = useState([]);
  const [progress, setProgress] = useStore("progress", []);
  const companyId = localStorage.getItem("company_id") || null;
  const [errorVal, setErrorVal] = useState([]);
  const [openBackPopup, setOpenBackPopup] = useState(false);
  const [backHeaderPopup, setBackHeaderPopup] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [bookingReleaseLoading, setBookingReleaseLoading] = useState(false);
  const [isTabChange, setIsTabChange] = useState(false);
  const previous_url = localStorage.getItem("previous_url") || null;
  const portalBookingId = localStorage.getItem("portalBookingId") || null;
  const formStatus = sessionStorage.getItem("formStatus") || null;
  const editCustomer = JSON.parse(localStorage.getItem("editCustomer")) || false;
  const booking_page = localStorage.getItem("booking_page") || null;

  const [isFormTriggered,setIsFormTriggered]=useState(false);

  const translate = useTranslate();
  const logout = useLogout();

  const handleChange = (event, newValue) => {
    if (path === "booking") {
      setSelectedTab(newValue)
    } else {
      isTabChange ? setOpenPopup(true) : setSelectedTab(newValue);
      setChangeIndex(newValue);
    }
  };

  useEffect(() => {
    if(!formStatus) {
      navigate("/home");
    }

    if(formStatus && formStatus !== "/booking-reservation-form") {
      navigate(formStatus, { state: { units, project_id } });
    }

    if(!bookingState && state) {
        localStorage.setItem("bookingState", JSON.stringify(state));
    }

    if (tabs?.length < storageTabs?.length) {
      if (!storageTabs?.[storageTabs?.length - 1]?.id) {
        setSelectedTab(`${(storageTabs?.length)?.toString()}`)
      }
      setTabs(storageTabs)
      setTabIndex(storageTabs?.length)
    }
    userDetails(setuserDetailsLoading, setUserData, "", notification);
  }, []);


  const createNewTab = () => {
    const newTab = { value: `${tabIndex + 1}` };
    setTabs([...tabs, newTab]);
    setSelectedTab(`${tabIndex + 1}`);
    setTabIndex(tabIndex + 1);
  }

  const setTabName = (value, firstName, lastName, companyName) => {
    const tabArray = [...tabs];
    if (customerType == 1) {
      const newTab = tabArray?.find((tab, index) => tab?.value === value);
      firstName = firstName?.charAt(0).toUpperCase();
      lastName = lastName?.charAt(0).toUpperCase();
      newTab.name = firstName + lastName;
      tabArray[value - 1] = newTab;
    }
    else {
      tabArray[0].name = companyName?.slice(0, 2).toUpperCase();
    }
    setTabs(tabArray);
  };

  const onClickBack = () => {
    localStorage.setItem("tabs", JSON.stringify(tabs?.filter((tab) => tab?.id)));
    setOpenBackPopup(false);
    if(previous_url === "/review-units" && formStatus === "/booking-reservation-form") {
      releaseBlockUnits(false);
    } else {
      let previousUrl = backNavigation(location.pathname, tabs, previous_url);
      navigate(previousUrl, { state: { units, project_id } });
    }
    setBackHeaderPopup(false);
  }

  const onClickYes = () => {
    setSelectedTab(changeIndex);
    setOpenPopup(false);
    setBackHeaderPopup(false);
    setErrorVal([]);
  }
  
  const releaseBlockUnits = (customerEdit) => {
    if(!customerEdit && formStatus === "/booking-reservation-form" && redirectUrl !== "/choose-contacts") {
      const apiRequestForBlock = {
        project_id: project_id,
        unit_ids: units?.map((unit) => unit?.unitCode)?.join(",")|| portalBookingId,
        is_initial: 0
      }
      if(portalBookingId) {
        apiRequestForBlock.portal_booking_id = parseInt(portalBookingId)
      }
      setBackHeaderPopup(false);
      blockUnits(setBookingReleaseLoading, apiRequestForBlock, notification, "", "", "", "", redirectUrl, navigate, logout, units);
    } else {
      onClickBack();
    }
  }
  const headerPopupClick = (url) => {
    setBackHeaderPopup(true);
    setRedirectUrl(url);
  }

  useEffect(() => {
    let bookingState = JSON.parse(localStorage.getItem("bookingState")) || state;
    if(formStatus === "/booking-reservation-form" && !booking_page) {
      navigate(location.href, { state: bookingState });
      window.onpopstate = function (e) {
        e.preventDefault();
        setBackHeaderPopup(true);
        setRedirectUrl(previous_url);
        window.history.go(1);
      }
    }
  }, [])
console.log(tabs,"tabs");

  return (
    <>
      <Header
        path="My Bookings"
        picture={userData?.profile}
        pictureLoading={userDetailsLoading}
        notification={notification}
        userData={userData}
        releaseBlockUnits={(!editCustomer && (formStatus === "/booking-reservation-form")) ? headerPopupClick : null}
        pagePath={(!editCustomer && (formStatus === "/booking-reservation-form")) ? "form-booking" : ""}
      />
      {!booking_page &&
        <BackButton close={() => path !== "booking" ? headerPopupClick(previous_url) : setOpenBackPopup(true)} />
      }
      {(userDetailsLoading || bookingReleaseLoading) && <Loader cls="form-loader-color" />}
      {!userDetailsLoading && (
        <Container className="company-container new-fix-container lead-panel-section lead-white-section mt-0">
          <Container className="company-details">
            <Grid
              className="form-panel profile-form-panel add-team-panel lead-form-panel booking-reservation-panel"
              sx={{ width: "100%" }}
            >
              {path === "booking" &&
                <>
                  {customerType === "2" ? (
                    <>
                      <Box className="reservation-top-section form-section-wrapper single-customer-wrapper">
                        <h3>
                          {translate("ra.fieldName.reviewCustomer")}
                        </h3>
                      </Box>
                      <ReviewCompanyCustomer
                        units={units}
                        project_id={project_id}
                        id={companyId || storageTabs?.[0]?.id}
                        customerType={customerType}
                        onClickBack={() => setOpenBackPopup(true)}
                        notification={notification}
                        setBookingFailedPopup={setBookingFailedPopup}
                        bookingFailedPopup={bookingFailedPopup}
                        dialogboxcss="single-customer-tab"
                      />
                    </>
                  ) : (
                    tabs?.length === 1 && (
                      <>
                        <Box className="reservation-top-section form-section-wrapper single-customer-wrapper">
                          <h3>
                            {translate("ra.fieldName.reviewCustomer")}
                          </h3>
                        </Box>
                        <ReviewIndividualCustomer
                          units={units}
                          project_id={project_id}
                          id={storageTabs?.[0]?.id}
                          customerType={customerType}
                          onClickBack={() => setOpenBackPopup(true)}
                          tabs={tabs}
                          panelValue={1}
                          setSelectedTab={setSelectedTab}
                          setBookingFailedPopup={setBookingFailedPopup}
                          bookingFailedPopup={bookingFailedPopup}
                          dialogboxcss="single-customer-tab"
                        />
                      </>
                    )
                  )
                  }
                </>
              }
              {(path !== "booking" ||
                (path === "booking" && tabs?.length > 1)) && (
                  <TabContext value={selectedTab}>
                    <Box className={`reservation-top-section form-section-wrapper ${path === "booking" ? "single-customer-wrapper" : ""}`}>
                      <h3>
                        {path === "booking"
                          ? "Review Customer Information"
                          : "Reservation Form"}
                      </h3>
                      <TabList onChange={handleChange}>
                        {customerType === "1" ? (
                          tabs?.map((tab, index) => {
                            return (
                              <Tab
                                key={tab?.value}
                                value={`${tab?.value}`}
                                label={
                                  <Grid className="reservation-border-section">
                                    <Grid className="reservation-image-section absolute">
                                      <Typography
                                        component="p"
                                        className={`lead-pic ${errorVal?.[index] ? "error" : "success"
                                          }`}
                                      >
                                        {tab?.name ? (
                                          <strong>{tab?.name}</strong>
                                        ) : (
                                          <img src={LeadPic} alt="lead pic" />
                                        )}
                                        {(progress?.[index] === 100 ||
                                          errorVal?.[index]) && (
                                            <Typography component="span">
                                              <i
                                                className={`iconwasalt icon-${errorVal?.[index]
                                                  ? "error"
                                                  : "check"
                                                  }`}
                                              ></i>
                                            </Typography>
                                          )}
                                      </Typography>
                                    </Grid>
                                    <CircularProgress
                                      className={`trial ${errorVal?.[index] && "error"}`}
                                      variant="determinate"
                                      value={
                                        errorVal?.[index]
                                          ? "100"
                                          : progress?.[index]
                                      }
                                    />
                                  </Grid>
                                }
                              />
                            );
                          })
                        ) : (
                          <Tab
                            label={
                              <Grid className="reservation-border-section">
                                <Grid className="reservation-image-section absolute">
                                  <Typography
                                    component="p"
                                    className={`lead-pic ${errorVal?.[0] ? "error" : "success"}`}
                                  >
                                    {(tabs[0]?.name) ? <strong>{tabs[0]?.name}</strong> : <img src={LeadPic} alt="lead pic" />}
                                    {(progress?.[0] === 100 || errorVal?.[0]) &&
                                      <Typography component="span">
                                        <i className={`iconwasalt icon-${errorVal?.[0] ? "error" : "check"}`} />
                                      </Typography>
                                    }
                                  </Typography>
                                </Grid>
                                <CircularProgress
                                  className={`trial ${errorVal?.[0] && "error"}`}
                                  variant="determinate"
                                  value={errorVal?.[0] ? "100" : progress?.[0]}
                                />
                              </Grid>
                            }
                            value="1"
                          />
                        )}
                      </TabList>
                    </Box>
                    {customerType === "1" ? (
                      tabs?.map((panel, index) => {
                        return (
                          <TabPanel key={panel?.value} value={`${panel?.value}`}>
                            {path === "booking" ? (
                              <ReviewIndividualCustomer
                                id={panel?.id}
                                customerType={customerType}
                                onClickBack={() => setOpenBackPopup(true)}
                                tabs={tabs}
                                units={units}
                                project_id={project_id}
                                panelValue={panel?.value}
                                setSelectedTab={setSelectedTab}
                                setBookingFailedPopup={setBookingFailedPopup}
                                bookingFailedPopup={bookingFailedPopup}
                                dialogboxcss="multiple-customer-tab"
                              />
                            ) : (
                              <>
                              <IndividualCustomerForm
                              isFormTriggered={isFormTriggered}
                              setIsFormTriggered={setIsFormTriggered}
                                panelValue={panel?.value}
                                setTabs={setTabs}
                                tabs={tabs}
                                setTabName={setTabName}
                                setCustomerData={setCustomerData}
                                notification={notification}
                                createNewTab={createNewTab}
                                customerType={customerType}
                                project_id={project_id}
                                units={units}
                                setErrorVal={setErrorVal}
                                errorVal={errorVal}
                                id={panel?.id || storageTabs?.[index]?.id}
                                setSelectedTab={setSelectedTab}
                                progress={progress}
                                setProgress={setProgress}
                                onClickBack={() => headerPopupClick(previous_url)}
                                selectedTab={selectedTab}
                                bookingFailedPopup={bookingFailedPopup}
                                setBookingFailedPopup={setBookingFailedPopup}
                                isTabChange={isTabChange}
                                setIsTabChange={setIsTabChange}
                              />
                              </>
                             
                            )}
                          </TabPanel>
                        );
                      })
                    ) : (
                      <TabPanel value="1">
                        <CompanyCustomerForm
                          customerData={customerData}
                          setCustomerData={setCustomerData}
                          notification={notification}
                          customerType={customerType}
                          project_id={project_id}
                          units={units}
                          setErrorVal={setErrorVal}
                          setTabName={setTabName}
                          errorVal={errorVal}
                          id={(storageTabs?.length && storageTabs?.[0]?.id) || companyId}
                          progress={progress}
                          setProgress={setProgress}
                          onClickBack={() => headerPopupClick(previous_url)}
                          editPath={state?.path}
                          tabs={tabs}
                          setBookingFailedPopup={setBookingFailedPopup}
                          bookingFailedPopup={bookingFailedPopup}
                        />
                      </TabPanel>
                    )}
                  </TabContext>
                )}
            </Grid>
          </Container>
        </Container>
      )}
      {(openPopup || openBackPopup) && (
        <DialogBox
          openPopup={openPopup || openBackPopup}
          setOpenPopup={openBackPopup ? setOpenBackPopup : setOpenPopup}
          content={translate("ra.content.changesLost")}
          handleYes={() => openBackPopup ? onClickBack() : onClickYes()}
          handleNo={() => { setOpenPopup(false); setOpenBackPopup(false) }}
          path="question"
          dialogClass="booking-back-content"
          innerContent={formStatus === "/booking-reservation-form" && translate("ra.content.innerLostContent")}
        />
      )}
      {backHeaderPopup && (
        <DialogBox
          openPopup={backHeaderPopup}
          setOpenPopup={setBackHeaderPopup}
          content={translate("ra.content.changesLost")}
          handleYes={() => releaseBlockUnits(false)}
          handleNo={() => setBackHeaderPopup(false)}
          path="question"
          dialogClass="booking-back-content"
          innerContent={translate("ra.content.lostProgress")}
        />
      )}
    </>
  );
}
