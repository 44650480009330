import * as webVitalsModule from "web-vitals";

export const reportWebVitals = (ReportCallback) => {
  const metricFunctions = [
    webVitalsModule.getCLS,
    webVitalsModule.getFID,
    webVitalsModule.getFCP,
    webVitalsModule.getLCP,
    webVitalsModule.getTTFB,
  ];

  metricFunctions.forEach((metricFunction) => {
    metricFunction(ReportCallback);
  });
};
