import React, { useState } from 'react'
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'
import Styles from './PropertydetailsDownloadinfo.scss'
import ReactWebShare from 'Components/Popup/ReactWebShare'
import { formatNumbers } from 'Utils/thousandSeperators'
import Typography from '@mui/material/Typography'
import gtagEventFire from 'Utils/ga4EventTrigger'

const PropertydetailsDownloadinfo = ({
  documents,
  startingPrice,
  currency,
  startingArea,
  unit_type,
  unit_id,
  location,
  city,
  unitNo,
  translate,
  formData,
}) => {
  const [openPopup, setOpenPopup] = useState(false)

  const textFormatChange = () => {
    if (unitNo) {
      return `${translate('ra.content.size')} ${formatNumbers(
        startingArea
      )} ${unit_type}
${translate('ra.content.price')} ${formatNumbers(startingPrice)} ${currency}
${translate('ra.content.unitNo')} ${unitNo}
`
    } else {
      return `${translate('ra.content.startSize')} ${formatNumbers(
        startingArea
      )} ${unit_type}
${translate('ra.content.startPrice')} ${formatNumbers(
        startingPrice
      )} ${currency}
`
    }
  }

  const handleShare = (doc) => {
    // Left aligned below text based on UI shared in social network
    return `
${translate('ra.content.shareContent')} ${doc?.documentName} ${translate(
      'ra.content.on'
    )} ${doc?.propertyName}: 
${doc?.documentLink}

${translate('ra.content.projectName')} ${doc?.propertyName}
${translate('ra.content.location')} ${city}, ${location}
${textFormatChange()}
${translate('ra.content.query')}
`
  }

  const gtag = (type, projectName, documentName) => {
    const event = window.dataLayer[window.dataLayer.length - 1]?.event
    if (
      event !== `unit_document_${type}` &&
      event !== `property_document_${type}`
    ) {
      if (unitNo) {
        gtagEventFire(`unit_document_${type}`, {
          user_email: formData?.email,
          property_name: projectName,
          document_name: documentName,
          unit_id: unitNo,
        })
      } else {
        gtagEventFire(`property_document_${type}`, {
          user_email: formData?.email,
          property_name: projectName,
          document_name: documentName,
        })
      }
    }
  }

  return (
    <>
      <Grid className="project-info-panel download-panel">
        <Typography variant="h2">
          {translate('ra.fieldName.document')}
        </Typography>
        <TableContainer className="payment-table">
          <Table aria-label="simple table">
            <TableBody>
              {documents?.map((doc, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>{doc?.documentName}</TableCell>
                    <TableCell className="download-button-section">
                      <ul>
                        <li>
                          <Button className="button-icon" variant="text">
                            <a
                              href={doc?.documentLink}
                              onClick={() =>
                                gtag(
                                  'download',
                                  doc?.propertyName,
                                  doc?.documentName
                                )
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="iconwasalt icon-download-new"></i>
                            </a>
                          </Button>
                        </li>
                        <li
                          onClick={() =>
                            gtag('share', doc?.propertyName, doc?.documentName)
                          }
                        >
                          <ReactWebShare
                            openPopup={openPopup}
                            setOpenPopup={setOpenPopup}
                            title={`Share ${doc?.documentName}`}
                            text=""
                            url={handleShare(doc)}
                          >
                            <Button className="button-icon" variant="text">
                              <i className="iconwasalt icon-share"></i>
                            </Button>
                          </ReactWebShare>
                        </li>
                      </ul>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  )
}

export default PropertydetailsDownloadinfo
