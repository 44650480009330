import React, { useState } from 'react'
import { useTranslate } from 'react-admin'
import {
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tooltip
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Loader from "Components/Loader";
import { roleData } from "Utils/constantValues";
import { useNavigate } from "react-router-dom";
import formatNumber from "Utils/conversionFunction";
import {PriceFormat } from 'Utils/conversionFunction'
import { formatNumbers } from "Utils/thousandSeperators";
import { dateCustomFormat } from "Utils/dateFormat";
import { ClickAwayListener } from "@material-ui/core";
const BookingDetailsTab = ({ projectInfoDetails, details, loading, userData }) => {
    const [openTooltip, setOpenTooltip] = useState(false);
    const unitType = userData?.unit_type;
    const unitArea = userData?.unit_type?.toLowerCase();
    const translate = useTranslate();
    const navigate = useNavigate();

  const contactRedirect = (id) => {
    navigate(`/contact-details?id=${id}`)
  }

  const handleTooltipClose = () => {
    setOpenTooltip(false)
  }

  const handleTooltipOpen = () => {
    setOpenTooltip(true)
  }

  const tooltipFunction = (reason) => {
    if (reason === 'tooltip') {
      const tooltipUnits = details?.paymentPlanNames
        ?.split(',')
        ?.filter((data, idx) => idx > 4)
      return tooltipUnits?.join(', ')
    } else {
      const paymentPlan = details?.paymentPlanNames
        ?.split(',')
        ?.filter((data, idx) => idx < 5)
      return paymentPlan?.join(', ')
    }
  }

  return (
    <>
      {loading && <Loader />}
      {details && (
        <>
          <Grid container className="booking-details" spacing={0}>
            <Typography variant="subtitle1">
              {translate('ra.fieldName.bookingDate')}
            </Typography>
            <Typography>
              {dateCustomFormat(details?.bookingDate) || '-'}
            </Typography>
          </Grid>
          <Grid container className="booking-details" spacing={0}>
            <Typography variant="subtitle1">
              {translate('ra.fieldName.bookingId')}
            </Typography>
            <Typography>{details?.bookingId || '-'}</Typography>
          </Grid>
          {userData?.user_type === 2 && roleData.includes(userData?.role) && (
            <Grid container className="booking-details" spacing={0}>
              <Typography variant="subtitle1">
                {translate('ra.fieldName.salesperson')}
              </Typography>
              <Typography>{details?.salespersonName || '-'}</Typography>
            </Grid>
          )}
          <Typography variant="h4">
            {translate('ra.fieldName.paymentDetails')}
          </Typography>
          <Grid container className="booking-details" spacing={0}>
            <Typography variant="subtitle1">
              {translate('ra.fieldName.payment')}
            </Typography>
            <Typography className="booking-details-payment">
              {details?.paymentPlanNames ? (
                details?.paymentPlanNames?.split(',')?.length < 6 ? (
                  details?.paymentPlanNames
                ) : (
                  <>
                    {tooltipFunction()}
                    <ClickAwayListener onClickAway={handleTooltipClose}>
                      <span>
                        <Tooltip
                          className="tooltip-info"
                          open={openTooltip}
                          title={tooltipFunction('tooltip')}
                          arrow
                          onClose={handleTooltipClose}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                        >
                          <span
                            onClick={handleTooltipOpen}
                            onMouseEnter={handleTooltipOpen}
                            onMouseLeave={handleTooltipClose}
                          >
                            {`+${
                              details?.paymentPlanNames?.split(',')?.length - 5
                            } more`}
                          </span>
                        </Tooltip>
                      </span>
                    </ClickAwayListener>
                  </>
                )
              ) : (
                '-'
              )}
            </Typography>
          </Grid>
          <Typography variant="h4">
            {translate('ra.fieldName.unitDetails')}
          </Typography>
          <Accordion
            className="unit-details-accordian"
            defaultExpanded={details?.units?.length === 1 ? true : false}
          >
            <AccordionSummary
              expandIcon={details?.units?.length > 1 ? <ExpandMoreIcon /> : ''}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid container className="booking-details mb0" spacing={0}>
                <Typography variant="subtitle1">
                  {translate('ra.fieldName.unitBook')}
                </Typography>
                <Typography>
                  {details?.units?.length}{' '}
                  {details?.units?.length > 1
                    ? translate('ra.fieldName.units')
                    : translate('ra.fieldName.unit')}
                </Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <ul className="shareList booking-share-list">
                <li className="li_margin_bottom booking-details-list">
                  <div className="booking-image-with-text">
                    <li className="info-with-image">
                      {projectInfoDetails?.images && (
                        <img
                          src={projectInfoDetails?.images?.[0]?.mobile_ImageURL}
                          alt="unit image Pic"
                        />
                      )}
                      <p>
                        <span>{details?.projectInfo?.name}</span>{' '}
                        {details?.projectInfo?.city},{' '}
                        {details?.projectInfo?.country}{' '}
                      </p>
                    </li>
                  </div>
                  {details?.units?.map((list, idx) => (
                    <li key={idx} className="unit-info-li">
                      <Typography className="unit-info-no">
                        {translate('ra.fieldName.unit_no')}{' '}
                        <span>{list?.unit_no}</span>
                      </Typography>
                      <Grid
                        container
                        className="unit-info-para-details"
                        spacing={0}
                      >
                        <Typography>
                          {translate('ra.fieldName.area')}({unitType})
                          <span>
                            {formatNumbers(list?.[`area_${unitArea}`])}
                          </span>
                        </Typography>
                        <Typography>
                          {translate('ra.fieldName.bedroom')}
                          <span>{list?.bedrooms || '-'}</span>
                        </Typography>
                        <Typography>
                          {translate('ra.fieldName.price')}(
                          {list?.currency_Code})
                          <span>{PriceFormat(list?.net_Price || list?.price)}</span>
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        className="buyer-details-info"
                        spacing={0}
                      >
                        <Grid container className="booking-details" spacing={0}>
                          <Typography variant="subtitle1">
                            {translate('ra.fieldName.primary')}
                          </Typography>
                          <Typography>
                            <a
                              onClick={() =>
                                contactRedirect(list?.primary_buyer?.id)
                              }
                            >
                              {list?.primary_buyer?.company_name ||
                                `${list?.primary_buyer?.first_name || ''} ${
                                  list?.primary_buyer?.last_name || ''
                                }` ||
                                ''}
                            </a>
                          </Typography>
                        </Grid>
                        {list?.joint_buyer?.map((data, i) => (
                          <Grid
                            key={i}
                            container
                            className="booking-details"
                            spacing={0}
                          >
                            <Typography variant="subtitle1">
                              {translate('ra.fieldName.jointBuyer')}{' '}
                              {list?.joint_buyer?.length > 1 ? i + 1 : ''}
                            </Typography>
                            <Typography>
                              <a onClick={() => contactRedirect(data?.id)}>
                                {data?.company_name ||
                                  `${data?.first_name || ''} ${
                                    data?.last_name || ''
                                  }` ||
                                  ''}
                              </a>
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </li>
                  ))}
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </>
  )
}

export default BookingDetailsTab
