import React, { useEffect, useState } from 'react'
import { useRedirect, useStore } from 'react-admin'
import { useForm } from 'react-hook-form'
import fileUploadService from 'Services/fileUploadService'
import { docForm, docListForm } from 'Utils/uploadSection'
import UploadDoc from 'Pages/RegisterAgency/CompanyUpload/UploadDoc'
import isInProcess from 'Utils/ui-inprocess-status'
import { isinDraft } from 'Utils/ui-lock-status'


import {useNavigate, useLocation } from 'react-router-dom';
import { isKsaPresent } from 'Utils/commonFunctions'

const Upload = ({ notification }) => {
  
  const location=useLocation();
  const navigate=useNavigate();
  const history_url=JSON.parse(localStorage.getItem("history_url"));
  const prevRedirectPage=localStorage.getItem("prevRedirectPage");
  // eslint-disable-next-line no-unused-vars, no-undef
  const [formData, setFormData] = useStore()
  const [os, setOs] = useState(null)
  const [addtionalDocument, setAddtionalDocument] = useState([])
  const [passport_array, setPassport_array] = useState({})
  const [residence_array, setResidence_array] = useState({})
  const [qatar_array, setQatar_array] = useState({})
  const [emiratesId_array, setEmiratesId_array] = useState({})
  const [brokerCertificate_array, setBrokerCertificate_array] = useState({})
  const [nationalityId_array, setNationalityId_array] = useState({})
  const [openDialog, setOpenDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [additionalDocumentsFiles, setAdditionalDocumentsFiles] = useState([])
  const [deleteId, setDeleteId] = useState([])
  const user_id = formData?.user_id
  const user_type = formData?.user_type
  const nationality = formData?.nationality
  const user_email = formData?.email
  const country_residence = formData?.country_residence

  const {
    register,
    handleSubmit,
    resetField,
    watch,
    setValue,
    formState: { errors },
  } = useForm()
  const redirect = useRedirect()

  const watchPassportNationalIdDocument = watch(
    'passportNationalIdDocument',
    []
  )
  const watchResidenceCivilCard = watch('residenceCivilCard', [])
  const watchQatarIdCivilCard = watch('qatarId', [])
  const watchAdditionalDocuments = watch('additionalDocuments', [])
  const watchAdditionalDocumentsName = watch('additionalDocumentName', [])
  const watchEmiratesIdDocumentsName = watch('emiratesID', [])

  const watchBrokerCertificateDocumentsName = watch('brokerIDCertificate', []) //new 
  const watchNationalityIdCertificateDocuments=watch('saudiIqama', []) //new 

  const setDetails = (resData) => {
    
    resData?.oracle_status ? setOs(resData?.oracle_status) : setOs('-')
    docListForm(
      resData,
      setAddtionalDocument,
      setAdditionalDocumentsFiles,
      setValue,
      setPassport_array,
      setResidence_array,
      setQatar_array,
      setEmiratesId_array,
      setBrokerCertificate_array,
      setNationalityId_array
    )
  }

  const handleBack = () => {
   isKsaPresent(location?.pathname)?redirect('/individual-ksa-licensed/bank'): redirect('/register-myself/bank')
  }

  const fileUploadData = async (data) => {
    const passportNationalIdDocument = data?.passportNationalIdDocument[0]
      ? docForm(data?.passportNationalIdDocument, 'passport')
      : {}

    const residenceCardDocument = data?.residenceCardDocument[0]
      ? docForm(data?.residenceCardDocument, 'residence')
      : {}

    const qatarCardDocument = data?.qatarCardDocument[0]
      ? docForm(data?.qatarCardDocument, 'qatarId')
      : {}

    const emiratesIdDocument = data?.emiratesID[0]
      ? docForm(data?.emiratesID, 'emiratesID')
      : {}

    const brokerCertificateId = data?.brokerIDCertificate[0]
      ? docForm(data?.brokerIDCertificate, 'brokerIDCertificate')
      : {}
    const saudiIqama = data?.saudiIqama[0]
      ? docForm(data?.saudiIqama, 'saudiIqama')
      : {}

    let formData = new FormData()

    if (passport_array && passportNationalIdDocument) {
      passportNationalIdDocument.id = passport_array.id
    }

    if (residence_array && residenceCardDocument) {
      residenceCardDocument.id = residence_array.id
    }

    if (qatar_array && qatarCardDocument) {
      qatarCardDocument.id = qatar_array.id
    }

    if (emiratesId_array && emiratesIdDocument) {
      emiratesIdDocument.id = emiratesId_array.id
    }
    //new
    if (brokerCertificate_array && brokerCertificateId) {
      brokerCertificateId.id = brokerCertificate_array.id
    }
    if (nationalityId_array && saudiIqama) {
      saudiIqama.id = nationalityId_array.id
    }
    

    

    let fileDataList
    fileDataList = [...additionalDocumentsFiles]

    if (additionalDocumentsFiles.length === addtionalDocument.length) {
      fileDataList = fileDataList?.filter(
        (list) => list.doc_type !== 'additional'
      )
    } else {
      fileDataList = fileDataList?.filter(
        (list) => list.doc_type === 'additional' && !('name' in list)
      )
    }
    fileDataList.push(passportNationalIdDocument)
    fileDataList.push(brokerCertificateId)
    fileDataList.push(saudiIqama)

    if (nationality === 'Omani' || country_residence === 'Oman') {
      fileDataList.push(residenceCardDocument)
    }

    if (nationality === 'Qatari' || country_residence === 'Qatar') {
      fileDataList.push(qatarCardDocument)
    }

    if (country_residence === 'United Arab Emirates') {
      fileDataList.push(emiratesIdDocument)
    }

    if (passport_array === data.passportNationalIdDocument?.[0]) {
      fileDataList = fileDataList?.filter(
        (list) => list.doc_type !== 'passport'
      )
    }
    if (brokerCertificate_array === data.brokerCertificateId?.[0]) {
      fileDataList = fileDataList?.filter(
        (list) => list.doc_type !== 'brokerIDCertificate'
      )
    }
    if (nationalityId_array === data.saudiIqama?.[0]) {
      fileDataList = fileDataList?.filter(
        (list) => list.doc_type !== 'saudiIqama'
      )
    }

    fileDataList=fileDataList?.filter(_=>_?.doc_type!==""&&_?.doc_type!==undefined);
    const file = fileDataList.map((value) => {
      formData.append('file', value.file)
      return value.file
    })

    formData.append(
      'user_id',
      user_id || JSON.parse(localStorage.getItem('user_id'))
    )

    formData?.append('user_type', 1)

    let additional = fileDataList?.map((doc) => {
      let data = {
        doc_name: doc?.doc_name,
        file_name: doc?.file_name,
        doc_type: doc?.doc_type,
      }

      if (doc?.id) {
        data.id = doc?.id
      }

      return data
    })

    deleteId.length !== 0 &&
      formData.append('delete_file_ids', JSON.stringify([...deleteId]))

    additional=additional?.filter(_=>_?.doc_type!==""&&_?.doc_type!==undefined);
    formData.append('data', JSON.stringify(additional))

    fileUploadService(
      formData,
      setLoading,
      notification,
      redirect,
      additionalDocumentsFiles?.length,
      addtionalDocument?.length,
      os,
      'individual',
      user_email
    )
  }

  const callApi = (data) => {
   
    if (!data?.passportNationalIdDocument) {
      data.passportNationalIdDocument = watchPassportNationalIdDocument
    }
    if (!data?.residenceCardDocument) {
      data.residenceCardDocument = watchResidenceCivilCard
    }
    if (!data?.qatarCardDocument) {
      data.qatarCardDocument = watchQatarIdCivilCard
    }
    if (!data?.emiratesID) {
      data.emiratesID = watchEmiratesIdDocumentsName
    }
    if (!data?.brokerIDCertificate) {
      data.brokerIDCertificate = watchBrokerCertificateDocumentsName
    }
    if (!data?.saudiIqama) {
      data.saudiIqama = watchNationalityIdCertificateDocuments
    }
    
    
    fileUploadData(data)
  }

  const onSubmit = async (data) => {
    if (!isInProcess(os) && !isinDraft(os)) {
      callApi(data)
    } else {
      if (openDialog || additionalDocumentsFiles?.length !== 20) {
        callApi(data)
      } else {
        if (additionalDocumentsFiles?.length === 20) {
          setOpenDialog(true)
        }
      }
    }
  }

  const onError = (errors, e) => console.log(errors, e)

  useEffect(()=>{
    (!(history_url?.some(path=>path==location.pathname)))&&navigate(prevRedirectPage);
  },[])
  return (
    <UploadDoc
      os={os}
      user_type={user_type}
      watchPassportNationalIdDocument={watchPassportNationalIdDocument}
      watchResidenceCivilCard={watchResidenceCivilCard}
      watchQatarIdCivilCard={watchQatarIdCivilCard}
      watchEmiratesId={watchEmiratesIdDocumentsName}
      addtionalDocument={addtionalDocument}
      setDeleteId={setDeleteId}
      additionalDocumentsFiles={additionalDocumentsFiles}
      setAdditionalDocumentsFiles={setAdditionalDocumentsFiles}
      loading={loading}
      submitHandler={handleSubmit(onSubmit, onError)}
      handleBack={handleBack}
      omanCheck={nationality === 'Omani' || country_residence === 'Oman'}
      qatarCheck={nationality === 'Qatari' || country_residence === 'Qatar'}
      uaeCheck={country_residence === 'United Arab Emirates'}
      watchAdditionalDocuments={watchAdditionalDocuments}
      watchAdditionalDocumentsName={watchAdditionalDocumentsName}
      setLoading={setLoading}
      setResponseData={setFormData}
      setDetails={setDetails}
      register={register}
      resetField={resetField}
      setValue={setValue}
      openDialog={openDialog}
      setOpenDialog={setOpenDialog}
      errors={errors}
      notification={notification}
      isBrokerCertificateNeed={formData?.country_residence=="Saudi Arabia"&&formData?.license_type=="L"}
      isSaudiIqamaNeed={formData?.country_residence=="Saudi Arabia"}
      watchBrokerCertificateDocumentsName={watchBrokerCertificateDocumentsName}
      watchNationalityIdCertificateDocuments={watchNationalityIdCertificateDocuments}
      isIndividualRegistration={true}
      isKsa={isKsaPresent(location.pathname)}
    />
  )
}

export default Upload
