import { apiServiceRequest } from "Utils/axiosInstance";

//currency Service
const country = async (setCountryList, setCountryLoading, notify) => {
  setCountryLoading(true);
  const res = await apiServiceRequest({
    url: `/countries`,
    notify: notify,
  });
  if (res?.code === "success") {
    setCountryList(res?.data);
  }
  setCountryLoading(false);
};

export default country;
