import Header from "Components/Header";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import userDetails from "Services/userDetailsServices";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { PaymentMethodTab } from "./PaymentMethodTab";
import { AttachProofTab } from "./AttachProofTab";
import Loader from "Components/Loader";
import { Grid } from "@mui/material";
import { Container } from '@mui/material';
import LooksOne from '@mui/icons-material/LooksOne';
import LooksTwo from '@mui/icons-material/LooksTwo';
import getCustomerUnits from "Services/assignOwnershipServices";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BackButton from "Components/BackButton";

export const TokenPaymentDetails = ({ notification, bookingFailedPopup, setBookingFailedPopup }) => {
  const location = useLocation();
  const bookingState = JSON.parse(localStorage.getItem("bookingState")) || null;
  const state = location?.state || bookingState;
  const [userData, setUserData] = useState(null);
  const [userDetailsLoading, setuserDetailsLoading] = useState(true);
  const [tabValue, setTabValue] = useState(state?.value || "1");
  const [adjustPaymentLoading, setAdjustPaymentLoading] = useState(false)
  const [customerLoading, setCustomerLoading] = useState(true);
  const [primary, setPrimary] = useState();
  const [data, setData] = useState([]);
  const [units, setUnits] = useState()
  const [bankDetailsAndLink, setBankDetailsAndLink] = useState(null);
  const portal_booking_id = localStorage.getItem("portalBookingId") || null;
  let formStatus = sessionStorage.getItem("formStatus") || null;
  const navigate = useNavigate();

  let apiRequestData = {
    projectId: state?.project_id,
    unitIds: state?.units?.map(unit => unit?.officE_SECTION_ID)
  };

  let headerPayload = {
    project_id: state?.project_id,
    unit_ids: state?.units?.map(unit => unit?.officE_SECTION_ID)?.join(","),
    portal_booking_id: portal_booking_id
  }

  useEffect(() => {
    if (!formStatus) {
      navigate("/home");
    }

    if (formStatus && formStatus !== "/token-payment-details") {
      navigate(formStatus, { state });
    }

    if(formStatus === "/token-payment-details") {
      userDetails(setuserDetailsLoading, setUserData, "", notification);
      getCustomerUnits(setCustomerLoading, setData, apiRequestData, notification, displayData, true, setBankDetailsAndLink, JSON.stringify(headerPayload), bookingFailedPopup, setBookingFailedPopup);
    }
    
    document.body.classList.add(
      'profile-page'
    );
    document.body.classList.add(
      'change-pass-page'
    );
    document.body.classList.add(
      'reservation-form'
    );
    return () => {
      document.body.classList.remove(
        'profile-page'
      );
      document.body.classList.remove(
        'change-pass-page'
      );
      document.body.classList.remove(
        'reservation-form'
      );
    };
  }, []);

  const displayData = (res) => {
    let allUnits = res?.map((val) => {
      return val?.unit_no
    })
    let removeDuplicateunits = removeDuplicates(allUnits);
    let sortedDataAsUnits = [];
    for (const unitNo of removeDuplicateunits) {
      const filterData = res?.filter((item) => item?.unit_no === unitNo);
      sortedDataAsUnits = [...sortedDataAsUnits,
      {
        unitNo, min: filterData[0]?.min_token || 0, max: filterData[0]?.max_token || 0, project_id: filterData[0]?.project_id,
        unit_id: filterData[0]?.unit_id, slider: Number(filterData[0]?.paid_token_amount) > 0 ? filterData[0]?.paid_token_amount || 0:  filterData[0]?.max_token || 0,
        customer_unit_id: filterData?.map(unit => unit?.customer_unit_id),
        primary: filterData?.filter(item => item?.is_primary_user === 1),
        customer_ids: filterData?.map(unit=>unit?.customer_id),
        currency: filterData[0]?.currency || ""
      }]
    }
    setPrimary(res[0]?.lead_type === 1 ? sortedDataAsUnits[0]?.primary[0] : res[0]);
    setUnits(sortedDataAsUnits)
  }

  function removeDuplicates(arr) {
    return arr.filter((item,
      index) => arr.indexOf(item) === index);
  }

  const setSliderValue = (index, value) => {
    const unitarray = [...units];
    unitarray[index].slider = `${value}`;
    setUnits(unitarray);
  }

  const onClickBack = () => {
    setTabValue("1");
  }

  return (
    <>
      {(adjustPaymentLoading || customerLoading) && <Loader />}
      <Header
        path="My Bookings"
        picture={userData?.profile}
        pictureLoading={userDetailsLoading}
        notification={notification}
      />
      {tabValue === "2" && <BackButton close={() => onClickBack()} />}
      {!customerLoading && (
        <Container className="company-container new-fix-container lead-panel-section lead-white-section token-payment-page">
          <Container className="company-details">
            <Grid className="form-panel token-payment-panel">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={tabValue}>
                  <Box className="token-payment-toppanel">
                    <TabList
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        className={`${tabValue === "2" && "Mui-success"}`}
                        icon={tabValue === "2"
                          ? <CheckCircleIcon />
                          : <LooksOne />
                        }
                        label="Payment Method" value="1"
                      />
                      <Tab
                        icon={<LooksTwo />}
                        label="Attach Proof" value="2"
                      />
                    </TabList>
                  </Box>
                  <Box className="token-payment-details">
                    <TabPanel value="1">
                      <PaymentMethodTab
                        setAdjustPaymentLoading={setAdjustPaymentLoading}
                        units={units}
                        bankDetailsAndLink={bankDetailsAndLink}
                        setTabValue={setTabValue}
                        notification={notification}
                        userData={userData}
                        data={data}
                        primary={primary}
                        setSliderValue={setSliderValue}
                        headerPayload={headerPayload}
                        bookingFailedPopup={bookingFailedPopup}
                        setBookingFailedPopup={setBookingFailedPopup}
                        portal_booking_id={portal_booking_id}
                      />
                    </TabPanel>
                    <TabPanel className="attach-proof-details" value="2">
                      <AttachProofTab
                        notification={notification}
                        portal_booking_id={portal_booking_id}
                        state={state}
                        onClickBack={onClickBack}
                        headerPayload={headerPayload}
                        units={units}
                        bookingFailedPopup={bookingFailedPopup}
                        setBookingFailedPopup={setBookingFailedPopup}
                      />
                    </TabPanel>
                  </Box>
                </TabContext>
              </Box>
            </Grid>
          </Container>
        </Container>
      )}
    </>
  );
};
