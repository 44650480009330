import Loader from 'Components/Loader'
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Button, Typography } from '@mui/material'
import InputField from 'Components/InputField'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-hook-form'
import nationality from 'Services/nationalityService'
import country from 'Services/countryService'
import { numberTextPattern } from 'Utils/regex-patterns'
import DatePickerComponent from 'Components/Common/DatePicker'
import { expiryDate } from 'Utils/maxMinDate'
import DialogBox from 'Components/DialogBox'
import BasicDetailsForm from 'Pages/BookinFlow/BasicDetailsForm'
import ResidentialDetails from 'Pages/BookinFlow/ResidentialDetails'
import { customerIndivialService } from 'Services/customerCreationandUpdation'
import { getDateFormat } from 'Utils/dateFormat'
import MultipleDocumentUpload from 'Components/MultipleDocumentUpload'
import { getLeadById } from 'Services/leadService'

const IndividualCustomerForm = ({
  notification,
  createNewTab,
  customerType,
  setTabs,
  setCustomerData,
  tabs,
  panelValue,
  project_id,
  units,
  id,
  setSelectedTab,
  setErrorVal,
  setProgress,
  setTabName,
  progress,
  errorVal,
  onClickBack,
  selectedTab,
  bookingFailedPopup,
  setBookingFailedPopup,
  isTabChange,
  setIsTabChange,
}) => {

  const [dd_authorised_alt_mobile_no,setDd_authorised_alt_mobile_no]=useState("");
  const [authorised_alt_mobile_no_with_code,setAuthorised_alt_mobile_no_with_code]=useState("");

  const [passportDocFiles, setPassportDocFiles] = useState([])
  const [passportDocumentArray, setPassportDocumentArray] = useState([])
  const [additionalDocFiles, setAdditionalDocFiles] = useState([])
  const [additionalDocumentArray, setAdditionalDocumentArray] = useState([])
  const [formData, setFormData] = useState(null)
  const [contactLoading, setContactLoading] = useState(id ? true : false)
  const [mobilePhoneNumber, setMobilePhoneNumber] = useState()
  const [alternateMobilePhoneNumber, setAlternateMobilePhoneNumber] = useState()
  const [
    alternateMobilePhoneNumberCountryCode,
    setAlternateMobilePhoneNumberCountryCode,
  ] = useState()
  const [deleteId, setDeleteId] = useState([])
  const [phoneErrors, setPhoneErrors] = useState(false)
  const [nationalityState, setNationalityState] = useState(null)
  const [nationalityCode, setNationalityCode] = useState(null)
  const [nationalityList, setNationalityList] = useState([])
  const [nationalityLoading, setNationalityLoading] = useState(false)
  const [customerIndividualLoading, setCustomerIndividualLoading] =
    useState(false)
  const [residenceDetails, setResidenceDetails] = useState({})
  const [countryCode, setCountryCode] = useState()
  const [countryState, setCountryState] = useState(null)
  const [partial, setPartial] = useState(false)
  const [ccode, setccode] = useState(null)
  const [countryList, setCountryList] = useState([])
  const [countryListLoading, setCountryListLoading] = useState()
  const [openAddDoc, setOpenAddDoc] = useState(false)
  const [openDialog, setOpenDialog] = useState(null)
  const [passportDocumentError, setPassportDocumentError] = useState({
    error: false,
    message: '',
  })
  const [checkLoading, setCheckLoading] = useState()
  const [additionalDocumentError, setAdditionalDocumentError] = useState({
    error: false,
    message: '',
  })
  const [addressCheck, setAddressCheck] = useState(
    tabs[panelValue - 1]?.check || false
  )
  const [isFocus, setIsFocus] = useState(false)
  const portal_booking_id = localStorage.getItem('portalBookingId')
  const formStatus = sessionStorage.getItem('formStatus') || null
  const booking_page = localStorage.getItem('booking_page') || null

  const [mobileNumber_with_code,setMobileNumber_with_code]=useState();
  const [dd_mobile_no,setDd_mobile_no]=useState();

  const [uids, setUids] = useState([])

  const translate = useTranslate()
  const navigate = useNavigate()
  const form = useForm({
    mode: 'onChange',
  })

  const {
    register,
    handleSubmit,
    formState: { errors, submitCount, touchedFields, dirtyFields },
    setError,
    clearErrors,
    watch,
    control,
    setValue,
  } = form

  const [istriggered,setIstriggered]=useState(false);

  useEffect(() => {
    if (!formStatus) {
      navigate('/home')
    }

    if (formStatus && formStatus !== '/booking-reservation-form') {
      navigate(formStatus, { state: { units, project_id } })
    }

    nationality(setNationalityList, setNationalityLoading, notification)
    country(setCountryList, setCountryListLoading, notification)
    id &&
      getLeadById(
        setContactLoading,
        id,
        setFormData,
        notification,
        'individual',
        addDocs,
        portal_booking_id,
        addPhoneNumbers
      )
    document.body.classList.add('profile-page')
    document.body.classList.add('change-pass-page')
    document.body.classList.add('reservation-form')
    return () => {
      document.body.classList.remove('profile-page')
      document.body.classList.remove('change-pass-page')
      document.body.classList.remove('reservation-form')
    }
  }, [])

console.log(project_id,"project_idproject_id",units);

  useEffect(() => {
    setTabName(panelValue, watch('first_name'), watch('last_name'))
  }, [watch('first_name'), watch('last_name')])

  useEffect(() => {
    let errorArray = [...errorVal]
    if (Object.keys(errors)?.length > 0) {
      errorArray[panelValue - 1] = true
    } else {
      errorArray[panelValue - 1] = false
    }
    setErrorVal(errorArray)
  }, [Object.keys(errors)?.length, openAddDoc])

  useEffect(() => {
    passportDocFiles?.length > 0 &&
      setPassportDocumentError({ error: false, message: null })
    passportDocFiles?.length === 0 &&
      passportDocumentError?.message === null &&
      setDocumentError()
    additionalDocFiles?.length > 0 &&
      openAddDoc &&
      setAdditionalDocumentError({ error: false, message: null })
    additionalDocFiles?.length === 0 &&
      openAddDoc &&
      additionalDocumentError?.message === null &&
      setDocumentError()
  }, [passportDocFiles?.length, additionalDocFiles?.length])

  let newObj = {
    ...watch(),
    ccode,
    nationalityCode,
    id_number: openAddDoc ? watch('id_number') : '',
    passportDocFiles: passportDocFiles?.length > 0 ? 1 : '',
    mobilePhoneNumber,
    alternateMobilePhoneNumber,
    id_expiry_date: openAddDoc ? watch('id_expiry_date') : '',
  }
  delete newObj.nationality
  delete newObj.country

  if (openAddDoc && additionalDocFiles?.length > 0) {
    newObj.additionalDocFiles = 1
  }

  const reduseDta = Object.keys(newObj).reduce(
    (a, b) => {
      if (
        (b === 'address_line_2' ||
          b === 'alternateMobilePhoneNumber' ||
          b === 'explain') &&
        newObj[b]
      ) {
        a.invalid++
      } else if (newObj[b]) {
        a.totalLength++
      }
      a.total = a.totalLength + a.invalid
      return a
    },
    { invalid: 0, totalLength: 0, total: 0 }
  )

  useEffect(() => {
    let total = openAddDoc ? 15 : 12
    total += reduseDta.invalid
    const percentage = (reduseDta.total / total) * 100
    const progressArray = [...progress] || []
    progressArray[panelValue - 1] = percentage
    setProgress(progressArray)
  }, [reduseDta])

  useEffect(() => {
    if (!contactLoading && !nationalityLoading && !countryListLoading) {
      if (
        formData?.nationality == nationalityCode &&
        formData?.country == ccode &&
        alternateMobilePhoneNumber == formData?.alt_mobile_no &&
        mobilePhoneNumber == formData?.mobile_no
      ) {
        if (Object.keys(dirtyFields)?.length > 0) {
          setIsTabChange(true)
        } else if (deleteId?.length) {
          setIsTabChange(true)
        } else if (
          passportDocumentArray?.length ||
          additionalDocumentArray?.length
        ) {
          setIsTabChange(true)
        }
      } else {
        setIsTabChange(true)
      }
    }
    return () => {
      setIsTabChange(false)
    }
  }, [
    Object.keys(dirtyFields)?.length,
    passportDocFiles?.length,
    additionalDocFiles?.length,
    ccode,
    nationalityCode,
    mobilePhoneNumber,
    alternateMobilePhoneNumber,
    formData,
  ])

  const addDocuments = (formData) => {
    
    if (passportDocFiles?.length) {
      passportDocFiles?.map(
        (file) => file?.name && formData?.append('file', file)
      )
    }
    if (additionalDocFiles?.length) {
      additionalDocFiles?.map(
        (file) => file?.name && formData?.append('file', file)
      )
    }
    if (additionalDocumentArray?.length || passportDocumentArray?.length) {
      let docArray = [...additionalDocumentArray, ...passportDocumentArray]
      formData?.append('docData', JSON.stringify(docArray))
    }
    deleteId?.length &&
      formData?.append('deletedDocIds', JSON.stringify([...deleteId]))
  }

  const addDocs = (resData) => {
    const progressArray = [...progress] || []
    progressArray[panelValue - 1] = '100'
    setProgress(progressArray)
    resData?.id_number && setOpenAddDoc(true)
    const arrPassport = []
    const arrAdditional = []

    resData?.documents?.map((doc) => {
      if (doc.doc_type === 'passport') {
        arrPassport.push(doc)
        setPassportDocFiles(arrPassport)
      }
      if (doc.doc_type === 'additional') {
        arrAdditional.push(doc)
        setAdditionalDocFiles(arrAdditional)
      }
    })
  }

  const addPhoneNumbers = (resData) => {
    setMobilePhoneNumber(resData?.mobile_no)
    setCountryCode(resData?.mobile_no_country_code)
    setAlternateMobilePhoneNumber(resData?.alt_mobile_no)
    setAlternateMobilePhoneNumberCountryCode(
      resData?.alt_mobile_no_country_code
    )
  }

  const addPayload = (formData, data) => {
    let {
      first_name,
      last_name,
      city,
      address_line_1,
      address_line_2,
      email,
      passport_expiry_date,
      postal_code_po_box,
      passport_number,
      id_expiry_date,
      id_number,
      explain,
    } = data

    const passportDate = getDateFormat(passport_expiry_date)
    let additionalDate = getDateFormat(id_expiry_date)

    // const unit_object = units?.map((unit, index) => {
    //   return {
    //     id: tabs[panelValue - 1]?.unitId?.[index],
    //     unit_id: unit?.officE_SECTION_ID,
    //     unit_no: unit?.officE_SECTION_CODE,
    //     total_unit_price: unit?.propertY_PRICE,
    //     saleable_area_sqft: unit?.saleablE_AREA_SQFT,
    //     floor_parcel_id: unit?.flooR_PARCEL_ID,
    //     release_id: unit?.releasE_ID,
    //     building_land_id: unit?.buildinG_LAND_ID || '',
    //     variation_comment: explain || '',
    //   }
    // })
    const unit_object = units?.map((unit, index) => {
      return {
        unit_id: unit?.unitCode||"null",
        unit_no: unit?.unitCode||"null",
        floor_parcel_id: unit?.floor||"null",
        saleable_area_sqft: unit?.saleableAreasqft||"null",
        release_id: unit?.releaseId||"null",
        building_land_id: unit?.buildingCode || 'null',

        total_unit_price: unit?.propertY_PRICE||"null",
        variation_comment: explain || 'null',
        id: tabs[panelValue - 1]?.unitId?.[index],
      }
    })

    const payload = {
      first_name,
      last_name,
      city,
      country: ccode,
      address_line_1,
      nationality: nationalityCode,
      email,
      passport_expiry_date: passportDate,
      passport_number,
      mobile_no: mobilePhoneNumber,
      mobile_no_country_code: countryCode?.toString(),
      project_id: project_id,
      unit_data: unit_object,
      org_id: units[0]?.organizationId||"null",
      portal_booking_id,
      postal_code_po_box: postal_code_po_box,
      id_expiry_date: additionalDate || '',
      id_number: id_number || '',
      address_line_2: address_line_2 || '',

      mobile_no_with_code:mobileNumber_with_code||"",
      dd_mobile_no:dd_mobile_no?.toLocaleLowerCase()||"gb",
      dd_agent_mobile_no:"",

      alt_mobile_no_country_code: alternateMobilePhoneNumber
        ? alternateMobilePhoneNumberCountryCode.toString()
        : '',
      alt_mobile_no: alternateMobilePhoneNumber || '',
    }

    if (id) payload.id = id
    formData.append('payload', JSON.stringify(payload))
    return payload
  }

console.log(formData,"formdata");

  const setTabIds = (id, res) => {
    const singleTabs = [...tabs]
    const selectedTab = singleTabs?.filter((tab) => tab?.value === panelValue)
    selectedTab[0].unitId =
      res?.unitIds?.length > 0
        ? res?.unitIds?.map((unit) => unit?.id)
        : selectedTab[0]?.unitId

    if (!selectedTab[0]?.id) {
      selectedTab[0].id = id
    }

    setTabs(singleTabs)
    localStorage.setItem('tabs', JSON.stringify(singleTabs))
    const tabNumber = (Number(selectedTab[0]?.value) + 1).toString()

    if (tabs?.length > 1 && selectedTab[0]?.value != tabs?.length) {
      setSelectedTab(tabNumber)
    } else {
      return true
    }
  }

  const setDocumentError = () => {
    if (passportDocFiles?.length === 0) {
      setError('passportDocFiles', {
        type: 'required',
        message: translate('ra.validation.uploadPassport'),
      })
      setPassportDocumentError({
        error: true,
        message: translate('ra.validation.uploadPassport'),
      })
    }
    if (additionalDocFiles?.length === 0 && openAddDoc) {
      setError('additionalDocFiles', {
        type: 'required',
        message: translate('ra.validation.uploadId'),
      })
      setAdditionalDocumentError({
        error: true,
        message: translate('ra.validation.uploadId'),
      })
    }
  }

 const errorHandler=(error)=>{
 console.log(error);
 
 }

 const onSubmittingForm=()=>{
   setIstriggered(true);
    handleSubmit(onSubmit,errorHandler)()
    setDocumentError()
    setPhoneErrors(formData?.mobile_no ? false : true)
    Object.keys(errors)?.length > 0 &&
      notification('ra.notification.invalidDetails', {
        type: 'error',
      })
 }

  const onSubmit = (data) => {
    const innerSubmit = () => {
      if (!passportDocumentError?.error && !additionalDocumentError?.error) {
        let formData = new FormData()
        let headerPayload = {
          project_id,
          unit_ids: units?.map((unit) => unit?.unitCode).join(','),
          portal_booking_id: portal_booking_id,
        }
        addPayload(formData, data)        
        addDocuments(formData)
        customerIndivialService(
          setCustomerIndividualLoading,
          formData,
          notification,
          setCustomerData,
          setTabIds,
          setOpenDialog,
          translate,
          JSON.stringify(headerPayload),
          bookingFailedPopup,
          setBookingFailedPopup
        )
      }
    }

    const filteredUnit = tabs
      ?.map((tab) => tab?.unitId?.length > 0)
      .slice(0, -1)
    if (panelValue == tabs?.length && filteredUnit?.includes(false)) {
      notification('ra.notification.registerCustomers', { type: 'error' })
    } else {
      innerSubmit()
    }
  }

  const handleClickNo = () => {
    sessionStorage.setItem(
      'formStatus',
      tabs?.length > 1
        ? '/assign-ownership-percentage'
        : '/review-customer-information'
    )
    localStorage.setItem('editCustomer', true)

    tabs?.length > 1
      ? navigate('/assign-ownership-percentage', {
          state: { tabs, units, project_id },
        })
      : navigate('/review-customer-information', {
          state: { customerType, id, units, project_id },
        })
  }

  const removeAdditional = () => {
    if (openAddDoc) {
      setDeleteId(additionalDocFiles?.map((doc) => doc?.id))
      setOpenAddDoc(false)
      setValue('id_number', null)
      setValue('id_expiry_date', null)
      setAdditionalDocumentError({ error: false, message: '' })
      setAdditionalDocFiles([])
      setAdditionalDocumentArray([])
      clearErrors('id_number')
      clearErrors('id_expiry_date')
      clearErrors('additionalDocFiles')
    }
  }

  useEffect(() => {
    if (tabs[panelValue - 1] && tabs[panelValue - 1]?.status) {
      if (tabs[panelValue - 1]?.leadType === 'Customer') {
        notification('ra.notification.autoFilledCustomer', { type: 'info' })
      } else {
        notification('ra.notification.autoFilledLead', { type: 'info' })
      }
      tabs[panelValue - 1].status = false
      localStorage.setItem('tabs', JSON.stringify(tabs))
    }
    
  }, [])

  useEffect(() => {
    const localStorageData = localStorage.getItem('tabs')
    const dataArray = JSON.parse(localStorageData)
    if (dataArray && dataArray.length > 0) {
      const ids = dataArray.map((item) => item.id)
      setUids(ids)
    }
  }, [])

  const onFocus = () => {
    setIsFocus(true)
  }

  const onBlur = () => {
    setIsFocus(false)
  }

  return (
    <>
      {(nationalityLoading ||
        countryListLoading ||
        customerIndividualLoading ||
        contactLoading ||
        checkLoading) && <Loader cls="form-loader-color" />}
      {!nationalityLoading &&
        !countryListLoading &&
        !customerIndividualLoading &&
        !contactLoading && (
          <>
            <Grid className="booking-reservation booking-reservation-individual">
              <Grid className="booking-reservation-info">
                <h3>{translate('ra.fieldName.basicDetails')}</h3>
                <Typography component="div" className="authorised-details">
                  <BasicDetailsForm
                    register={register}
                    errors={errors}
                    setNationalityCode={setNationalityCode}
                    setNationalityState={setNationalityState}
                    clearErrors={clearErrors}
                    nationalityList={nationalityList}
                    nationalityState={nationalityState}
                    formData={formData}
                    setPhoneErrors={setPhoneErrors}
                    setError={setError}
                    mobilePhoneNumber={mobilePhoneNumber}
                    phoneErrors={phoneErrors}
                    alternateMobilePhoneNumber={alternateMobilePhoneNumber}
                    setMobilePhoneNumber={setMobilePhoneNumber}
                    setCountryCode={setCountryCode}
                    setAlternateMobilePhoneNumber={
                      setAlternateMobilePhoneNumber
                    }
                    customerType={customerType}
                    setAlternateMobilePhoneNumberCountryCode={
                      setAlternateMobilePhoneNumberCountryCode
                    }
                    setMobileNumber_with_code={setMobileNumber_with_code}
                    setDd_mobile_no={setDd_mobile_no}

                    setDd_authorised_alt_mobile_no={setDd_authorised_alt_mobile_no}
                    setAuthorised_alt_mobile_no_with_code={setAuthorised_alt_mobile_no_with_code}
                  />
                </Typography>
              </Grid>
              <Grid className="booking-reservation-info residence-details-panel">
                <h3>{translate('ra.fieldName.residenceDetails')}</h3>
                <Typography component="div" className="authorised-details">
                  <ResidentialDetails
                    register={register}
                    errors={errors}
                    formData={formData}
                    clearErrors={clearErrors}
                    countryState={countryState}
                    setCountryState={setCountryState}
                    setccode={setccode}
                    countryList={countryList}
                    customerType={customerType}
                    panelValue={panelValue}
                    addressCheck={addressCheck}
                    setAddressCheck={setAddressCheck}
                    checkLoading={checkLoading}
                    setCheckLoading={setCheckLoading}
                    tabs={tabs}
                    setValue={setValue}
                    notification={notification}
                    portal_booking_id={portal_booking_id}
                    partial={partial}
                    setPartial={setPartial}
                    watch={watch}
                    residenceDetails={residenceDetails}
                    setResidenceDetails={setResidenceDetails}
                    ccode={ccode}
                  />
                </Typography>
              </Grid>
              <Grid className="booking-reservation-info booking-reservation-info-last ">
                <h3>{translate('ra.fieldName.idDetails')}</h3>
                <Typography component="div" className="authorised-details">
                  {/* passport id number */}
                  <Grid>
                    <InputField
                      name={translate('ra.fieldName.passportNo')}
                      register={register}
                      errors={errors}
                      defaultValue={formData?.passport_number || ''}
                      id="outlined-basic-passport-national-id"
                      state="passport_number"
                      pattern={{
                        value: numberTextPattern,
                        message: translate('ra.validation.validInput'),
                      }}
                      placeholderHide={true}
                      message={translate('ra.validation.validPassport')}
                    />
                  </Grid>
                  {/* Expiry date */}
                  <Grid container spacing={0} className="date-calender-icon">
                    <DatePickerComponent
                      title={translate('ra.fieldName.passportExpiryDate')}
                      control={control}
                      name="passport_expiry_date"
                      value={formData?.passport_expiry_date}
                      minDate={expiryDate}
                      errors={errors?.passport_expiry_date}
                      message={translate('ra.validation.validPassporExpiry')}
                      notification={notification}
                      no_icon={false}
                    />
                    <i className="iconwasalt icon-calendar-month date-calender-position" />
                  </Grid>
                  {/* Passport doc*/}
                  <Grid className="upload-section">
                    <Typography variant="subtitle1">
                      {translate('ra.fieldName.upload_Passport')}
                      <span style={{ color: '#EF4349' }}>*</span>
                    </Typography>
                    <MultipleDocumentUpload
                      notification={notification}
                      deleteId={deleteId}
                      setDeleteId={setDeleteId}
                      maxFile={4}
                      fileType="passport"
                      clearErrors={clearErrors}
                      documentFilesArray={passportDocFiles}
                      setDocumentFilesArray={setPassportDocFiles}
                      documentObjectArray={passportDocumentArray}
                      setDocumentObjectArray={setPassportDocumentArray}
                      error={passportDocumentError?.error}
                      message={passportDocumentError?.message}
                    />
                  </Grid>
                </Typography>
              </Grid>

              {/* Additional Document Button */}

              <Grid
                className={`booking-reservation-info ${
                  uids && uids[0] !== formData?.id
                    ? 'booking-reservation-info-last'
                    : ''
                }`}
              >
                <Grid className="additional-details">
                  {!openAddDoc && (
                    <Button
                      className="add-remove-id-button"
                      onClick={() => setOpenAddDoc(true)}
                    >
                      <i className="iconwasalt icon-plus" />{' '}
                      <span>{translate('ra.fieldName.addAdditional')}</span>
                    </Button>
                  )}
                  {openAddDoc ? (
                    <>
                      <Grid className="booking-reservation-info booking-reservation-info-last additional-id-details">
                        <h3>{translate('ra.fieldName.additionalIdDetails')}</h3>
                        <Typography
                          component="div"
                          className="authorised-details"
                        >
                          {/*  id number */}
                          <Grid>
                            <InputField
                              name={translate('ra.fieldName.additionIdNumber')}
                              register={register}
                              errors={errors}
                              defaultValue={
                                openAddDoc ? formData?.id_number || '' : ''
                              }
                              id="outlined-basic-id"
                              className="mt0"
                              state="id_number"
                              pattern={{
                                value: numberTextPattern,
                                message: translate('ra.validation.idNumber'),
                              }}
                              placeholderHide={true}
                              message={translate('ra.validation.idNumber')}
                              required={openAddDoc}
                            />
                          </Grid>

                          {/* id Expiry date */}
                          <Grid
                            container
                            spacing={0}
                            className="date-calender-icon"
                          >
                            <DatePickerComponent
                              title={translate('ra.fieldName.idExpiryDate')}
                              control={control}
                              name="id_expiry_date"
                              value={
                                openAddDoc
                                  ? formData?.id_expiry_date !== '0000-00-00'
                                    ? formData?.id_expiry_date
                                    : ''
                                  : ''
                              }
                              minDate={expiryDate}
                              errors={errors?.id_expiry_date}
                              message={translate('ra.validation.validIdxpiry')}
                            />
                            <i className="iconwasalt icon-calendar-month date-calender-position" />
                          </Grid>
                          {/*id upload doc*/}
                          <Grid
                            className="upload-section"
                            container
                            spacing={0}
                          >
                            <Typography variant="subtitle1">
                              {translate('ra.fieldName.additionalUploadLabel')}
                              <span style={{ color: '#EF4349' }}>*</span>
                            </Typography>
                            <MultipleDocumentUpload
                              notification={notification}
                              maxFile={10}
                              fileType="additional"
                              documentFilesArray={additionalDocFiles}
                              setDocumentFilesArray={setAdditionalDocFiles}
                              documentObjectArray={additionalDocumentArray}
                              setDocumentObjectArray={
                                setAdditionalDocumentArray
                              }
                              error={additionalDocumentError?.error}
                              message={additionalDocumentError?.message}
                              deleteId={deleteId}
                              clearErrors={clearErrors}
                              setDeleteId={setDeleteId}
                            />
                          </Grid>
                        </Typography>
                      </Grid>
                      <Button
                        className="add-remove-id-button"
                        onClick={() => removeAdditional()}
                      >
                        <i className="iconwasalt icon-close" />{' '}
                        <span>
                          {translate('ra.fieldName.removeAdditional')}
                        </span>
                      </Button>
                    </>
                  ) : null}
                </Grid>
              </Grid>

              {/* {uids && uids[0] === formData?.id && (
                <>
                  <Grid
                    className="booking-reservation-info booking-reservation-variation-comment 
              booking-reservation-info-last"
                  >
                    <h3>{translate('ra.fieldName.comment')}
                    <span style={{ color: 'rgb(239, 67, 73)' }}>*</span>
                    </h3>
                    <textarea
                      className="textarea"
                      name="description"
                      rows="10"
                      cols="50"
                      onFocusCapture={onFocus}
                      defaultValue={formData?.units[0]?.variation_comment || ''}
                      onBlurCapture={onBlur}
                      placeholder={
                        !isFocus && translate('ra.placeholder.explain')
                      }
                      {...register('explain', { required: true })}
                      maxLength={4000}
                    ></textarea>
                    <span
                      className={watch('explain')?.length >= 4000 && 'error'}
                    >
                      {watch('explain')?.length === 4000 ? (
                        <span className="characterlimitexceeded">
                          {translate('ra.validation.characterlimitexceeded')}
                        </span>
                      ) : (
                        `${watch('explain')?.length}/4000`
                      )}
                    </span>
                  </Grid>
                </>
              )} */}
            </Grid>

            <Grid
              container
              className="company-details-button align-right"
              spacing={0}
            >
              {!booking_page && (
                <Button
                  variant="outlined"
                  className="mobile-only"
                  onClick={() => onClickBack()}
                >
                  {translate('ra.button.back')}
                </Button>
              )}

              <Button
                variant="contained"
                className={booking_page ? 'full-width-button' : ''}
                onClick={() => {
                 onSubmittingForm()
                }}
              >
                {' '}
                {translate('ra.button.continue')}
              </Button>
            </Grid>
            <div>
              {openDialog && (
                <DialogBox
                  openPopup={openDialog}
                  setOpenPopup={setOpenDialog}
                  icon="add-buyer"
                  content={translate('ra.content.addMoreBuyer')}
                  dialogAction="question"
                  handleNo={() => handleClickNo()}
                  handleYes={() => {
                    setOpenDialog(false)
                    createNewTab()
                  }}
                />
              )}
            </div>
          </>
        )}
    </>
  )
}

export default IndividualCustomerForm
