import { apiServiceRequest } from "Utils/axiosInstance";
import { getUserToken } from "Utils/tokenProvider";

// Update user details
const updateUserDetails = async (
  setLoading,
  form_data,
  notify,
  navigate,
  message,
  callUserDetails
) => {
  setLoading(true);
  const res = await apiServiceRequest({
    url: `/update-user-details`,
    headers: {
      Authorization: `Bearer ${await getUserToken(notify)}`,
      "Content-Type": "multipart/form-data",
    },
    method: "post",
    data: form_data,
    notify: notify,
  });
  if (res?.code === "success") {
    notify(message || "ra.notification.saveChanges", { type: "success" });
    if (navigate) navigate(-1);
    if (callUserDetails) callUserDetails("");
  } else {
    notify(message || res?.message, { type: "error" });
  }
  setLoading(false);
};

export default updateUserDetails;
