
  export const getDateFormat = (dateFormat) => {
    if (dateFormat) {

      const date = dateFormat?.getDate();
      const month = dateFormat?.getMonth() + 1;
      const year = dateFormat?.getFullYear();

      return year + "-" + numFormat(month) + "-" + numFormat(date);
    }
    return false;
  };  

  export const numFormat = (num) => {
    return num > 9 ? num : '0' + num
  }

  export const getCustomisedDate = (date) => {
    const monthNames = ["January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"
                       ];

    const shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June",
                             "July", "Aug", "Sep", "Oct", "Nov", "Dec"
                            ];
                            
    let day = new Date(date).getDate();
    let month = new Date(date).getMonth();
    let year = new Date(date).getFullYear();

    let dateCheck = "th"
    const date1 = ["1", "21", "31", 1, 21, 31]
    const date2 = ["2", "22", 2, 22]
    const date3 = ["3", "23", 3, 23]

    if(date1?.includes(day)) {
        dateCheck = "st"
    }
    if(date2?.includes(day)) {
        dateCheck = "nd"
    }
    if(date3?.includes(day)) {
        dateCheck = "rd"
    }

    return `${day}${dateCheck} ${shortMonthNames[month]} ${year}`
  }

  export const dateCustomFormat = (date) => {
    if(date) {
      return date.split("-").reverse().join("/");
    }
  }
