// in src/MyLayout.js
import React from "react";
import { Layout } from "react-admin";
import Header from "Components/Header";

export const MyLayout = (props) => (
  <Layout {...props} 
    appBar={()=> <></>}
    // appBar={() => <Header />} 
    sidebar={() => <></>} 
  />
);
