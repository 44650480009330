import { apiServiceRequest } from "Utils/axiosInstance";
import { getUserToken } from "Utils/tokenProvider";

const createInvoice = async (setLoading, data, notify, navigate, unit_no) => {
    setLoading(true);
    const res = await apiServiceRequest({
        url: `/create-invoice`,
        headers: { Authorization: `Bearer ${await getUserToken(notify)}`, "Content-Type": "multipart/form-data" },
        method: "post",
        data: data,
        notify: notify
    })
    if (res?.code === "success") {
        navigate("/invoice-success", { state: { unit_no } });
    }
    else {
        notify(res?.message, { type: "error" });
    }
    setLoading(false);
}

export default createInvoice; 