import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Styles from "./PropertydetailsAmenities.scss";
import AmenityIcon from "images/amenities.png";
import { amenitiesMap, replaceSpecialCharacters } from "Utils/amenitiesMapping";

const PropertydetailsAmenities = ({ amenities, translate }) => {
  return (
    <>
      <Grid className="project-info-panel pb-0">
        <Typography variant="h2">{translate("ra.fieldName.amenities")}</Typography>
        <ul
          className="amenities-list"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          {amenities?.map((amenity_object, index) => {
            const { amenity } = amenity_object;
            const icon = amenitiesMap[replaceSpecialCharacters(amenity)];

            return (
              <li key={index}>
                <span>
                  {icon ?
                    <i className={`iconwasalt icon-${icon}`}></i>
                  :
                    <img src={AmenityIcon} alt="amenity" />
                  }
                  {amenity}
                </span>
              </li>
            );
          })}
        </ul>
      </Grid>
    </>
  );
};

export default PropertydetailsAmenities;
