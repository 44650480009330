import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Mousewheel, Pagination } from "swiper";
import "./ImageGallery.scss";
import "swiper/css";

const ImageGallery = (props) => {
  const [imagesNavSlider, setImagesNavSlider] = useState(null);
  return (
    <section className="slider">
      <div className="slider__flex">
        <div className="slider__images">
          <div className="slider__pagination"></div>
          <div className="slider__image_section">
            <div className="slider__prev">
              <i className="iconwasalt icon-chevron-left"></i>
            </div>
            <Swiper
              thumbs={{
                swiper:
                  imagesNavSlider && !imagesNavSlider.destroyed
                    ? imagesNavSlider
                    : null,
              }}
              pagination={{
                el: ".slider__pagination",
                type: "fraction",
              }}
              direction="horizontal"
              slidesPerView={1}
              spaceBetween={0}
              mousewheel={true}
              navigation={{
                nextEl: ".slider__next",
                prevEl: ".slider__prev",
              }}
              breakpoints={{
                0: {
                  direction: "horizontal",
                },
                768: {
                  direction: "horizontal",
                },
              }}
              className="swiper-container2"
              modules={[Navigation, Thumbs, Mousewheel, Pagination]}
            >
              {props?.images?.map((slide, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="slider__image">
                      <img src={slide?.pL_ImageURL} alt="" />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="slider__next">
              <i className="iconwasalt icon-chevron-right"></i>
            </div>
          </div>
        </div>
        <div className="slider__col">
          <div className="slider__prev"><i className="iconwasalt icon-chevron-up"></i></div>

          <div className="slider__thumbs">
            <Swiper
              onSwiper={setImagesNavSlider}
              direction="vertical"
              spaceBetween={16}
              slidesPerView={5}
              navigation={{
                nextEl: ".slider__next",
                prevEl: ".slider__prev",
              }}
              className="swiper-container1"
              breakpoints={{
                0: {
                  direction: "horizontal",
                },
                768: {
                  direction: "vertical",
                },
              }}
              modules={[Navigation, Thumbs]}
            >
              {props?.images?.map((slide, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="slider__image">
                      <img src={slide?.pL_ImageURL} alt="" />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>

          <div className="slider__next"><i className="iconwasalt icon-chevron-down"></i></div>
        </div>
      </div>
    </section>
  );
};

export default ImageGallery;
