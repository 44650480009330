import { apiServiceRequest } from "Utils/axiosInstance";
import { getUserToken } from "Utils/tokenProvider";


const onlinePaymentMail = async (data, setOnlinePaymentLoading, notification, translate, headerPayload, setShowBookingIncomplete, bookingFailedPopup, setBookingFailedPopup) => {

  setOnlinePaymentLoading(true);
  const portalBookingId = localStorage.getItem("portalBookingId");
  data.portalBookingId = JSON.parse(portalBookingId);
  const res = await apiServiceRequest({
    url: "/booking-payment-mail",
    headers: { Authorization: `Bearer ${await getUserToken(notification)}`, payload: headerPayload },
    method: "post",
    data: data,
    notify: notification
  })

  if (res?.code === "success") {
    data.isOnline == "0"
      ? notification(translate("ra.notification.accountDetailsSentToCustomer"), { type: "info" })
      : notification(translate("ra.notification.paymentLinkSentToCustomer"), { type: "info" });
    setShowBookingIncomplete(false);
  }
  else {
    if (res.message.includes("booking time")) {
      if (!bookingFailedPopup) setBookingFailedPopup(true);
    } else {
      notification(res.message, { type: "error" });
    }
  }
  setOnlinePaymentLoading(false);
}


export const setPaymentMethod = async (setLoading, data, notification, headerPayload, setProceed) => {
  setLoading(true);
  const portalBookingId = localStorage.getItem("portalBookingId");
  data.portalBookingId = JSON.parse(portalBookingId);
  const res = await apiServiceRequest({
    url: "/payment-method",
    headers: { Authorization: `Bearer ${await getUserToken(notification)}`, payload: headerPayload },
    method: "put",
    data: data,
    notify: notification
  })
  if (res?.code === "success") {
    setProceed && setProceed(true);
  } else {
    setProceed && setProceed(false);
    notification(res?.error, { type: "error" });
  }
  setLoading(false);
}

export default onlinePaymentMail;