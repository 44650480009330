import { apiServiceRequest } from "Utils/axiosInstance";
import {
  bookingTableText,
  invoiceStatus,
  pendingStatus,
  statusButtonBlock,
  statusComplete,
} from "Utils/constantValues";
import { getCustomisedDate } from "Utils/dateFormat";
import { bookingContactFromQuery } from "Utils/filterData";
import { getFilterQuery } from "Utils/queryValues";
import { getUserToken } from "Utils/tokenProvider";

export const getBookingList = async (
  setLoading,
  data,
  setData,
  setTotalCount,
  setCount,
  page,
  notify,
  queryData,
  setFilter,
  salesList,
  loadType,
  translate,
  tabName,
  pageName
) => {
  if (setLoading) setLoading(true);

  let queryParam = "";
  if (queryData) {
    queryParam = getFilterQuery(queryData, pageName);
  }

  const res = await apiServiceRequest({
    url: `/my-bookings?limit=20&page=${page ? page : 0}${
      queryParam ? queryParam : ""
    }${tabName ? `&type=${tabName}` : ""}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });

  let resData = [];

  if (res?.code === "success") {
    if (res?.data?.list?.length > 0) {
      resData = res?.data?.list?.map((value) => {
        return {
          id: value?.portal_booking_id,
          title:
            Object.keys(value?.units)?.length > 1
              ? value?.project_name
              : getUnitList(value?.units)[0]?.unit_no,
          project_id: value?.project_id,
          project_name: value?.project_name,
          lead_type: value?.lead_type,
          last_updated: getCustomisedDate(value?.last_updated),
          unit: Object.keys(value?.units)?.length,
          units: getUnitList(value?.units),
          status:
            Object.keys(value?.units)?.length === 1 ||
            !statusButtonBlock?.includes(value?.booking_status)
              ? translate(bookingTableText[value?.booking_status])
              : getUnitBookingStatus(
                  value?.units,
                  translate,
                  value?.booking_status
                ),
          statusDetail: statusComplete.includes(value?.booking_status)
            ? "Completed"
            : "Pending",
          buttonBlock:
            Object.keys(value?.units)?.length === 1
              ? statusButtonBlock?.includes(value?.booking_status)
                ? "Completed"
                : "Pending"
              : getUnitStatus(value?.units),
          booking_id: value?.portal_booking_id,
          cancellation_count: value?.cancellation_count,
          cancellation_count_message: value?.cancellation_count
            ? `${value.cancellation_count} ${translate(
                "ra.fieldName.unitcancelled"
              )} `
            : null,
        };
      });
    }

    if (loadType) {
      const additionalData = [...data, ...resData];
      setData(additionalData);
    } else {
      setData(resData);
    }

    setTotalCount(res?.data?.total_count || 0);
    setCount(res?.data?.count || 0);
    if (setFilter)
      bookingContactFromQuery(
        queryData,
        salesList,
        setFilter,
        "",
        "",
        "",
        "",
        "",
        "",
        pageName
      );
  }
  if (setLoading) setLoading(false);
};

const getUnitList = (units) => {
  return Object.values(units).map((list) => {
    return list;
  });
};

const getUnitBookingStatus = (units, translate, booking_status) => {
  const unitData = Object.values(units).map((list) => {
    return list;
  });

  if (
    unitData?.filter((value) => value?.booking_status === "completed")
      ?.length === unitData?.length
  ) {
    return translate("ra.fieldName.paymentDone");
  } else if (
    unitData?.find((value) => invoiceStatus?.includes(value?.booking_status)) ||
    (booking_status === "completed" &&
      unitData?.every((value) => {
        return pendingStatus?.includes(value?.booking_status);
      }))
  ) {
    return translate("ra.fieldName.invoice_pending");
  } else if (
    unitData?.every((value) => {
      return pendingStatus?.includes(value?.booking_status);
    })
  ) {
    return translate("ra.fieldName.awaitingPay");
  }
};

const getUnitStatus = (units) => {
  const unitData = Object.values(units).map((list) => {
    return list;
  });

  if (
    unitData?.filter((value) => value?.booking_status === "completed")
      ?.length === unitData?.length
  ) {
    return "Completed";
  } else {
    return "Pending";
  }
};

export const getBookingDetails = async (
  setLoading,
  id,
  setData,
  notify,
  projectData,
  bookingFailedPopup,
  setBookingFailedPopup,
  setDocSeen
) => {
  setLoading(true);

  const res = await apiServiceRequest({
    url: `/booking-details?portalBookingId=${id}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });

  if (res?.code === "success") {
    setData(res?.data);
    setDocSeen && setDocSeen(res?.data?.seen_documents === 0 ? false : true);
    projectData && projectData(res?.data);
  } else {
    if (res?.message?.includes("booking time")) {
      if (!bookingFailedPopup) setBookingFailedPopup(true);
    } else {
      notify(res?.message, { type: "error" });
    }
  }
  setLoading(false);
};

export const documentSeenApi = async (res_id, notify, setDocSeen) => {
  const res = await apiServiceRequest({
    url: `/seen-documents/${res_id}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
    method: "put",
  });

  if (res?.code === "success") {
    setTimeout(() => {
      setDocSeen(true);
    }, 5000);
  }
};
