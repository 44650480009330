import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import Popup from "Components/Popup/Popup";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Styles from "./PropertydetailsImage.scss";
import ImageSlider from "Components/ImageSlider";
import { formatNumbers } from "Utils/thousandSeperators";
import GalleryDialog from "Components/GalleryDialog";

const PropertydetailsImage = ({
  imageType,
  images,
  startingPrice,
  startingArea,
  projectName,
  currency,
  unit_type,
  location,
  city,
  documents,
  handleShare,
  translate
}) => {
  const [openGallery, setOpenGallery] = useState(false);

  return (
    <>
      <Grid className="project-info-panel no-paddin">
        <Grid className="property-details">
          <ImageSlider
            showSlideNumber={true}
            imageType={imageType}
            imageList={images}
            type="project"
            handleShare={handleShare}
            imageClass="card-minheight-600"
            autoPlay={true}
            documents={documents}
            showArrows={true}
            clickhandler={() => setOpenGallery(true)}
          />
        </Grid>
        <Grid className="project-info">
          <Typography variant="h2" className="main-heading">
            {projectName}
          </Typography>
          <Typography variant="body1" className="location-text">
            {city}, {location}
          </Typography>
          <Grid className="project-info-section new-property-details">
            <Typography className="text-panel" component="div">
              <Typography component="h3">
                {translate("ra.fieldName.startPrice")}
                <span>
                  {formatNumbers(Math.round(startingPrice)) || 0}{" "}
                  <strong>{currency} </strong>
                </span>
              </Typography>
              <Typography component="h3">
                {translate("ra.fieldName.startArea")}
                <span>
                  {formatNumbers(Math.round(startingArea)) || 0}{" "}
                  <strong>{unit_type}</strong>
                </span>
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        {openGallery && (
          <GalleryDialog
            openGallery={openGallery}
            setOpenGallery={setOpenGallery}
            images={images}
          />
        )}
      </Grid>
    </>
  );
};

export default PropertydetailsImage;
