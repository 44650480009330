export const removeLocalStorage = () => {
    removePartLocalStorage();
    localStorage.removeItem("editPath");
    localStorage.removeItem("previous_url");
    localStorage.setItem("editCustomer", false);

    
    localStorage.removeItem("units");
    localStorage.removeItem("project_id");
}

export const removePartLocalStorage = () => {
    // sessionStorage.removeItem("formStatus");
    // // localStorage.removeItem("portalBookingId");
    // // localStorage.removeItem("tabs");
    // localStorage.removeItem("unit-reservation");
    // // localStorage.removeItem("bookingState");
    // localStorage.removeItem("bookingId");
}

export const clearLocalStorageValues=()=>{
  sessionStorage.removeItem("formStatus");
    localStorage.removeItem("portalBookingId");
    localStorage.removeItem("tabs");
    localStorage.removeItem("unit-reservation");
    localStorage.removeItem("bookingState");
    localStorage.removeItem("bookingId");
    
    localStorage.removeItem("editPath");
    localStorage.removeItem("previous_url");
    localStorage.setItem("editCustomer", false);
    
    localStorage.removeItem("units");
    localStorage.removeItem("project_id");
    localStorage.removeItem("customerType");
}