import React, { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import {
    Grid,
    Typography
} from "@mui/material";
import Loader from "Components/Loader";
import { documentSeenApi } from "Services/bookingService";
import { statusButtonBlock } from "Utils/constantValues";

const DownloadsTab = ({ details, loading, notification, docSeen, setDocSeen }) => {
    const translate = useTranslate();

    useEffect(() => {
        if (!details?.units?.map((unit) => unit?.kyc_status === 0)?.includes(true) && !docSeen) {
            const res_id = details?.units?.map(list => list?.oracle_reservation_id);
            documentSeenApi(res_id, notification, setDocSeen);
        }
    }, [])

    return (
        <>
            {loading && <Loader />}
            {details?.units &&
                (details?.units?.map((unit) => unit?.kyc_status === 0)?.includes(true)
                    ?
                    <Grid className="download-tab" spacing={0} >
                        <Typography variant="h6">{translate("ra.fieldName.verification")}</Typography>
                    </Grid>
                    :
                    <>
                        <Typography variant="h3">
                            <i className="iconwasalt icon-document" />
                            {translate("ra.fieldName.reservationForm")}
                        </Typography>
                        <Grid container className="documents-allinfo">
                            {details?.units?.map((list, idx) => {
                                return (
                                    <Grid container className="documents-info-section" spacing={0} key={idx}>
                                        <Typography variant="h4">
                                            {list?.unit_no}
                                            <a href={`/api/getOracleDocuments?Registration_ID=${list?.oracle_reservation_id}&docType=URF`} target="_blank" rel="noreferrer">
                                                <i
                                                    className="iconwasalt icon-download-new"
                                                />
                                            </a>
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {statusButtonBlock.includes(list?.booking_status)
                                                ? <i className="iconwasalt green-text icon-tick-outline " />
                                                : <i className="iconwasalt icon-tooltip" />
                                            }
                                            {statusButtonBlock.includes(list?.booking_status)
                                                ? <span className="green-text">{translate("ra.content.signed")}</span>
                                                : <span>{translate("ra.content.signature")}</span>
                                            }
                                        </Typography>
                                    </Grid>
                                )
                            })}
                        </Grid>
                        <Typography variant="h3">
                            <i className="iconwasalt icon-lead" />
                            {translate("ra.fieldName.salesAgreement")}
                        </Typography>
                        <Grid container className="documents-allinfo">
                            {details?.units?.map((list, idx) => {
                                return (
                                    <Grid container className="documents-info-section" spacing={0} key={idx}>
                                        <Typography variant="h4">
                                            {list?.unit_no}
                                            <a href={`/api/getOracleDocuments?Registration_ID=${list?.oracle_reservation_id}&docType=SPA`} target="_blank" rel="noreferrer">
                                            <i
                                                className="iconwasalt icon-download-new"
                                            />
                                            </a>
                                        </Typography>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </>
                )
            }
        </>
    );
};

export default DownloadsTab;
