import { apiServiceRequest } from 'Utils/axiosInstance'
import { getUserToken } from 'Utils/tokenProvider'
export const getFileDoc = async (
  setImageUrl,
  clickedDoc,
  setShowIframe,
  notify
) => {
  try {
    const { id } = clickedDoc
    const encodedJsonParam = btoa(id)

    const token = await getUserToken(notify)
    const response = await apiServiceRequest({
      url: `/register-user-doc/${encodedJsonParam}`,
      headers: { Authorization: `Bearer ${token}` },
    })
    if (response?.code === 'success') {
      let imageUrl = response?.data?.doc_url
      setImageUrl(response?.data?.doc_url)
      setShowIframe(true)
    } else {
      setImageUrl()
      setShowIframe(false)
    }
  } catch (error) {
    console.error('Error fetching document:', error)
    setImageUrl()
  }
}
