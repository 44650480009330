import React, { useEffect, useState } from "react";
import { TextField, Autocomplete, styled, InputAdornment } from "@mui/material";

const SearchIconAutoCompleteComponent = ({
  name,
  value,
  onChange,
  options,
  placeholder,
  className,
  getOptionLabel,
  getOptionDisabled,
  id,
  startAdornment = true
}) => {
  const Search = styled('div')(({ theme }) => ({}));
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
   if(inputValue?.length === 0) {
    setOpen(false)
   } else {
    setOpen(true)
   }
  }, [inputValue])

  return (
    <Autocomplete
      sx={{
        "& legend": { display: "none" },
        width: "100%",
        marginBottom: "20px",
      }}
      id={id}
      name={name}
      value={value}
      autoHighlight
      disablePortal
      getOptionLabel={getOptionLabel}
      onChange={(e, newInputValue) => {
        onChange(newInputValue);
        setOpen(false);
      }}
      options={options}
      popupIcon={false}
      blurOnSelect
      getOptionDisabled={getOptionDisabled}
      disableClearable
      open={open}
      onInputChange={(event, valueInput, reason) => {
        if (event && event.type === 'blur') {
          setInputValue("");
        } else if (reason === 'input' && valueInput?.length === 0 && value) {
          onChange("");
          setInputValue("");
        } else if (reason !== 'reset') {
          setInputValue(valueInput);
        }
      }}
      onClose={() => setOpen(false)}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          value={value}
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: startAdornment && (
              <InputAdornment position="start">
                <Search >
                  <i className="iconwasalt icon-search-new" />
                </Search>
              </InputAdornment>
            ),
            className: className
          }}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default SearchIconAutoCompleteComponent;
