import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Button,
  Typography,
  Link,
  Container,
  Grid,
} from "@mui/material";

import logo from "../../images/new-logo.svg";
import Styles from "./Header.scss";
import { useRedirect } from "react-admin";
import { removeLocalStorage } from "Utils/removeStorage";

function TopHeader({ path, pictureLoading, picture, page, notification, userData, releaseBlockUnits, pagePath= null }) {
  
  const [showBox, setShowBox] = useState(false);
  const formStatus = sessionStorage.getItem("formStatus") || null;
  const previous_url = localStorage.getItem("previous_url") || null;
  const redirect = useRedirect();

  const redirectLinkPath = (e, url) => {
    e.preventDefault();
    if(pagePath === "form-booking") {
      releaseBlockUnits(url);
      return;
    } 
  }

  const redirectPath = (e, url) => {
    e.preventDefault();
    redirect(url);
    if(formStatus || previous_url) {
      window.location.reload();
      removeLocalStorage();
      localStorage.removeItem("page");
      localStorage.removeItem("params");
      localStorage.removeItem("page-redirect");
    }
  }

  return (
    <Box className="top-header" sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar className={`header ${showBox ? "header-shadow" : ""}`} 
      component="nav">
        <Toolbar className="header-inner-panel">
          <Typography 
            className="header-logo" 
            component="div" 
            onClick={(e) => releaseBlockUnits ? redirectLinkPath(e, "/home") : redirectPath(e, "/home")} 
            sx={{ flexGrow: 1, textAlign: 'center' }}
          >
            <Link href={(pagePath === "form-booking") ? null : "#/home"} >
              <img src={logo} alt="Dar logo" />
            </Link>
          </Typography>
         
        </Toolbar>
      </AppBar>
      
    </Box>
  );
}

export default TopHeader;
