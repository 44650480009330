import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLogin, useRedirect, useTranslate, useStore } from 'react-admin'
import { Button, CardContent, Typography, Grid, Divider } from '@mui/material'
import image from '../../images/brokers_image.png'
import logo from '../../images/logo.svg'
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import { emailPattern } from 'Utils/regex-patterns'
import InputField from 'Components/InputField'
import gtagEventFire from 'Utils/ga4EventTrigger'
import useWebVitals from 'Utils/useWebVitals'

const Index = ({ setOracle_status, notification }) => {
  const [user_type, setUser_type] = useStore('user_type')
  const [rememberMe, setRememberMe] = useState(false)
  const login = useLogin()
  const redirect = useRedirect()
  const translate = useTranslate()
  const form = useForm({
    mode: 'onChange',
  })
  const { register, handleSubmit, formState } = form
  const { errors } = formState
  const pageType = 'Login'
  useWebVitals(pageType)

  const onSubmit = (data) => {
    const { email, password } = data
    gtagEventFire('sign-in', {
      user_email: email,
    })
    login({
      email,
      password,
      rememberMe,
      setOracle_status,
      notification,
    }).catch((error) => notification(error?.message, { type: 'error' }))
  }

  const handleRegister = () => {
    gtagEventFire('sign-up')
    setUser_type(2)
    //redirect("/roles");
    redirect('/register-agency')
  }

  const handleChange = () => {
    setRememberMe(!rememberMe)
  }

  return (
    <>
      <Grid
        className="form-panel height100 form-fixed"
        container
        sx={{ minHeight: '100vh', width: '100%' }}
      >
        <Grid
          xs={{ display: 'none' }}
          style={{ backgroundImage: `url(${image})` }}
          md={6}
          className="text-heading-section"
        >
          <Typography className="main-heading">
            {translate('ra.content.welcome')} {<br />}{' '}
            {translate('ra.content.dargloabal')} {<br />}{' '}
            <span>
              {translate('ra.content.network')}
              {<br />} {translate('ra.content.agent')}
            </span>
          </Typography>
          <Typography className="divider-separator">
            <span></span>
          </Typography>
          <Typography className="sub-heading">
            {translate('ra.content.realEstate')}
          </Typography>
          <Typography className="main-info">
            {translate('ra.content.joinAgents')}
          </Typography>
        </Grid>

        <Grid
          item
          className="first-login-section"
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column', // Align items in a column
            alignItems: 'center', // Center items horizontally
            position: 'relative',
            
          }}
        >
          <CardContent style={{ textAlign: 'left', maxWidth: '80%',  marginTop: '12%'}}>
            {' '}
            {/* Added maxWidth */}
            <div style={{ marginBottom: '20px' }}>
              {' '}
              {/* Added marginBottom */}
              <img
                src={logo}
                className="login-logo"
                alt="Dar logo"
                style={{ alignSelf: 'center' }}
              />{' '}
              {/* Center the logo */}
              <Typography className="form-user-name">
                {translate('ra.content.portal')}
              </Typography>
            </div>
            <Grid container spacing={2} className="mb0">
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'left',
                }}
              >
                <Typography
                  variant="body1"
                  component="p"
                  style={{
                    fontFamily: 'Arial, sans-serif',
                    fontSize: '16px',
                    fontWeight: 'normal',
                    color: '#333',
                  }}
                >
                  <Typography
                    variant="body1"
                    component="p"
                    style={{
                      fontFamily: 'Arial, sans-serif',
                      fontSize: '16px',
                      fontWeight: 'normal',
                      color: '#333',
                      textAlign: 'left', // Center the text horizontally
                    }}
                  >
                    Dear Valued Partner,
                  </Typography>
                  <br />

                  <Typography
                    variant="body1"
                    component="p"
                    style={{
                      fontFamily: 'Arial, sans-serif',
                      fontSize: '16px',
                      fontWeight: 'normal',
                      color: '#333',
                      textAlign: 'left',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      '@media (max-width: 600px)': {
                        // Adjust styles for screens up to 600px wide (typical mobile devices)
                        fontSize: '14px', // Decrease font size for smaller screens
                      },
                    }}
                  >
                    We would like to inform you that our Agent Portal will be
                    undergoing maintenance from June 21
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    style={{
                      fontFamily: 'Arial, sans-serif',
                      fontSize: '16px',
                      fontWeight: 'normal',
                      color: '#333',
                      textAlign: 'left', 
                      overflow: 'hidden',
                      '@media (max-width: 600px)': {
                        fontSize: '14px', 
                      },
                    }}
                  >
                    to June 25, 2024. During this time, the system will be
                    inaccessible.
                  </Typography>
                  <br />
                  <Typography
                    variant="body1"
                    component="p"
                    style={{
                      fontFamily: 'Arial, sans-serif',
                      fontSize: '16px',
                      fontWeight: 'normal',
                      color: '#333',
                      textAlign: 'left',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      '@media (max-width: 600px)': {
                        // Adjust styles for screens up to 600px wide (typical mobile devices)
                        fontSize: '14px', // Decrease font size for smaller screens
                      },
                    }}
                  >
                    If you need any assistance, please contact your designated
                    Relationship Manager or reach out
                  </Typography>
                  <Typography
                    variant="body1"
                    component="p"
                    style={{
                      fontFamily: 'Arial, sans-serif',
                      fontSize: '16px',
                      fontWeight: 'normal',
                      color: '#333',
                      textAlign: 'left',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      '@media (max-width: 600px)': {
                        fontSize: '14px', 
                      },
                    }}
                  >
                    directly to our Agents Support Team at{' '}
                    <a href="tel:+97142478990">+971 4 247 8990</a> or via email
                    at{' '}
                    <a href="mailto:agents@darglobal.co.uk">
                      agents@darglobal.co.uk
                    </a>
                    .
                  </Typography>
                  <br />

                  <Typography
                    variant="body1"
                    component="p"
                    style={{
                      fontFamily: 'Arial, sans-serif',
                      fontSize: '16px',
                      fontWeight: 'normal',
                      color: '#333',
                      textAlign: 'left', 
                    }}
                  >
                    Thank you for your cooperation and understanding.
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
    </>
  )
}

export default Index
