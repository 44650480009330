import React, { useState, useEffect } from "react";
import { useTranslate, useRedirect } from "react-admin";
import {
  Grid,
  Container,
  Typography,
  Box,
  Tab,
  Button,
  Tooltip
} from "@mui/material";
import Header from "Components/Header";
import BackButton from "Components/BackButton";
import Loader from "Components/Loader";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useLocation, useNavigate } from "react-router-dom";
import Error from "Pages/Error";
import { roleData } from "Utils/constantValues";
import BookingHistoryDetails from "../BookingDetails";
import { ClickAwayListener } from "@material-ui/core";
import { getLeadById } from "Services/leadService";

const CustomerDetails = ({ notification, userLoading, userData }) => {
  const [value, setValue] = useState("1");
  const [totalCount, setTotalCount] = useState(0);
  const [contactDetails, setContactDetails] = useState();
  const [contactLoading, setContactLoading] = useState(true);
  const [openTooltip, setOpenTooltip] = useState(false);
  const translate = useTranslate();
  const redirect = useRedirect();
  const location = useLocation();
  const navigate = useNavigate();
  const param = new URLSearchParams(location?.search);
  const id = parseInt(param?.get("id")) || "";

  useEffect(() => {
    if (id) {
      getLeadById(setContactLoading, id, setContactDetails, notification);
    }

    if (location?.pathname?.includes("/booking-history")) {
      setValue("2");
    } else {
      setValue("1");
    }

    document.body.classList.add("profile-page");
    return () => {
      document.body.classList.remove("profile-page");
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const redirectPage = () => {
    if (contactDetails?.leadType === "Individual") {
      redirect(`/individual-prospect-form?id=${id}`);
    } else {
      redirect(`/company-prospect-form?id=${id}`);
    }
  }

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  const tabSwitchHandler = (url) => {
    navigate(`${url}${location?.search}`, { replace: true });
  }

  return (
    <>
      {contactLoading && <Loader />}
      {!id ? (
        <Error />
      )
        :
        <>
          <Header path="My Contacts" picture={userData?.profile} pictureLoading={userLoading} notification={notification} userData={userData} />
          {!contactLoading &&
            <>
              <BackButton />
              <Typography className="edit-button mobile-only">
                {contactDetails?.leadStatus === "Prospect" &&
                  <i className="iconwasalt icon-edit-outline" onClick={redirectPage} />
                }
              </Typography>
              <Container className="company-container my-contacts-section customer-details-wrapper my-contacts-customer-details pt-0 mt-0">
                <Container className={`company-details customer-details-panel ${value == "2" ? "booking-history-height-wrapper" : ""}`}>
                  <Grid
                    className="form-panel profile-form-panel"
                    sx={{ width: "100%" }}
                  >
                    <Grid container className="profile-top-panel">
                      <Typography className="profile-top-info" variant="div">
                        <Typography variant="h3">
                          {contactDetails?.leadType === "Company" 
                            ? contactDetails?.company_name
                            : `${contactDetails?.first_name} ${contactDetails?.last_name}`
                          }
                        </Typography>
                        <Typography variant="h4">{contactDetails?.leadStatus} {translate("ra.fieldName.details")} ({contactDetails?.leadType})</Typography>
                      </Typography>
                    </Grid>
                    <Grid container className="profile-bottom-panel">
                      <Box sx={{ width: "100%", typography: "body1" }}>
                        <TabContext value={value}>
                          <Box sx={{ borderBottom: 1, borderColor: "#E9E9E9" }}>
                            <TabList
                              onChange={handleChange}
                              aria-label="details tab"
                            >
                              <Tab label={translate("ra.fieldName.contactDetails")} value="1" onClick={() => tabSwitchHandler("/contact-details")} />
                              {contactDetails?.leadStatus === "Customer" &&
                                <Tab label={translate("ra.fieldName.bookingHistory")} value="2" onClick={() => tabSwitchHandler("/booking-history")} />
                              }
                            </TabList>
                          </Box>
                          <TabPanel className={`${value === "2" ? "hidden" : ""}`} value="1">
                            {contactDetails?.leadType === "Company" &&
                              <>
                                <Typography variant="h4">{translate("ra.fieldName.companyDetails")}</Typography>
                                <Grid container spacing={0}>
                                  <Typography variant="subtitle1">{translate("ra.fieldName.companyName")}</Typography>
                                  <Typography>{contactDetails?.company_name}</Typography>
                                </Grid>
                                <Grid container spacing={0}>
                                  <Typography variant="subtitle1">{translate("ra.fieldName.tradeNo")}</Typography>
                                  <Typography>{contactDetails?.trade_license_number}</Typography>
                                </Grid>
                              </>
                            }
                            <Typography variant="h4">
                              {contactDetails?.leadType === "Company"
                                ? translate("ra.fieldName.authorisedDetails")
                                : translate("ra.fieldName.PersonalDetails")
                              }
                            </Typography>
                            <Grid container spacing={0}>
                              <Typography variant="subtitle1">{translate("ra.fieldName.firstName")}</Typography>
                              <Typography>{contactDetails?.first_name}</Typography>
                            </Grid>
                            <Grid container spacing={0}>
                              <Typography variant="subtitle1">{translate("ra.fieldName.lastName")}</Typography>
                              <Typography>{contactDetails?.last_name}</Typography>
                            </Grid>
                            <Typography variant="h4" className="mobile-mt32">{translate("ra.fieldName.contactDetails")}</Typography>
                            <Grid container spacing={0}>
                              <Typography variant="subtitle1">{translate("ra.fieldName.mobileNo")}</Typography>
                              <Typography>+({contactDetails?.mobile_no_country_code}) {contactDetails?.mobile_no}</Typography>
                            </Grid>
                            <Grid container spacing={0}>
                              <Typography variant="subtitle1">{translate("ra.fieldName.emailAddress")}</Typography>
                              <Typography className="email-info">
                                <ClickAwayListener onClickAway={handleTooltipClose}>
                                  <div>
                                    <Tooltip
                                      className="tooltip-info"
                                      open={openTooltip}
                                      title={contactDetails?.email}
                                      arrow
                                      onClose={handleTooltipClose}
                                      disableFocusListener
                                      disableHoverListener
                                      disableTouchListener
                                    >
                                      <i className="iconwasalt icon-eye" onClick={handleTooltipOpen} />
                                    </Tooltip>
                                  </div>
                                </ClickAwayListener>
                                <span>{contactDetails?.email}</span></Typography>
                            </Grid>
                            {contactDetails?.leadType === "Individual" &&
                              <>
                                <Typography variant="h4" className="mobile-mt32">{translate("ra.fieldName.idDetails")}</Typography>
                                <Grid container spacing={0}>
                                  <Typography variant="subtitle1">{translate("ra.fieldName.passport")}</Typography>
                                  <Typography>{contactDetails?.passport_number || "-"}</Typography>
                                </Grid>
                                <Grid container spacing={0}>
                                  <Typography variant="subtitle1">{translate("ra.fieldName.idPlaceIssue")}</Typography>
                                  <Typography>{contactDetails?.place_id_issue || "-"}</Typography>
                                </Grid>
                              </>
                            }
                            {userData?.user_type === 2 && roleData.includes(userData?.role) &&
                              <>
                                <Typography variant="h4" className="mobile-mt32">{translate("ra.fieldName.salesDetails")}</Typography>
                                <Grid container spacing={0}>
                                  <Typography variant="subtitle1">{translate("ra.fieldName.salesName")}</Typography>
                                  <Typography>{contactDetails?.salesperson_name}</Typography>
                                </Grid>
                              </>
                            }
                            {contactDetails?.leadStatus === "Prospect" &&
                              <Grid container className="company-details-button new-button-panel  desktop-only align-right" spacing={0}>
                                <Button
                                  variant="contained"
                                  className="new-color-button"
                                  onClick={redirectPage}
                                >
                                  {translate("ra.button.edit")}
                                </Button>
                              </Grid>
                            }
                          </TabPanel>
                          <TabPanel className="flex-column booking-tab-hide-padding" value="2">
                            <BookingHistoryDetails
                              userData={userData}
                              currentPage="booking-history"
                              notification={notification}
                              setTotalCount={setTotalCount}
                            />
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </Container>
            </>
          }
        </>
      }
    </>
  );
};

export default CustomerDetails;
