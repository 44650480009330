import polyglotI18nProvider from "ra-i18n-polyglot";
import { englishMessages } from "Language/englishMessages";

// multiple language support
const translations = { 
  "en": englishMessages 
};

export const i18nProvider = polyglotI18nProvider(locale =>
  translations[locale],
);