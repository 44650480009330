import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Typography } from "@mui/material";
import Heart from "../../images/wishlist-icon.png";
import ShareIcon from "../../images/share-icon.svg";
import HeartActive from "../../images/wishlist-active-icon.svg";
import ButtonComponent from "Components/Common/ButtonComponent";
import Styles from "./ImageSlider.scss";

const ImageSlider = ({
  imageList,
  type,
  handleShare,
  wishlist,
  wishlistHandler,
  imageClass,
  imageType,
  clickhandler,
  autoPlay = false,
  documents,
  showArrows = false,
  showSlideNumber,
}) => {
  return (
    <>
      {
        /* eslint-disable */
        imageList?.length ? (
          <Carousel
            showArrows={showArrows}
            showThumbs={false}
            autoPlay={autoPlay}
            infiniteLoop={true}
            showStatus={showSlideNumber}
            onClickItem={clickhandler}
            interval={3000}
            statusFormatter={(currentItem, totalCount) => {
              return `${currentItem}/${totalCount}`;
            }}
          >
            {imageList?.map((image, idx) => (
              <div key={idx}>
                <img
                  className={`campaign-header-image ${imageClass}`}
                  src={image[imageType]}
                  fetchpriority="high"
                  alt={image?.name}
                  loading="lazy"
                />
              </div>
            ))}
          </Carousel>
        ) : (
          <img
            className={`campaign-header-image ${imageClass}`}
            src="/assets/images/no-image.jpg"
            alt="no image"
            onClick={(type !== "project" && type !== "unit") ? clickhandler : null}
          />
        )
      }
      {(type === "project" || type === "unit") && (
        <Typography className="share-info" component="div">
          <ul>
            {type !== "project" && (
              <li className="wishlist-button-icon">
                <ButtonComponent
                  onClick={() => wishlistHandler()}
                  src={wishlist ? HeartActive : Heart}
                  alt_name="wishlist"
                  className={`button-icon "active"}`}
                />
              </li>
            )}
            {documents?.length > 0 && (
              <li>
                <ButtonComponent
                  onClick={() => handleShare()}
                  src={ShareIcon}
                  alt_name="share"
                  className="button-icon"
                />
              </li>
            )}
          </ul>
        </Typography>
      )}
    </>
  );
};

export default ImageSlider;
