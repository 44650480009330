import { apiServiceRequest } from "Utils/axiosInstance";

//currency Service
const resetPasswordService = async (
  requestId,
  setIsDisableButton,
  setResetLoading,
  url,
  data,
  notify,
  redirect
) => {
  setResetLoading(true);
  const res = await apiServiceRequest({
    url: `resetPassword/${requestId}`,
    method: "post",
    data: data,
    notify: notify,
  });
  if (res?.code === "success") {
    notify(res?.message, { type: "success" });
    setIsDisableButton(true);
    setTimeout(() => {
      redirect(url?.origin);
    }, 1000);
  } else {
    notify(res?.message, { type: "error" });
  }
  setResetLoading(false);
};

export default resetPasswordService;
