import React from "react";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Typography } from "@mui/material";
import isRead from "Utils/ui-lock-status";
import { useTranslate } from "react-admin";
import ErrorComponent from "../ErrorComponent";

const DatePickerComponent = ({
    title,
    hide = false,
    control,
    name,
    value,
    minDate,
    maxDate,
    errors,
    no_validation = false,
    os,
    onChangeHandler,
    placeholder = "dd-mm-yyyy",
    message 
}) => {
    const translate = useTranslate();
    const dropdownYears = new Date().getFullYear() - 1850;

    return (
        <>
            <Typography variant="subtitle1">
                {title}
                {!hide && !no_validation && <span style={{ color: "#EF4349" }}>*</span>}
            </Typography>
            <Controller
                control={control}
                name={name}
                rules={{ required: !hide && !no_validation && (message || translate("ra.validation.validDate") )}}
                error={Boolean(errors)}
                helperText={errors?.message}
                defaultValue={value && value !== "Invalid date"
                    ? new Date(value)
                    : ""
                }
                render={({ field }) => (
                    <DatePicker
                        placeholderText={placeholder}
                        dateFormat="dd-MM-yyyy"
                        readOnly={isRead(os) || hide}
                        showMonthDropdown
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={dropdownYears}
                        onChange={(date) => {
                            onChangeHandler && onChangeHandler(date)
                            field.onChange(date)
                        }}
                        wrapperClassName={errors ? "react-datepicker-wrapper-error" : ""}
                        selected={(field.value == null || field.value) 
                            ? field.value 
                            : value && value !== "Invalid date"
                            ? new Date(value)
                            : ""
                        }
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                )}
            />
            {errors?.message && (
                <ErrorComponent errors={errors?.message} />
            )}
        </>
    );
};

export default DatePickerComponent;
