import { apiServiceRequest } from 'Utils/axiosInstance'
import { baseURL as mapBaseURL } from 'Utils/baseUrl'
import { getQueryFromParams } from 'Utils/queryValues'
import { useQueryClient } from 'react-query'
import { getUserToken } from 'Utils/tokenProvider'
import {
  unitApiDataModification,
  unitInfoApiDataModification,
} from '../unitsDataFormation'

import { getWishListId } from './wishlistService'
/**
Salesforce API implementation
@author Fawwad Khan
@date 09-02-2024
Retrieve all units of the project by country and project ID.
*/
export const unitListInfo = async (
  setLoading,
  setData,
  setCount,
  setTotalNoOfRecords,
  userDetail,
  location,
  setWishlistProject,
  callingDocApi,
  unit_no,
  notify,
  pagePath
) => {
  setLoading(true)
  let query = {}
  const unit_type = userDetail?.unit_type?.toUpperCase() || ''
  const currency = userDetail?.currency?.toUpperCase() || ''
  const officE_SECTION_ID = unit_no
  if (location) {
    query = getQueryFromParams(
      location,
      currency,
      unit_type,
      'unitlist',
      officE_SECTION_ID
    )
  }

  
  /** API Implementaion */
  const res = await apiServiceRequest({
    url: `${mapBaseURL}inventory?jsonParam=${btoa(
      JSON.stringify({
        Limit: 12,
        Page: pagePath ? 1 : parseInt(query?.page) || 1,
        Conditions: query?.conditions || [],
        Sorts: query?.sort || [],
      })
    )}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  })
  let totalNoOfRecords;
  if (res?.successStatus) {
    
    totalNoOfRecords=res?.response?.totalNoOfRecords
    const objList = res?.response?.objList
    let resData = []
    if (objList && objList.length > 0) {
     const projectImages = location.projectid
  ? await fetchProjectImagesById(location.projectid)
  : await fetchProjectImagesById(objList[0]?.projectCode);
      const updatedObjList = objList.map((item) => ({
        ...item,
        images: projectImages,
      }))
      resData = unitApiDataModification(
        updatedObjList,
        currency,
        userDetail,
        unit_type
      )

      // getting project id and calling wishlist api to get all unit id which is wishlisted
      let project_id = location.projectid
      const projectIdArray = [project_id]
      let responseArray = await Promise.all(
        projectIdArray.map(async (id, idx) => {
          let response = await getWishListId('', id, '', notify)
          return response || []
        })
      )

      setWishlistProject && setWishlistProject(responseArray.flat() || [])
      callingDocApi && callingDocApi(resData, projectIdArray)
    }
    setData(resData)
    setCount && setCount(totalNoOfRecords || 0)
    setTotalNoOfRecords && setTotalNoOfRecords(totalNoOfRecords || 0)
  }
  setLoading(false)
}

/**
 * Fetches unit information asynchronously and updates the state accordingly.
 *
 * @param {function} setLoading - A function to set the loading state, typically used to indicate whether data is being fetched.
 * @param {function} setData - A function to update the state with fetched data.
 * @param {function} notify - A function to notify the user or handle notifications.
 * @param {string} unitcode - The code or identifier of the unit for which information is being fetched.
 * @param {function} callAmenities - A function to fetch additional information or amenities related to the unit.
 */
export const unitInfo = async (
  setLoading,
  setData,
  notify,
  unitcode,
  callAmenities,
  setUnitPlanDocument
) => {
  setLoading(true)
  try {
    const res = await apiServiceRequest({
      url: `${mapBaseURL}inventory?jsonParam=${btoa(
        JSON.stringify({
          Conditions: [
            {
              Field: 'officE_SECTION_ID',
              Value: `${unitcode}`,
              Filter: 'equal',
            },
          ],
        })
      )}`,
      headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
      notify: notify,
    })
    if (res?.successStatus) {
      const unit = res?.response?.objList
      if (unit?.length) {
        const resData = await unitInfoApiDataModification(unit[0])
        const projectCode = resData[0]?.project_id
        if (projectCode) {
          const projectImages = await fetchProjectImagesById(projectCode)
          // Append project images to resData[0]
          const updatedData = {
            ...resData[0],
            images: projectImages,
          }
          setData(updatedData)
        } else {
          setData(resData[0] || [])
        }

        if (callAmenities) {
          callAmenities(resData[0]?.projecT_ID, resData[0])
        }
        // Additional functionality for unit plan layout
        let updatedUnitPlanDocumentArray
        if (resData[0]?.unitPlan) {
          const unitPlanDocumentData = {
            documentName: 'Unit Layout',
            propertyID: resData[0]?.project_id || '',
            propertyName: resData[0]?.projecT_NAME || '',
            unitNo: resData[0]?.unitCode || '',
            documentLink:
              resData[0]?.unitLayoutLinkTiny || resData[0]?.unitPlan,
          }

          updatedUnitPlanDocumentArray = [unitPlanDocumentData]
        } else {
          updatedUnitPlanDocumentArray = []
        }
        // Set the state with the updated array
        setUnitPlanDocument(updatedUnitPlanDocumentArray)
      }
    }
  } catch (error) {
    notify('Error fetching unit info. Please try again later.', 'error')
  }
  setLoading(false)
}

/**
 * Fetches project images by project ID.
 *
 * @param {string} id - The ID of the project.
 * @returns {Array|null} An array of project images if successful, otherwise null.
 */

export const fetchProjectImagesById = async (id) => {
  
  try {
    const token = await getUserToken()
    const res = await apiServiceRequest({
      url: `${mapBaseURL}GetBrokerProjects?jsonParam=${btoa(
        JSON.stringify({
          Conditions: [
            {
              Field: 'propertY_ID',
              Value: `${id}`,
              Filter: 'equal',
            },
          ],
        })
      )}`,
      headers: { Authorization: `Bearer ${token}` },
    })

    if (res?.successStatus) {
      const project = res?.response?.objList
      const images = project[0]?.ProjectImage
      return images
    }
  } catch (error) {
    // Handle errors here
    return null
  }
}

/**
 * Retrieves details of units specifically for review purposes.
 *
 * @param {function} setLoading - Function to set the loading state.
 * @param {function} setData - Function to set the unit data.
 */
export const UnitListDetailsForReview = async (
  setLoading,
  setData,
  setCount,
  userDetail,
  location,
  notify,
  setWishlistProject,
  callingDocApi,
  unit_id,
  setFullData,
  setFailedUnits,
  unit_no,
  setFailedUnitsPopup,
  pagePath,
  deleteCode,
  setDeleteCode
) => {
  setLoading(true)
  let query = {}

  const unit_type = userDetail?.unit_type?.toUpperCase() || ''
  const currency = userDetail?.currency?.toUpperCase() || ''
  const officE_SECTION_ID = unit_id
  
  if (location) {
    query = getQueryFromParams(
      location,
      currency,
      unit_type,
      'unitlist',
      officE_SECTION_ID
    )
  }

  /* eslint-disable */
  const res = await apiServiceRequest({
    url: `${mapBaseURL}inventory?jsonParam=${btoa(
      JSON.stringify({
        Limit: 12,
        Page: pagePath ? 1 : parseInt(query?.page) || 1,
        Conditions: query?.conditions || [],
        Sorts: query?.sort || [],
      })
    )}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  })

  if (res?.successStatus) {
    setFullData && setFullData(res?.response?.objList)
    const resData = unitApiDataModification(
      res?.response?.objList,
      currency,
      userDetail,
      unit_type
    )
   
    setData && setData(resData || [])
    setCount && setCount(res?.response?.totalNoOfRecords || 0)
    
    if (pagePath) {
      if (res?.response?.totalNoOfRecords === 0) {
        const non_delete_unit = unit_no?.filter(
          (unit) => !deleteCode?.includes(unit)
        )
        setDeleteCode([...deleteCode, ...non_delete_unit])

        if (non_delete_unit?.length > 0) {
          setFailedUnitsPopup && setFailedUnitsPopup(true)
          setFailedUnits && setFailedUnits([...non_delete_unit])
        }
      } else {
        
        const units = res?.response?.objList?.map(
          (unit) => unit?.officE_SECTION_CODE
        )
        const non_delete_unit = unit_no?.filter(
          (unit) => !deleteCode?.includes(unit)
        )
        
        const unit_array = non_delete_unit?.filter(
          (unit) => !units?.includes(unit)
        )
        setDeleteCode([...deleteCode, ...unit_array])

        // if (unit_array?.length > 0) {
        //   setFailedUnitsPopup && setFailedUnitsPopup(true)
        //   setFailedUnits && setFailedUnits([...unit_array])
        // }
      }
    }

    //   // getting project id and calling wishlist api to get all unit id which is wishlisted
    //   if (resData?.length) {
    //     const projectIdArray = []
    //     let responseArray = await Promise.all(
    //       projectIdArray.map(async (id, idx) => {
    //         let response = await getWishListId('', id, '', notify)
    //         return response || []
    //       })
    //     )
    //     setWishlistProject && setWishlistProject(responseArray.flat() || [])
    //     callingDocApi && callingDocApi(resData, projectIdArray)
    //   }
  }
  setLoading(false)
}

export const unitListCount = async (
  setLoading,
  setData,
  userDetail,
  location,
  notify
) => {
  setLoading(true)
  let query = {}

  const unit_type = userDetail?.unit_type?.toUpperCase() || ''
  const currency = userDetail?.currency?.toUpperCase() || ''

  if (location) {
    query = getQueryFromParams(location, currency, unit_type, 'unitlist')
  }

  /* eslint-disable */
  const res = await apiServiceRequest({
    url: `${mapBaseURL}inventory?jsonParam=${btoa(
      JSON.stringify({
        Conditions: query?.conditions || [],
      })
    )}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  })

  if (res?.successStatus) {
    const resData = res?.response?.totalNoOfRecords
    setData(resData || 0)
  }
  setLoading(false)
}

export const retrieveUnitDataByprojectids = async (projectcode, notify) => {
  try {
    const res = await apiServiceRequest({
      url: `${mapBaseURL}inventory?jsonParam=${btoa(
        JSON.stringify({
          Conditions: [
            {
              Field: 'projecT_ID',
              Value: `${projectcode}`,
              Filter: 'in',
            },
          ],
        })
      )}`,
      headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
      notify: notify,
    })
    if (res?.successStatus) {
      const objList = res?.response?.objList
      return objList
    }
  } catch (error) {
    notify('Error fetching unit info. Please try again later.', 'error')
  }
}

export const brokerUnitLayout = async (
  setDocuments,
  setLoading,
  notify,
  id,
  unitNo,
  projectName,
  projectId,
  unitArray
) => {
  
  setLoading(true)

  let unitId = ''
  if (unitArray?.length) {
    unitId = unitArray?.map((list) => list?.unit_id)?.toString()
  } else {
    unitId = id
  }

  const res = await apiServiceRequest({
    url: `${mapBaseURL}inventory?jsonParam=${btoa(
      JSON.stringify({
        Conditions: [
          {
            Field: 'officE_SECTION_ID',
            Value: `${unitId}`,
            Filter: 'equal',
          },
        ],
      })
    )}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  })
  if (res?.successStatus) {
    const unit = res?.response?.objList
    let resData = unit.map((list) => ({
      propertyID: list?.projectCode || '',
      propertyName: list?.projectName || '',
      unitNo: list?.unitCode || '',
      documentName: 'Unit Layout',
      documentLink: list?.unitLayoutLinkTiny || list?.unitLayoutLink,
    }))

    if (setDocuments) setDocuments(resData || [])
    setLoading(false)
    return resData
  }
}

export const brokerSaleOfferService = async (
  setSaleOffer,
  setSaleOfferLoading,
  notify,
  id,
  unitNo,
  name,
  project_id,
  unitArray
) => {
  setSaleOfferLoading(true)

  let unitId = ''
  if (unitArray?.length) {
    unitId = unitArray?.map((list) => list?.unit_id)?.toString()
  } else {
    unitId = id
  }

  const res = await apiServiceRequest({
    url: `${mapBaseURL}inventory?jsonParam=${btoa(
      JSON.stringify({
        Conditions: [
          {
            Field: 'officE_SECTION_ID',
            Value: `${unitId}`,
            Filter: 'equal',
          },
        ],
      })
    )}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  })
  if (res?.successStatus) {
    const unit = res?.response?.objList
    let resData = unit.map((list) => ({
      propertyID: list?.projectCode || '',
      propertyName: list?.projectName || '',
      unitNo: list?.unitCode || '',
      documentName: 'Sales Offer',
      documentLink: list?.salesOfferTinyUrl || list?.salesOfferLink,
    }))
    if (setSaleOffer) setSaleOffer(resData || [])
    setSaleOfferLoading(false)
    return resData
  }
}


export const brokerUnitLayoutSaleOfferService = async(
  
  setSaleOffer,
  setSaleOfferLoading,
  setUnitPlanDocument,
  setUnitPlanLoading,
  notify,
  id,
  unitNo,
  projectName,
  projectId,
  unitArray) =>{

    setSaleOfferLoading(true)
    setUnitPlanLoading(true)
  let unitId = ''
  if (unitArray?.length) {
    unitId = unitArray?.map((list) => list?.unit_id)?.toString()
  } else {
    unitId = id
  }

  const res = await apiServiceRequest({
    url: `${mapBaseURL}inventory?jsonParam=${btoa(
      JSON.stringify({
        Conditions: [
          {
            Field: 'officE_SECTION_ID',
            Value: `${unitId}`,
            Filter: 'equal',
          },
        ],
      })
    )}`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  })

  if (res?.successStatus) {
    const unit = res?.response?.objList
  
  let salesOfferData  = unit.map((list) => ({
    propertyID: list?.projectCode || '',
    propertyName: list?.projectName || '',
    unitNo: list?.unitCode || '',
    documentName: 'Sales Offer',
    documentLink: list?.salesOfferTinyUrl || list?.salesOfferLink,
  }))

  let unitLayoutData = unit.map((list) => ({
    propertyID: list?.projectCode || '',
    propertyName: list?.projectName || '',
    unitNo: list?.unitCode || '',
    documentName: 'Unit Layout',
    documentLink: list?.unitLayoutLinkTiny || list?.unitLayoutLink,
  }))
  // Combine salesOfferData and unitLayoutData into a single array
  let finalData = [...salesOfferData, ...unitLayoutData];

  if (setSaleOffer) setSaleOffer(salesOfferData || [])
  if(setUnitPlanDocument) setUnitPlanDocument(unitLayoutData || [])
  setSaleOfferLoading(false)
  setUnitPlanLoading(false)
  return finalData;
  }
}

/** 
 * unitAvailabilityCheck 
*/

export const unitAvailabilityCheck = async (
  setLoading,
  notify,
  unitIds,
  setCustomerSelectionPopup,
  setFailedUnitsPopup,
  setFailedUnits,
  details,
  setDetails,
  deleteCode,
  setDeleteCode
) =>{
  
  setLoading(true)
  const res = await apiServiceRequest({
    url: `${mapBaseURL}inventory?jsonParam=${btoa(
      JSON.stringify({
        Conditions: [
          {
            Field: 'officE_SECTION_ID',
            Value: `${unitIds}`,
            Filter: 'equal',
          },
        ],
      })
    )}`,

    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  })
  
  if (res?.successStatus) {
    
    const failedUnits = res?.response?.objList?.filter(
      (units) => units?.inventoryStatus !== 'Available'
    )
    
    if (failedUnits?.length > 0) {
      setFailedUnitsPopup(true)
      let failed = failedUnits?.filter((failed) => {
        return details?.filter((units) => {
          return units?.unitCode == failed?.unitCode
        })
      })
      let failedCode = failed?.map((unit) => unit?.unitCode)
      const failedId = failed?.map((unit) => unit?.unitCode)
      setFailedUnits(failedCode)
      setDetails(
        details?.filter((unit) => !failedId?.includes(unit?.unitCode))
      )
      setDeleteCode([...deleteCode, ...failedCode])
    } else {
      setCustomerSelectionPopup(true)
    }
  }
  setLoading(false)
}