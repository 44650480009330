import { apiServiceRequest } from "Utils/axiosInstance";
import { getUserToken } from "Utils/tokenProvider";

const adjustPaymentToken = async (setLoading, data, notify, showAdjustTokenPayment, headerPayload, changeAmountSlider, bookingFailedPopup,
    setBookingFailedPopup, setProceed) => {
    setLoading(true);
    const res = await apiServiceRequest({
        url: "/adjust-payment-token",
        headers: { Authorization: `Bearer ${await getUserToken(notify)}`, payload: headerPayload },
        method: "put",
        data: data,
        notify: notify
    })
    if (res?.code === "success") {
        setProceed && setProceed(true);
        if (changeAmountSlider) changeAmountSlider();
        if (showAdjustTokenPayment) {
            notify("ra.notification.tokenAdjusted", { type: "success" })
            showAdjustTokenPayment(false);
        }
    }
    else {
        setProceed && setProceed(false);
        if (res.message.includes("booking time")) {
            if (!bookingFailedPopup) setBookingFailedPopup(true);
        } else {
            notify(res?.message, { type: "error" });
        }
    }
    if (showAdjustTokenPayment) showAdjustTokenPayment(false);
    setLoading(false);
}

export default adjustPaymentToken;