import { apiServiceRequest } from "Utils/axiosInstance";
import { getUserToken } from "Utils/tokenProvider";

export const bannerDetails = async (setBannerLoading, setBannerData, notify) => {
    const productionUrl = "https://agents.darglobal.co.uk";
    const url = new URL(window.location);

    setBannerLoading(true)

    if(url?.origin === productionUrl) {
        // calling live url of darglobal
        const res = await apiServiceRequest({
            url: "https://darglobal.co.uk/wp-json/project-data/v1/allprojects",
            headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
            notify: notify
        });

        if(res?.status === "200") {
            setBannerData(res?.data);
        }
    } else {
        // calling preprod url of darglobal
        const res = await apiServiceRequest({
            url: "/all-campaigns",
            headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
            notify: notify
        });

        if(res?.code === "success") {
            setBannerData(res?.data);
        }
    }
    setBannerLoading(false)
};