import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Styles from "./PropertydetailsInfo.scss";
import { formatNumbers } from "Utils/thousandSeperators";

const PropertydetailsInfo = ({
  unit_number,
  type,
  bedroom,
  total_area,
  internal_area,
  status,
  price,
  unit_type,
  currency,
  balcony_area,
  completion_date,
  translate
}) => {
  return (
    <>
      <Grid className="project-info-panel">
        <Typography variant="h2">{translate("ra.fieldName.propertyDetails")}</Typography>
        <ul className="project-details">          
          <li>
            <span>{translate("ra.fieldName.bedroom")}</span>
            <span>{bedroom ? bedroom : "-"}</span>
          </li>
          <li>
            <span>{type?.includes("VILLA") ? translate("ra.fieldName.bua") : translate("ra.fieldName.totalArea")}</span>
            <span>
              {total_area
                ? `${formatNumbers(parseFloat(total_area)?.toFixed(2))} ${unit_type}`
                : "-"}
            </span>
          </li>
          <li>
            <span>
              {type?.includes("VILLA") ? translate("ra.fieldName.plotArea") : translate("ra.fieldName.internalArea")}
            </span>
            <span>
              {internal_area
                ? `${formatNumbers(parseFloat(internal_area)?.toFixed(2))} ${unit_type}`
                : "-"}
            </span>
          </li>
          <li>
            <span>{translate("ra.fieldName.balconyArea")}</span>
            <span>
              {balcony_area
                ? `${formatNumbers(parseFloat(balcony_area)?.toFixed(2))} ${unit_type}`
                : "-"}
            </span>
          </li>
          <li>
            <span>{translate("ra.fieldName.status")}</span>
            <span>{status || "-"}</span>
          </li>
          <li>
            <span>{translate("ra.fieldName.price")}</span>
            <span>
              {currency}{" "}
              {formatNumbers(price) || 0}{" "}
            </span>
          </li>
          <li>
            <span>{translate("ra.fieldName.completionDate")}</span>
            <span>{completion_date?.split(" ")[0] || "-"}</span>
          </li>
        </ul>
      </Grid>
    </>
  );
};

export default PropertydetailsInfo;
