// Filtering project based on country
import { useState } from 'react'
import { currencyStatus } from './currencyCheck'
import { removeDuplicates } from './removeDuplicates'
import { retrieveUnitDataByprojectids } from '../Services/Salesforce/unitService'
import { transformData } from 'Utils/utils'

export const countryBasedFilter = (
  countryValues,
  projectFilterDetails,
  setProjectDetails,
  projectValues,
  setProjectValues,
  filterData
) => {
  if (countryValues?.length) {
    const countryArray = [...countryValues]
    const projectArray = []
    countryArray.map((list) =>
      projectFilterDetails?.map((data) => {
        if (data?.country === list?.name) {
          projectArray.push(data)
        }
      })
    )

    setProjectDetails(projectArray || [])
    const projectList = [...projectValues]
    if (projectList?.length) {
      let filterProject = []
      projectArray?.filter((data) => {
        projectList.map((list) => {
          if (list?.propertY_ID === data?.propertY_ID) {
            filterProject.push(data)
          }
        })
      })
      // handling this when project is not selected
      if (filterProject?.length === 0) {
        setProjectValues([])
      } else {
        setProjectValues(filterProject)
      }
    }
  } else {
    let filterProjectData = filterData?.projectList?.length
      ? filterData?.projectList
      : []
    setProjectDetails(filterProjectData)
  }
}
// Filtering type, price, size and status based on country and project
export const projectBasedFilter = (
  projectValues,
  projectDetails,
  userData,
  filterData,
  page,
  propertyTypeValues,
  bedroomValues,
  statusValues,
  setPropertyTypeDetails,
  setBedroomDetails,
  setStatusDetails,
  setPropertyTypeValues,
  setBedroomValues,
  setStatusValues
) => {
  const projectList = projectValues?.length > 0 ? projectValues : projectDetails
  if (projectList?.length) {
    const type = []
    const bedroom = []
    const status = []
    const typeList = [...propertyTypeValues]
    const bedroomList = [...bedroomValues]
    const statusList = [...statusValues]

    let filterType = []
    let filterBed = []
    let filterStatus = []

    if (page) {
      // filtering type based on project
      filterData?.propertyTypes.map((list) =>
        projectList?.map((data) => {
          if (data?.propertY_ID === list?.customeField) {
            type.push(list)
          }
        })
      )

      // checking selected type is within selected project
      removeDuplicates(type)?.map((list) =>
        typeList?.map((data) => {
          if (data === list?.name) {
            filterType.push(data)
          }
        })
      )

      // assigning values based on project and type match
      if (filterType?.length === 0) {
        setPropertyTypeValues([])
      } else {
        setPropertyTypeValues(filterType)
      }

      // filtering bed based on project
      filterData?.noOfBedrooms.map((list) =>
        projectList.map((data) => {
          if (data?.propertY_ID === list?.customeField) {
            bedroom.push(list)
          }
        })
      )

      // checking selected bed is within selected project
      removeDuplicates(bedroom)?.map((list) =>
        bedroomList?.map((data) => {
          if (data === list?.name) {
            filterBed.push(data)
          }
        })
      )

      // assigning values based on project and bed match
      if (filterBed?.length === 0) {
        setBedroomValues([])
      } else {
        setBedroomValues(filterBed)
      }
    }

    // filtering status based on project
    if (page) {
      filterData?.unitStatuses.map((list) =>
        projectList.map((data) => {
          if (data?.propertY_ID === list?.customeField) {
            status.push(list)
          }
        })
      )
    } else {
      filterData?.projectStatuses.map((list) =>
        projectList.map((data) => {
          if (data?.propertY_ID === list?.customeField) {
            status.push(list)
          }
        })
      )
    }

    // checking selected status is within selected project
    removeDuplicates(status)?.map((list) =>
      statusList?.map((data) => {
        if (data === list?.name) {
          filterStatus.push(data)
        }
      })
    )

    // assigning values based on project and status match
    if (filterStatus?.length === 0) {
      setStatusValues([])
    } else {
      setStatusValues(filterStatus)
    }

    setPropertyTypeDetails(removeDuplicates(type))
    setBedroomDetails(removeDuplicates(bedroom))
    setStatusDetails(removeDuplicates(status))
  }
}

/**
 * Manages the filtering functionality for the Salesforce project.
 * This function handles the logic for applying filters to project data.
 * Author: Fawwad Khan
 * Date: March 13, 2024
 */
export const SFProjectFilterManager = async (
  projectValues,
  projectDetails,
  userData,
  filterData,
  page,
  propertyTypeValues,
  bedroomValues,
  statusValues,
  setPropertyTypeDetails,
  setBedroomDetails,
  setStatusDetails,
  setPropertyTypeValues,
  setBedroomValues,
  setStatusValues,
  notification
) => {
  const projectList = projectValues?.length > 0 ? projectValues : projectDetails
  if (projectList?.length) {
    const type = []
    const bedroom = []
    const status = []
    const typeList = [...propertyTypeValues]
    const bedroomList = [...bedroomValues]
    const statusList = [...statusValues]
    let filterType = []
    let filterBed = []
    let filterStatus = []

    if (page) {
      const projectList =
        projectValues?.length > 0 ? projectValues : projectDetails
      const projectCodes = projectList
        ?.map((data) => data?.projectCode)
        .join(',')
      try {
        const retrieveUnitData = await retrieveUnitDataByprojectids(
          projectCodes,
          notification
        )

        // Transform and update bedroom details
        const transformedapartmentType = transformData(
          retrieveUnitData,
          'apartmentType'
        )
        setBedroomDetails(removeDuplicates(transformedapartmentType))

        // Transform and update property type details
        const transformeunitType = transformData(retrieveUnitData, 'unitType')
        setPropertyTypeDetails(removeDuplicates(transformeunitType))

        // Transform and update status details
        const transformeunitUnitStatus = transformData(
          retrieveUnitData,
          'inventoryStatus'
        )
        setStatusDetails(removeDuplicates(transformeunitUnitStatus))
      } catch (error) {
        console.error('Error retrieving unit data:', error)
      }
    } else {
      const projectListArray = Object.values(projectList)
      const transformeunitUnitStatus = transformData(
        projectListArray,
        'propertyStatus'
      )
      setStatusDetails(removeDuplicates(transformeunitUnitStatus))
    }
  }
}
