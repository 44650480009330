import { apiServiceRequest } from "Utils/axiosInstance";
import { getUserToken } from "Utils/tokenProvider";
import { projectDetails } from "./projectService";

export const syncCustomer = async (setLoading, setData, notification, portalID, setProjectDetailsLoading, setDetails, headerPayload, bookingFailedPopup,
  setBookingFailedPopup) => {
  const data = { portalBookingId: Number(portalID) };
  setLoading(true);
  const res = await apiServiceRequest({
    url: "/sync-customers",
    headers: { Authorization: `Bearer ${await getUserToken(notification)}`, payload: headerPayload },
    data,
    method: "put",
    notify: notification
  })
  if (res?.code === "success") {
    localStorage.setItem("bookingId", (res?.data?.bookingId));
    localStorage.setItem("unit-reservation", JSON.stringify(res?.data?.units?.map((unit) => { return { unit_no: unit?.unit_no, reservation_id: unit?.oracle_reservation_id } })));
    projectDetails(setProjectDetailsLoading, setDetails, notification, res?.data?.units[0]?.project_id);
    setData(res?.data);
  }
  else {
    if (res.message.includes("booking time")) {
      if (!bookingFailedPopup) setBookingFailedPopup(true);
    } else {
      notification(res.message, { type: "error" });
    }
  }
  setLoading(false);
}

const attachTokenProofsService = async (formData,
  setAttachTokenProofLoading,
  notification,
  navigate, headerPayload, units, project_id, bookingFailedPopup,
  setBookingFailedPopup) => {
  setAttachTokenProofLoading(true);

  const res = await apiServiceRequest({
    url: "/attach-token-proofs",
    headers: { Authorization: `Bearer ${await getUserToken(notification)}`, "Content-Type": "multipart/form-data", payload: headerPayload },
    method: "post",
    data: formData,
    notify: notification
  })

  if (res?.code === "success") {
    navigate("/reservation-successfull", { state: { units, project_id } })
    sessionStorage.setItem("formStatus", "/reservation-successfull")
  }
  else {
    if (res.message.includes("booking time")) {
      if (!bookingFailedPopup) setBookingFailedPopup(true);
    } else {
      notification(res.message, { type: "error" });
    }
  }
  setAttachTokenProofLoading(false);
}

export default attachTokenProofsService;