import React from "react";
import { Typography } from "@mui/material";

const ErrorComponent = ({
    className,
    errors
}) => {

    return (
        <Typography className={`${className} date-error`} style={{ color: "#EF4349" }}>
            {errors}
        </Typography>
    );
};

export default ErrorComponent;
