import React, { useState, useEffect } from "react";
import {
  useList,
  ListContextProvider,
  Datagrid,
  TextField,
  useTranslate,
  useStoreContext,
} from "react-admin";
import {
  Grid,
  Container,
  Button,
  Typography,
  useMediaQuery
} from "@mui/material";
import Header from "Components/Header";
import styles from "./styles.module.scss";
import FilterSearch from "Pages/FilterSection/FilterSearch";
import Loader from "Components/Loader";
import { getLeadList, getSalesperson } from "Services/leadService";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useLocation } from "react-router-dom";
import BackButton from "Components/BackButton";
import CheckboxGrid from "./CheckboxGrid";
import createBookingId, { blockUnits } from "Services/createBookingId";

const ChooseContacts = ({ notification, userLoading, userData }) => {
  const [contactList, setContactList] = useState([]);
  const [bookingIdLoading, setBookingIdLoading] = useState(false);
  const [contactLoading, setContactLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [showSelectContact, setShowSelectContact] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [filterList, setFilterList] = useState([]);
  const [salesPersonList, setSalesPersonList] = useState([]);
  const [infiniteLoading, setInfintieLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const matches = useMediaQuery("(max-width:800px)");
  const translate = useTranslate();
  const navigate = useNavigate();
  const store = useStoreContext()
  const location = useLocation();
  const param = new URLSearchParams(location?.search);
  const params = Object.fromEntries(param.entries());
  const customerType = localStorage.getItem("customerType") === "1" ? "Individual" : "Company";
  const bookingState = JSON.parse(localStorage.getItem("bookingState")) || null;
  const units = location?.state?.units || bookingState?.units;
  const project_id = location?.state?.project_id || bookingState?.project_id;
  const state = location?.state || bookingState;
  const listContext = useList({ data: contactList });
  const lock_id = JSON.parse(localStorage.getItem("lockIds"));
  const previous_url = localStorage.getItem("previous_url") || null;
  const portal_booking_id = localStorage.getItem("portalBookingId");
  const formStatus = sessionStorage.getItem("formStatus") || null;
  const editCustomer = JSON.parse(localStorage.getItem("editCustomer")) || false;

  useEffect(() => {
    if(!previous_url && !formStatus) {
      navigate("/home");
    }

    if(!bookingState && state && !formStatus) {
      localStorage.setItem("bookingState", JSON.stringify(state));
    }

    if(portal_booking_id && formStatus) {
      navigate(formStatus, { state: { units, project_id } });
    } else {
      store.removeItem("ownership");
      // localStorage.removeItem("tabs");
      localStorage.removeItem("companyId");
    }

    leadAPiCall();

    if (!matches) {
      document.body.classList.add(
        "profile-page"
      );
      return () => {
        document.body.classList.remove(
          "profile-page"
        );
      };
    }
  }, []); 

  const leadAPiCall = async () => {
    const salesList = await getSalesperson("", setSalesPersonList, notification);
    leadDetails(setContactLoading, 0, params, setFilterList, salesList);
  }

  const leadDetails = (setLoadingData, page, queryData, setFilter, salesList, loadType) => {
    getLeadList(
      setLoadingData,
      contactList,
      setContactList,
      setTotalCount,
      setCount,
      page,
      notification,
      queryData,
      setFilter,
      salesList,
      loadType,
      "choose-contact"
    );
  }

  const postRowStyle = (record, index) => ({
    backgroundColor: (selected?.length > 0 ?
      selected.find(data => data?.id === record?.id)
      : selected?.id === record?.id) ? "#EEEFEA" : ""
  });

  const onSelect = (e, record) => {
    if (e) e.stopPropagation();
    if (customerType === "Individual") {
      const selectedContacts = [...selected];
      let records = selectedContacts?.length > 0 ? selectedContacts : [];
      const index = records.findIndex(list => list?.id === record?.id);

      if (index > -1) {
        records.splice(index, 1);
        records.map((list, idx) => list.value = idx + 1);
      } else {
        records.push({
          value: records?.length + 1,
          id: record?.id,
          leadType: record?.status,
          name: `${record?.first?.charAt(0)?.toUpperCase()}${record?.last?.charAt(0)?.toUpperCase()}`,
          status: true
        })
      }
      localStorage.setItem("tabs", JSON.stringify(records));
      setSelected(records);
    } else {
      record = {
        value: 1,
        id: record?.id,
        leadType: record?.status,
        name: record?.company_name?.slice(0, 2).toUpperCase(),
        status: true
      }
      localStorage.setItem("tabs", JSON.stringify([record]));
      setSelected(record);
    }
  }

  const getLeadListData = async () => {
    if (!infiniteLoading) {
      if (contactList && contactList?.length < count) {
        leadDetails(setInfintieLoading, page + 1, params, "", "", "infiniteLoad");
        setPage(page + 1);
      } else {
        setHasMore(false);
      }
    }
  }

  const onContinueClick = () => {
    
    if (selected?.length > 0 || Object.keys(selected)?.length > 0) {
      const data = {
        project_id: project_id, unit_ids: units?.map(unit => unit?.unitCode).join(","), lock_id: lock_id?.join(",")
      }
      createBookingId(setBookingIdLoading, data, notification, handleSubmit);
      localStorage.setItem("previous_url", "/choose-contacts");
    } else {
      notification("ra.validation.chooseContacts", { type: "error" });
    }
  }

  const handleSubmit = () => {
    navigate("/booking-reservation-form", { state: { units, project_id } })
  }

  const releaseBlockUnits = (url) => {
    const apiRequestForBlock = {
      project_id: project_id,
      unit_ids: units?.map((unit) => unit?.unitCode)?.join(","),
      is_initial: 0,
    }
    if (localStorage.getItem("portalBookingId")) {
      apiRequestForBlock.portal_booking_id = portal_booking_id
    }
    blockUnits(setBookingIdLoading, apiRequestForBlock, notification, "", "", "", "", url, navigate, "", units);
  }

  useEffect(() => {
    let bookingState = JSON.parse(localStorage.getItem("bookingState")) || state;
    if(!formStatus && previous_url) {
      navigate(location.href, { state: bookingState });
      window.onpopstate = function (e) {
        e.preventDefault();
        releaseBlockUnits("/review-units");
        window.history.go(1);
      }
    }
  }, [])

  return (
    <>
      {(userLoading || contactLoading || bookingIdLoading) && <Loader />}
      <Header
        path="My Bookings"
        picture={userData?.profile}
        pictureLoading={userLoading}
        notification={notification}
        userData={userData}
        releaseBlockUnits={!editCustomer ? releaseBlockUnits : null}
        pagePath={!editCustomer ? "form-booking" : ""}
      />
      <Typography className="for-desktop">
        <BackButton
          releaseBlockUnits={() => releaseBlockUnits("/review-units")}
        />
      </Typography>
      <Container className="company-container my-contacts-section contact-container-section">
        <Container className="my-contacts-panel contact-heightwrapper filter-choose-contact">
          <Grid className="form-panel contact-panel" container>
            <Grid className="contact-panel-heading choose-contact-header">
              <Typography variant="h2">{translate("ra.pageTitle.chooseContact")}<span>({totalCount})</span></Typography>
            </Grid>
            <Grid className={`contact-form-section ${filterList?.length > 0 ? "contact-padding-adjust" : ""}`}>
              <FilterSearch
                filterList={filterList}
                setFilterList={setFilterList}
                className="filter-autocomplete"
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                setShowSelectContact={setShowSelectContact}
                salesPersonList={salesPersonList}
                setContactLoading={setContactLoading}
                userData={userData}
                detailsApi={leadDetails}
                setPage={setPage}
                setHasMore={setHasMore}
                params={params}
                path="choose-contacts"
                pageName="choose-contacts"
              />
            </Grid>
            {!contactLoading &&
              <Grid className="unit-list-panel contacts-table-view choose-contact-table">
                {(filterList?.length > 0 || searchValue) && count > 0 &&
                  <Typography variant="h3" className="search-result-no" component="h3">
                    {count} {translate("ra.fieldName.contactsFound")}
                  </Typography>
                }
                <InfiniteScroll
                  dataLength={contactList && contactList?.length}
                  next={getLeadListData}
                  scrollThreshold={0.7}
                  hasMore={hasMore}
                  loader={infiniteLoading && <div className="my-team-loader">{translate("ra.fieldName.loadMore")}</div>}
                >
                  {contactList?.length > 0 ?
                    <ListContextProvider value={listContext}>
                      <Datagrid bulkActionButtons={false} className="hover-unit-class" setSort={false}
                        rowStyle={postRowStyle} rowClick={(id, resource, record) => onSelect("", record)}
                      >
                        <TextField label={translate("ra.fieldName.name")} source={customerType === "Company" ? "company_name" : "name"} />
                        <TextField label={translate("ra.fieldName.contactDetails")} source="mobile" />
                        <TextField label={translate("ra.fieldName.status")} source="status" />
                        <TextField label={translate("ra.fieldName.prospectType")} source="leadType" />
                        <CheckboxGrid tabs={selected} customerType={customerType} onSelect={onSelect} label={translate("ra.fieldName.actions")} source="actions" />
                      </Datagrid>
                    </ListContextProvider>
                    :
                    <Grid className="no-data-section">{translate("ra.noData.contact")}</Grid>
                  }
                </InfiniteScroll>
              </Grid>
            }
            {!contactLoading && contactList?.length > 0 &&
              <Grid container className="company-details-button contact-page-button-panel align-right" spacing={0}>
                <Button 
                  variant="outlined" 
                  className="mobile-only" 
                  onClick={() => releaseBlockUnits("/review-units")}
                >
                  {translate("ra.button.back")}
                </Button>
                <Button variant="contained" onClick={() => onContinueClick()}>
                  {translate("ra.button.continue")}
                </Button>
              </Grid>
            }
          </Grid>
        </Container>
      </Container>
    </>
  );
};

export default ChooseContacts;