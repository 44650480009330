import React from 'react'
import { Grid } from "@mui/material";
import { formatNumbers } from 'Utils/thousandSeperators';

export const OfflinePayment = ({ bankDetailsAndLink, sum, translate, offline, userData, units }) => {

    return (
        <Grid ref={offline} className="account-details-popup-panel">
            <Grid id="offline-bank-details" className="account-details-popup-token">
                <p>{translate("ra.content.tokenAmount")} <span> {formatNumbers(sum)} {units[0]?.currency}</span></p>
                <p>{translate("ra.content.accoutHolderName")} <span>{bankDetailsAndLink?.account_name || "-"}</span> </p>
                <p>{translate("ra.content.bankName")} <span>{bankDetailsAndLink?.bank_name || "-"}</span></p>
                <p>{translate("ra.content.accountNumber")} <span>{bankDetailsAndLink?.account_no || "-"}</span></p>
                <p>{translate("ra.content.IbanNumber")} <span>{bankDetailsAndLink?.iban_no || "-"}</span></p>
                <p>{translate("ra.content.SwiftCode")} <span>{bankDetailsAndLink?.swift_code || "-"}</span></p>
            </Grid>
            <Grid className="account-details-popup-token cheque-payable">
                <p>{translate("ra.content.chequePayable")} <span>{bankDetailsAndLink?.account_name || "-"}</span></p>
            </Grid>
        </Grid>
    )
}
