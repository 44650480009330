import React from 'react'
import { Typography, Grid, Button } from '@mui/material'

const MultipleButtonSelect = ({
  title,
  gridClass,
  arrayData,
  clickHandler,
  state,
  setState,
  name,
}) => {
  return (
    <>
      <Typography variant="subtitle1">{title}</Typography>
      <Grid className={`filter-button-info ${gridClass}`}>
        {arrayData?.map((list, index) => {
          if (list?.name) {
            return (
              <Button
                key={index}
                value={list?.id}
                onClick={(e) => clickHandler(e.target.value, state, setState)}
                variant={state?.includes(list?.id) ? 'contained' : 'outlined'}
                sx={{
                  textTransform: 'none',
                }}
              >
                {list?.name}
              </Button>
            )
          }
        })}
      </Grid>
    </>
  )
}

export default MultipleButtonSelect
