import React, { useState } from 'react'
import { Accordion, AccordionSummary, Typography } from '@material-ui/core'
import ExpandMoreIcon from "@mui/icons-material/KeyboardArrowDown";
import NewAccordionContent from './NewAccordionContent';

const NewAccordion = ({expanded,setExpanded,index,val,data,setData,setunitPercentageObj,percentageObj}) => { 
   
    const handleChange=()=>{
        if(expanded==`panel${index}`) return setExpanded("");
        setExpanded(`panel${index}`);
    } 
  return (
    <>
        <Accordion
        className="assign-accordian"
        expanded={expanded === `panel${index}`}
        onChange={()=>{handleChange()}}
        >
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        style={{maxHeight:"46px"}}
        >
        <Typography sx={{ color: "text.secondary" }}>{val?.unitCode}</Typography>
        </AccordionSummary>
            <NewAccordionContent
            data={data} defaultValue={0} isChecked={true} max={100} setData={setData} percentageObj={percentageObj} setunitPercentageObj={setunitPercentageObj} unitCode={val?.unitCode}/>
        </Accordion>
    </>
  )
}

export default NewAccordion