import { apiServiceRequest } from "Utils/axiosInstance";
import { baseURL } from "Utils/baseUrl";
import { getUserToken } from "Utils/tokenProvider";

//currency Service
const currency = async (setCurrencyList, setCurrencyListLoading, notify) => {
  setCurrencyListLoading(true);
  const res = await apiServiceRequest({
    url: `/salesforce-currencies`,
    headers: { Authorization: `Bearer ${await getUserToken(notify)}` },
    notify: notify,
  });
  if (res?.code === 'success') {
    setCurrencyList(res?.data);
  }
  setCurrencyListLoading(false);
};

export default currency;
