import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import ImageGallery from "Components/ImageGallery";

const GalleryDialog = ({ openGallery, setOpenGallery, images }) => {
  return (
    <Dialog className="popup-slider" open={openGallery}>
      <DialogTitle className="close-button-section">
        <IconButton
          aria-label="close"
          className="cross-button"
          onClick={() => setOpenGallery(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ImageGallery images={images} />
      </DialogContent>
    </Dialog>
  );
};

export default GalleryDialog;
