import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTranslate } from "react-admin";

export default function CustomNotify({
  textMsg,
  setTextMsg
}) {
  const [open, setOpen] = useState(true);
  const translate = useTranslate();

  const handleClose = () => {
    setOpen(false);
    setTextMsg(null);
  };

  useEffect(() => {
    if(textMsg?.text) {
      setOpen(true)
    } else {
      setTextMsg(null);
    }
  }, [textMsg])

  return (
    textMsg?.text && 
    <Snackbar 
      open={open} 
      autoHideDuration={5000} 
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={handleClose}
      sx={{ zIndex: 100000}}
    >
      <MuiAlert 
        className={textMsg?.type === "info" ? "info-color-notify" : ""}
        onClose={handleClose} 
        severity={textMsg?.type} 
        sx={{ width: '100%' }} 
        elevation={6} 
        variant="filled" 
      >
        {translate(textMsg?.text)}
      </MuiAlert>
    </Snackbar>
  );
}