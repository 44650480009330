import React,{useState} from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import styles from "./WishlistdetailsImage.scss";
import ImageSlider from "Components/ImageSlider";
import { capitalFirstLetterOfWord } from "Utils/commonFunctions";
import GalleryDialog from "Components/GalleryDialog";

const WishlistdetailsImage = ({
  type,
  unitNumber,
  imageType,
  images,
  city,
  country,
  projectName,
  unitName,
  wishlist,
  wishlistHandler,
  handleShare,
  documents,
  currency,
  price,
  showArrows,
  translate
}) => {
  const [openGallery, setOpenGallery] = useState(false);

  const goToLocation = () => {
    const element = document.getElementById("location");
    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <>
      <Grid className="project-info-panel no-paddin">
        <ImageSlider
          showSlideNumber={true}
          imageType={imageType}
          imageList={images}
          type="unit"
          handleShare={handleShare}
          wishlist={wishlist}
          wishlistHandler={wishlistHandler}
          imageClass="card-minheight-600"
          documents={documents}
          showArrows={showArrows}
          clickhandler={() => setOpenGallery(true)}
        />
        <Grid className="project-info">
          <Typography className="separator-text" variant="h3" component="h3">
            <span>{unitNumber}</span>
            <span className="seperator">|</span>
            <span>{capitalFirstLetterOfWord(type)}</span>
          </Typography>
          <Typography variant="h2" className="main-heading">
            {unitName || projectName}
          </Typography>
          <Grid className="project-info-section">
            <Typography variant="body1" className="location-text">
              {city ? `${city}, ${country}` : `${country}`}
            </Typography>
            <Typography className="icon-with-text" variant="p">
              <Button className="location-link" variant="text" onClick={() => goToLocation()}>
                <i className="iconwasalt icon-map-marker"></i>
                <span>{translate("ra.fieldName.viewLocation")}</span> <i className="iconwasalt icon-chevron-right"></i>
              </Button>
            </Typography>
          </Grid>
          <Typography className="price-text" component="h3">
            {currency}
            <span>
              &nbsp; {new Intl.NumberFormat("en-US").format(Math.round(price))}
            </span>
          </Typography>
        </Grid>
        {openGallery && (
          <GalleryDialog
            openGallery={openGallery}
            setOpenGallery={setOpenGallery}
            images={images}
          />
        )}
      </Grid>
    </>
  );
};

export default WishlistdetailsImage;
