import { currencyStatus } from 'Utils/currencyCheck'

export const objectKeyRename = (item, keyname, rename) => {
  let objectValue = Object.fromEntries(
    Object.entries(item).filter(([key]) => key.includes(keyname))
  )

  if (rename) {
    Object.keys(objectValue).forEach(function (key) {
      let newkey = key.replace(keyname, keyname?.toLowerCase())
      objectValue[newkey] = objectValue[key]
      delete objectValue[key]
    })
  }

  return objectValue
}

export const unitApiProjectDetailsModificationData = (project) => {
  // Check if project exists
  if (!project) {
    return null
  }

  const modifiedProject = {
    projecT_NAME: project.reraProjectName,
    propertyStatus: project.propertyStatus,
    projectCurrency: project.projectCurrency,
    city: project.projectCity,
    location: project.country,
    startingArea_SQFT: project.startingAreasqft,
    startingArea_SQM: project.startingAreasqm,
    images: project.ProjectImage,
    ...project,
  }

  return modifiedProject
}
