import React from "react";
import { Typography } from "@mui/material";
import styles from "./ContactDetails.module.scss";

const ContactDetails = ({ email, phone, phone_title, chat, country }) => {
  return (
    <Typography variant="div" className={styles.containerClass}>
      {country && <Typography variant="h4">{country}</Typography>}
      {email && (
        <Typography variant="div" className={styles.subContainerClass}>
          <i className="iconwasalt icon-email" ></i>
          <a href={"mailto:" + email} target="_blank" rel="noopener noreferrer">
            <span>{email}</span>
          </a>
        </Typography>
      )}

      {phone && (
        <Typography variant="div" className={styles.subContainerClass}>
          <i className="iconwasalt icon-call-outline" ></i>
          <a href={"tel:" + phone}>
            <span>
              {phone}
              {phone_title && ` (${phone_title})`}
            </span>
          </a>
        </Typography>
      )}

      {chat && (
        <Typography
          variant="div"
          className={`${styles.subContainerClass} display-none`}
        >
          <img src="/assets/images/Chat-Bubble.svg" alt="chat" />
          <span>{chat}</span>
        </Typography>
      )}
    </Typography>
  );
};

export default ContactDetails;
