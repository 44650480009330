import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { Grid, Button } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import adjustPaymentToken from "Services/adjustPaymentTokenService";
import Loader from 'Components/Loader';
import { AccordionSlider } from './AccordionSlider'
import { formatNumbers } from 'Utils/thousandSeperators';

const UnitsPayment = ({ item, userData, notification, data, setSliderValue, index, headerPayload,  bookingFailedPopup,
    setBookingFailedPopup }) => {

    const translate = useTranslate();
    const [showAdjustTokenPayment, setshowAdjustTokenPayment] = useState(false);
    const [adjustPaymentLoading, setAdjustPaymentLoading] = useState(false);
    const [sliderItemValue, setSliderItemValue] = useState(item?.slider || JSON.parse(item?.max));

    const apiRequestData =
        item?.customer_unit_id?.map((customer) => {
            return {
                id: customer,
                project_id: item?.project_id,
                unit_id: item?.unit_id,
                paid_token_amount: `${sliderItemValue}`
            }
        })

    const changeAmountSlider = () => {
        setSliderValue(index, sliderItemValue)
    }
    
    const handleSubmit = () => {
        adjustPaymentToken(setAdjustPaymentLoading, apiRequestData, notification, setshowAdjustTokenPayment, JSON.stringify(headerPayload), changeAmountSlider,  bookingFailedPopup,
        setBookingFailedPopup);
    };

    return (
        <>
        {adjustPaymentLoading && <Loader />}
            <Grid className="token-payment-unit">
                <p>{item?.unitNo}</p>
                <Button
                    variant="success"
                    style={{ backgroundColor: "#dfe8ef" }}
                    onClick={() => {
                        setshowAdjustTokenPayment(true)
                    }}
                >
                    {item?.currency}  {formatNumbers(item?.slider) || 0} <i className="iconwasalt icon-edit-outline"></i>
                </Button>
            </Grid>
            {showAdjustTokenPayment && (
                <Dialog
                    open={showAdjustTokenPayment}
                    className={`new-popup-box add-contact-popup popup-share account-details-popup assign-persentage-popup popup-bottom-align`}
                >
                    <DialogTitle className="share-heading">
                        <span>{translate("ra.pageTitle.adjustTokenPaymnet")}</span>
                        <p className="paragraph-style">{item?.unitNo}</p>
                        <i
                            className="iconwasalt icon-close cross-button"
                            onClick={() => setshowAdjustTokenPayment(false)}
                        />
                    </DialogTitle>
                    <DialogContent className="token-payment-popup-info">
                        <AccordionSlider 
                            defaultValue={item?.slider || JSON.parse(item?.max)} 
                            setSliderItemValue={setSliderItemValue} 
                            max={JSON.parse(item?.max)} 
                            min={JSON.parse(item?.min)} 
                            currency={item?.currency} 
                        />
                    </DialogContent>
                    <DialogActions className="padding-0 button-panel token-payment-tab-buttons button-payment" >
                        <Button variant="outlined" onClick={() => setshowAdjustTokenPayment(false)} className="mobile-only">{translate("ra.button.back")}</Button>
                        <Button variant="contained" onClick={() => handleSubmit()}>{translate("ra.button.save")}</Button>
                    </DialogActions>
                </Dialog>
            )}

        </>
    )
}

export default UnitsPayment