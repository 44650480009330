
import BackButton from "Components/BackButton";
import {
  Grid,
  Button,
  Typography
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "Components/Header";
import React, { useState, useEffect } from "react";
import userDetails from "Services/userDetailsServices";
import { useForm } from "react-hook-form";
import { useTranslate } from "react-admin";
import { Container } from "@material-ui/core";
import UploadDocumentSection from "Components/Common/DocumentSection/UploadDocumentSection";
import uploadIDocumentsnOracle from "Services/uploadIDocumentsnOracle";
import Loader from "Components/Loader";

export const UploadSignedReservationForm = ({ notification, bookingFailedPopup, setBookingFailedPopup }) => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [uploadDocnOracleLoading, setUploadDocnOracleLoading] = useState(null);
  const unit_reservation = JSON.parse(localStorage?.getItem("unit-reservation"));
  let formStatus = sessionStorage.getItem("formStatus") || null;
  const bookingState = JSON.parse(localStorage.getItem("bookingState")) || null;
  const translate = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || bookingState;
  
  useEffect(() => {
    if(!formStatus) {
      navigate("/home");
    }

    if(formStatus && formStatus !== "/upload-reservation") {
      navigate(formStatus, { state });
    }

    userDetails(setLoading, setUserData, "", notification);
    document.body.classList.add(
      'profile-page'
    );
    document.body.classList.add(
      'reservation-successfull'
    );
    document.body.classList.add(
      'sign-reservtion'
    );
    return () => {
      document.body.classList.remove(
        'profile-page'
      );
      document.body.classList.remove(
        'reservation-successfull'
      );
      document.body.classList.remove(
        'sign-reservtion'
      );
    };
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    resetField,
    formState: { errors, dirtyFields }
  } = useForm();

  const onSubmit = async (data) => {
    let formData = new FormData();

    Object.values(data)?.map((file) => formData.append("file", file[0]))

    const payload = Object.keys(data)?.map((keys) => {
      let res_id = unit_reservation.find(list => list?.unit_no === keys)?.reservation_id;

      return { reservation_id: Number(res_id), file_name: data[keys][0]?.name }
    })

    formData.append("payload", JSON.stringify(payload))
    uploadIDocumentsnOracle(setUploadDocnOracleLoading, formData, notification, navigate, bookingFailedPopup, setBookingFailedPopup, state)
  };

  const backNavigation = () => {
    navigate("/download-reservation-form", { state });
    sessionStorage.setItem("formStatus", "/download-reservation-form");
  }

  return (
    <>
      {(uploadDocnOracleLoading || loading) && <Loader />}
      <Header
        path="My Bookings"
        picture={userData?.profile}
        pictureLoading={loading}
        notification={notification}
      />
      <BackButton close={backNavigation}/>
      <Container className="lead-panel-section invoice-submitted-panel reservation-form-submitted mt-0">
        <Container className="company-details">
          <Grid className="form-panel profile-form-panel add-team-panel invoice-submitted" sx={{ width: "100%" }}>
            <Grid container spacing={0} className="download-upload-section">
              <Typography variant="h3">{translate("ra.fieldName.uploadForm")}</Typography>
              <Typography
                component="p"
                className={`uploaded-info ${Object.keys(dirtyFields)?.length === unit_reservation?.length && "success"}`}
              >
                <span className="uploaded-doc-section-wrapper">
                  {Object.keys(dirtyFields)?.length}/{unit_reservation?.length} {translate("ra.fieldName.uploaded")}
                  {Object.keys(dirtyFields)?.length === unit_reservation?.length &&
                    <i className="iconwasalt green-text icon-tick-outline " />
                  }
                </span>
              </Typography>
              <Grid container spacing={0} className="download-upload-info upload-section-wrapper">
                {unit_reservation?.map((item, index) => {
                  const watchsignedReservationForm = watch(item?.unit_no, []);
                  return (
                    <Grid container className="upload-section" key={index}>
                      <Typography component="p" className="uploaded-info">{item?.unit_no}</Typography>
                      <UploadDocumentSection
                        watchDoc={watchsignedReservationForm}
                        fieldName={item?.unit_no}
                        register={register}
                        hide="true"
                        errorMsg={translate("ra.validation.signedReservationUpload")}
                        resetField={resetField}
                        errors={errors?.[item?.unit_no]}
                        notification={notification}
                        base64={true}
                        fileSizeInKb={true}
                      />
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
            <Grid container spacing={0} className="invoice-success-button">
              <Button
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                className="new-color-button invoice-success-button"
              >
                {translate("ra.button.submit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
};