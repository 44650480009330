import React from "react";
import {
  Button,
} from "@mui/material";
import Styles from "./styles.module.scss";

const ButtonComponent = ({type = "text", title = null, title1 = null, container_class, sx, onClick, src = null, alt_name, children}) => {

  return (
    <Button 
        variant={type}
        onClick={onClick}
        sx={sx}
        className={container_class}
    >
        {title1}
        {src && <img src={src} alt={alt_name} />}
        {title}

        {children}
    </Button>
  );
};

export default ButtonComponent;
