import React from "react";
import { useRecordContext } from "react-admin";

const ContactField = ({ source }) => {
    const record = useRecordContext();
    if (!record) return null;

   return (
      <ul className="table-info-section">
        <li className="team-text-transform font-bold">+({record?.country_code}) {record?.mobile}</li>
        <li className="team-text-transform">{record?.email}</li>
      </ul>
    );
  };
  
  export default ContactField;