import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const Password = ({
  id,
  state,
  message,
  register,
  errors,
  watch,
  passwordPattern,
  validPassword,
  comparePassword,
}) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);

  const handleClickShowPassword = () => setShowCurrentPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <TextField
        id={id}
        variant="outlined"
        type={showCurrentPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showCurrentPassword ? (
                  <VisibilityOffOutlinedIcon />
                ) : (
                  <VisibilityOutlinedIcon />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...register(state, {
          required: message,
          validate: (val) => {
            if (comparePassword) {
              if (watch("password") !== val) {
                return "Passwords do no match";
              }
            }
          },
          pattern: {
            value: passwordPattern,
            message: validPassword,
          },
        })}
        error={Boolean(errors?.[state])}
        helperText={errors?.[state]?.message}
      ></TextField>
    </>
  );
};

export default Password;
