import React, { useState } from "react";
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel
} from "@mui/material";
import { useTranslate } from "react-admin";
import ErrorComponent from "Components/Common/ErrorComponent";

const ContactSelection = ({
    leadType,
    setLeadType,
    error,
    setError
}) => {
  const translate = useTranslate();

  const handleChange = (e) => {
    setLeadType(e.target.value);
    setError(null);
  };

  return (
    <>
        <FormControl>
            <FormLabel 
                id="radio-button-group"
            >
                {translate("ra.fieldName.selectProspectCustomer")}
            </FormLabel>
            <RadioGroup
                aria-labelledby="radio-button-group"
                name="controlled-radio-buttons-group"
                value={leadType}
                onChange={handleChange}
            >
                <FormControlLabel 
                    value="individual" 
                    control={<Radio />} 
                    label={translate("ra.fieldName.individual")} 
                />
                <FormControlLabel 
                    value="company" 
                    control={<Radio />} 
                    label={translate("ra.fieldName.company")} 
                />
            </RadioGroup>
        </FormControl>
        {error &&
            <ErrorComponent className="contact-error" errors={error} />
        }
    </>
  );
}

export default ContactSelection;