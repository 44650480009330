const unlock_status = ["INPROCESS"]; 

 const isInProcess = (os) => {
  
    if (os) {
      return unlock_status.includes(os) ? true : false;
    }
    return false;
  };

  export default isInProcess